import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Directive({
  selector: '[appSanitizeInput]'
})
export class SanitizeInputDirective {

  constructor(
    private sanitizer: DomSanitizer,
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  @HostListener('input') onInput() {
    const value = this.el.nativeElement.value;
    this.updateValue(this.sanitizeInput(value));
  }

  private sanitizeInput(input: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(input);
  }

  private updateValue(value: SafeHtml) {
    this.renderer.setProperty(this.el.nativeElement, 'value', value);
  }
}
