import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { first, map, switchMap, take, tap } from 'rxjs/operators';

import { User } from '../models/user.model';

import { OauthTokenGuard } from './oauth-token.guard';

import { UserService } from '../services/user.service';
import { UserSessionService } from '../services/userSession.service';
import { TrackingService } from '../services/tracking.service';

@Injectable()
export class AnonymousGuard {
	public wasInitialized: boolean = false;

	constructor(
		private router: Router,
		private oauthTokenGuard: OauthTokenGuard,
		private userService: UserService,
		private userSessionService: UserSessionService,
		private trackingService: TrackingService
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		return this.oauthTokenGuard.canActivate(route, state).pipe(
			take(1),
			switchMap(() => this.isUserUnAuthenticated())
		);
	}

	private isUserUnAuthenticated(): Observable<boolean> | Promise<boolean> {
		return this.userService.user$.pipe(
			take(1),
			switchMap((currentUser: User) => {
				if (!currentUser && !this.wasInitialized) {
					this.wasInitialized = true;
					return this.userService.attemptLogin().pipe(
						tap((obj: BehaviorSubject<User>) => {
							if (obj && obj.subscribe) {
								obj.subscribe({
									next: (user) => {
										if (user) {
											this.trackingService.identifyUser(user.data).finally(() => {
												this.userSessionService.startUserSession('auto', user.data);
											});
										}
									},
								});
							} else if (obj && obj instanceof User) {
								this.trackingService.identifyUser(obj.data).finally(() => {
									this.userSessionService.startUserSession('auto', obj.data);
								});
							}
						})
					);
				}

				return of(currentUser);
			}),
			map((currentUser: User) => {
				if (!currentUser) {
					return true;
				}

				this.router.navigate([currentUser.defaultRoute]);
				return false;
			})
		);
	}
}
