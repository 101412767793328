import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { firstValueFrom, take } from 'rxjs';
import { Router } from '@angular/router';

import { RxjsUtils } from '../../../../../utils/rxjs';
import { ZocdocInfo } from '../../../../../models/zocdoc-data.model';
import {
	AppointmentFetch,
	AppointmentStatuses,
	AvailabilityData,
	SlotData,
} from '../../../../../models/appointment.model';
import { ZocdocInfosStoreService } from '../../../../../services/stores/zocdoc-infos-store/zocdoc-infos-store.service';
import { Provider } from '../../helpers/providers.helpers';

import { AppointmentConfirmationComponent } from './appointment-confirmation/appointment-confirmation.component';
import { UserService } from '../../../../../services/user.service';
import { AppointmentService } from '../../../../../services/appointment.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { T } from '@transifex/angular';
import { RescheduleAppointmentEvents } from 'src/app/models/tracking-events.model';
import { TrackingService } from 'src/app/services/tracking.service';
import { ProvidersSearchUrlsService } from 'src/app/services/providers-search/providers-search-urls.service';
import { UnleashService } from '../../../../../services/unleash.service';

export enum FlowSteps {
	zocdocAuth,
	availability,
	confirm,
	reschedule,
	submitted,
}

@Component({
	selector: 'app-provider-appointment',
	templateUrl: './provider-appointment.component.html',
	styleUrls: ['./provider-appointment.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ProviderAppointmentComponent implements OnInit {
	@Input()
	providerData: Provider;

	@Input()
	appointment: AppointmentFetch = null;

	@Output()
	closeSignal = new EventEmitter();

	@ViewChild(AppointmentConfirmationComponent)
	userForm!: AppointmentConfirmationComponent;

	@T('Ok')
	successAction: string;

	@T('Oh no, something went wrong, and you can’t request to reschedule this appointment. Please try again later.')
	errorMessage: string;

	public currentStep: FlowSteps;
	public isSelectionEdited: boolean = false;
	public zocdocData: ZocdocInfo;
	public isProcessing = false;
	public isSubmitting = false;
	public isUserFormValid = false;
	private subscriptionChoice: boolean;

	public availabilitySelection: AvailabilityData;
	public availableSlots;
	public selectedSlot: SlotData;

	public get flowSteps(): typeof FlowSteps {
		return FlowSteps;
	}

	public setAvailabilitySelection(data: AvailabilityData): void {
		this.availabilitySelection = data;
	}

	public setSelectedSlot(data: SlotData): void {
		this.selectedSlot = data;
	}

	constructor(
		private zocdocInfosStoreService: ZocdocInfosStoreService,
		private appointmentService: AppointmentService,
		private router: Router,
		private userService: UserService,
		private _snackBar: MatSnackBar,
		private trackingService: TrackingService,
		private providersSearchUrlsService: ProvidersSearchUrlsService,
		private unleashService: UnleashService,
	) {}


	async ngOnInit(): Promise<void> {
		this.getZocdocData();
		await this.getZocdocRefreshToken();
		this.currentStep = await this.getStepIfAuthenticated(FlowSteps.availability);
		if (this.appointment && this.appointment.status !== AppointmentStatuses.failed)
			this.currentStep = this.getStepIfAuthenticated(FlowSteps.reschedule);
	}

	private getStepIfAuthenticated(step) {
		if (!this.unleashService.isEnabled('use-zocdoc-client-auth')) {
			return step;
		}

		const expiresAt = localStorage.getItem('zocdoc_refresh_token_expires_at');

		if (expiresAt && new Date().getTime() < new Date(expiresAt).getTime()) {
				return step;
		}

		localStorage.removeItem('zocdoc_refresh_token_expires_at');
		return FlowSteps.zocdocAuth;
	}

	private getZocdocData() {
		this.isProcessing = true;
		this.zocdocInfosStoreService
			.get(this.providerData.npi)
			.pipe(RxjsUtils.isNotNil(), take(1))
			.subscribe({
				next: (data: ZocdocInfo) => {
					this.zocdocData = data;
					this.isProcessing = false;
				},
				error: (error) => {
					console.log(error);
				},
			});
	}

	private async getZocdocRefreshToken(): Promise<void> {
		const pkce_code = localStorage.getItem('pkce_code')
		const pkce_code_verifier = localStorage.getItem('pkce_code_verifier')
		if (pkce_code && pkce_code_verifier) {
			try {
				const payload = await firstValueFrom(
					this.appointmentService.getRefreshToken(pkce_code, pkce_code_verifier)
				);
				localStorage.setItem('zocdoc_refresh_token_expires_at', payload.expiresAt);
				localStorage.removeItem('pkce_code');
				localStorage.removeItem('pkce_code_verifier');
			} catch (error) {
				console.log(`getZocdocRefreshToken error: ${error}`);
			}
		}
	}

	public cancelSubmit() {
		this.isSubmitting = false;
	}

	public goToStep(step: FlowSteps) {
		if (step === FlowSteps.availability) {
			this.isSelectionEdited = true;
		}
		this.currentStep = step;
		this.isSubmitting = false;
		this.scrollToTop();
	}

	private scrollToTop(): void {
		document.getElementsByClassName('header-close')[0]?.scrollIntoView({
			behavior: 'auto',
			block: 'start',
		});
	}

	public submitSelectedSlot() {
		this.selectedSlot && this.goToStep(FlowSteps.confirm);
	}

	public onUserFormValid(valid: boolean) {
		this.isUserFormValid = valid;
	}

	public submitAppointmentRequest() {
		this.isSubmitting = true;
		this.userForm.onSubmitForm(this.userForm, this.isSelectionEdited, this.appointment);
	}

	public submitRescheduleRequest() {
		this.isSubmitting = true;
		this.appointmentService
			.rescheduleAppointment({
				appointmentId: this.appointment.appointmentId,
				date: this.selectedSlot.date,
			})
			.subscribe({
				next: () => {
					this.goToStep(FlowSteps.submitted);
					this.trackingService.trackClientEvent(RescheduleAppointmentEvents.ReschedulingRequested);

					this.isSubmitting = false;
				},
				error: (error: Error) => {
					console.log(error);
					this.isSubmitting = false;
					this.showErrorPopUp();
				},
			});
	}

	private showErrorPopUp(): void {
		const snackBarRef = this._snackBar.open(this.errorMessage, this.successAction, {
			duration: 5000,
			panelClass: 'snackbar-failure',
			verticalPosition: 'top',
		});
		snackBarRef.onAction();
	}

	public onClickDone() {
		if (this.currentStep === FlowSteps.confirm) {
			this.userService.updateAppointmentNotificationChoice(!!this.subscriptionChoice);
		}
		this.closeSignal.emit();
		this.router.navigate([this.providersSearchUrlsService.baseUrl]);
	}

	public onSubscriptionChanged(event): void {
		this.subscriptionChoice = event;
	}

	public onCloseZocdocAuth() {
		this.closeSignal.emit();
	}
}
