<!-- WARNING: Do NOT change ngClass to ngIF="isLoading" because
	iframe has to be in the DOM while loading in background -->
<app-mobile-action-bar></app-mobile-action-bar>

<div [ngClass]="{ 'loading-indicator-wrapper': (isLoadingEmitter$ | async), hidden: !(isLoadingEmitter$ | async) }">
	<app-loader size="large"></app-loader>
</div>

<div
	*ngIf="isElementInDom && isTalonServiceSearchAvailable"
	[ngClass]="{
		'talon-container': !(isLoadingEmitter$ | async),
		hidden: (isLoadingEmitter$ | async),
		'is-mobile-app': isMobileApp
	}"
>
	<iframe #iframe width="100%" height="100%"></iframe>
</div>

<div *ngIf="!isTalonServiceSearchAvailable" [ngClass]="{ 'no-access-container': !(isLoadingEmitter$ | async) }" d-flex>
	<img src="../../../../../assets/images/icons/carecost_talon_error.svg" alt="" />
	<div class="title">{{ 'We’re unable to display medical services at the moment' | translate }}</div>
	<div class="description">
		<UT
			str="This can be caused by several reasons. <br>Please click on button below to report the issue (with one click) so we can investigate it."
		></UT>
	</div>
	<button *ngIf="!isSendingReport && !isReportSent" class="bt-teritary" (click)="onSendToFrontIssueReport()">
		{{ 'Report the issue' | translate }}
	</button>

	<button *ngIf="isSendingReport" class="bt-teritary loading" (click)="onSendToFrontIssueReport()">
		{{ 'Reporting' | translate }}
		<app-loader></app-loader>
	</button>

	<button *ngIf="isReportSent" class="bt-teritary">
		<img src="../../../../../assets/images/icons/carecost_talon_checked.svg" alt="" />
		{{ 'Issue reported' | translate }}
	</button>
</div>
