import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, first, from, mergeMap, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';

import CFG from '../config/app-config.json';

import { User } from '../models/user.model';
import { UserData } from '../models/user-data';
import { LoginFlowResponse } from '../models/login-flow-response';
import { isIonic, isNotNullOrUndefined } from '../utils/utils';

import { StorageService } from './storage.service';
import { UIService } from './ui.service';
import { LanguageService } from './language.service';

import { UserPlanDataStoreService } from './stores/user-plan-data-store/user-plan-data-store.service';
import { ConnectionTimeoutError, NoConnectionError } from '../error-handlers/app-errors';
import { DeductiblesStoreService } from './stores/deductibles-store/deductibles-store.service';
import { UnleashService } from './unleash.service';
import { BenefitStoreService } from './stores/benefit-store/benefit-store.service';
import { AllServicesStoreService } from './stores/all-services-store/all-services-store.service';
import { UrlService } from './url.service';
import { SessionStorageService } from './sessionStorage.service';
import { AuthResponseBefore2FA } from '../models/auth-response';
import { MeUserStoreService } from './stores/me-user-store/me-user-store.service';
import { StoreUpdateRegistryService } from './stores/store-update-registry.service';
import { MeUserStoreToken } from './stores/me-user-store/me-user-store.token';
import { PlanSelectionStoreService } from './stores/plan-selection-store/plan-selection-store.service';
import { UserApiService } from './api/user-api/user-api.service';
import { TokenRegisterParams } from './api/mfa-api/helpers/token-register.params';
import { TokenRegisterResponse } from './api/mfa-api/helpers/token-register.response';
import { AuthService } from '../modules/account/login/auth.service';
import { FfNewBrandLogo } from '../config/feature-flags/ff-new-brand-logo';
const AFFILIATED_COMPANIES_ASSETS_PATH = 'https://assets.healthee.co/affiliated_companies/';

export interface LoginCredentials {
	id: string;
	email: string;
	password: string;
	code: string;
	MFA: boolean;
	mfaToken: string;
	recaptchaToken?: string;
}
export enum MFAMethods {
	'sms' = 'sms',
	'email' = 'email',
}

export enum MFAMethodCaptions {
	'sms' = 'Phone',
	'email' = 'Email',
}
export enum UserUpdateAction {
	LoginUpdate,
	DataUpdate,
}

export interface ChangePassword {
	hash: string;
	password: string;
	twoFACode: string;
	mfaToken: string;
}
export interface AppLogos {
	primary: string,
	primarySm: string,
	secondary: string,
	secondarySm: string,
}

@Injectable({ providedIn: 'root' })
export class UserService {
	private _user = new BehaviorSubject<User>(null);
	public _appLogoUrl = new BehaviorSubject<AppLogos>(null);
	private _token = new BehaviorSubject<string>(null);
	public wasInitialized: boolean = false;
	public userEmail: string = '';

	constructor(
		private http: HttpClient,
		private languageService: LanguageService,
		private storageService: StorageService,
		private uiService: UIService,
		private userPlanDataStoreService: UserPlanDataStoreService,
		private deductiblesStoreService: DeductiblesStoreService,
		private benefitStoreService: BenefitStoreService,
		private allServicesStoreService: AllServicesStoreService,
		private unleashService: UnleashService,
		private urlService: UrlService,
		private sessionStorageService: SessionStorageService,
		private meUserStoreService: MeUserStoreService,
		private storeUpdateRegistryService: StoreUpdateRegistryService,
		private planSelectionStoreService: PlanSelectionStoreService,
		private userApiService: UserApiService,
		private authService: AuthService
	) {}

	get user$() {
		return this._user.asObservable();
	}

	get token$() {
		return from(this.authService.getJwt()).pipe(
			first(),
			catchError(() => {
				// fallback for non cognito users
				//TODO: once fully migrate to cognito, we should logout the user here.
				return this._token.asObservable();
			})
		);
	}

	get userFullname$() {
		return this.user$.pipe(
			map((user) => user?.data),
			filter((data) => !!data),
			map((userData: UserData) => {
				const firstname = userData.firstName || '';
				const lastname = userData.lastName || '';
				return firstname + ' ' + lastname;
			})
		);
	}

	get userInitials$() {
		return this.user$.pipe(
			map((user) => user?.data),
			filter((data) => !!data),
			map((userData: UserData) => {
				const firstnameInitial = userData.firstName[0] || '';
				const lastnameInitial = userData.lastName[0] || '';
				return firstnameInitial + lastnameInitial;
			})
		);
	}

	get userData$() {
		return this.user$.pipe(
			map((user) => user?.data),
			filter((data) => !!data)
		);
	}

	get userAvatar$() {
		return this.user$.pipe(
			map((user) => user?.data),
			filter((data) => !!data),
			map((userData: UserData) => userData.avatar)
		);
	}

	get isUserEligible$() {
		return this.userData$.pipe(
			map((userData) => {
				if (userData.esiEligible != null) return userData.esiEligible;
				if (userData.isEligible != null) return userData.isEligible;

				return true;
			})
		);
	}

	/**
	 * Authenticate user credentials, sends sms with 6 digit OTP (Login - step 1)
	 */
	public sendEmail(credentials: LoginCredentials): Observable<LoginFlowResponse> {
		return this.http.post<LoginFlowResponse>(CFG.apiEndpoints.userLogin_CheckEmail, credentials, {
			params: { 'cognito-flow': 'true' },
		});
	}
	/**
	 * Authenticate user credentials, sends email with 6 digit OTP (Login - step 1)
	 */
	public sendUsernameAndPassword(credentials: LoginCredentials): Observable<AuthResponseBefore2FA> {
		return this.http
			.post<AuthResponseBefore2FA>(CFG.apiEndpoints.userLogin_Credentials, credentials)
			.pipe(tap((response) => this.tryLogginingInWithoutMFA(response?.userData)));
	}

	//TODO: check for better way to pass the bearer token
	public registerWithJWT(jwt: string, params: TokenRegisterParams) {
		return this.http.post<TokenRegisterResponse>(CFG.apiEndpoints.registerUser, params, {
			headers: { Authorization: `Bearer ${jwt}` },
		});
	}

	private tryLogginingInWithoutMFA(userData: UserData) {
		const canSkipMFALogin = !!userData;

		if (canSkipMFALogin) {
			// userData is available to us without the MFA step, so we can skip it
			// and login without it (the user is considered "logged-in")
			this.saveUserDataAndToken(userData);
		}
	}
	private saveUserDataAndToken(userData: UserData) {
		this.updateToken(userData.token);
		this.updateUserFromData(userData, UserUpdateAction.LoginUpdate);
	}

	public updateToken(token: string) {
		this.storageService.storeToken(token);
		this._token.next(token);
		const isToken = !!token;
		this.meUserStoreService.setTokenReady(isToken);
	}

	private updateMfaToken(mfaToken: string): void {
		if (!mfaToken) return;

		this.storageService.storeMfaToken(mfaToken);
	}

	/** Updates the current user store, from UserData, and saves it to the disk (localStorage)
	 * @param  {UserData} userData - data that builds the User object. If null - user store and saved data are set to null.
	 */
	public async updateUserFromData(userData: UserData, updateAction: UserUpdateAction = UserUpdateAction.DataUpdate) {
		const user: User = userData ? new User(userData, updateAction) : null;

		await this.updateLocaleIfNeeded(user?.data?.preferredLanguage, user?.data?.company?.availableLanguages);
		await this.unleashService.updateContext({
			userId: userData?.uid,
			companyId: userData?.company?.id,
			emailDomain: userData?.email,
		});

		this.unleashService.isEnabled$(FfNewBrandLogo).subscribe((isEnabled) => {
			let secondary, secondarySm;
			let primary = isEnabled ? '/assets/images/logo-v2/logo_text.svg'
				: '/assets/images/logo_vertical.svg'
			let primarySm = isEnabled ? '/assets/images/logo-v2/logo.svg'
				: '/assets/images/logo.svg'

			const { primaryLogoSlug, secondaryLogoSlug } = userData?.company?.whiteLabel || {};

			if (primaryLogoSlug) {
				primary = AFFILIATED_COMPANIES_ASSETS_PATH + primaryLogoSlug + '.png'
				primarySm = AFFILIATED_COMPANIES_ASSETS_PATH + primaryLogoSlug + '-small.png'
			}
			if (secondaryLogoSlug) {
				secondary = AFFILIATED_COMPANIES_ASSETS_PATH + secondaryLogoSlug + '.png'
				secondarySm = AFFILIATED_COMPANIES_ASSETS_PATH + secondaryLogoSlug + '-small.png'
			}
			this._appLogoUrl.next({ primary, primarySm, secondary, secondarySm });
		});
		this._user.next(user);
		this.debugUserId(userData?.uid);
	}

	private async updateLocaleIfNeeded(preferredLanguage: string, availableLanguages: string[]) {
		if (preferredLanguage && preferredLanguage !== this.languageService.currentLanguage)
			await this.languageService.setLocale(preferredLanguage);

		if (availableLanguages)
			await this.languageService.setSupportedLanguages(availableLanguages);
	}

	/**
	 * loginWithToken
	 * @param token
	 * @param vendor
	 * @returns Observable - UserData - after updating local storage with new data,
	 */
	public storeUserDataFromExternalToken(token: string, vendor: string): Observable<UserData> | any {
		return this.http
			.post<UserData>(CFG.apiEndpoints.userLoginToken, {
				token: `${token}:${vendor}`,
			})
			.pipe(
				map((userData: UserData) => {
					this.storageService.storeToken(userData.token);
					return userData;
				})
			);
	}

	public loginTrinetUser(jwt: string): Observable<any> {
		this.storageService.storeToken(jwt);
		this.attemptLogin().subscribe();
		return of({});
	}

	public attemptLogin(url: string = null, queryParams: object = null) {
		return from(
			this.authService.getJwt().catch((err) => {
				console.log('error getting jwt', err);
				return null;
			})
		).pipe(
			mergeMap((jwt) => {
				try {
					if (!jwt) throw new Error('No jwt found');
					if (this.wasInitialized) return this.user$.pipe(filter(isNotNullOrUndefined));
					this.wasInitialized = true;
					this.updateToken(jwt);
				} catch (err) {
					console.log('Error reading user data from local storage. Clearing local storage', err);
					this.storageService.removeUserData(); // TODO: Refactor this to the storage service
					this.logout(false, url, queryParams);
					this.wasInitialized = false;
					return new Observable(null);
				}

				return this.getUserRequest().pipe(
					catchError((error) => {
						if (error instanceof ConnectionTimeoutError || error instanceof NoConnectionError) {
							this.storageService.removeUserData();
							this.logout();
						}
						return of(null);
					})
				);
			})
		);
	}

	public getUserRequest() {
		return this.fetchUser().pipe(
			switchMap(async (userData: UserData) => {
				await this.updateUserFromData(userData);
				return this.user$;
			})
		);
	}

	public fetchUser(): Observable<UserData> {
		return this.http.get<UserData>(CFG.apiEndpoints.user);
	}

	public async logout(requestedExplicitlyByUser: boolean = false, url: string = null, queryParams: object = null) {
		await this.authService.signOut(isIonic());

		let routeData = {};
		const doNotAskToBioAutoLoginObject = {
			supressSilentLoginAttempts: 'true',
		};
		this.updateToken(null);
		this.updateUserFromData(null, UserUpdateAction.LoginUpdate);
		this.deleteUserDataFromLocalStorage();
		this.sessionStorageService.clearStorage();
		this.setLastUrl(requestedExplicitlyByUser, url, queryParams);
		this.wasInitialized = false;

		if (isIonic() && requestedExplicitlyByUser) routeData = doNotAskToBioAutoLoginObject;

		await this.uiService.navigate(['/account/login'], {
			queryParams: { ...routeData, idp: this.uiService.isMPIAppMode ? 'mpi' : undefined },
		});

		this.userPlanDataStoreService.purge();
		this.deductiblesStoreService.purge();
		this.benefitStoreService.purgeAll();
		this.allServicesStoreService.purge();
		this.meUserStoreService.purgeAll();
		this.planSelectionStoreService.purge();

		// Reloading the page to properly clear everything in Stores
		if (!isIonic()) {
			window.location.reload();
		}
	}

	private setLastUrl(isDedicatedLogout, url, queryParams) {
		isDedicatedLogout ? this.urlService.clearPreviousUrl() : this.urlService.setPreviousUrl(url, queryParams);
	}

	private deleteUserDataFromLocalStorage() {
		this.storageService.removeUserData();
	}

	/** Updates the user store with the userData and logs the user in after a successful user registration.
	 * Exposes the user saving and logging-in functionality, and should be used only by the registration service
	 * @param  {UserData} userData
	 */
	public updateNewUserAfterSuccessfulRegistration(userDataWithToken: UserData) {
		this.saveUserDataAndToken(userDataWithToken);
	}

	public requestPasswordChange2FACode() {
		return this.http.get(CFG.apiEndpoints.user2FACode);
	}

	public requestPasswordChangeHash() {
		return this.http.get(CFG.apiEndpoints.userForgotPasswordHashWhileLogged);
	}

	//change  password
	public sendChangePasswordRequest(payload: ChangePassword): Observable<UserData> {
		return this.http.post(CFG.apiEndpoints.userChangePassword, payload).pipe(
			tap((userDataWithToken: UserData) => {
				this.updateToken(userDataWithToken.token);
				this.updateMfaToken(userDataWithToken.mfaToken);
				return this.updateUserFromData(userDataWithToken);
			})
		);
	}

	//forgot password
	public sendResetPasswordRequest(payload: { password: string; hash: string }) {
		return this.http.post(CFG.apiEndpoints.userResetPassword, payload);
	}

	public updateUserFullnameOrAvatar(updateData: { firstName?: string; lastName?: string; avatar?: string }) {
		return this.http
			.patch(CFG.apiEndpoints.userFullNameAndAvatar, updateData)
			.pipe(tap(() => this.handleUserFullnameOrAvatarUpdateSuccess(updateData)));
	}

	public updateUserProfileFromFormData(formData: any): Observable<UserData> {
		return this.userApiService.updateProfile(formData).pipe(
			tap((userData) => this.updateUserFromData(userData)),
			tap(() => this.storeUpdateRegistryService.registerUpdate(MeUserStoreToken, undefined))
		);
	}

	public updateUserProfileFromUserData(userData: Partial<UserData>): Observable<UserData> {
		return this.http
			.post<UserData>(CFG.apiEndpoints.userProfileData, userData)
			.pipe(tap((userData) => this.updateUserFromData(userData)));
	}

	private handleUserFullnameOrAvatarUpdateSuccess(updateData: {
		firstName?: string;
		lastName?: string;
		avatar?: string;
	}) {
		this.user$.pipe(take(1)).subscribe((user) => {
			const updatedUserData: UserData = { ...user.data, ...updateData };
			this.updateUserFromData(updatedUserData);
		});
	}

	public sendContactUsEmail(message: { subject: string; text: string; email?: string }) {
		if (message.email) {
			return this.http.post(CFG.apiEndpoints.contactRegistration, message);
		}
		return this.http.post(CFG.apiEndpoints.sendContactUsEmail, message);
	}
	public setMembershipCardUploaded(): void {
		this.user$.pipe(take(1)).subscribe((user) => {
			const updatedUserData: UserData = user.data;
			updatedUserData.hasMembershipCard = true;
			this.updateUserFromData(updatedUserData);
		});
	}
	public debugUserId(uid) {
		try {
			if (uid) {
				document.body.setAttribute('data-uid', uid);
			} else {
				document.body.removeAttribute('data-uid');
			}
		} catch (e) {
			//ignore catch in this case
		}
	}

	public updateAppointmentNotificationChoice(choice) {
		const url = '/user/update-appointment-notification-choice';
		const params = new HttpParams({
			fromObject: {
				choice,
			},
		});

		return this.http
			.get<any>(url, { params })
			.pipe(take(1))
			.subscribe({
				next: () => {
					const user = this._user.getValue();
					const userData = user.data;
					userData.subscribeToAppointmentNotifications = choice;
					this.updateUserFromData(userData, UserUpdateAction.LoginUpdate);
				},
			});
	}

	public setIsPreventiveCareInit() {
		return this.user$.pipe(
			take(1),
			switchMap((user) => {
				const updatedUserData: UserData = user.data;
				updatedUserData.isHomePagePreventiveCareInit = true;

				return this.updateUserProfileFromUserData(updatedUserData);
			}),
			catchError(() => of(null))
		);
	}
}
