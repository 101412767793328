import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Contract } from '../../../../models/contract.model';
import { PlanType } from '../../../../models/plan-type.model';

const ASSETS_PATH = '/assets/images/health-profile';

@Component({
	selector: 'app-locked-card',
	templateUrl: './locked-card.component.html',
	styleUrls: ['./locked-card.component.scss'],
})
export class LockedCardComponent {
	public PlanType = PlanType;

	@Input()
	contract: Contract;

	@Output()
	unlockClick = new EventEmitter<PlanType>();

	public assetsPath = ASSETS_PATH;
}
