import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { T } from '@transifex/angular';
import { TrackingService } from 'src/app/services/tracking.service';
import { TOP_RATED_PROVIDERS } from './top-rated-providers';

@Component({
	selector: 'app-top-rated-providers',
	templateUrl: './top-rated-providers.component.html',
	styleUrls: ['./top-rated-providers.component.scss'],
})
export class TopRatedProvidersComponent implements OnInit {
	@Input() defaultAddress: string;

	@Output() public selectTopRatedProviderEvent = new EventEmitter();
	@Output() public openFilters = new EventEmitter<void>();

	@T('PCP')
	private primaryCare: string;

	@T('Dentist')
	private dentist: string;

	@T('Optometrist')
	private optometrist: string;

	@T('OB-GYN')
	private obGyn: string;

	@T('Dermatology')
	private dermatology: string;

	@T('Psychologist')
	private psychologist: string;

	public topSearchedServices = TOP_RATED_PROVIDERS;

	constructor(private trackingService: TrackingService, private route: ActivatedRoute) {}

	ngOnInit() {
		this.setDefaultService();
		this.initiateTranslations();
	}

	private initiateTranslations() {
		this.topSearchedServices[0].text = this.primaryCare;
		this.topSearchedServices[1].text = this.dentist;
		this.topSearchedServices[2].text = this.optometrist;
		this.topSearchedServices[3].text = this.obGyn;
		this.topSearchedServices[4].text = this.dermatology;
		this.topSearchedServices[5].text = this.psychologist;
	}

	private async setDefaultService() {
		await this.selectService(this.topSearchedServices[0]);
	}

	public onClick(service) {
		this.trackingService.trackClientEvent('PS - Frequently Searched', {
			speciality_type: `${service.text}`,
		});

		this.selectService(service);
	}

	private async selectService(service) {
		this.selectUIService(service);
		this.selectTopRatedProviderEvent.emit(service);
	}

	private selectUIService(selectedService) {
		this.topSearchedServices.forEach((service) => {
			if (service.selected) {
				service.selected = false;
				return;
			}
		});

		selectedService.selected = true;
	}

	public onClickFiltersHandler() {
		this.openFilters.emit();
	}
}
