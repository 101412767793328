import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { UIService } from 'src/app/services/ui.service';
import { RxjsUtils } from 'src/app/utils/rxjs';
import { ProvidersSearchUrlKey } from '../../models/ribbon-data.model';

@Injectable({
	providedIn: 'root',
})
export class ProvidersSearchUrlsService {
	private baseUrl$ = new BehaviorSubject<string>('');

	constructor(uiService: UIService) {
		uiService.route$.pipe(RxjsUtils.isNotNil()).subscribe((route) => {
			if (route.startsWith('/providers-search')) {
				this.baseUrl$.next('/providers-search');
			} else {
				this.baseUrl$.next('/care-and-costs/providers');
			}
		});
	}

	get baseUrl(): string {
		return this.baseUrl$.value;
	}

	get resultsUrl(): string {
		return `${this.baseUrl}/results`;
	}

	get specialistUrl(): string {
		return `${this.baseUrl}/${ProvidersSearchUrlKey.PROVIDER}`;
	}

	get facilityUrl(): string {
		return `${this.baseUrl}/${ProvidersSearchUrlKey.FACILITY}`;
	}
}
