import { Injectable } from '@angular/core';
import { Observable, combineLatest, filter, map } from 'rxjs';
import { Capacitor } from '@capacitor/core';

import { UserMenuLocalize } from './user-menu.localize';
import { UserData } from '../models/user-data';

import { AppManagerService } from './app-manager.service';
import { AppLanguage, LanguageService } from './language.service';
import { UIService } from './ui.service';
import { UserService } from './user.service';
import { PlanSelectionData, PlanSelectionStoreService } from './stores/plan-selection-store/plan-selection-store.service';

const ONE_SUPPORTED_LANGUAGE = 1;
export interface DropDownMenu {
	name: string;
	label: string;
	icon: string;
	func: () => void;
	disabled?: boolean;
	hidden?: boolean;
	hiddenOnMobile?: boolean;
	hiddenOnWeb?: boolean;
}

@Injectable({ providedIn: 'root' })
export class UserMenuService {
	UserMenuLocalize = UserMenuLocalize;

	private readonly isMobile = Capacitor.isNativePlatform();

	// Used to save phine number for number verification through the user menu.
	// Not sure this is the right place for it.
	public phoneNumber: string = undefined;

	// NOTE TO DEVELOPER: userDropDownMenu$ is used and displayed by the following components:
	// Web: UserAvatarComponent
	// Mobile: MobileAppSettingsRouteComponent
	// Make sure to apply display changes in both component when needed
	public userDropDownMenu: DropDownMenu[] = [
		{
			name: 'language',
			label: '',
			icon: '/assets/icons/icon-language.svg',
			func: () => this.appManager.openChangeLanguageDialog(),
		},
		{
			// Change password option - not available on mobile
			name: 'changePass',
			label: '',
			icon: '/assets/icons/icon-security.svg',
			func: () => this.appManager.openChangePasswordModal(),
			hiddenOnMobile: true,
		},
		{
			// Change security option - not available on web
			name: 'security',
			label: '',
			icon: '/assets/icons/icon-security.svg',
			func: () => this.uiService.navigate(['/me', 'security']),
			hiddenOnWeb: true,
		},
		{
			name: 'verifyPhone',
			label: '',
			icon: '/assets/icons/icon-verify-phone.svg',
			func: () => this.appManager.openVerifyPhoneNumber(),
		},
		{
			name: 'changePhone',
			label: '',
			icon: '/assets/icons/icon-verify-phone.svg',
			func: () => this.appManager.openChangePhoneNumber(),
		},
		{
			name: 'toggleNotifications',
			label: '',
			icon: '/assets/icons/icon-notifications.svg',
			func: () => this.appManager.openToggleNotifications(),
		},
		{
			name: 'contactUs',
			label: '',
			icon: '/assets/icons/icon-contact-us.svg',
			func: () => this.isMobile
				? this.uiService.navigate(['/me', 'contact-us'])
				: this.appManager.openContactUsModal(),
		},
		{
			name: 'privacyPolicy',
			label: '',
			icon: '/assets/icons/icon-privacy-policy.svg',
			func: () => this.uiService.navigate(['/me', 'privacy-policy']),
		},
		{
			name: 'logout',
			label: '',
			icon: '/assets/icons/icon-log-out.svg',
			func: () => this.appManager.openLogoutConfirmationModal(),
		},
	];

	public userDropDownMenu$: Observable<DropDownMenu[]>;

	constructor(
		private appManager: AppManagerService,
		public languageService: LanguageService,
		private uiService: UIService,
		private userService: UserService,
		private planSelectionStoreService: PlanSelectionStoreService
	) {
		this.initUserMenu();
	}

	private initUserMenu() {
		this.userDropDownMenu$ = combineLatest([
			this.languageService.appLanguage$,
			this.languageService.supportedLanguages$,
			this.userService.userData$,
			this.planSelectionStoreService.get()
		]).pipe(
			map(([, supportedLanguages, userData, planSelectionData]) => {
				this.populateTranslatedItemLabels();
				this.hideLanguageMenuIfOnlyOneLanguageIsSupported(supportedLanguages);
				this.hidePasswordAndPhoneVerificationIfNumberExists(userData);
				this.customizeMenuForTriNet(planSelectionData)
				this.customizeMenuForPCTUsers();

				return this.userDropDownMenu;
			}),
			filter(userDropDownMenuItems => !!userDropDownMenuItems)
        );
	}

	private populateTranslatedItemLabels() {
		const localizationMap = {
			language:		this.UserMenuLocalize.language,
			changePass:		this.UserMenuLocalize.changePass,
			security:		this.UserMenuLocalize.security,
			verifyPhone:	this.UserMenuLocalize.verifyPhone,
			changePhone:	this.UserMenuLocalize.changePhone,
			contactUs:		this.UserMenuLocalize.contactUs,
			privacyPolicy:	this.UserMenuLocalize.privacyPolicy,
			logout:			this.UserMenuLocalize.logout,
			toggleNotifications: this.UserMenuLocalize.toggleNotifications,
		};

		this.userDropDownMenu.forEach((item) => {
			if (localizationMap[item.name]) {
				item.label = localizationMap[item.name];
			}
		});
	}

	private hideLanguageMenuIfOnlyOneLanguageIsSupported(supportedLanguages: AppLanguage[]) {
		if (supportedLanguages?.length <= ONE_SUPPORTED_LANGUAGE) {
			this.hideMenuItem('language');
		}
	}

	private hidePasswordAndPhoneVerificationIfNumberExists(userData: UserData) {
		this.phoneNumber = userData.phoneNumber;

		if (this.phoneNumber) {
			this.hideMenuItem('verifyPhone');
			this.hideMenuItem('changePass');
		} else {
			// phone number not present
			this.hideMenuItem('changePhone');
		}
	}

	private customizeMenuForTriNet(planSelectionData: PlanSelectionData) {
		if (planSelectionData?.companyData?.isTriNet) {
			this.hideMenuItem('changePass');
			this.hideMenuItem('logout');
		}
	}

	private customizeMenuForPCTUsers() {
		if (this.uiService.PCTOnlyMode) {
			this.hideMenuItem('language');
			this.hideMenuItem('verifyPhone');
			this.hideMenuItem('changePhone');
			this.hideMenuItem('toggleNotifications');
		}
	}

	private hideMenuItem(itemName: string) {
		const item = this.userDropDownMenu.find((item) => item.name === itemName);

		if (item) {
			item.hidden = true;
		}
	}
}
