import { NgModule, inject } from '@angular/core';
import { Routes, RouterModule, CanMatchFn, Route, UrlSegment } from '@angular/router';
import { DISABLED_BACK_NAVIGATION } from '../../../services/ui.service';
import { TalonWidgetComponent } from '../main-layout-shared/talon-widget/talon-widget.component';
import { CareAndCostsComponent } from './care-and-costs.component';
import { CompanyStoreService } from 'src/app/services/stores/company-store/company-store.service';
import { map } from 'rxjs';


const canMatchDefault: CanMatchFn = () => {
	return inject(CompanyStoreService).get().pipe(map(company => !!(company?.name !== 'MPI')))
};
const canMatchMPI: CanMatchFn = () => {
	return inject(CompanyStoreService).get().pipe(map(company => !!(company?.name === 'MPI')))
};

const routes: Routes = [
	{
		path: '',
		component: CareAndCostsComponent,
		data: {
			title: 'care_and_costs',
			ionicNativeBackOverrideLink: DISABLED_BACK_NAVIGATION,
		},
		children: [
			{
				path: '',
				redirectTo: 'providers',
				pathMatch: 'full',
			},
			{
				path: 'providers',
				loadChildren: () =>
					import('./providers-search-page/providers-search-page.module').then((loadedModule) => loadedModule.ProvidersSearchPageModule),
				data: { title: 'care_and_costs', ionicNativeBackOverrideLink: DISABLED_BACK_NAVIGATION },
				canMatch: [canMatchDefault],
			},
			{
				path: 'providers',
				loadChildren: () =>
					import('../../mpi/providers-search/providers-search-page/providers-search-page.module').then((loadedModule) => loadedModule.MPIProvidersSearchPageModule),
				data: { title: 'care_and_costs', ionicNativeBackOverrideLink: DISABLED_BACK_NAVIGATION },
				canMatch: [canMatchMPI],

			},
			{
				path: 'services',
				component: TalonWidgetComponent,
				data: { title: 'care_and_costs', ionicNativeBackOverrideLink: DISABLED_BACK_NAVIGATION },
			},
		],
	},
	{
		path: '**',
		redirectTo: '',
		pathMatch: 'prefix',
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class CareAndCostsRoutingModule { }
