import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import {
	MPIProvidersSearchService,
	searchBarParamsToServerParams,
} from 'src/app/services/providers-search/mpi-providers-search.service';
import { MPISearchBarService } from 'src/app/services/providers-search/mpi-search-bar.service';
import { MPIRibbonEntitiesStoreService } from 'src/app/services/stores/ribbon-entities-store/mpi-ribbon-entities-store.service';
import {
	DEFAULT_MPI_LOCATION,
	SearchEntity,
	SearchFields,
	SearchType,
	retrieveValueByEntity,
} from '../../../helpers/providers-search.helper';
import { RxjsUtils } from 'src/app/utils/rxjs';
import { FormGroup } from '@angular/forms';
import { Observable, combineLatest } from 'rxjs';
import { NetworkStructure } from 'src/app/models/chat.model';
import { UserPlanDataStoreService } from 'src/app/services/stores/user-plan-data-store/user-plan-data-store.service';
import { Maybe } from 'src/app/utils/types/maybe';
import { ProvidersSearchUrlsService } from 'src/app/services/providers-search/providers-search-urls.service';
import { CurrentLocationService } from 'src/app/services/current-location.service';
import { TrackingService } from 'src/app/services/tracking.service';
import { LoaderColor, LoaderSize } from 'ripple';

@Component({
	selector: 'app-top-rated-providers',
	templateUrl: './top-rated-providers.component.html',
	styleUrls: ['./top-rated-providers.component.scss'],
})
export class TopRatedProvidersComponent implements OnInit {
	public networkStructure$: Observable<NetworkStructure> = this.userPlanDataStoreService.getPlansNetworkStructure();
	public results$ = this._providersSearchService.results$;
	public resultsInNetwork$: Observable<Maybe<boolean>> = this._providersSearchService.isResultsInNetwork$;
	public isLoading$: Observable<boolean> = this._providersSearchService.showLoader$;
	public isSuccess$ = this._providersSearchService.isSuccess$;
	public defaultLocation: string;
	public showLocationLoader: boolean = false;
	public isLocationEnabled: boolean;
	public loaderColor = LoaderColor.gray;
	public loaderSize = LoaderSize.xsmall;

	private categoryName: string = 'PRIMARY CARE PROVIDENCE-INCLUDES FAM/INT MEDICINE';

	@Input() searchEvent: EventEmitter<SearchFields>;

	constructor(
		private providersSearchService: MPIProvidersSearchService,
		private _providersSearchService: MPIProvidersSearchService,
		private userPlanDataStoreService: UserPlanDataStoreService,
		private searchBarService: MPISearchBarService,
		private trackingService: TrackingService,
		private _currentLocationService: CurrentLocationService,
		public providersSearchUrlsService: ProvidersSearchUrlsService,
		private ribbonEntitiesStoreService: MPIRibbonEntitiesStoreService
	) {}

	async ngOnInit(): Promise<void> {
		this.isLocationEnabled = await this.checkCurrentLocation();
		const params = this.getParams().value;
		combineLatest([
			this.ribbonEntitiesStoreService.get().pipe(RxjsUtils.isNotNil()),
			this.networkStructure$,
		]).subscribe({
			next: ([ribbonEntities, networkStructure]: [any, NetworkStructure]) => {
				const customFieldValue =
					params.searchType === SearchType.Options
						? retrieveValueByEntity(params.value, params.entity, ribbonEntities)
						: params.value;

				const searchParams = searchBarParamsToServerParams(params, customFieldValue, networkStructure);

				this.providersSearchService.search(searchParams, params);
			},
		});
	}

	onMoreInfo() {
		const params = this.getParams();

		this.trackingService.trackClientEvent('PS - Top rated providers: Show all button clicked', {
			Source: 'MPI empty state',
		});

		this.searchEvent.emit(params.value);
	}

	private getParams(): FormGroup {
		const params = this.searchBarService.getSearchFormGroup();

		params.patchValue({
			address: this.defaultLocation,
			entity: SearchEntity.Provider,
			value: this.categoryName.toUpperCase(),
			searchType: SearchType.Options,
		});

		return params;
	}

	private async checkCurrentLocation(): Promise<boolean> {
		this.showLocationLoader = true;

		const isApproved = await this._currentLocationService.checkPermission();
		if (isApproved) {
			await this.getCurrentLocation();
			return true;
		}

		this.showLocationLoader = false;
		this.defaultLocation = DEFAULT_MPI_LOCATION;
		return false;
	}

	private async getCurrentLocation(): Promise<void> {
		const place = await this._currentLocationService.getCurrentPlaceInfo();
		this.showLocationLoader = false;
		this.defaultLocation = place?.formatted_address;
	}
}
