import { Component, Input } from '@angular/core';

import { Copay, CopayCadence, MemberResponsibility } from '../../../../models/benefits.model';
import { CommonComponent } from '../../../../utils/components/common-component';
import { ServicePanelCopayLocalize as T }  from './service-panel-copay.localize';

@Component({
	selector: 'app-service-panel-copay',
	templateUrl: './service-panel-copay.component.html',
	styleUrls: ['./service-panel-copay.component.scss'],
})
export class ServicePanelCopayComponent extends CommonComponent {
	@Input()
	copay: Copay;

	@Input()
	mr: MemberResponsibility;

	@Input()
	subjectToDeductible: boolean;

	@Input()
	dependsOnLocation: boolean;

	constructor() {
		super();
	}

	private createCopayObj(
		headline: string,
		textGreen: string,
		textDBlue
	): { headline: string; textGreen: string; textDBlue: string } {
		return {
			headline,
			textGreen,
			textDBlue,
		};
	}

	public getCopayText(): { headline: string; textGreen: string; textDBlue: string } {
		if (this.dependsOnLocation) return this.createCopayObj('', T.coverageDependsOnLocation, '');
		if (this.copay.label) return this.createCopayObj('', this.copay.label, '');
		if (!this.copay.amount && this.mr.percent === 100) return this.createCopayObj('', T.notCovered, '');
		if (!this.copay.amount && this.mr.percent === 0) return this.createCopayObj('', T.coveredAt100Percent, '');

		const textGreen = `$${this.copay.amount}${this.copay.upToLimit ? ' - ' + this.copay.upToLimit : ''}`;
		let textDBlue = '';
		if (this.copay.unit) {
			switch (this.copay.cadence) {
				case CopayCadence.Admission:
					textDBlue += ` ${T.per} ${this.copay.unit} ${T.per} ${this.copay.cadenceCount} ${this.copay.cadence}${
						this.copay.cadenceCount > 1 ? 's' : ''  // TODO: Fix this plural form for ES language
					}`;
					break;
				case CopayCadence.FirstNDays:
					textDBlue += ` ${T.per} ${this.copay.unit} ${T.forTheFirst} ${this.copay.cadenceCount} ${T.days}`;
					break;
				default:
					textDBlue += ` ${T.per} ${this.copay.unit}`;
					break;
			}
		}
		return this.createCopayObj(T.copay, textGreen, textDBlue);
	}

	public getMRText(): { headline: string; textGreen: string; textDBlue: string } {
		if (
			this.dependsOnLocation ||
			this.copay.label ||
			(!this.copay.amount && this.mr.percent === 100) ||
			(!this.copay.amount && this.mr.percent === 0)
		)
			return undefined;

		let textGreen = '';
		if (this.mr.dollar || this.mr.percent) {
			textGreen = this.mr.dollar === null ? `${this.mr.percent}%` : `$${this.mr.dollar}`;
		}
		if (!textGreen) return undefined;
		let textDBlue = '';
		if (this.mr.amount !== null) {
			textGreen += ` ${T.upTo} $${this.mr.amount}`;
			if (this.mr.additionalPercent !== null) textDBlue += `, ${T.then} ${this.mr.additionalPercent}%`;
		}
		if (this.mr.cadence !== null) textDBlue += ` ${T.per} ${this.mr.cadence}`;
		return this.createCopayObj(T.coinsurance, textGreen, textDBlue);
	}
}
