<div class="verify-phone-container">
	<div class="progress-line" [ngClass]="{ 'step-1': step === 1, 'step-2': step === 2, 'step-3': step === 3 }"></div>

	<div *ngIf="isProcessing" class="loading-indicator-wrapper">
		<app-loader size="medium"></app-loader>
	</div>

	<div class="content" [ngClass]="{ loading: isProcessing }">
		<p *ngIf="!isChangeExistingNumber" class="sub-title">{{ 'Let’s make your next login easier!' | translate }}</p>
		<ng-container [ngSwitch]="step" class="steps">
			<ng-container *ngSwitchCase="1" [ngTemplateOutlet]="step1"></ng-container>
			<ng-container *ngSwitchCase="2" [ngTemplateOutlet]="step2"></ng-container>
			<ng-container *ngSwitchCase="3" [ngTemplateOutlet]="step3"></ng-container>
		</ng-container>
	</div>
</div>

<ng-template #step1>
	<div class="phone-instruction">
		<p class="sub-title">{{ 'Enter your number' | translate }}</p>
		<p class="explanation">
			{{ 'Please enter your phone number so we could verify it’s really you.' | translate }}
		</p>
	</div>
	<form (ngSubmit)="onPhoneSubmit()" #phoneForm>
		<app-phone-input-component
			(phoneSubmited)="onPhoneSubmit()"
			(phoneChanged)="onPhoneChanged($event)"
			[phone]="credentials.device"
		></app-phone-input-component>
		<button class="healthee-btn md primary" [disabled]="!credentials.device" type="submit">
			{{ 'Next' | translate }}
		</button>
	</form>
</ng-template>

<ng-template #step2>
	<div class="phone-instruction">
		<p class="sub-title">{{ 'Enter Your Code' | translate }}</p>
		<p class="explanation">
			{{ 'We sent a 6 digit code to ' | translate }}
			{{ credentials.device }}
			{{ ' please enter it below' | translate }}
		</p>
	</div>
	<form (ngSubmit)="on2FACodeSubmit()" #twofaForm>
		<app-twofa-input-component
			[showInputErrorSignal$]="twoFAErrorSignal$"
			[showCodeResentMessage]="show2FACodeResent"
			(twoFACodeChanged)="on2FACodeChanged($event)"
			(twoFACodeRequested)="request2FACodeAgain()"
			(twoFACodeSubmited)="on2FACodeSubmit()"
		></app-twofa-input-component>

		<div *ngIf="error" class="confirm-twofa-indicator">
			<span class="twofa-invalid-indicator-icon"></span>
			<p [innerHtml]="error" class="indicator-text"></p>
		</div>
		<p>
			{{ 'Or' | translate }}
			<a (click)="goToPreviousStep()">{{ 'edit your phone number' | translate }}</a>
		</p>

		<button class="healthee-btn md primary" type="submit">{{ 'Activate' | translate }}</button>
	</form>
</ng-template>

<ng-template #step3>
	<div class="phone-instruction">
		<p class="sub-title">{{ 'Great!' | translate }}</p>
		<p class="explanation">{{ 'You’re all set.' | translate }}</p>
	</div>
	<button class="healthee-btn md primary" type="button" (click)="closePhoneDialog()">
		{{ 'Finish' | translate }}
	</button>
</ng-template>
