import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, take } from 'rxjs';
import {
	AppointmentFetch,
	AvailabilityData,
	ZocdocAnonymousToken,
	ZocdocRefreshTokenExpiry,
} from '../models/appointment.model';
import { normalizeAvailability } from '../models/availability.model';
import { normalizeZocdocProvider, ZocdocInfoResponse } from '../models/zocdoc-data.model';
import { StoreApi } from '../utils/store/store-api';
import { StoreUpdateRegistryService } from './stores/store-update-registry.service';
import { AppointmentsListStoreToken } from './stores/appointments-list-store/appointments-list-store.token';
import { LoggerService } from './logger.service';
import { environment } from '../../environments/environment';

const ZOCDOC_URLS: any = {
	getProviderInfos: '/zocdoc/providers',
	getAvailableSlots: '/zocdoc/availability',
	appointments: '/appointment',
	anonymousToken: '/zocdoc/anonymous-token',
	refreshToken: '/zocdoc/refresh-token',
};

@Injectable({
	providedIn: 'root',
})
export class AppointmentService extends StoreApi {
	constructor(
		private http: HttpClient,
		private storeUpdateRegistryService: StoreUpdateRegistryService,
		private logger: LoggerService
	) {
		super(storeUpdateRegistryService);
	}

	public getProviderInfosForScheduling(providerNpi: string) {
		this.logger.info(`Getting provider infos for scheduling with npi: ${providerNpi}`);
		const params = new HttpParams({
			fromObject: {
				npis: providerNpi,
			},
		});

		return this.http
			.get<ZocdocInfoResponse>(ZOCDOC_URLS.getProviderInfos, { params })
			.pipe(map((response) => ({ data: normalizeZocdocProvider(response.data), reasons: response.reasons })));
	}

	public getAvailableSlots(data: AvailabilityData) {
		const params = new HttpParams({
			fromObject: {
				providerLocationIds: [data.providerLocationId],
				reason: data.visitReasonId,
				patientType: data.patientType,
			},
		});

		return this.http
			.get(ZOCDOC_URLS.getAvailableSlots, { params })
			.pipe(map((response) => ({ ...normalizeAvailability(response[0]) })));
	}

	public createAppointment(data) {
		const params = { ...data };
		params.reason = params.visitReasonId;
		params.locationId = params.providerLocationId;
		delete params.visitReasonId;
		delete params.providerLocationId;

		return this.http
			.post(ZOCDOC_URLS.appointments, { ...params })
			.pipe(this.withStoreUpdate(AppointmentsListStoreToken, undefined), take(1));
	}

	public getAppointments() {
		return this.http.get<AppointmentFetch[]>(ZOCDOC_URLS.appointments);
	}

	public getAnonymousToken() {
		return this.http.get<ZocdocAnonymousToken>(ZOCDOC_URLS.anonymousToken);
	}

	public getRefreshToken(code: string, codeVerifier: string) {
		const isTrinet = window.location.hostname.includes('trinet');
		const appDomain = (isTrinet ? environment.trinetDomain : environment.domain) || 'app.healthee.co';
		const redirectUri = `https://${appDomain}`;

		const body = {
			'authorization-code': code,
			'authorization-code-verifier': codeVerifier,
			'authorization-redirect-uri': redirectUri,
		};

		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
		});

		return this.http.post<ZocdocRefreshTokenExpiry>(ZOCDOC_URLS.refreshToken, body, { headers });
	}

	public cancelAppointment(params) {
		return this.http
			.put(ZOCDOC_URLS.appointments, { ...params, action: 'cancel' })
			.pipe(this.withStoreUpdate(AppointmentsListStoreToken, undefined), take(1));
	}

	public rescheduleAppointment(params) {
		return this.http
			.put(ZOCDOC_URLS.appointments, { ...params, action: 'reschedule' })
			.pipe(this.withStoreUpdate(AppointmentsListStoreToken, undefined), take(1));
	}
}
