import { Component, OnInit } from '@angular/core';
import { Observable, map } from 'rxjs';

import { DropDownMenu, UserMenuService } from '../../../../services/user-menu.service';
import { LanguageService } from '../../../../services/language.service';

@Component({
	selector: 'app-user-avatar',
	templateUrl: './user-avatar.component.html',
	styleUrls: ['./user-avatar.component.scss'],
})
export class UserAvatarComponent implements OnInit {
	public userDropDownMenu$: Observable<DropDownMenu[]>;

	constructor(
		public userMenuService: UserMenuService,
		public languageService: LanguageService,
	) {}

	ngOnInit(): void {
		this.userDropDownMenu$ = this.userMenuService.userDropDownMenu$.pipe(
			map((userDropDownMenuItems) => userDropDownMenuItems
				.filter(item => !item.hidden)
				.filter(item => !item.hiddenOnWeb)
			)
		);
	}
}
