import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AppInfo } from '@capacitor/app';
import { Clipboard } from '@capacitor/clipboard';
import { Snapshot } from '@capacitor/live-updates';

import { EnvironmentConfigurationInterface } from '../../../../environments/environment.interface';

import { BiometryService } from '../../../services/biometry.service';
import { StorageService } from '../../../services/storage.service';
import { UIService } from '../../../services/ui.service';
import { HealtheeDialogService } from '../../../services/healthee-dialog.service';
import { LoggerService } from '../../../services/logger.service';

import { HealtheeDialogData } from '../healthee-dialog/healthee-dialog.component';
import { MobileDeveloperDebugLogComponent } from '../mobile-developer-debug-log/mobile-developer-debug-log.component';

declare global {
	interface Window {
		FullStory: any; // FullStory instance
		FS: any; // FullStory instance
	}
}

@Component({
	selector: 'app-mobile-developer-menu',
	templateUrl: './mobile-developer-menu.component.html',
	styleUrls: ['./mobile-developer-menu.component.scss'],
})
export class MobileDeveloperMenuComponent implements OnInit {
	@Input() appInfo: AppInfo;
	@Input() appFlowInfo: Snapshot;
	@Input() environment: EnvironmentConfigurationInterface;
	@Input() host: string;
	@Input() isOpen: boolean = false;

	@Output() dismissed = new EventEmitter();
	@Output() dismissedAndDeactivated = new EventEmitter();

	public bioAuthData: boolean;
	public fcmToken: string = '';

	// Fullstory
	public fullStoryAssetMapId = window['_fs_asset_map_id'];
	public fullStorySessionUrl: string | null = null;

	constructor(
		private bioService: BiometryService,
		private storageService: StorageService,
		private uiService: UIService,
		private dialogService: HealtheeDialogService,
		private logger: LoggerService
	) {}

	ngOnInit() {
		this.updateBioAuthSettings();
		this.fullStorySessionUrl = this.getFullStorySessionUrl();

		this.logger.debugOnDevice('Developer menu opened.');
	}

	public getFullStorySessionUrl(): string | null {
		if (window.FullStory && typeof window.FullStory.getCurrentSessionURL === 'function') {
			return window.FullStory.getCurrentSessionURL();
		} else if (window.FS && typeof window.FS.getCurrentSessionURL === 'function') {
			return window.FS.getCurrentSessionURL();
		} else {
			console.warn('FullStory is not available');
			return 'FullStory instance is undefined';
		}
	}

	public updateBioAuthSettings() {
		// deliberate use of storage service directly and not the bioAuthService observable.
		this.bioAuthData = this.storageService.getBiometrySettings();
	}

	public dismissMenu() {
		this.dismissed.emit();
	}

	public clearBioAuthCacheData() {
		this.bioService.clearBiometryUsage();
		this.storageService.removeBiometrySettings();
		this.uiService.displayAppMessage('Bio auth data is cleared');
	}

	public async copyFullStoryAssetMapToClipboard() {
		if (!this.fullStoryAssetMapId) {
			this.uiService.displayAppMessage('fullStoryAssetMapId is undefined');
			return;
		}

		try {
			await Clipboard.write({ string: this.fullStoryAssetMapId });
			this.uiService.displayAppMessage('fullStoryAssetMapId copied to clipboard');
		} catch (err) {
			this.uiService.displayAppMessage('Failed to copy fullStoryAssetMapId to clipboard');
		}
	}

	public async copyFullStorySessionUrlToClipboard() {
		if (!this.fullStorySessionUrl) {
			this.uiService.displayAppMessage('fullStorySessionUrl is undefined');
			return;
		}

		try {
			await Clipboard.write({ string: this.fullStorySessionUrl });
			this.uiService.displayAppMessage('fullStorySessionUrl copied to clipboard');
		} catch (err) {
			this.uiService.displayAppMessage('Failed to copy fullStorySessionUrl to clipboard');
		}
	}

	public dismissMenuAndLockDevMode() {
		this.dismissedAndDeactivated.emit();
	}

	public clearZocdocTokenFromLocalStorage() {
		localStorage.removeItem('zocdoc_refresh_token_expires_at');
		this.uiService.displayAppMessage('Zocdoc auth token was cleared');
	}

	public openOnDeviceLogs() {
		const options: HealtheeDialogData = {
			hasCloseButton: true,
			disableClose: false,
			component: MobileDeveloperDebugLogComponent,
			componentOnly: true,
			fullHeight: true,
			fullWidth: true,
		};

		return this.dialogService.open(options).subscribe();
	}
}
