import { Component } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

import { HealtheeDialogContent } from '../../../app-shared/healthee-dialog/healthee-dialog.component';
import { TrackingService } from '../../../../services/tracking.service';
import { delay } from 'src/app/utils/utils';

@Component({
	selector: 'app-share-summary-modal',
	templateUrl: './share-summary-modal.component.html',
	styleUrls: ['./share-summary-modal.component.scss'],
})
export class ShareSummaryModalComponent extends HealtheeDialogContent {
	readonly separatorKeysCodes = [ENTER, COMMA] as const;
	public addOnBlur = true;
	public isShared = false;
	public emails: string[] = [];

	constructor(private trackingService: TrackingService) {
		super();
	}

	public add(event: MatChipInputEvent): void {
		const value = (event.value || '').trim();

		if (value) {
			this.emails.push(value);
		}

		event.chipInput!.clear();
	}

	public remove(email: string): void {
		const index = this.emails.indexOf(email);

		if (index >= 0) {
			this.emails.splice(index, 1);
		}
	}

	public async send(): Promise<void> {
		this.isShared = true;
		await delay(1500);
		this.confirmDialog.emit(this.emails);
	}
}
