import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, combineLatest, map } from 'rxjs';
import { ProvidersApiService } from 'src/app/services/api/providers-api/providers-api.service';
import {
	MPIProvidersSearchService,
	searchBarParamsToServerParams,
} from '../../../../services/providers-search/mpi-providers-search.service';
import { MPIRibbonEntitiesStoreService } from '../../../../services/stores/ribbon-entities-store/mpi-ribbon-entities-store.service';
import { UIService } from '../../../../services/ui.service';
import { CommonComponent } from '../../../../utils/components/common-component';
import { RxjsUtils } from '../../../../utils/rxjs';
import {
	isValidSearchParams,
	normalizeQueryParams,
	retrieveValueByEntity,
	SearchEntity,
	SearchFields,
	SearchType,
} from '../helpers/providers-search.helper';
import { ProvidersSearchUrlsService } from 'src/app/services/providers-search/providers-search-urls.service';
import { NetworkStructure } from 'src/app/models/chat.model';
import { UserPlanDataStoreService } from 'src/app/services/stores/user-plan-data-store/user-plan-data-store.service';

@Component({
	selector: 'app-providers-search-results-page',
	templateUrl: './providers-search-results-page.component.html',
	styleUrls: ['./providers-search-results-page.component.scss'],
})
export class ProvidersSearchResultsPageComponent extends CommonComponent implements OnInit {
	results$ = this.providersSearchService.results$;
	queryParams$ = this.route.queryParams.pipe(map(normalizeQueryParams));
	public networkStructure$: Observable<NetworkStructure> = this.userPlanDataStoreService.getPlansNetworkStructure();

	public showAppointmentFlow = false;
	public showMobileMap = false;

	constructor(
		private uiService: UIService,
		private route: ActivatedRoute,
		private providersSearchService: MPIProvidersSearchService,
		private ribbonEntitiesStoreService: MPIRibbonEntitiesStoreService,
		private providersApiService: ProvidersApiService,
		private providersSearchUrlsService: ProvidersSearchUrlsService,
		private userPlanDataStoreService: UserPlanDataStoreService
	) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();

		this.subsBag.add = combineLatest([
			this.route.queryParams,
			this.ribbonEntitiesStoreService.get().pipe(RxjsUtils.isNotNil()),
			this.networkStructure$,
		]).subscribe({
			next: ([queryParams, ribbonEntities, networkStructure]: [SearchFields, any, NetworkStructure]) => {
				if (!isValidSearchParams(queryParams)) {
					this.uiService.navigate([this.providersSearchUrlsService.baseUrl]);
				}
				const customFieldValue =
					queryParams.searchType === SearchType.Options
						? retrieveValueByEntity(queryParams.value, queryParams.entity, ribbonEntities)
						: queryParams.value;

				const searchParams = searchBarParamsToServerParams(queryParams, customFieldValue, networkStructure);

				this.providersSearchService.search(searchParams, queryParams);
			},
		});
	}

	onSearch(queryParams: Partial<{ entity: SearchEntity; searchType: SearchType }>) {
		this.providersApiService.searchByParams(queryParams, 'merge');
	}
}
