import { T } from '@transifex/angular';

export class DeductibleTeaserLocalize {
	@T('In-network')
	static deductibleInNetwork: string;

	@T('Out-of-Network')
	static deductibleOutOfNetwork: string;
}

export enum CoverageTierValues {
	"INDIVIDUAL" = 'individual',
	"PLUS_CHILD" = 'plusChild',
	"PLUS_SPOUSE" = 'plusSpouse',
	"FAMILY" = 'family',
}
