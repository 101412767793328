import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ICON_SIZE } from 'ripple';

@Component({
	selector: 'app-service-panel-info-frame',
	templateUrl: './service-panel-info-frame.component.html',
	styleUrls: ['./service-panel-info-frame.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicePanelInfoPanelComponent {
	public readonly ICON_SIZE = ICON_SIZE;

	@Input()
	headline?: string;

	@Input()
	text?: boolean;

	@Input()
	ps?: string;

	@Input()
	showTooltip: boolean = true;

	@Input()
	hasPlanLevelDeductible: boolean = true;
}
