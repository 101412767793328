<div class="telehealth-teaser-container">
	<div class="content-wrapper">
		<div class="content-wrapper-mobile">
			<img *ngIf="isMobile$ | async" class="telehealth-img-mobile" src="/assets/images/telehealth-teaser/telehealth-teaser-mobile.png" alt="telehealth image">
			<div>
				<div class="title">{{ 'Free 24/7 Telehealth Support' | translate }}</div>
				<div class="subTitle">{{ 'Connect with doctors and mental health specialists' | translate }}</div>
			</div>
		</div>
		<div class="actions-wrapper">
			<ripple-button
				class="action doctor-btn"
				[isFullWidth]="isMobile$ | async"
				[hierarchy]="'tertiary'"
				[leadingIcon]="'video'"
				[label]="'Doctor' | translate"
				(onClick)="onTeaserClick('Doctor')"
			></ripple-button>
			<ripple-button
				class="action"
				[isFullWidth]="isMobile$ | async"
				[hierarchy]="'tertiary'"
				[leadingIcon]="'video'"
				[label]="'Therapist' | translate"
				(onClick)="onTeaserClick('Therapist')"
			></ripple-button>
		</div>
	</div>
	<img *ngIf="(isMobile$ | async) === false" class="telehealth-img" src="/assets/images/telehealth-teaser/telehealth-teaser.png" alt="telehealth image">
</div>
