<div class="map-wrapper">
	<div class="map-frame">
		<app-providers-map [providerLocations]="locations" [activeLocationIndex]="activeLocationIndex">
		</app-providers-map>
	</div>
	<div class="map-infos-and-slider-btns">
		<div class="map-infos">
			<mat-tab-group class="hidden-tabs" [selectedIndex]="activeLocationIndex">
				<mat-tab *ngFor="let location of locations" label="">
					<div class="location">
						<div class="address">{{ location.mpiAddress }}</div>
						<div class="distance">{{ location.distance }}</div>
						<div *ngIf="location.insuranceGroup">
							<b>{{ location.insuranceGroup }}</b>
						</div>
					</div>
					<div class="directions-and-phone">
						<div class="directions">
							<button class="healthee-btn sm secondary" (click)="openGoogleMapsDirections()">
								{{ 'Directions' | translate }}
								<img src="/assets/images/icons/arrow_right.svg" />
							</button>
						</div>
						<div *ngIf="location.phoneNumbers[0]" class="phone">
							<img src="{{ assetsPath }}/provider-card-call-primary.svg" alt="Call" />
							<a href="tel:{{ location.phoneNumbers[0] }}">{{ location.phoneNumbers[0] }}</a>
						</div>
						<div *ngIf="!location.phoneNumbers[0]" class="phone">
							<img src="{{ assetsPath }}/provider-card-call-disabled.svg" alt="Call" />
							<div class="not-available">{{ 'Not available' | translate }}</div>
						</div>
						<div *ngIf="location.faxes?.[0]" class="phone">
							<img src="{{ assetsPath }}/fax.svg" alt="Call" />
							<a href="tel:{{ location.faxes[0] }}">FAX - {{ location.faxes[0] }}</a>
						</div>
					</div>
				</mat-tab>
			</mat-tab-group>
		</div>
		<div class="slider-btns" *ngIf="locations.length > 1">
			<button
				[ngSwitch]="activeLocationIndex === 0"
				class="chevron-btn"
				[ngClass]="{ active: activeLocationIndex > 0 }"
			>
				<img *ngSwitchCase="true" src="/assets/images/icons/chevron-left-unactive.svg" />
				<img
					*ngSwitchCase="false"
					src="/assets/images/icons/chevron-left.svg"
					(click)="displayPrevLocation()"
				/>
			</button>
			<button
				[ngSwitch]="isLastLocation(locations)"
				class="chevron-btn"
				[ngClass]="{ active: !isLastLocation(locations) }"
			>
				<img *ngSwitchCase="true" src="/assets/images/icons/chevron-right-unactive.svg" />
				<img
					*ngSwitchCase="false"
					src="/assets/images/icons/chevron-right.svg"
					(click)="displayNextLocation()"
				/>
			</button>
		</div>
	</div>
</div>
