<app-providers-search-bar
	[searchParams]="queryParams$ | async"
	appearance="partial"
	[selectedTopSearch]="selectedTopSearch"
	[resetFiltersToggle]="resetFiltersToggle"
	(searchEvent)="onSearch($event)"
	[startMobileCollapsed]="true"
	[(showMobileMapView)]="showMobileMap"
	[isResultPage]="true"
	[showEmptyState]="false"
></app-providers-search-bar>
<div class="main-container">
	<app-provider-search-results
		(setTopServiceSelected)="setService($event)"
		(outOfNetworkSearch)="onSearch($event)"
		(resetFiltersEvent)="resetFiltersHandler()"
		[showMobileMapView]="showMobileMap"
	></app-provider-search-results>
</div>
