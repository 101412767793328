import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonComponent } from '../../../../utils/components/common-component';
import { LabelColorTheme } from 'ripple';
import { ServiceCoverage } from '../../../../models/service-coverage.model';

@Component({
	selector: 'app-service-panel-header',
	templateUrl: './service-panel-header.component.html',
	styleUrls: ['./service-panel-header.component.scss'],
})
export class ServicePanelHeaderComponent extends CommonComponent {
	@Input()
	shrinked: boolean;

	@Input()
	bgImage: string;

	@Input()
	title: string;

	@Input()
	serviceCoverage: ServiceCoverage;

	@Output()
	closeClick = new EventEmitter<void>();

	AllServiceCoverages: typeof ServiceCoverage = ServiceCoverage;

	constructor() {
		super();
	}

	protected readonly LabelColorTheme = LabelColorTheme;
}
