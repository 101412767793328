<div class="information-panel">
	<ripple-icon class="information-icon" name="wallet"></ripple-icon>
	<div class="information-texts">
		<p *ngIf="headline">{{ headline | translate }}</p>

		<p *ngIf="text" class="highlighted">{{ text }}</p>

		<div class="ps-line" *ngIf="ps">
			<p>{{ ps | translate }}</p>

			<ripple-icon
				*ngIf="showTooltip"
				[matTooltip]="
					hasPlanLevelDeductible
						? ('Your deductible is the amount you\'ll have to pay out of pocket before your health plan kicks in and starts covering medical costs. Deductibles reset every year.'
						  | translate)
						: ('The amount you\'ll pay out-of-pocket during a plan year for covered medical services. Once you reach your maximum, your health plan will pay 100% of your covered services'
						  | translate)
				"
				[size]="ICON_SIZE.size12"
				name="infoCircle"
			></ripple-icon>
		</div>
	</div>
</div>
