import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { StoreApi } from '../../../utils/store/store-api';
import { StoreUpdateRegistryService } from '../../stores/store-update-registry.service';
import { ReportFeature, ReportService } from '../../report.service';

const AUTOCOMPLETE_API = '/api/search-providers/autocomplete';
const DEFAULT_SERVICES = ['Mental Health Disorders'];

export interface AutocompleteData {
	providers: [];
	facilities: [];
}

@Injectable({
	providedIn: 'root',
})
export class AutocompleteApiService extends StoreApi {
	constructor(
		private httpClient: HttpClient,
		private storeUpdateRegistryService: StoreUpdateRegistryService,
		private reportService: ReportService
	) {
		super(storeUpdateRegistryService);
	}

	search(
		name: string,
		pageSize: number,
		address?: string,
		providedServices: string[] = []
	): Observable<AutocompleteData> {
		let params = new HttpParams({
			fromObject: {
				name,
				pageSize,
			},
		});

		if (address) {
			params = params.set('address', address);
		}

		const services = [...DEFAULT_SERVICES, ...providedServices];

		return this.httpClient.get<AutocompleteData>(AUTOCOMPLETE_API, { params }).pipe(
			switchMap((result) => {
				services.some((service) => {
					const lcService = service.toLowerCase();
					const lcName = name.toLowerCase();

					return lcService.startsWith(lcName) || lcName.startsWith(lcService);
				});

				return of(result);
			})
		);
	}
}
