import { Pipe, PipeTransform } from '@angular/core';

const SERVICES_ASSETS_PATH = 'https://assets.healthee.co/services_icons/';
const ADDITIONAL_BENEFITS_ASSETS_PATH = 'https://assets.healthee.co/additional_benefits_icons/';
const ADDITIONAL_BENEFITS_BROCHURE_PATH = 'https://assets.healthee.co/';
const COMPANIES_ASSETS_PATH = 'https://assets.healthee.co/companies_logos/';
const DEFAULT_ASSETS_FILE_NAME = '/assets/images/default-benefit-pic.png';
const DEFAULT_BENEFIT_THUMBNAIL_PATH = '/assets/images/default-benefit-thumbnails/thumbnail-{N}.png';
const DEFAULT_BENEFIT_THUMBNAIL_COUNT = 6;

@Pipe({ name: 'serviceImage', pure: false })
export class ServiceImagePipe implements PipeTransform {
	constructor() {}

	transform(url: string, index: number = 0): string {
		if (url) {
			return SERVICES_ASSETS_PATH + url;
		}
		const n = index % DEFAULT_BENEFIT_THUMBNAIL_COUNT;
		return DEFAULT_BENEFIT_THUMBNAIL_PATH.replace("{N}", String(n));
	}
}

@Pipe({ name: 'additionalBenefitImage', pure: false })
export class AdditionalBenefitImagePipe implements PipeTransform {
	constructor() {}

	transform(url: string, index: number = 0): string {
		if (url) {
			return ADDITIONAL_BENEFITS_ASSETS_PATH + url;
		}
		const n = index % DEFAULT_BENEFIT_THUMBNAIL_COUNT;
		return DEFAULT_BENEFIT_THUMBNAIL_PATH.replace("{N}", String(n));
	}
}
@Pipe({ name: 'additionalBenefitBrochure', pure: false })
export class AdditionalBenefitBrochure implements PipeTransform {
	constructor() {}

	transform(url): string {
		if (url) {
			return encodeURI(ADDITIONAL_BENEFITS_BROCHURE_PATH + url);
		}
		return null;
	}
}

@Pipe({ name: 'companyImage', pure: false })
export class CompanyImagePipe implements PipeTransform {
	constructor() {}

	transform(url): string {
		if (url) {
			return COMPANIES_ASSETS_PATH + url;
		}
		return DEFAULT_ASSETS_FILE_NAME;
	}
}

