<div *ngIf="facilityData" class="card-details-container mpi-mode">
	<div class="details-header">
		<button class="back-btn" (click)="goBack()">
			<img class="back-icon-desktop" src="/assets/images/icons/back-btn.svg" alt="back" />
			<img class="back-icon-mobile" src="/assets/images/icons/mobile-back-btn.svg" alt="back" />
		</button>
		<app-facility-card appearance="standard" [facility]="facilityData"> </app-facility-card>
	</div>
	<div class="content">
		<div class="details-infos">
			<div class="provider-infos">
				<ng-container class="provider-card-tabs-container">
					<mat-tab-nav-panel #tabPanel>
						<div [id]="tabs[0]" class="attributes tab-panel">
							<div *ngIf="facilityData.locationTypes?.length" class="specialties">
								<div class="title">{{ 'Specialties' | translate }}</div>
								<div *ngFor="let specialty of facilityData.locationTypes" class="info">
									{{ specialty | uppercase }}
								</div>
							</div>
						</div>
						<div [id]="tabs[1]" class="attributes tab-panel">
							<div class="title">{{ 'Location' | translate }}</div>
							<app-map-and-info
								[locations]="[
								{
									latitude: facilityData.latitude,
									longitude: facilityData.longitude,
									address: facilityData.address,
									phoneNumbers: [facilityData.phoneNumbers[0]],
									faxes: [facilityData.faxes?.[0]],
									distance: facilityData.distance,
									mpiAddress: facilityData.mpiAddress,
									insuranceGroup: facilityData.insuranceGroup,
								}
							]"
							>
							</app-map-and-info>
						</div>
					</mat-tab-nav-panel>
				</ng-container>
			</div>
		</div>
	</div>
</div>

<app-loading-indicator *ngIf="!facilityData"></app-loading-indicator>
