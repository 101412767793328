import { Component, TemplateRef } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';

@Component({
	selector: 'app-tooltip',
	templateUrl: './tooltip.component.html',
	styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
	tooltip: TemplateRef<any>;
	overlayRef: OverlayRef;
	autoHide: boolean;

	constructor() {}

	public hideTooltip() {
		if (this.overlayRef) {
			this.overlayRef.detach();
		}
	}
}
