import { NgModule, ErrorHandler, APP_INITIALIZER, Injector } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Router } from '@angular/router';
import { LoggerModule } from 'ngx-logger';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { NgIdleModule } from '@ng-idle/core';
import { TxNativeModule } from '@transifex/angular';
import * as Sentry from '@sentry/angular';

import { IonicModule } from '@ionic/angular';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';

import { AppAuthGuard } from './guards/app-auth.guard';
import { GlobalErrorHandler } from './error-handlers/global-error-handler';

import { AppRoutingModule } from './app-routing.module';
import { MainLayoutSharedModule } from './modules/main-layout/main-layout-shared/main-layout-shared.module';
import { MainLayoutModule } from './modules/main-layout/main-layout.module';

import { AuthInterceptor } from './interceptors/auth.interceptor';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { HostnameInterceptor } from './interceptors/hostname.interceptor';
import { MockBackendInterceptor } from './interceptors/mock-backend.interceptor';
import { TranslationInterceptor } from './interceptors/translation.interceptor';

import { HealthProfileService } from './services/health-profile.service';
import { UnleashService } from './services/unleash.service';
import { AuthService } from './modules/account/login/auth.service';

import { AppComponent } from './app.component';
import { AnonymousGuard } from './guards/anonymous.guard';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { LoggerService } from './services/logger.service';
import { DOCUMENT } from '@angular/common';
import { initializeGtm, initializeMobileAutoLogout } from './app.initializers';
import { MeUserStoreService } from './services/stores/me-user-store/me-user-store.service';
import { MPIProvidersSearchModule } from './modules/mpi/providers-search/mpi-providers-search.module';
import { LastActivityTrackerService } from './services/last-activity-tracker.service';
import { UserService } from './services/user.service';
import { environment } from '../environments/environment';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		HttpClientModule,
		AppRoutingModule,
		MainLayoutModule,
		MPIProvidersSearchModule,
		BrowserAnimationsModule,
		NgIdleModule.forRoot(),
		MainLayoutSharedModule,
		MatSnackBarModule,
		MatDialogModule,
		TxNativeModule.forRoot(),

		// Ionic
		IonicModule.forRoot({
			swipeBackEnabled: false,
			animated: false,
			hardwareBackButton: false,
		}),
		LoggerModule.forRoot(null),
	],
	providers: [
		SocialSharing,
		AppAuthGuard,
		AnonymousGuard,
		UnleashService,
		AuthService,
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: HostnameInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: MockBackendInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: TranslationInterceptor, multi: true },
		{ provide: ErrorHandler, useClass: GlobalErrorHandler },
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{ provide: MatDialogRef, useValue: {} },
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: (unleash: UnleashService) => () => unleash.start(),
			deps: [UnleashService],
			multi: true,
		},
		{ provide: APP_INITIALIZER, useFactory: () => () => null, deps: [HealthProfileService], multi: true },
		{
			provide: APP_INITIALIZER,
			useFactory: (injector: Injector) => () =>
				initializeGtm(injector.get(DOCUMENT), injector.get(MeUserStoreService)),
			deps: [Injector],
			multi: true,
		},
		LoggerService,

		// Ionic
		FingerprintAIO,
		LastActivityTrackerService,
		{
			provide: APP_INITIALIZER,
			useFactory: (injector: Injector) => () =>
				initializeMobileAutoLogout(
					injector.get(LastActivityTrackerService),
					injector.get(UserService),
					environment.mobileAutoLogoutMillis
				),
			deps: [Injector],
			multi: true,
		},
	],
	exports: [],
	bootstrap: [AppComponent],
})
export class AppModule {}
