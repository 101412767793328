import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ServicesResponse } from "./api/employee-api/helpers/services.response";
import { AdditionalBenefit } from "./api/domain/additional-benefit";

type ServicesResponseType = ServicesResponse | AdditionalBenefit

export const providersSearchKeys = ['primary care provider', 'mental', 'psych', 'family medicine', 'pediatrics', 'nurse practitioner'];
export const providersSearchTelehealthMap = {
    'primary care provider': '65e1b2ca242cc5a87085d313',
    'mental': '65e1b05a242cc5a87085d312',
    'psych': '65e1b05a242cc5a87085d312',
    'family medicine': '65e1b2ca242cc5a87085d313',
    'pediatrics': '65e1b2ca242cc5a87085d313',
    'nurse practitioner': '65e1b2ca242cc5a87085d313'
}

export const telehealthServiceIds = ['65e1b05a242cc5a87085d312', '65e1b2ca242cc5a87085d313'];
export const servicesLinkedWithTelehealth = new Set(['5d9c3af263507c70ef68860f',
    '602807378a799d07b94845ba',
    '602fc6a0f9b9675f5bf9a3e7',
    '60e3290954823252fe1b8f07',
    '60e3be0754823252fe1b8f56',
    '60e3bf1954823252fe1b8f58',
    '60e3ee3154823252fe1b8fa0',
    '6175548786563262b0c2360c',
    '6180193e86563262b0c258a8',
    '6180198486563262b0c258b4',
    '61811a8386563262b0c259c7',
    '619cc1965fdc06090e9a2da0',
    '61afeff1e09bec71011f8a6f',
    '61c0ee6b9ed1961dd7a38dc3',
    '61e044711336ea43c1e29574',
    '623b780e773e2d59a5fa47d1',
    '5ea1531363f59515f7baf9fe'])

export const telehealthServiceRelations = {
    '5d9c3af263507c70ef68860f': '65e1b2ca242cc5a87085d313',
    '602807378a799d07b94845ba': '65e1b05a242cc5a87085d312',
    '602fc6a0f9b9675f5bf9a3e7': '65e1b05a242cc5a87085d312',
    '60e3290954823252fe1b8f07': '65e1b2ca242cc5a87085d313',
    '60e3be0754823252fe1b8f56': '65e1b2ca242cc5a87085d313',
    '60e3bf1954823252fe1b8f58': '65e1b2ca242cc5a87085d313',
    '60e3ee3154823252fe1b8fa0': '65e1b05a242cc5a87085d312',
    '6175548786563262b0c2360c': '65e1b2ca242cc5a87085d313',
    '6180193e86563262b0c258a8': '65e1b05a242cc5a87085d312',
    '6180198486563262b0c258b4': '65e1b05a242cc5a87085d312',
    '61811a8386563262b0c259c7': '65e1b05a242cc5a87085d312',
    '619cc1965fdc06090e9a2da0': '65e1b2ca242cc5a87085d313',
    '61afeff1e09bec71011f8a6f': '65e1b2ca242cc5a87085d313',
    '61c0ee6b9ed1961dd7a38dc3': '65e1b05a242cc5a87085d312',
    '61e044711336ea43c1e29574': '65e1b05a242cc5a87085d312',
    '623b780e773e2d59a5fa47d1': '65e1b05a242cc5a87085d312',
    '5ea1531363f59515f7baf9fe': '65e1b05a242cc5a87085d312'
}

const telehealthServiceTitlesMap = {
    "65e1b05a242cc5a87085d312": "Mental Health Specialist - Free Telehealth",
    "65e1b2ca242cc5a87085d313": "Primary Care Provider - Free Telehealth"
}

const telehealthServicesPath = "/assets/telehealth/telehealth-services.json";

export const telehealthSerivcesIDFromDB = '5ddfaa5fcace7e30c3c90d6e';
export const telehealthSerivceTitles = ["Mental Health Specialist - Free Telehealth", "Primary Care Provider - Free Telehealth"];

export const telehealthSericesCategoryId = 'Telehealth'
@Injectable({
    providedIn: 'root',
})
export class TelehealthDataService {


    constructor(private http: HttpClient) { }

    isServiceLinkedWithTelehealth(serviceId: string): boolean {
        return servicesLinkedWithTelehealth.has(serviceId)
    }

    getRelatedTelehealthService(serviceId: string) {
        if (this.isServiceLinkedWithTelehealth(serviceId)) {
            return telehealthServiceRelations[serviceId];
        }
    }

    getRelatedTelehealthServices(serviceIds: string[]) {
        const telehealthFreeServices = new Set();
        serviceIds.map(id => {
            telehealthFreeServices.add(this.getRelatedTelehealthService(id))
        })
        return telehealthFreeServices;
    }

    isTelehealthLinkedServiceInResults(serviceIds: string[]): boolean {
        return serviceIds.some(id => this.isServiceLinkedWithTelehealth(id))
    }

    getLinekdServiceName(serviceId: string): string {
        const linkeServiceID = this.getRelatedTelehealthService(serviceId)
        return telehealthServiceTitlesMap[linkeServiceID];
    }

    getTelehealthFreeServices(): Observable<ServicesResponseType[]> {
        return this.http.get<ServicesResponseType[]>(telehealthServicesPath)
    }

    getRelatedTelehealthServiceWithIndexToInsert(serviceIds: string[]) {
        let map = new Map();
        serviceIds.map((id, index) => {
            const relatedTelehealthService = this.getRelatedTelehealthService(id);
            if (relatedTelehealthService && !map.has(relatedTelehealthService)) {
                map.set(relatedTelehealthService, index)
            }
        })
        return map;
    }
}