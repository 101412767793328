<ng-container *ngFor="let provider of providers.records">
	<app-search-results-base-card *ngIf="entityType === SearchEntity.Provider">
		<div avatar>
			<img
				*ngIf="provider.gender === ProviderGender.Male"
				src="./assets/images/provider-card/provider-card-male.svg"
				alt="Provider avatar"
			>
			<img
				*ngIf="provider.gender === ProviderGender.Female"
				src="./assets/images/provider-card/provider-card-female.svg"
				alt="Provider avatar"
			>
		</div>
		<ng-container title>
			{{ provider.firstName }} {{ provider.lastName}}
		</ng-container>
		<ng-container subTitle>
			<span *ngFor="let specialty of provider.specialties" class="comma-list-item">
				{{ specialty }}
			</span>
		</ng-container>
		<div class="body" body>
			<div class="textual-info-container">
				<p class="provider-info">
					<b>Languages: </b>
					<span *ngFor="let language of provider.mpiLanguages" class="comma-list-item">
						{{ language }}
					</span>
				</p>
				<ng-container *ngIf="getMPILocation(provider.locations) as location">
					<p class="provider-info">
						<b>Group: </b> <span>{{ location.insuranceGroup }}</span>
					</p>
					<p class="provider-info">
						<b>Address: </b>
						<span class="underline">{{ location.mpiAddress }}</span>
					</p>
				</ng-container>
			</div>
			<div class="phone-info-container" *ngIf="getMPILocation(provider.locations) as location">
				<p class="provider-info phones">
					<span *ngIf="location.phoneNumbers?.[0] as phoneNumber">
						<img
							src="./assets/images/provider-card/provider-card-call-primary.svg"
							alt="Phone icon"
							class="card-icon"
						>
						<span class="underline">{{ phoneNumber }}</span>
					</span>
					<span *ngIf="location.faxes?.[0] as fax">
						<img src="./assets/images/provider-card/fax.svg" alt="Fax icon" class="card-icon">
						<span class="underline">{{ fax }}</span>
					</span>
				</p>
			</div>
		</div>
	</app-search-results-base-card>
	<app-search-results-base-card *ngIf="entityType === SearchEntity.Facility">
		<div avatar>
			<img src="./assets/images/provider-card/provider-card-facility.svg" alt="Facility icon">
		</div>
		<ng-container title>
			{{ provider.name }}
		</ng-container>
		<ng-container subTitle>
			<span *ngFor="let type of provider.locationTypes" class="comma-list-item">
				{{ type }}
			</span>
		</ng-container>
		<div body class="body">
			<div class="textual-info-container">
				<p class="provider-info">
					<b>Group: </b> <span>{{ provider.insuranceGroup }}</span>
				</p>
				<p class="provider-info">
					<b>Address: </b>
					<span class="underline">{{ provider.mpiAddress }}</span>
				</p>
			</div>
			<div class="phone-info-container">
				<p class="provider-info phones">
					<span *ngIf="provider.phoneNumbers?.[0] as phoneNumber">
						<img
							src="./assets/images/provider-card/provider-card-call-primary.svg"
							alt="Phone icon"
							class="card-icon"
						>
						<span class="underline">{{ phoneNumber }}</span>
					</span>
					<span *ngIf="provider.faxes?.[0] as fax">
						<img src="./assets/images/provider-card/fax.svg" alt="Fax icon" class="card-icon">
						<span class="underline">{{ fax }}</span>
					</span>
				</p>
			</div>
		</div>
	</app-search-results-base-card>
</ng-container>
<p class="date">The providers were printed on: {{date}}</p>
