import { Injectable } from '@angular/core';
import { Store } from '../../../utils/store/store';
import { BenefitDocTypes, NewBenefit, UserBenefits } from '../../../models/benefits.model';
import { Observable, of } from 'rxjs';
import { Maybe } from '../../../utils/types/maybe';
import { GalleryApiService } from '../../api/gallery-api/gallery-api.service';
import { catchError, map } from 'rxjs/operators';
import { RxjsUtils } from 'src/app/utils/rxjs';

@Injectable({
	providedIn: 'root',
})
export class NewBenefitStoreService extends Store<void, UserBenefits> {
	constructor(private galleryApiService: GalleryApiService) {
		super();
	}

	protected retrieve(): Observable<Maybe<UserBenefits>> {
		return this.galleryApiService.getNewBenefitsOfUser().pipe(catchError(() => of({} as UserBenefits)));
	}

	getByContractIdAndOldBenefitId({ contractId, oldBenefitId }): Observable<Maybe<NewBenefit>> {
		const notFoundBenefit: NewBenefit = {
			oid: '',
			contractId: '',
			title: '',
			slug: '',
			service: '',
			networks: [],
			docType: BenefitDocTypes.EOC,
		};
		return this.get().pipe(
			RxjsUtils.isNotNil(),
			map(
				(userBenefits) =>
					userBenefits.benefits.find(
						(benefit) => benefit.contractId === contractId && benefit.oldBenefitId === oldBenefitId
					) || notFoundBenefit
			)
		);
	}
}
