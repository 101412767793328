import { Capacitor } from '@capacitor/core';
import { Geolocation, Position, PermissionStatus } from '@capacitor/geolocation';

import { GeolocationService } from './geolocation-service.base';

import {
	GeolocationServiceNotAvailableError,
	GeolocationPermissionsDeniedOniOSError,
	GeolocationUnknownError
} from './geolocation-error';

const TAG = 'IOSGeolocationService';

export class IOSGeolocationService extends GeolocationService {
	constructor() {
		super();

		if (Capacitor.getPlatform() !== 'ios') {
			console.log(TAG, 'IOSGeolocationService instance is running on a non-iOS platform');
			throw new Error('IOSGeolocationService instance is running on a non-iOS platform');
		}
	}

	/** Get current position for mobile phones only (ionic web - for developing -
	 * should use the web version of this service).
	 * @returns Promise with GeolocationPosition or null if position cannot be resolved.
	 * @throws - GeolocationServiceNotAvailableError, GeolocationUnknownError
	 * and other permission errors.
	 */
	public async getCurrentPosition(): Promise<GeolocationPosition> {
		// checkPermission throws if no has permission, or returns true if perms granted.
		// Permission errors should be handeled by the calling CurrentLocationService
		// that should display an approporiate messge to user (how to grant perms, etc.)
		await this.checkAndRequestPermission();

		try {
			const coordinates: Position = await Geolocation.getCurrentPosition(this.positionOptions);
			return coordinates; // No need for type casting, Position as GeolocationPosition
			// are similar and coordinates of type Position conforms to the GeolocationPosition interface
		} catch (e) {
			console.log(TAG, 'Error obtaining Geolocation:', JSON.stringify(e))
			throw new GeolocationUnknownError('Error obtaining Geolocation', e);
		}
	}

	/** Check for Geolocation availability and location permission.
	 * @returns Promise with boolean
	 * - true - if geolocation can be used (is available and permissions granted)
	 * - false - is never returned in the iOS implementation (because if there's no permission
	 * we ask for it, and if it is denied after asking - the denial is final and we throw - see @throws)
	 * @throws - if geolocation cannot be used (not available or perms denied), or on other errors
	 */
	public async checkAndRequestPermission(): Promise<boolean> {
		let permissionStatus: PermissionStatus;

		try {
			// Geolocation.checkPermissions() will throw if system location
	 		// services are disabled or not available.
			permissionStatus = await Geolocation.checkPermissions();
		} catch (e) {
			// System location services are disabled or not avilable
			throw new GeolocationServiceNotAvailableError();
		}

		if (permissionStatus.location === 'denied' && permissionStatus.coarseLocation === 'denied') {
			// permissions were previously denied by the user --> Only to be allowed
			// by manually enabling them through the device settings.
			console.log(TAG, 'iOS geolocation permissions were previously denied by the user')
			throw new GeolocationPermissionsDeniedOniOSError();
		}

		if (permissionStatus.location === 'granted' || permissionStatus.coarseLocation === 'granted') {
			// permissions were previously granted by the user
			console.log(TAG, 'iOS geolocation permissions were previously granted by the user')
			return true;
		}

		// logical else for 'prompt' or 'prompt-with-rationale'
		try {
			permissionStatus = await Geolocation.requestPermissions();
		} catch (e) {
			// Unexpected error. If an error occurs, it should be thrown
			// in the previous code block by Geolocation.checkPermissions
			throw new GeolocationUnknownError('Unexpected error while requesting geolocation permission');
		}

		if (permissionStatus.location === 'denied' && permissionStatus.coarseLocation === 'denied') {
			// permissions were denied by the user explicitly -> Only to be allowed
			// by manually enabling them through the device settings.
			console.log(TAG, 'iOS geolocation permissions were denied by the user');
			throw new GeolocationPermissionsDeniedOniOSError();
		}

		if (permissionStatus.location === 'granted' || permissionStatus.coarseLocation === 'granted') {
			// permissions were granted by the user
			console.log(TAG, 'iOS geolocation permissions were granted by the user');
			return true;
		}

		console.log(TAG, 'Unexpected permissionStatus: ' + permissionStatus.location);
		throw new GeolocationUnknownError('Unexpected location permission status');
	}

	//TODO: Implement this for mobile (for now we were asked to develop only for web).
	public getAddressFromZipCode(zipCode: string): Promise<string> {
		return new Promise((resolve) => {
			resolve(zipCode);
		});
	}
}
