import { Pipe, PipeTransform } from '@angular/core';
import { isObjectEmpty } from '../utils/utils';

@Pipe({
	name: 'isShowImportantToKnow',
	pure: true,
})
export class IsShowImportantToKnow implements PipeTransform {
	transform(newBenefit: any, activeNetworkTabIndex: number): boolean {
		return (
			!isObjectEmpty(newBenefit.networks[activeNetworkTabIndex].reimbursement) ||
			newBenefit.gender ||
			!isObjectEmpty(newBenefit.networks[activeNetworkTabIndex].ageRestriction) ||
			!isObjectEmpty(newBenefit.networks[activeNetworkTabIndex].countLimitations) ||
			newBenefit.networks[activeNetworkTabIndex].preExistConditions?.length ||
			newBenefit.networks[activeNetworkTabIndex].notes?.length
		);
	}
}
