import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MenuItem } from '../../../../models/menu-item.model';
import { QuickAccessLocalize } from './quick-access.localize';
import { LanguageService } from '../../../../services/language.service';
import { IntercomService } from '../../../../services/intercom.service';
import { AppManagerService } from '../../../../services/app-manager.service';
import { Subject, takeUntil } from 'rxjs';
import { UserService } from '../../../../services/user.service';
import { UnleashService } from '../../../../services/unleash.service';
type MenuItemWithSelected = MenuItem & { isActive: boolean; selected?: boolean; action?: () => void };

@Component({
	selector: 'app-quick-access',
	templateUrl: './quick-access.component.html',
	styleUrls: ['./quick-access.component.scss'],
})
export class QuickAccessComponent implements OnInit {
	private _unsubscribe = new Subject<void>();

	public quickAccessItems: MenuItemWithSelected[] = [
		{
			id: 1,
			label: QuickAccessLocalize.chatItem,
			icon: 'question_mark.svg',
			analyticEvent: 'Home Page Coverage Info Click',
			action: () => {
				// TODO: Should change the string comparisson logic below to explicit flags on the language object
				if (this.languageService.currentLanguage === 'en' || this.languageService.currentLanguage === 'jhs') {
					this.router.navigate(['/coverage-info'], { fragment: 'Quick Access', preserveFragment: false });
					return;
				}
				this.intercomService.openMessenger();
			},
			isActive: true,
		},
		{
			id: 2,
			label: QuickAccessLocalize.careCostItem,
			icon: 'stethoscope.svg',
			link: '/care-and-costs',
			isActive: true,
		},
		{
			id: 3,
			label: QuickAccessLocalize.urgentCareItem,
			icon: 'urgent_care.svg',
			additionalIcon: 'coming_soon.svg',
			link: '/home',
			analyticEvent: 'Home Page Urgent Care',
			isActive: false,
		},
		{
			id: 4,
			label: QuickAccessLocalize.immediateCareTitle,
			icon: 'urgent_care.svg',
			link: '/immediate-care',
			analyticEvent: 'Immediate Care click',
			isActive: true,
		},
		{
			id: 5,
			label: QuickAccessLocalize.myAppsTitle,
			icon: 'my_apps.svg',
			link: '/my_apps',
			analyticEvent: 'My Apps click',
			isActive: false,
		},
	];

	constructor(
		private languageService: LanguageService,
		private intercomService: IntercomService,
		private router: Router,
		private userService: UserService,
		private unleashService: UnleashService
	) {


	}

	ngOnInit(): void {

		this.userService.user$.pipe(takeUntil(this._unsubscribe)).subscribe((user) => {
			if (!user?.isTalonTpa || !this.unleashService.isEnabled('BO-426-my-apps-page')) return;
			const talonWidgetItem = this.quickAccessItems.find((item) => item.id === 5);
			if (talonWidgetItem) {
				talonWidgetItem.isActive = !!user;
			}
		});
	}

	ngOnDestroy(): void {
		this._unsubscribe.next();
		this._unsubscribe.complete();
	}

}
