import {Cookies} from "./cookies";
import {getCookieDomain} from "./domain-resolver";

export class HybridStorage implements Storage {
    constructor(private storage: Storage) {}

    get length(): number {
        return this.storage.length
    }

    clear() {
        this.storage.clear()
        Cookies.removeCookie(Cookies.REFRESH_TOKEN_VALUE, getCookieDomain(window.location.hostname))
        Cookies.removeCookie(Cookies.REFRESH_TOKEN_NAME, getCookieDomain(window.location.hostname))
        Cookies.removeCookie(Cookies.LAST_AUTH_USER_TOKEN_NAME, getCookieDomain(window.location.hostname))
        Cookies.removeCookie(Cookies.LAST_AUTH_USER_TOKEN_VALUE, getCookieDomain(window.location.hostname))
    }

    key(index: number): string | null {
        return this.storage.key(index)
    }

    getItem(key: string): string | null {
        return this.storage.getItem(key)
    }

    removeItem(key: string) {
        if (key.includes('refreshToken')) {
            Cookies.removeCookie(Cookies.REFRESH_TOKEN_NAME, getCookieDomain(window.location.hostname))
            Cookies.removeCookie(Cookies.REFRESH_TOKEN_VALUE, getCookieDomain(window.location.hostname))
        }
        if (key.endsWith('LastAuthUser')) {
            Cookies.removeCookie(Cookies.LAST_AUTH_USER_TOKEN_NAME, getCookieDomain(window.location.hostname))
            Cookies.removeCookie(Cookies.LAST_AUTH_USER_TOKEN_VALUE, getCookieDomain(window.location.hostname))
        }

        this.storage.removeItem(key)
    }

    setItem(key: string, value: string) {
        if (key.endsWith('refreshToken')) {
            Cookies.setCookie(Cookies.REFRESH_TOKEN_NAME, key, getCookieDomain(window.location.hostname))
            Cookies.setCookie(Cookies.REFRESH_TOKEN_VALUE, value, getCookieDomain(window.location.hostname))
        }
        if (key.endsWith('LastAuthUser')) {
            Cookies.setCookie(Cookies.LAST_AUTH_USER_TOKEN_NAME, key, getCookieDomain(window.location.hostname))
            Cookies.setCookie(Cookies.LAST_AUTH_USER_TOKEN_VALUE, value, getCookieDomain(window.location.hostname))
        }

        this.storage.setItem(key, value)
    }
}