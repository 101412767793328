import { Injectable } from '@angular/core';
import { combineLatest, map, Observable, of, shareReplay, switchMap } from 'rxjs';
import { RxjsUtils } from '../../../utils/rxjs';
import { AllServicesStoreService } from 'src/app/services/stores/all-services-store/all-services-store.service';
import { UserService } from 'src/app/services/user.service';
import { UserBenefitsStoreService } from 'src/app/services/stores/user-benefits-store/user-benefits-store.service';
import { AdditionalBenefitsStoreService } from 'src/app/services/stores/additional-benefits-store/additional-benefits-store.service';

const DefaultTeasersOrder = {
	quickAccess: 1,
	telehealth: 2,
	deductible: 3,
	healthCards: 4,
	selectedBenefits: 5,
	preventiveCare: 6,
	myCareTeam: 7,
	planSelection: 8,
	quantumSupport: 9,
};

@Injectable({
	providedIn: 'root',
})
export class HomePageService {
	teasersOrder$: Observable<Record<keyof typeof DefaultTeasersOrder, number>> = of(DefaultTeasersOrder);
	userBenefitsMap$: Observable<Map<string, any>> = this.userService.userData$.pipe(
		switchMap((user) => this.userBenefitsStoreService.benefitsMap(user.uid)),
		shareReplay(1)
	);

	constructor(
		private allServicesStoreService: AllServicesStoreService,
		private userService: UserService,
		private userBenefitsStoreService: UserBenefitsStoreService,
		private additionalBenefitsStoreService: AdditionalBenefitsStoreService
	) {}

	public getMostPopularCoveredServicesAndAdditionalBenefits(amount: number): Observable<any[]> {
		return combineLatest([
			this.getMostPopularCoveredServices(amount),
			this.additionalBenefitsStoreService.get().pipe(RxjsUtils.isNotNil()),
		]).pipe(
			map(([mostPopularCoveredServices, additionalBenefits]) => {
				return additionalBenefits.length ? additionalBenefits : mostPopularCoveredServices;
			}),
			shareReplay(1)
		);
	}
	public getMostPopularCoveredServices(amount: number): Observable<any[]> {
		return combineLatest([
			this.allServicesStoreService.get().pipe(
				RxjsUtils.isNotNil(),
				map((services) => services.sort((a, b) => b.weight - a.weight))
			),
			this.userBenefitsMap$,
		]).pipe(
			map(([services, userBenefitsMap]) => {
				return services.filter((service) => userBenefitsMap.has(service._id)).slice(0, amount);
			}),
			shareReplay(1)
		);
	}
}
