import { Injectable } from '@angular/core';
import { Observable, take, tap, map, switchMap } from 'rxjs';
import { AppManagerService } from '../services/app-manager.service';
import { UserPlanDataStoreService } from '../services/stores/user-plan-data-store/user-plan-data-store.service';

import { RxjsUtils } from '../utils/rxjs';
import { UserApiService } from '../services/api/user-api/user-api.service';

@Injectable({
	providedIn: 'root',
})
export class ContractAndAdditionalBenefitsGuard {
	constructor(
		private userPlanDataStoreService: UserPlanDataStoreService,
		private userApiService: UserApiService,
		private appManager: AppManagerService
	) {}

	canActivate(): Observable<boolean> {
		return this.userPlanDataStoreService.hasContract().pipe(
			RxjsUtils.isNotNil(),
			take(1),
			switchMap((hasContract) =>
				this.userApiService.allBenefits().pipe(
					map((response) => response?.additionalBenefits?.length > 0),
					map((hasBenefits) => hasContract || hasBenefits) // Combine both results
				)
			),
			tap((canActivate) => {
				if (!canActivate) {
					this.appManager.openBlockedAccountModal();
				}
			})
		);
	}
}
