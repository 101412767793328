<button
	class="button"
	[ngClass]="[hierarchy, size]"
	(click)="handleClick($event)"
	[type]="type"
	[class.disabled]="isDisabled"
	[class.isFullWidth]="isFullWidth"
	[class.isElevated]="isElevated"
	[class.isCurrentColor]="isCurrentColor"
	[class.size-with-label]="labelText || labelTemplate"
	[class.active]="!isDisabled && !isLoading"
	[class.skeleton-loader]="isSkeletonLoader"
	[class.static]="isStatic"
	[class.without-border]="withoutBorder"
	[disabled]="isDisabled || isLoading"
>
	<div class="loader" [class.active]="isLoading">
		<ripple-loader [color]="loaderOnColoredButton()" [size]="getLoaderSize()"> </ripple-loader>
	</div>
	<div class="button-content" [class.loading]="isLoading">
		<ripple-icon
			*ngIf="leadingIcon"
			[name]="leadingIcon"
			[size]="getIconSize()"
			[isSkeletonLoader]="isSkeletonLoader"
		>
		</ripple-icon>
		<ng-container *ngIf="labelTemplate">
			<ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
		</ng-container>
		{{ labelText }}
		<ripple-icon
			*ngIf="trailingIcon"
			[name]="trailingIcon"
			[size]="getIconSize()"
			[isSkeletonLoader]="isSkeletonLoader"
		></ripple-icon>
	</div>
</button>
