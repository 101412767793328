import { Injectable } from '@angular/core';
import { RibbonHealthService } from '../../../services/ribbon.service';
import { Store } from '../../../utils/store/store';
import { RibbonSearchEntity, RibbonSearchOptions } from '../../../modules/plan-selection/plan-selection.types';
import { RxjsUtils } from '../../../utils/rxjs';
import { shareReplay } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class RibbonPCTStoreService extends Store<any, any> {
	constructor(private ribbonHealthService: RibbonHealthService) {
		super();
	}

	protected retrieve(context: RibbonSearchOptions) {
		switch (context.entity) {
			case RibbonSearchEntity.Npis:
				return this.ribbonHealthService.getRibbonProvidersByNPIs(context.parameter);
			case RibbonSearchEntity.Uuids:
				return this.ribbonHealthService.getRibbonFacilitiesByUUIDs(context.parameter);
		}
	}

	getByContext(context: RibbonSearchOptions) {
		return this.get(context).pipe(RxjsUtils.isNotNil(), shareReplay(1));
	}
}
