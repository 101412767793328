<div [ngClass]="{ mobile: mobileView, desktop: !mobileView }">
	<div class="benefit-wrapper">
		<div class="benefit-header">
			<ng-container [ngSwitch]="planClassType$ | async">
				<h4 *ngSwitchCase="PlanClassType.EOC" class="text">
					{{ 'You have coverage for' | translate }} <strong [innerHtml]="message.title"></strong>
				</h4>
				<h4 *ngSwitchCase="PlanClassType.SBC" class="text">
					{{
						'Based on your currently available summary information of your core services, you have coverage for'
							| translate
					}}
					<strong [innerHtml]="message.title"></strong>
				</h4>
			</ng-container>

			<div
				[ngClass]="message.planType"
				class="plantype-indicator"
				*ngIf="showPlantypeIndicator$ | async"
				[appTooltipWithTouch]="tooltipInfo"
				(tooltipOpened)="onTrackTooltip(message.planType)"
			>
				<ng-template #tooltipInfo>
					<div>
						{{ 'Covered by' | translate }} {{ message.planType | titlecase }} {{ 'Plan' | translate }}
					</div>
				</ng-template>
			</div>
		</div>

		<app-healthee-content-slider>
			<!-- Blue Options -->
			<div
				*ngIf="message.blueOptionsNetwork && !networkStructureSupportsAdditionalNetworks(message.networkStructure)"
				class="py-15 px-23 border-input-border radius-16 color-network-box"
			>
				<div>
					<div *ngIf="message.planCareType !== 'Open_acsess'" class="font-weight-500 font-size-17 mb-13">
						{{ 'Blue Options' | translate }}
					</div>
					<div class="d-flex align-items-center font-size-14 mb-10">
						<img
							class="icon-size-18 mr-10"
							[src]="getDeductibleImgSrc(message.blueOptionsNetworkSubjectToDeductibles)"
						/>
						<div
							[innerHtml]="getDeductibleTooltipText(message.blueOptionsNetworkSubjectToDeductibles)"
						></div>
					</div>
					<div class="d-flex align-items-center font-size-14">
						<img class="icon-size-18 mr-10" [src]="calcBenefitIcon(message.blueOptionsNetwork)" />
						<div [innerHtml]="message.blueOptionsNetwork"></div>
					</div>
				</div>
			</div>

			<!-- In-network -->
			<div *ngIf="!networkStructureSupportsAdditionalNetworks(message.networkStructure)">
				<div class="py-15 px-23 color-network-box network" [ngClass]="message.networkStructure">
					<div *ngIf="message.planCareType !== 'Open_acsess'" class="font-weight-500 font-size-17 mb-13">
						{{ 'In-network' | translate }}
					</div>
					<div class="d-flex align-items-center font-size-14 mb-10">
						<img
							class="icon-size-18 mr-10"
							[src]="getDeductibleImgSrc(message.inNetworkSubjectToDeductibles)"
						/>
						<div [innerHtml]="getDeductibleTooltipText(message.inNetworkSubjectToDeductibles)"></div>
					</div>
					<div class="d-flex align-items-center font-size-14">
						<img class="icon-size-18 mr-10" [src]="calcBenefitIcon(message.inNetwork)" />
						<div [innerHtml]="message.inNetwork"></div>
					</div>
				</div>
				<div
					*ngIf="message.networkStructure === NetworkStructure.RBP"
					class="py-15 px-23 color-network-box rbp-indemnity-note"
				>
					<span class="font-weight-500 font-size-17 mb-13">{{ 'Note:' | translate }}</span>
					<span class="font-size-14">{{
						' Not all healthcare providers may participate in an RBP arrangement, which could lead to balance-billing.'
							| translate
					}}</span>
				</div>
				<div
					*ngIf="message.networkStructure === NetworkStructure.INDEMNITY"
					class="py-15 px-23 color-network-box rbp-indemnity-note"
				>
					<span class="font-weight-500 font-size-17 mb-13">{{ 'Note:' | translate }}</span>
					<span class="font-size-14">{{
						' The plan pays for costs up to the UCR price and will refund you based on this amount once you file a claim.'
							| translate
					}}</span>
				</div>
			</div>

			<div
				*ngIf="message.planCareType !== 'Open_acsess' && message.networkStructure === NetworkStructure.SINGLE"
				class="py-15 px-23 border-input-border radius-16 color-network-box"
			>
				<div class="font-weight-500 font-size-17 mb-13">{{ 'Out-of-network' | translate }}</div>
				<div class="d-flex align-items-center font-size-14 mb-10">
					<img
						class="icon-size-18 mr-10"
						[src]="getDeductibleImgSrc(message.outNetworkSubjectToDeductibles)"
					/>
					<div [innerHtml]="getDeductibleTooltipText(message.outNetworkSubjectToDeductibles)"></div>
				</div>
				<div class="d-flex align-items-center font-size-14">
					<img class="icon-size-18 mr-10" [src]="calcBenefitIcon(message.outNetwork)" />
					<div [innerHtml]="message.outNetwork"></div>
				</div>
			</div>

			<!-- additionalNetworks -->
			<!-- Note regadring 'No Network': When networkStructure = 'no_network', there will only be one network in the 'additionalNetworks'
			 	array, called 'No network'. It's title, however, has to be displayed as "Coverage" instead of "No Network".
			-->
			<ng-container *ngFor="let item of message?.additionalNetworks">
				<div *ngIf="item.value" class="py-15 px-23 border-input-border radius-16 color-network-box">
					<div class="font-weight-500 font-size-17 mb-13">
					{{
						item?.name.toLowerCase() === NO_NETWORK_NAME
							? ('Coverage' | translate)
							: item?.name
					}}
				</div>

					<div class="d-flex align-items-center font-size-14 mb-10">
						<img class="icon-size-18 mr-10" [src]="getDeductibleImgSrc(item.subjectToDeductible)" />
						<div [innerHtml]="getDeductibleTooltipText(item.subjectToDeductible)"></div>
					</div>
					<div class="d-flex align-items-center font-size-14">
						<img class="icon-size-18 mr-10" [src]="calcBenefitIcon(item.value)" />
						<div [innerHtml]="item.value"></div>
					</div>
				</div>
			</ng-container>
		</app-healthee-content-slider>
	</div>
</div>
