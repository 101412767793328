import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NavController } from '@ionic/angular';
import { combineLatestWith, map, Observable, shareReplay, switchMap } from 'rxjs';
import { TrackingService } from '../../../../services/tracking.service';
import { FavoriteProvidersStoreService } from '../../../../services/stores/favorite-providers-store/favorite-providers-store.service';
import { ProviderDetailsStoreService } from '../../../../services/stores/provider-details-store/provider-details-store.service';
import { RxjsUtils } from '../../../../utils/rxjs';
import { providerCardToFavoriteProvider } from '../../../my-care-team/helpers/providers.helpers';
import { MyCareTeamService } from '../../../my-care-team/services/my-care-team.service';
import { Provider, ProviderDetails } from '../helpers/providers.helpers';
import { mapProviderLocations } from '../helpers/providers-search.helper';

@Component({
	selector: 'app-provider-details-page',
	templateUrl: './provider-details-page.component.html',
	styleUrls: ['./provider-details-page.component.scss'],
})
export class ProviderDetailsPageComponent {
	npi$ = this.route.params.pipe(map((params: Params) => params.npi));
	location_uuid$ = this.route.queryParams.pipe(map((params: Params) => params.location_uuid));
	providerData$: Observable<ProviderDetails> = this.createProviderDataObservable().pipe(
		combineLatestWith(this.location_uuid$),
		map(([provider, locationUuid]) => mapProviderLocations(provider, locationUuid))
	);
	isFavorite$ = this.npi$.pipe(switchMap((npi: string) => this.favoriteProvidersStoreService.isFavorite(npi)));

	constructor(
		private providerDetailsStore: ProviderDetailsStoreService,
		private route: ActivatedRoute,
		private myCareTeamService: MyCareTeamService,
		private favoriteProvidersStoreService: FavoriteProvidersStoreService,
		private navCtrl: NavController,
		private trackingService: TrackingService
	) {}

	onClickBack() {
		this.navCtrl.back();
	}

	createProviderDataObservable(): Observable<ProviderDetails> {
		return this.npi$.pipe(
			switchMap((npi: string) => this.providerDetailsStore.get(npi).pipe(RxjsUtils.isNotNil(), shareReplay(1)))
		);
	}

	onIsFavoriteChange(isFavorite: boolean, provider: Provider): void {
		const favoriteProviderInput = providerCardToFavoriteProvider(provider);

		if (!isFavorite) {
			this.myCareTeamService.removeFromFavorites(favoriteProviderInput).subscribe({
				next: () => {
					this.trackFavorites(isFavorite, provider);
				},
			});
			return;
		}

		this.myCareTeamService.addToFavorites(favoriteProviderInput);
		this.trackFavorites(isFavorite, provider);
	}

	public trackFavorites(isFavorite, provider) {
		const specialty = provider?.specialties[0];
		const inNetwork = provider?.coverage?.isInNetwork || false;
		const npi = provider?.npi;

		const event = isFavorite ? 'MCT - Provider card: add favorites' : 'MCT - Provider card: remove favorites';
		this.track(event, { specialty, inNetwork, npi });
	}

	private track(event, metaData = {}) {
		this.trackingService.trackClientEvent(event, metaData);
	}
}
