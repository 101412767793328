import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs';
import { PlanSelectionStoreService } from 'src/app/services/stores/plan-selection-store/plan-selection-store.service';
import { RxjsUtils } from 'src/app/utils/rxjs';

@Component({
	selector: 'app-privacy-policy-banner',
	templateUrl: './privacy-policy-banner.component.html',
	styleUrls: ['./privacy-policy-banner.component.scss'],
})
export class PrivacyPolicyBannerComponent implements OnInit {
	public isTrinet: boolean = false;
	constructor(private planSelectionStoreService: PlanSelectionStoreService) {}

	ngOnInit(): void {
		this.planSelectionStoreService
			.get()
			.pipe(RxjsUtils.isNotNil(), take(1))
			.subscribe((data) => {
				this.isTrinet = data.companyData.isTriNet;
			});
	}
}
