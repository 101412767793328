import * as Sentry from '@sentry/angular';
import { isNil } from './is/is-nil';

const PROJECT_NAME = 'healthee app';
const IGNORED_ERRORS = [
	'network error',
	'not connected to the internet',
	'geocoder_geocode',
	'axioserror: request aborted',
	'reaching the internet is taking too long',
	'no elements in sequence'
];

export function captureExceptionSentry(error: any, tag?: string, captureContext?: any) {
	let msg;
	if (isNil(error)) {
		msg = 'An error occurred';
	} else if (error.message) {
		msg = error.message;
	} else if (error.name) {
		msg = error.name;
	} else {
		msg = error;
	}

	const errorMessage = `${PROJECT_NAME} [${tag}]: ${msg}`;

	const lowerCaseErrorMessage = errorMessage.toLowerCase();
	for (const ignoredError of IGNORED_ERRORS) {
		if (lowerCaseErrorMessage.includes(ignoredError)) {
			return;
		}
	}

	const errorWithProjectInfo = new Error(errorMessage);
	if (error?.stack) {
		errorWithProjectInfo.stack = error.stack;
	}

	if (captureContext?.extra) {
		captureContext.extra.tag = tag;
	}

	Sentry.captureException(errorWithProjectInfo, captureContext);
}
