<div class="header">
	<h2>{{ 'Benefit Cost Breakdown' | translate }}</h2>
	<p>{{ 'How costs are split between you and your payer' | translate }}</p>
</div>

<div class="info-panel-frames">
	<app-service-panel-info-frame
		*ngIf="firstLineText?.text"
		[headline]="firstLineText.headline"
		[text]="firstLineText.text"
		[ps]="firstLineText.ps"
		[hasPlanLevelDeductible]="deductiblesService.hasPlanLevelDeductible"
		[showTooltip]="firstLineText.showDeductibleTooltip"
	></app-service-panel-info-frame>

	<app-service-panel-info-frame
		*ngIf="secondLineText?.text"
		[headline]="secondLineText.headline"
		[text]="secondLineText.text"
		[ps]="secondLineText.ps"
		[hasPlanLevelDeductible]="deductiblesService.hasPlanLevelDeductible"
		[showTooltip]="secondLineText.showDeductibleTooltip"
	></app-service-panel-info-frame>

	<div
		*ngIf="(userHasHRAPlan$ | async) && !serviceIsFullyCoveredByPayer"
		class="hra-note-wrapper"
	>
		<span class="note-title">
			{{ 'Note' | translate }}:
		</span>

		{{ 'Your HRA may help cover costs for this benefit. Check your' | translate }}
		<a href="#" [routerLink]="'/health-profile/medical-spendings'">{{ 'plan details' | translate }}</a>
		{{ 'for specifics.' | translate }}
	</div>

	<app-service-panel-teleheatlh-frame
		*ngIf="(isTelehealth$ | async) === true && telehealthDataService.isServiceLinkedWithTelehealth(this.serviceId)"
	></app-service-panel-teleheatlh-frame>
</div>

<app-service-panel-deductible
	[subjectToDeductible]="subjectToDeductible"
	[networkTitle]="networkTitle"
></app-service-panel-deductible>
