import { Capacitor } from '@capacitor/core';
import { Geolocation, Position, PermissionStatus } from '@capacitor/geolocation';

import { GeolocationService } from './geolocation-service.base';

import {
	GeolocationServiceNotAvailableError,
	GeolocationPermissionsDeniedOnAndroidError,
	GeolocationUnknownError,
	GeolocationAccessDeniedTemporarilyError
} from './geolocation-error';

const TAG = 'AndroidGeolocationService';

export class AndroidGeolocationService extends GeolocationService {
	constructor() {
		super();

		if (Capacitor.getPlatform() !== 'android') {
			console.log(TAG, 'AndroidGeolocationService instance is running on a non-Android platform');
			throw new Error('AndroidGeolocationService instance is running on a non-Android platform');
		}
	}

	/** Get current position for mobile phones only (ionic web - for developing -
	 * should use the web version of this service).
	 * @returns Promise with GeolocationPosition or null if position cannot be resolved.
	 * @throws - GeolocationServiceNotAvailableError, GeolocationUnknownError
	 * and other permission errors.
	 */
	public async getCurrentPosition(): Promise<GeolocationPosition> {
		// Permission errors should be handeled by the calling CurrentLocationService
		// that should display an approporiate messge to user (how to grant perms, etc.)
		const hasPermissions = await this.checkAndRequestPermission();

		if (!hasPermissions) {
			throw new GeolocationAccessDeniedTemporarilyError();
		}

		try {
			const coordinates: Position = await Geolocation.getCurrentPosition(this.positionOptions);
			return coordinates; // No need for type casting, Position as GeolocationPosition
			// are similar and coordinates of type Position conforms to the GeolocationPosition interface
		} catch (e) {
			console.log(TAG, 'Error obtaining Geolocation:', JSON.stringify(e))
			throw new GeolocationUnknownError('Error obtaining Geolocation', e);
		}
	}

	/** Check for Geolocation availability and location permission.
	 * @returns Promise with boolean
	 * - true - if geolocation can be used (is available and permissions granted)
	 * - false - if geolocation can be used (is available), but permissions are not granted (should prompt)
	 * @throws - if geolocation cannot be used (not available or perms denied), or on other errors
	 */
	public async checkAndRequestPermission(): Promise<boolean> {
		let permissionStatus: PermissionStatus;

		try {
			// Geolocation.checkPermissions() will throw if system location
	 		// services are disabled or not available.
			permissionStatus = await Geolocation.checkPermissions();
		} catch (e) {
			// System location services are disabled or not avilable
			throw new GeolocationServiceNotAvailableError();
		}

		if (permissionStatus.location === 'granted' || permissionStatus.coarseLocation === 'granted') {
			// permissions were previously granted by the user
			console.log(TAG, 'Android geolocation permissions were previously granted by the user')
			return true;
		}

		if (permissionStatus.location === 'denied' && permissionStatus.coarseLocation === 'denied') {
			// permissions were previously denied by the user --> In android the user
			// can be displayed a rationale and asked again
			console.log(TAG, 'Andrioid geolocation permissions were previously denied by the user')
		}

		// logical else for: 'prompt' or 'prompt-with-rationale' or 'denied'

		try {
			permissionStatus = await Geolocation.requestPermissions();
		} catch (e) {
			// Unexpected error. If an error occurs, it should
			// be thrown in the previous code block by Geolocation.checkPermissions
			throw new GeolocationUnknownError('Unexpected error while requesting geolocation permission');
		}

		if (permissionStatus.location === 'granted' || permissionStatus.coarseLocation === 'granted') {
			// permissions were granted by the user
			console.log(TAG, 'Android geolocation permissions were granted by the user');
			return true;
		}

		if (permissionStatus.location === 'denied' && permissionStatus.coarseLocation === 'denied') {
			// permissions were denied by the user explicitly -> Only to be allowed
			// by manually enabling them through the device settings.
			console.log(TAG, 'Android geolocation permissions were denied by the user');
			throw new GeolocationPermissionsDeniedOnAndroidError();
		}

		if (permissionStatus.location === 'prompt-with-rationale' || permissionStatus.coarseLocation === 'prompt-with-rationale') {
			// permissions were denied once. Android gives another chance to display
			// a rationale and ask the user again.
			// the next call to Geolocation.requestPermissions - if denied - the system won't trigger
			// or prompt the user anymore (final permission denial).

			const shouldTryAgain = await this.displayPermissionsRationale();

			if (shouldTryAgain) {
				permissionStatus = await Geolocation.requestPermissions();
			}

			if (permissionStatus.location === 'granted' || permissionStatus.coarseLocation === 'granted') {
				// permissions were granted by the user after displaying rationale
				console.log(TAG, 'Android geolocation permissions were granted by the user after displaying rationale');
				return true;
			}

			if (permissionStatus.location === 'denied' || permissionStatus.coarseLocation === 'denied') {
				// permissions were granted by the user after displaying rationale
				console.log(TAG, 'Android geolocation permissions were denied by the user');
				throw new GeolocationPermissionsDeniedOnAndroidError()
			}

			// this is NOT denied (permission denial is not final), the user can ask for permissions one more time.
			return false;
		}

		throw new GeolocationUnknownError('Unexpected location permission status');
	}

	//TODO: Implement this for mobile (for now we were asked to develop only for web).
	public getAddressFromZipCode(zipCode: string): Promise<string> {
		return new Promise((resolve) => {
			resolve(zipCode);
		});
	}
}
