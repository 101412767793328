import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

const ALLOW_ONLY_LINE_BREAK_TAGS = true;

@Pipe({ name: 'safeLineBreaks' })
export class SafeLineBreaksPipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {}

	transform(content: string): SafeHtml {
		const sanitizedContent = ALLOW_ONLY_LINE_BREAK_TAGS
			? this.removeHtmlTagsExceptLineBreaks(content)
			: content;

		const unifiedLineBreaks = this.formatHtmlLineBreaks(sanitizedContent);
		return this.sanitizer.sanitize(SecurityContext.HTML, unifiedLineBreaks);
	}

	private removeHtmlTagsExceptLineBreaks(content: string) {
		return content.replace(/<(?!br\b)[^>]+>/gi, '');
	}

	private formatHtmlLineBreaks(content: string): string {
		return content.replace(/\n/g, '<br />').replace(/<br>/gi, '<br />').replace(/<br >/gi, '<br />');
	}
}
