import { Component, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { rippleIconNames } from '../icons/icons';
import { ICON_SIZE } from '../icons/icons.component';
import { LoaderSize } from '../loader/loader.component';
@Component({
	selector: 'ripple-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
	@Input() set label(value: string | TemplateRef<any>) {
		this.labelText = typeof value === 'string' ? value : '';
		this.labelTemplate = typeof value === 'string' ? null : value;
	}

	@Input() hierarchy: ButtonHierarchy = ButtonHierarchy.primary;
	@Input() size: ButtonSize = ButtonSize.small;
	@Input() type: ButtonType = ButtonType.button;
	@Input() isLoading: boolean = false;
	@Input() isDisabled: boolean = false;
	@Input() isFullWidth: boolean = false;
	@Input() isElevated: boolean = false;
	@Input() withoutBorder: boolean = false;
	@Input() isCurrentColor: boolean = false;
	@Input() isStatic: boolean = false;
	@Input() iconSize: ICON_SIZE | null = null;

	@Input() leadingIcon: rippleIconNames;
	@Input() trailingIcon: rippleIconNames;

	@Input() isSkeletonLoader: boolean = false;

	@Output() public onClick = new EventEmitter<MouseEvent>();

	public labelText: string = '';
	public labelTemplate: TemplateRef<any> | null = null;

	public handleClick(event: MouseEvent): void {
		if (!this.isDisabled && !this.isSkeletonLoader && !this.isLoading) {
			this.onClick.emit(event);
		}
	}

	public loaderOnColoredButton(): string {
		if (this.hierarchy === ButtonHierarchy.primary) {
			return 'white';
		}
	}

	public getLoaderSize(): LoaderSize {
		if (this.size === ButtonSize.large) return LoaderSize.small;
		return LoaderSize.xsmall;
	}

	public getIconSize(): ICON_SIZE {
		if (this.iconSize) {
			return this.iconSize;
		}

		if (this.size === ButtonSize.xsmall) {
			return ICON_SIZE.size20;
		}

		return ICON_SIZE.size24;
	}
}

export enum ButtonType {
	'button' = 'button',
	'submit' = 'submit',
}
export enum ButtonHierarchy {
	'primary' = 'primary',
	'secondary' = 'secondary',
	'tertiary' = 'tertiary',
	'link' = 'link',
	'accent' = 'accent',
}
export enum ButtonSize {
	'contentmax' = 'content-max',
	'xsmall' = 'xs',
	'small' = 'sm',
	'medium' = 'md',
	'large' = 'lg',
}
