import { PlanData } from '../../../models/plan-data.model';
import { isNil } from '../../../utils/is/is-nil';
import { NetworkStructure } from '../../../models/chat.model';

export const retrieveDeductiblesFromPlan = (plan: PlanData): { inNetwork: string; outNetwork: string } => {
	const defaultTexts = {inNetwork: '', outNetwork: ''};
	const networkStructure = plan?.contract?.networkStructure;
	if (networkStructure === NetworkStructure.MN || networkStructure === NetworkStructure.MTN) {
		if (!plan?.contract?.additionalNetworks?.length || plan.contract.additionalNetworks.length < 2) {
			return defaultTexts;
		}
		const inNetwork = plan.contract.additionalNetworks[0];
		const outNetwork = plan.contract.additionalNetworks[plan.contract.additionalNetworks.length - 1];
		if (inNetwork?.deductible?.individual?.text) {
			defaultTexts.inNetwork = inNetwork?.deductible?.individual?.text ?? "";
		}
		if (outNetwork?.deductible?.individual?.text) {
			defaultTexts.outNetwork = outNetwork?.deductible?.individual?.text ?? "";
		}
	} else {
		if (isNil(plan?.contract?.deductible?.individual)) {
			return defaultTexts;
		}
		defaultTexts.inNetwork = plan.contract.deductible.individual.inNetwork.text;
		defaultTexts.outNetwork = plan.contract.deductible.individual.outNetwork.text;
	}
	defaultTexts.inNetwork = transformDeductible(defaultTexts.inNetwork);
	defaultTexts.outNetwork = transformDeductible(defaultTexts.outNetwork);
	console.log("opneeee", defaultTexts);
	return defaultTexts;
};

export const defaultDeductibles = {
	inNetwork: '',
	outNetwork: '',
};

/**
 * Deductible string should have:
 * 1. number (mandatory)
 * 2. dollar sign prefix (optional)
 * 3. comma (optional)
 * 4. dots (optional)
 */
export const isValidDeductible = (deductible: string): boolean =>
	/^\$?\d{1,3}(,?\d{3})*(\.\d+)?$/.test(deductible.trim());

export const transformDeductible = (value: string): string => (isValidDeductible(value) ? value.trim() : '');
