<div class="search-bar-container" [ngClass]="{ 'after-search': shorterContent }">
	<img
		class="sun"
		[ngClass]="{ 'no-img': shorterContent }"
		src="/assets/images/providers-search/sun-desktop.svg"
		alt="img"
	/>

	<div class="search-bar-container__header" [class.no-title]="shorterContent">
		<ripple-button
			*ngIf="shouldDisplayBackButton"
			hierarchy="link"
			leadingIcon="arrowLeft"
			[isCurrentColor]="true"
			(onClick)="goBack()"
		></ripple-button>

		<div class="title">
			{{ 'Find a provider' | translate }}
		</div>
	</div>
	<div class="search-wrapper">
		<div class="btn-container" [class.with-form]="!showMobileMapView">
			<div>
				<button
					*ngIf="!showEmptyState && shorterContent && !showMobileMapView"
					(click)="onClickBack()"
					class="back-button"
				>
					<ripple-icon name="chevronLeft"></ripple-icon>
				</button>
			</div>

			<div *ngIf="(isMobile$ | async) && shorterContent" (click)="toggleMobileMapView()" class="map-buttons">
				<button *ngIf="!showMobileMapView" class="map-button">
					{{ 'Map' | translate }}
					<div class="floating-btn-icon"><ripple-icon name="map"></ripple-icon></div>
				</button>
				<button *ngIf="showMobileMapView" class="map-button">
					{{ 'List' | translate }}
					<div class="floating-btn-icon"><ripple-icon name="list"></ripple-icon></div>
				</button>
			</div>
		</div>
		<!-- desktop form -->
		<form
			*ngIf="(uiService.isMobileDeviceScreen() | async) === false"
			[formGroup]="searchForm"
			class="desktop-view form-wrapper"
		>
			<div class="search-content">
				<ng-container *ngTemplateOutlet="providerInput"></ng-container>
			</div>
			<div class="search-content">
				<ng-container *ngTemplateOutlet="addressInput"></ng-container>
			</div>

			<div class="d-flex align-items-center search-button-wrapper">
				<button
					class="healthee-btn sm primary"
					[disabled]="showAutocompleteLoader || showLocationLoader"
					(click)="onFormSubmit()"
				>
					{{ 'Find Providers' | translate }}
				</button>
			</div>
		</form>
		<!-- mobile collapsed one input view -->
		<div
			*ngIf="isMobileCollapsed && (uiService.isMobileDeviceScreen() | async) === true && !showMobileMapView"
			class="mobile-view collapsed-input-wrapper"
		>
			<div class="collapsed-input" (click)="setMobileInputsCollapsed(false)">
				<ripple-icon name="search"></ripple-icon>
				<div>
					<p class="highlight">{{ searchForm?.get('value').value }}</p>
					<p>{{ searchForm?.get('address').value }}</p>
				</div>
			</div>

			<ripple-button
				leadingIcon="filters"
				iconSize="size-32"
				[withoutBorder]="true"
				size="xs"
				[isCurrentColor]="true"
				[isFullWidth]="true"
				hierarchy="tertiary"
				(onClick)="
					healtheeDialogService.open({
						templateRef: dialogFilters,
						actionsTemplateRef: dialogFiltersActions,
						title: modalTitle,
						hasCloseButton: true
					})
				"
			></ripple-button>
		</div>
		<!-- mobile two input view -->
		<div
			*ngIf="!isMobileCollapsed && (uiService.isMobileDeviceScreen() | async) === true && !showMobileMapView"
			class="form-wrapper mobile-view"
		>
			<div class="search-input" (click)="setMobileSearchAndFocus(SearchFieldEnum.provider)">
				<div class="icon">
					<ripple-icon name="search"></ripple-icon>
				</div>
				<p>
					{{ searchForm?.get('value').value }}
					<span *ngIf="!searchForm?.get('value').value" class="placeholder">
						{{ 'Doctor name, Specialty, Facility' | translate }}
					</span>
				</p>
			</div>
			<div class="search-input" (click)="setMobileSearchAndFocus(SearchFieldEnum.location)">
				<div class="icon">
					<ripple-icon name="location"></ripple-icon>
				</div>
				<p>
					{{ searchForm?.get('address').value }}
					<span *ngIf="!searchForm?.get('address').value" class="placeholder">
						{{ 'City, State or Zip code' | translate }}
					</span>
				</p>
			</div>
		</div>
		<!-- mobile full screen -->
		<form
			*ngIf="(uiService.isMobileDeviceScreen() | async) === true && !showMobileMapView"
			[formGroup]="searchForm"
			class="mobile-view form-wrapper"
		>
			<div class="search-content mobile" [hidden]="mobileSearchOpen !== SearchFieldEnum.provider">
				<div class="mobile-search-header">
					<span class="header-close close-btn" (click)="setMobileSearchAndFocus(null)">
						<ripple-icon name="arrowLeft"></ripple-icon>
					</span>
					<ng-container *ngTemplateOutlet="providerInput"></ng-container>
				</div>
			</div>

			<div class="search-content mobile" [hidden]="mobileSearchOpen !== SearchFieldEnum.location">
				<div class="mobile-search-header">
					<span class="header-close close-btn" (click)="setMobileSearchAndFocus(null)">
						<ripple-icon name="arrowLeft"></ripple-icon>
					</span>
					<ng-container *ngTemplateOutlet="addressInput"></ng-container>
				</div>
			</div>
			<div *ngIf="!isMobileCollapsed" class="d-flex align-items-center search-button-wrapper">
				<button
					class="healthee-btn sm primary"
					(click)="onFormSubmit()"
					[disabled]="showAutocompleteLoader || showLocationLoader"
				>
					{{ 'Find Providers' | translate }}
				</button>
				<ripple-button
					leadingIcon="filters"
					iconSize="size-32"
					[withoutBorder]="true"
					size="xs"
					[isCurrentColor]="true"
					[isFullWidth]="true"
					hierarchy="tertiary"
					(onClick)="
						healtheeDialogService.open({
							templateRef: dialogFilters,
							actionsTemplateRef: dialogFiltersActions,
							title: modalTitle,
							hasCloseButton: true
						})
					"
				></ripple-button>
			</div>
		</form>
	</div>

	<div *ngIf="showFilters" class="filters-form d-none sm-d-flex align-items-center pt-20" #filterWrapperRef>
		<ng-container *ngIf="!isOverflowing; else filterWrapperWithSlider">
			<ng-container *ngTemplateOutlet="filterContent"></ng-container>
		</ng-container>
	</div>
</div>

<app-provider-search-empty-state
	*ngIf="showEmptyState && !showMobileMapView"
	[defaultAddress]="userSelectedAddress"
	(openFiltersEvent)="
		healtheeDialogService.open({
			templateRef: dialogFilters,
			actionsTemplateRef: dialogFiltersActions,
			title: modalTitle,
			hasCloseButton: true
		})
	"
	(selectTopRatedProviderEvent)="selectTopRatedProviderHandler($event)"
></app-provider-search-empty-state>

<ng-template #providerInput>
	<div class="search-input" [formGroup]="searchForm">
		<div class="colored-icon">
			<ripple-icon name="search"></ripple-icon>
		</div>
		<input
			type="text"
			class="fs-unmask"
			matInput
			(click)="onInputClick()"
			#inputNative
			placeholder="{{ 'Doctor name, Specialty, Facility' | translate }}"
			formControlName="value"
			[matAutocomplete]="autoGroup"
			(keyup.enter)="onFormSubmit()"
		/>
		<ripple-loader *ngIf="showAutocompleteLoader" [size]="'xs'" [color]="'gray'"></ripple-loader>
		<mat-autocomplete
			#autoGroup="matAutocomplete"
			class="autocomplete-container providers-autocomplete-container"
			(optionSelected)="onOptionSelected($event)"
			[autoActiveFirstOption]="isFirstOptionHighlighted"
		>
			<ng-container>
				<ng-container *ngIf="!inputNative.value && (attributeGroups[5].options | async) as topSpecialties">
					<mat-optgroup [label]="attributeGroups[5].name | titlecase" *ngIf="topSpecialties.length > 0">
						<mat-option
							[id]="attributeGroups[5].id + '_' + i"
							*ngFor="let topSpecialty of topSpecialties; let i = index"
							[value]="topSpecialty"
						>
							<span [innerHTML]="topSpecialty | highlight : inputNative.value"></span>
						</mat-option>
					</mat-optgroup>
				</ng-container>
				<ng-container *ngIf="inputNative.value && attributeGroups[7].options | async as searchByNeeds">
					<mat-optgroup [label]="attributeGroups[7].name | titlecase" *ngIf="searchByNeeds.length > 0">
						<mat-option
							[id]="attributeGroups[7].id + '_' + i"
							*ngFor="let searchByNeed of searchByNeeds; let i = index"
							[value]="searchByNeed.text"
						>
							<span [innerHTML]="searchByNeed.text | titlecase | highlight : inputNative.value"></span>
						</mat-option>
					</mat-optgroup>
				</ng-container>
				<ng-container
					*ngIf="!inputNative.value && (attributeGroups[6].options | async) as mentalHealthSpecialties"
				>
					<mat-optgroup
						[label]="attributeGroups[6].name | titlecase"
						*ngIf="mentalHealthSpecialties.length > 0"
					>
						<mat-option
							[id]="attributeGroups[6].id + '_' + i"
							*ngFor="let mentalHealthSpecialty of mentalHealthSpecialties; let i = index"
							[value]="mentalHealthSpecialty"
						>
							<span [innerHTML]="mentalHealthSpecialty | highlight : inputNative.value"></span>
						</mat-option>
					</mat-optgroup>
				</ng-container>
				<ng-container *ngIf="attributeGroups[0].options | async as specialties">
					<mat-optgroup
						[label]="
							inputNative.value
								? (attributeGroups[0].name | titlecase)
								: attributeGroups[0].nameInExploreMode
						"
						*ngIf="specialties.length > 0"
					>
						<mat-option
							[id]="attributeGroups[0].id + '_' + i"
							*ngFor="let specialtie of specialties; let i = index"
							[value]="specialtie"
						>
							<span [innerHTML]="specialtie | titlecase | highlight : inputNative.value"></span>
						</mat-option>
					</mat-optgroup>
				</ng-container>
				<ng-container *ngIf="inputNative.value && (attributeGroups[1].options | async) as conditions">
					<mat-optgroup [label]="attributeGroups[1].name | titlecase" *ngIf="conditions.length > 0">
						<mat-option
							[id]="attributeGroups[1].id + '_' + i"
							*ngFor="let condition of conditions; let i = index"
							[value]="condition.label"
						>
							<span [innerHTML]="condition.label | titlecase | highlight : inputNative.value"></span>
						</mat-option>
					</mat-optgroup>
				</ng-container>
				<ng-container *ngIf="inputNative.value && (attributeGroups[2].options | async) as treatments">
					<mat-optgroup [label]="attributeGroups[2].name | titlecase" *ngIf="treatments.length > 0">
						<mat-option
							[id]="attributeGroups[2].id + '_' + i"
							*ngFor="let treatment of treatments; let i = index"
							[value]="treatment.label"
						>
							<span [innerHTML]="treatment.label | titlecase | highlight : inputNative.value"></span>
						</mat-option>
					</mat-optgroup>
				</ng-container>
				<ng-container *ngIf="attributeGroups[3].options | async as facilities">
					<mat-optgroup [label]="attributeGroups[3].name | titlecase" *ngIf="facilities.length > 0">
						<mat-option
							[id]="attributeGroups[3].id + '_' + i"
							*ngFor="let facility of facilities; let i = index"
							[value]="facility.text"
						>
							<span [innerHTML]="facility.text | titlecase | highlight : inputNative.value"></span>
						</mat-option>
					</mat-optgroup>
				</ng-container>
				<ng-container
					*ngIf="
						inputNative.value &&
						!showAutocompleteLoader &&
						(attributeGroups[4].options | async) as autocompleteValues
					"
				>
					<mat-optgroup [label]="attributeGroups[4].name | titlecase" *ngIf="autocompleteValues.length > 0">
						<mat-option
							[id]="attributeGroups[4].id + '_' + i"
							*ngFor="let autocompleteValue of autocompleteValues; let i = index"
							[value]="autocompleteValue.name"
							(click)="autocompleteValue.goToDetailsPage()"
						>
							<div class="provider-mini-card">
								<provider-avatar [gender]="autocompleteValue.gender"></provider-avatar>
								<div class="provider-infos">
									<div
										class="name"
										[innerHTML]="autocompleteValue.name | titlecase | highlight : inputNative.value"
									></div>
									<div class="specialty-and-address">
										{{ autocompleteValue.specialty ? autocompleteValue.specialty + ' |' : '' }}
										{{ autocompleteValue.address }}
									</div>
								</div>
							</div>
						</mat-option>
					</mat-optgroup>
				</ng-container>
			</ng-container>
		</mat-autocomplete>
		<ripple-icon
			*ngIf="inputNative.value"
			name="close"
			[hasClickEvent]="true"
			(click)="deleteInputText($event)"
			[class.visible]="searchForm.controls.value.value"
			class="close"
		>
		</ripple-icon>
	</div>
</ng-template>

<ng-template #addressInput>
	<div class="search-input google-places-autocomplete-input" [formGroup]="searchForm">
		<div class="colored-icon">
			<ripple-icon name="location"></ripple-icon>
		</div>
		<input
			ngx-google-places-autocomplete
			type="text"
			class="fs-unmask"
			#placesRef="ngx-places"
			#locationInput
			formControlName="address"
			[options]="googlePlaceOptions"
			(input)="onGoogleLocationChange()"
			(onAddressChange)="onAddressChange($event.formatted_address)"
			placeholder="{{ 'City, State or Zip code' | translate }}"
			[matAutocomplete]="locationGroup"
			[matAutocompleteDisabled]="locationInput.value !== ''"
			[class.autocomplete-container]="true"
		/>
		<ripple-loader *ngIf="showLocationLoader" [size]="'xs'" [color]="'gray'"></ripple-loader>
		<mat-autocomplete #locationGroup="matAutocomplete" class="autocomplete-container">
			<mat-option (click)="onClickGetCurrentLocation()">
				<div class="search-bar-option">
					<ripple-icon name="currentLocation" color="blue"></ripple-icon>
					{{ 'Current location' | translate }}
				</div>
			</mat-option>
			<mat-optgroup [label]="'Recent searches' | titlecase" *ngIf="(recentSearches$ | async)?.length > 0">
				<mat-option
					*ngFor="let recentSearch of recentSearches$ | async"
					(click)="onClickSetRecentLocation(recentSearch)"
					(onSelectionChange)="onClickSetRecentLocation(recentSearch)"
					class="recent-search-option"
				>
					<div class="search-bar-option">
						<div class="recent-searches-location-pin"></div>
						{{ recentSearch }}
					</div>
				</mat-option>
			</mat-optgroup>
		</mat-autocomplete>
		<div class="icon">
			<ripple-icon
				*ngIf="locationInput.value"
				name="close"
				[hasClickEvent]="true"
				(click)="deleteLocationText($event)"
				[class.visible]="searchForm.controls.address.value"
				class="icon close"
			>
			</ripple-icon>
		</div>
	</div>
</ng-template>

<ng-template #dialogFiltersActions let-close="close">
	<button class="healthee-btn md secondary mr-8" (click)="close(); handleDialogFiltersActions(true)">
		{{ 'Reset' | translate }}
	</button>
	<button class="healthee-btn md primary" (click)="close(); handleDialogFiltersActions(false)">
		{{ 'Show Providers' | translate }}
	</button>
</ng-template>

<ng-template #dialogFilters>
	<div [formGroup]="filtersForm" class="mobile-block" *ngIf="networkStructure$ | async as networkStructure">
		<div class="first-label mobile-label">{{ 'Distance' | translate }}</div>
		<app-chip-list-form-control
			keyField="id"
			displayField="display"
			[items]="distances"
			formControlName="distance"
			[options]="mobileFilterersOptions"
		>
		</app-chip-list-form-control>

		<ng-container *ngIf="networkStructure === NetworkStructure.SINGLE">
			<mat-divider class="divider"></mat-divider>
			<div class="mobile-label">{{ 'Network' | translate }}</div>
			<app-chip-list-form-control
				keyField="id"
				displayField="display"
				[items]="networks"
				formControlName="outOfNetwork"
				[options]="mobileFilterersOptions"
			>
			</app-chip-list-form-control>
		</ng-container>

		<ng-container *ngIf="networkStructure === NetworkStructure.MN || networkStructure === NetworkStructure.MTN">
			<mat-divider class="divider"></mat-divider>
			<div class="mobile-label">{{ 'Network' | translate }}</div>
			<app-chip-list-form-control
				keyField="id"
				displayField="display"
				[items]="multiNetworks"
				formControlName="mnFavoriteSearch"
				[options]="mobileFilterersOptions"
			>
			</app-chip-list-form-control>
		</ng-container>

		<mat-divider class="divider"></mat-divider>
		<div class="mobile-label">{{ 'Gender' | translate }}</div>
		<app-chip-list-form-control
			keyField="id"
			displayField="display"
			[items]="genders"
			formControlName="gender"
			[options]="mobileFilterersOptions"
		>
		</app-chip-list-form-control>

		<ng-container>
			<mat-divider class="divider"></mat-divider>
			<div class="mobile-label">{{ 'Rating' | translate }}</div>
			<app-chip-list-form-control
				keyField="id"
				displayField="value"
				[items]="scores"
				formControlName="minRating"
				[options]="mobileFilterersOptions"
			>
				<ng-template #item let-data>
					{{ data?.value }} {{ data?.value <= 4 ? '+' : '' }}
					<mat-icon class="text-healthee-yellow icon-size-10">star</mat-icon>
				</ng-template>
			</app-chip-list-form-control>
		</ng-container>

		<mat-divider class="divider"></mat-divider>
		<div class="mobile-label">{{ 'Language' | translate }}</div>
		<app-select-form-control
			[placeholderText]="'Language' | translate"
			floatLabel="always"
			valueField="title"
			displayField="title"
			[items]="languages"
			[multi]="false"
			formControlName="language"
		></app-select-form-control>

		<mat-divider *ngIf="hasAppointmentBookingFeature" class="divider"></mat-divider>
		<button *ngIf="networkStructure === NetworkStructure.RBP" class="online-booking-only-button">
			<mat-checkbox formControlName="rbpApproved" (change)="onFormSubmit()" color="primary">
				{{ 'RBP Participating' | translate }}
			</mat-checkbox>
		</button>
		<div class="online-booking-only-section-mobile">
			<div class="mobile-label online-booking-only-first-line">
				<div class="mobile-label online-booking-only-title">
					{{ 'Accepts online booking' | translate }}
				</div>
				<mat-slide-toggle class="online-booking-only-toggle" formControlName="onlineBookingOnly">
				</mat-slide-toggle>
			</div>
			<div
				[class.online-booking-only-description-on]="this.filtersForm?.controls?.onlineBookingOnly?.value"
				[class.online-booking-only-description-off]="!this.filtersForm?.controls?.onlineBookingOnly?.value"
			>
				{{ 'Only providers with online bookings will be displayed.' | translate }}
			</div>
		</div>
	</div>
</ng-template>

<ng-template #filterWrapperWithSlider>
	<app-healthee-content-slider [enableClickScroll]="false" class="with-slider">
		<ng-container *ngTemplateOutlet="filterContent"></ng-container>
	</app-healthee-content-slider>
</ng-template>

<ng-template #filterContent [formGroup]="filtersForm">
	<div
		*ngIf="networkStructure$ | async as networkStructure"
		class="filters-wrapper d-flex align-items-center pb-10 pt-10"
		#filterRef
	>
		<app-select-form-control
			class="mr-8"
			valueField="id"
			displayField="display"
			[label]="'Distance' | translate"
			[items]="distances"
			[hasBottomSpace]="false"
			[multi]="false"
			(ngModelChange)="onFormSubmit()"
			formControlName="distance"
		></app-select-form-control>

		<app-select-form-control
			*ngIf="networkStructure === NetworkStructure.SINGLE"
			class="mr-8"
			valueField="id"
			displayField="display"
			[label]="'Network' | translate"
			[items]="networks"
			[hasBottomSpace]="false"
			[multi]="false"
			(ngModelChange)="onFormSubmit()"
			formControlName="outOfNetwork"
		></app-select-form-control>

		<app-select-form-control
			*ngIf="networkStructure === NetworkStructure.MN || networkStructure === NetworkStructure.MTN"
			class="mr-8"
			valueField="id"
			displayField="display"
			[label]="'Network' | translate"
			[items]="multiNetworks"
			[hasBottomSpace]="false"
			[multi]="false"
			(ngModelChange)="onFormSubmit()"
			formControlName="mnFavoriteSearch"
		></app-select-form-control>

		<app-select-form-control
			class="mr-8"
			valueField="title"
			displayField="title"
			[label]="'Language' | translate"
			[items]="languages"
			[hasBottomSpace]="false"
			[multi]="false"
			(ngModelChange)="onFormSubmit()"
			formControlName="language"
		></app-select-form-control>

		<app-select-form-control
			class="mr-8"
			valueField="id"
			displayField="display"
			[label]="'Gender' | translate"
			[items]="genders"
			[hasBottomSpace]="false"
			[multi]="false"
			(ngModelChange)="onFormSubmit()"
			formControlName="gender"
		></app-select-form-control>

		<app-select-form-control
			class="mr-8"
			valueField="id"
			displayField="value"
			[label]="'Rating' | translate"
			[items]="scores"
			[hasBottomSpace]="false"
			[multi]="false"
			(ngModelChange)="onFormSubmit()"
			formControlName="minRating"
		>
			<ng-template #item let-data>
				{{ data?.value }} {{ data?.value <= 4 ? '+' : '' }}
				<mat-icon class="text-healthee-yellow icon-size-10">star</mat-icon>
			</ng-template>
		</app-select-form-control>

		<button *ngIf="networkStructure === NetworkStructure.RBP" class="online-booking-only-button">
			<mat-checkbox formControlName="rbpApproved" (change)="onFormSubmit()" color="primary">
				{{ 'RBP Participating' | translate }}
			</mat-checkbox>
		</button>

		<button *ngIf="hasAppointmentBookingFeature" class="online-booking-only-button">
			<mat-checkbox formControlName="onlineBookingOnly" (change)="onFormSubmit()" color="primary">
				{{ 'Accepts online booking' | translate }}
			</mat-checkbox>
			<app-healthee-mark-tooltip
				[text]="'Only providers with online bookings will be displayed.' | translate"
			></app-healthee-mark-tooltip>
		</button>

		<div class="text-healthee-blue cursor-pointer ml-15" (click)="onResetFilter()">
			{{ 'Reset Filters' | translate }}
		</div>
	</div>
</ng-template>
