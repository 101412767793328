export class Cookies {

    static REFRESH_TOKEN_NAME = 'refreshTokenName';
    static REFRESH_TOKEN_VALUE = 'refreshTokenValue';
    static LAST_AUTH_USER_TOKEN_NAME = 'lastAuthUserTokenName';
    static LAST_AUTH_USER_TOKEN_VALUE = 'lastAuthUserTokenValue';
    public static setCookie(name: string, value: string, domain: string, days?: number) {
        let expires = "";
        let secure = ';Secure';
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }

        if (domain === 'localhost') {
            secure = '';
        }

        document.cookie = name + "=" + (value || "")  + expires + ";domain="+domain+";path=/"+secure;
    }

    public static getCookie(name: string) {
        const nameEQ = name + "=";
        const cookies = document.cookie.split(';');
        for(let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1, cookie.length);
            }
            if (cookie.indexOf(nameEQ) === 0) {
                return cookie.substring(nameEQ.length, cookie.length);
            }
        }
        return null;
    }

    public static removeCookie(name: string, domain: string) {
        document.cookie = name + "=; path=/; Max-Age=-99999999;domain="+domain;
    }
}
