import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import CFG from '../../../config/app-config.json';
import { UserOpenEnrollmentData } from '../../../models/open-enrollment.model';
import { MedicalEvent, OpenEnrollmentData } from '../../plan-selection.service';
import { UserData } from 'src/app/models/user-data';
import { UnleashService } from '../../unleash.service';

export interface RecommendationsRequest {
	effectiveDate: string;
	referralRequired: boolean;
	medicalEvents: { serviceType: MedicalEvent }[];
}

export interface EnrollmentResponse {
	userData: UserOpenEnrollmentData;
	companyData: OpenEnrollmentData;
}

@Injectable({
	providedIn: 'root',
})
export class PlanSelectionAPIService {
	constructor(private http: HttpClient, private unleashService: UnleashService) {}

	public fetchEnrollment(): Observable<EnrollmentResponse> {
		const effectiveDate = localStorage.getItem('eventDate');
		const eventType = localStorage.getItem('eventType');
		const url: string = this.addParamsToUrl(CFG.apiEndpoints.enrollmentData, effectiveDate, eventType);

		return this.http.get<EnrollmentResponse>(url);
	}

	public fetchRecommendations(params: RecommendationsRequest): Observable<any> {
		const url: string = this.addParamsToUrl(CFG.apiEndpoints.enrollmentRecommendations, params.effectiveDate, 'OE');
		return this.http.post(url, params);
	}

	public updateServer(data: Partial<UserOpenEnrollmentData>, effectiveDate: string): Observable<any> {
		const url: string = this.addParamsToUrl(CFG.apiEndpoints.userOpenEnrollmentData, effectiveDate, 'OE');
		return this.http.post<UserData>(url, data);
	}

	private addParamsToUrl(url: string, effectiveDate?: string, eventType?: string) {
		if (effectiveDate || eventType) url += '?';
		if (effectiveDate) url += 'effectiveDate=' + effectiveDate + '&';
		if (eventType) url += 'eventType=' + eventType;
		return url;
	}
}
