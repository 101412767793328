import CFG from '../../config/app-config.json';

type CallbackFunction = () => Promise<boolean>;

export abstract class GeolocationService {
	protected positionOptions: PositionOptions = {
		enableHighAccuracy: CFG.geolocation.enableHighAccuracy,
		timeout: CFG.geolocation.waitForLocationUpdatesTimeoutInMs,
	};
	protected displayPermissionsRationale: CallbackFunction;

	/** Get current position for web through navigator or from native GPS.
	 * @returns Promise with GeolocationPosition or null if position cannot be resolved.
	 * @throws - GeolocationServiceNotAvailableError, GeolocationUnknownError
	 * and other permission errors.
	 */
	public abstract getCurrentPosition(): Promise<GeolocationPosition>;

	// TODO: Change the API and signature for checkAndRequestPermission
	/** Check for Geolocation availability and  requests permissions if not yet granted or denied.
	 * @returns Promise with boolean
	 * - true - if geolocation can be used (is available and permissions granted)
	 * - false - if geolocation can be used (is available), but permissions are not yet granted (prompt)
	 * @throws - if geolocation cannot be used (not available or perms denied)
	 */
	public abstract checkAndRequestPermission(): Promise<boolean>;

	/** Android Only: Function to set the callback for displaying a rationale dialog.
	 * Implemented this way because the inheriting classes are manually instanciated classes
	 * (and not Angular classes) - therefore don't have access to Angular's Injector (for ex:
	 * don't have, and should not have access to the our uiService).
	 * @param  {CallbackFunction} displayRationaleCallback
	 * @returns void
	 */
	public setDisplayPermissionsRationaleCallback(displayRationaleCallback: CallbackFunction): void {
		this.displayPermissionsRationale = displayRationaleCallback;
	}

	public abstract getAddressFromZipCode(zipCode: string): Promise<string>;
}
