import { Component, OnInit } from '@angular/core';
import { HealtheeDialogContent } from 'src/app/modules/app-shared/healthee-dialog/healthee-dialog.component';

@Component({
	selector: 'app-recompare-plan-modal',
	templateUrl: './recompare-plan-modal.component.html',
	styleUrls: ['./recompare-plan-modal.component.scss'],
})
export class RecomparePlanModalComponent extends HealtheeDialogContent implements OnInit {
	constructor() {
		super();
	}

	ngOnInit(): void {}

	public onConfirm(): void {
		this.confirmDialog.emit(true);
	}

	public onCancel(): void {
		this.closeDialog.emit();
	}
}
