import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoverageInfoComponent } from './coverage-info/coverage-info.component';
import { FacilityCardDetailsComponent } from './facility-card-details/facility-card-details.component';
import { FacilityCardComponent } from './facility-card/facility-card.component';
import { MapAndInfoComponent } from './map-and-info/map-and-info.component';
import { PaginationComponent } from './pagination/pagination.component';
import { AppointmentZocdocAuthComponent } from './provider-appointment/appointment-zocdoc-auth/appointment-zocdoc-auth.component';
import { AppointmentAvailabilityForm } from './provider-appointment/appointment-availability-form/appointment-availability-form.component';
import { AppointmentConfirmationComponent } from './provider-appointment/appointment-confirmation/appointment-confirmation.component';
import { AppointmentRescheduleComponent } from './provider-appointment/appointment-reschedule/appointment-reschedule.component';
import { AppointmentSlotsAvailableComponent } from './provider-appointment/appointment-slots-available/appointment-slots-available.component';
import { AppointmentSubmittedComponent } from './provider-appointment/appointment-submitted/appointment-submitted.component';
import { ProviderAppointmentComponent } from './provider-appointment/provider-appointment.component';
import { AppointmentCardComponent } from './provider-appointments/appointment-card/appointment-card.component';
import { CancelAppointmentModalComponent } from './provider-appointments/cancel-appointment-modal/cancel-appointment-modal.component';
import { ProviderAppointmentsComponent } from './provider-appointments/provider-appointments.component';
import { ClinicalAreaComponent } from './provider-card-details/clinical-area/clinical-area.component';
import { ProviderAboutComponent } from './provider-card-details/provider-about/provider-about.component';
import { ProviderCardDetailsComponent } from './provider-card-details/provider-card-details.component';
import { ProviderLocationsComponent } from './provider-card-details/provider-locations/provider-locations.component';
import { ProviderReviewComponent } from './provider-card-details/provider-reviews/provider-review/provider-review.component';
import { ProviderReviewsComponent } from './provider-card-details/provider-reviews/provider-reviews.component';
import { ProviderCardLoaderComponent } from './provider-card/provider-card-loader/provider-card-loader.component';
import { ProviderCardComponent } from './provider-card/provider-card.component';
import { ProvidersMapCardComponent } from './providers-map-card/providers-map-card.component';
import { ProviderSearchResultsComponent } from './providers-map/provider-search-results/provider-search-results.component';
import { ProvidersSearchBarComponent } from './providers-search-bar/providers-search-bar.component';
import { ProvidersMapComponent } from './providers-map/providers-map.component';
import { SearchResultsBaseCardComponent } from './search-results-base-card/search-results-base-card.component';

import { TxNativeModule } from '@transifex/angular';
import { AppSharedModule } from 'src/app/modules/app-shared/app-shared.module';
import { RippleModule } from 'ripple';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ChipListFormControlModule } from 'src/app/modules/app-shared/standalone/form-controls/chip-list-form-control/chip-list-form-control.module';
import { SelectFormControlModule } from 'src/app/modules/app-shared/standalone/form-controls/select-form-control/select-form-control.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { GoogleMapsModule } from '@angular/google-maps';
import { HighlightStringsModule } from 'src/app/modules/standalone/highlight-strings/highlight-strings.module';
import { ProviderAvatarModule } from 'src/app/modules/app-shared/standalone/provider-avatar/provider-avatar.module';
import { MatBadgeModule } from '@angular/material/badge';
import { DrawerModule } from 'src/app/modules/standalone/drawer/drawer.module';
import { RouterLink } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { ServicePanelModule } from 'src/app/modules/standalone/service-panel/service-panel.module';
import { RescheduleDisclaimerComponent } from 'src/app/modules/main-layout/care-costs/shared/provider-appointment/appointment-reschedule/reschedule-disclaimer/reschedule-disclaimer.component';
import { PhoneMaskDirective } from 'src/app/modules/main-layout/care-costs/shared/provider-appointment/appointment-confirmation/helpers/phone-mask.directive';
import { NgxGooglePlacesAutocompleteModule } from '../../../standalone/ngx-google-places-autocomplete/ngx-google-places-autocomplete.module';
import { ShareProviderComponent } from '../../../../standalone/share-provider/share-provider.component';
import { ProvidersSearchResultsPrintComponent } from 'src/app/modules/main-layout/care-costs/shared/providers-map/provider-search-results/providers-search-results-print/providers-search-results-print.component';
import { ProvidersSearchNoResultComponent } from './providers-map/provider-search-results/providers-search-no-results/providers-search-no-result.component';
import { ExploreTopSearchesComponent } from './providers-map/provider-search-results/providers-search-no-results/explore-top-searches/explore-top-searches.component';
import { TelehealthMedicalAdviceComponent } from './providers-map/provider-search-results/providers-search-no-results/medical-advice/telehealth-medical-advice.component';
import { TelehealthCardComponent } from './telehealth-card/telehealth-card.component';
import { ProviderSearchEmptyStateComponent } from './provider-search-empty-state/provider-search-empty-state.component';
import { TopRatedProvidersComponent } from './provider-search-empty-state/top-searched-specialties/top-rated-providers.component';
import { TopSearchedSpecialtiesComponent } from './top-searched-specialties/top-searched-specialties.component';

@NgModule({
	declarations: [
		CoverageInfoComponent,
		FacilityCardComponent,
		FacilityCardDetailsComponent,
		MapAndInfoComponent,
		PaginationComponent,
		ProviderAppointmentComponent,
		AppointmentZocdocAuthComponent,
		AppointmentAvailabilityForm,
		AppointmentConfirmationComponent,
		PhoneMaskDirective,
		AppointmentRescheduleComponent,
		RescheduleDisclaimerComponent,
		AppointmentSlotsAvailableComponent,
		AppointmentSubmittedComponent,
		ProviderAppointmentsComponent,
		AppointmentCardComponent,
		CancelAppointmentModalComponent,
		ProviderCardComponent,
		ProviderCardLoaderComponent,
		ProviderCardDetailsComponent,
		ClinicalAreaComponent,
		ProviderAboutComponent,
		ProviderLocationsComponent,
		ProviderReviewComponent,
		ProviderReviewsComponent,
		ProvidersMapComponent,
		ProviderSearchResultsComponent,
		ProvidersSearchResultsPrintComponent,
		ProvidersSearchNoResultComponent,
		ProvidersMapCardComponent,
		ProvidersSearchBarComponent,
		SearchResultsBaseCardComponent,
		ExploreTopSearchesComponent,
		TelehealthMedicalAdviceComponent,
  		TelehealthCardComponent,
		ProviderSearchEmptyStateComponent,
		TopRatedProvidersComponent,
		TopSearchedSpecialtiesComponent,
	],
	exports: [
		CoverageInfoComponent,
		FacilityCardComponent,
		FacilityCardDetailsComponent,
		MapAndInfoComponent,
		PaginationComponent,
		ProviderAppointmentComponent,
		AppointmentZocdocAuthComponent,
		AppointmentAvailabilityForm,
		AppointmentConfirmationComponent,
		PhoneMaskDirective,
		AppointmentRescheduleComponent,
		RescheduleDisclaimerComponent,
		AppointmentSlotsAvailableComponent,
		AppointmentSubmittedComponent,
		ProviderAppointmentsComponent,
		AppointmentCardComponent,
		CancelAppointmentModalComponent,
		ProviderCardComponent,
		ProviderCardLoaderComponent,
		ProviderCardDetailsComponent,
		ClinicalAreaComponent,
		ProviderAboutComponent,
		ProviderLocationsComponent,
		ProviderReviewComponent,
		ProviderReviewsComponent,
		ProvidersMapComponent,
		ProviderSearchResultsComponent,
		ProvidersSearchResultsPrintComponent,
		ProvidersSearchNoResultComponent,
		ProvidersMapCardComponent,
		ProvidersSearchBarComponent,
		SearchResultsBaseCardComponent,
		ExploreTopSearchesComponent,
		TelehealthMedicalAdviceComponent,
		TelehealthCardComponent,
		ProviderSearchEmptyStateComponent,
		TopRatedProvidersComponent,
		TopSearchedSpecialtiesComponent,
	],
	imports: [
		TxNativeModule,
		CommonModule,
		AppSharedModule,
		RippleModule,
		ChipListFormControlModule,
		SelectFormControlModule,
		MatCheckboxModule,
		MatSlideToggleModule,
		MatDividerModule,
		ReactiveFormsModule,
		MatSelectModule,
		MatAutocompleteModule,
		NgxGooglePlacesAutocompleteModule,
		HighlightStringsModule,
		ProviderAvatarModule,
		MatBadgeModule,
		GoogleMapsModule,
		DrawerModule,
		RouterLink,
		MatTabsModule,
		MatMenuModule,
		ServicePanelModule,
		ShareProviderComponent,
	],
})
export class CareAndCostsSharedModule {}
