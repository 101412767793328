<div [ngSwitch]="isShared" class="share-summary-container">
	<div *ngSwitchCase="false">
		<div class="main">{{ 'Share your health plan selections' | translate }}</div>

		<mat-form-field class="example-chip-list" appearance="fill">
			<mat-label>{{ 'Enter one or more email addresses' | translate }}</mat-label>
			<mat-chip-grid #chipGrid aria-label="Email selection">
				<mat-chip-row *ngFor="let email of emails" (removed)="remove(email)">
					{{ email }}
					<button matChipRemove>
						<mat-icon>cancel</mat-icon>
					</button>
				</mat-chip-row>
				<input
					class="fs-unmask"
					placeholder="New email..."
					[matChipInputFor]="chipGrid"
					[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
					[matChipInputAddOnBlur]="addOnBlur"
					(matChipInputTokenEnd)="add($event)"
				/>
			</mat-chip-grid>
		</mat-form-field>

		<div class="button-container">
			<button class="bt primary-blue" (click)="send()" [disabled]="emails?.length < 1">
				{{ 'Send' | translate }}
			</button>
		</div>
	</div>

	<div *ngSwitchCase="true" class="share-success-container">
		<img src="../../../../../assets/images/icons/v-circle.svg" alt="success vi symbol" />
		<span>health plans selections successfully shared</span>
	</div>
</div>
