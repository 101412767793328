import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { T } from '@transifex/angular';

import { getUTCDateWithoutTime, isIonic } from '../../../utils/utils';

import { UserService } from '../../../services/user.service';
import { TrackingService } from '../../../services/tracking.service';

import { UserData } from '../../../models/user-data';
import { HealtheeRadioButton } from '../../app-shared/healthee-radio-group/healthee-radio-group.component';
import { HealtheeDialogContent } from '../../app-shared/healthee-dialog/healthee-dialog.component';

@Component({
	selector: 'app-preventive-care-personalize-tracker',
	templateUrl: './preventive-care-personalize-tracker.component.html',
	styleUrls: ['./preventive-care-personalize-tracker.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class PreventiveCarePersonalizeTrackerComponent extends HealtheeDialogContent implements OnInit {
	@T('Male')
	txtMale: string;

	@T('Female')
	txtFemale: string;

	public editInfoForm: FormGroup;
	public minDate = new Date(1900, 0, 1);
	public maxDate = new Date();
	public isIonic = isIonic();
	public chooseGenderRadios: HealtheeRadioButton<string>[];

	constructor(
		private formBuilder: FormBuilder,
		private userService: UserService,
		private trackingService: TrackingService
	) {
		super();

		this.chooseGenderRadios = [
			{ value: 'male', text: this.txtMale, isChecked: false },
			{ value: 'female', text: this.txtFemale, isChecked: false },
		];
	}

	ngOnInit(): void {
		this.userService.userData$.subscribe((data: UserData) => {
			this.chooseGenderRadios.forEach((button) => (button.isChecked = button.value === data.gender));

			const date = new Date(data.birthday + ' 12:00:00'); //prevent timezone issues with angular's forms date conversions

			this.editInfoForm = this.formBuilder.group({
				dateOfBirth: [date, [Validators.required]],
				gender: [data.gender, [Validators.required]],
			});
		});
	}

	public onGenderButtonChange(value: string) {
		this.editInfoForm.controls['gender'].setValue(value);
	}

	public onInfoSubmit(): void {
		if (!this.editInfoForm.valid) return;

		const formData = this.editInfoForm.value;

		const utcDateOnlyObject = getUTCDateWithoutTime(new Date(formData.dateOfBirth));
		formData.dateOfBirth = new Date(utcDateOnlyObject);

		this.userService.updateUserProfileFromFormData(formData).subscribe({
			next: () => {
				this.trackingService.trackClientEvent('Preventive care - Choose the customized list', {
					source: this.data.source,
				});
				this.confirmDialog.emit(true);
			},
			error: () => {
				this.closeDialog.emit(false);
			},
		});
	}

	public onContinueWithoutProvidingDetails(): void {
		this.trackingService.trackClientEvent('Preventive care - Choose the generic list');
		this.confirmDialog.emit(true);
	}
}
