import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of, shareReplay, startWith, switchMap } from 'rxjs';
import { Maybe } from '../../utils/types/maybe';
import { SearchResultsStoreService } from '../stores/search-results-store/search-results-store.service';
import { HttpRequestProgressStatus } from '../../utils/http-response/http-response';
import {
	entityHelper,
	LngLat,
	ProviderLocationMarker,
	retrieveSearchTypeAttr,
} from './helpers/providers-search.helpers';
import { ObjectUtils } from '../../utils/object';

import { TrackingService } from '../tracking.service';
import { MPISearchBarService } from './mpi-search-bar.service';
import { NetworkStructure } from 'src/app/models/chat.model';
import {
	DEFAULT_FORM_VALUES,
	DEFAULT_RESULTS,
	DEFAULT_SEARCH_OPTIONS,
	ProvidersSearchResults,
	SearchEntity,
	SearchFields,
	SearchOptions,
	SearchPage,
	SearchType,
} from 'src/app/modules/mpi/providers-search/helpers/providers-search.helper';
import { getKeyByValue } from 'src/app/utils/utils';
import { ReportFeature, ReportService } from '../report.service';
import { isMp3Location } from 'src/app/modules/mpi/providers-search/helpers/providers.helpers';

@Injectable({
	providedIn: 'root',
})
export class MPIProvidersSearchService {
	private _activeLocationIndex$: BehaviorSubject<Maybe<number>> = new BehaviorSubject<Maybe<number>>(null);
	private _showActiveLocationCard$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	private _requestStatus = new BehaviorSubject<HttpRequestProgressStatus>(HttpRequestProgressStatus.Pending);
	private _results = new BehaviorSubject<ProvidersSearchResults>(DEFAULT_RESULTS);
	private _searchOptions = new BehaviorSubject<Maybe<SearchOptions>>(DEFAULT_SEARCH_OPTIONS);
	private _services = new BehaviorSubject<{ id: string; name: string }[]>(null);
	private _isDataFromStore = new BehaviorSubject<boolean>(null);
	private _disablePanTo$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(
		private searchResultsStoreService: SearchResultsStoreService,
		private reportService: ReportService,
		private trackingService: TrackingService,
		private searchBarService: MPISearchBarService
	) {}

	get requestStatus$(): Observable<HttpRequestProgressStatus> {
		return this._requestStatus.asObservable();
	}

	get searchOptions$() {
		return this._searchOptions.asObservable();
	}

	get searchParams$() {
		return this._searchOptions.asObservable().pipe(map((options) => options.params));
	}

	get providersLocations$(): Observable<ProviderLocationMarker[]> {
		return this.results$.pipe(
			map((results) =>
				results.records
					.filter((entity) => !entity.address || entity.insuranceGroup)
					.map((entity) => {
						if (entity.locations?.length) {
							entity.locations = entity.locations.filter(isMp3Location);
						}
						return entity;
					})
					.map((record, providerIndex: number) =>
						entityHelper[results.entity]
							.retrieveLocations(record)
							.map((location: LngLat, i: number) => ({
								...location,
								providerIndex,
								mpiAddress: record.locations?.[i]?.mpiAddress,
							}))
					)
					.flat()
			),
			startWith([]),
			shareReplay(1)
		);
	}

	get activeLocationIndex$(): Observable<number> {
		return this._activeLocationIndex$.asObservable();
	}

	get showActiveLocationCard$(): Observable<boolean> {
		return this._showActiveLocationCard$.asObservable();
	}

	get isSuccess$(): Observable<boolean> {
		return this._requestStatus.asObservable().pipe(map((status) => status === HttpRequestProgressStatus.Success));
	}

	get isPending$(): Observable<boolean> {
		return this._requestStatus.asObservable().pipe(map((status) => status === HttpRequestProgressStatus.Pending));
	}

	get showLoader$(): Observable<boolean> {
		return this._requestStatus.asObservable().pipe(map((status) => status === HttpRequestProgressStatus.Loading));
	}

	get isResultsInNetwork$(): Observable<Maybe<boolean>> {
		return this.results$.pipe(map((results) => results.isInNetwork));
	}

	get results$() {
		return this._results.asObservable();
	}

	get entityType$() {
		return this._results.asObservable().pipe(map((results) => results.entity));
	}

	get services$() {
		return this._services.asObservable();
	}

	get isDataFromStore$() {
		return this._isDataFromStore.asObservable();
	}

	get resultsNetworkStatus$() {
		return this._results.asObservable().pipe(map((results) => results.isInNetwork));
	}

	get disabledPanTo$(): Observable<boolean> {
		return this._disablePanTo$.asObservable();
	}

	get searchNetworkStructure$(): Observable<NetworkStructure> {
		return this._results.asObservable().pipe(
			switchMap((results) => {
				const networkStructure = getKeyByValue(results.networkStructure, NetworkStructure);
				if (networkStructure) return of(NetworkStructure[networkStructure]);
				return of(NetworkStructure.SINGLE);
			}),
			shareReplay(1)
		);
	}

	private setIsDataFromStoreState(state: boolean): void {
		this._isDataFromStore.next(state);
	}

	public setActiveLocationIndex(
		locationIndex: number,
		options: { showCard?: boolean; disablePanTo?: boolean } = { showCard: false, disablePanTo: true }
	): void {
		this._activeLocationIndex$.next(locationIndex);
		this._disablePanTo$.next(options.disablePanTo);
		this._showActiveLocationCard$.next(options.showCard);
	}

	public setServices(services: { id: string; name: string }[]): void {
		this._services.next(services);
	}

	public search(formData: SearchOptions, logParams: any = null): void {
		this._requestStatus.next(HttpRequestProgressStatus.Loading);
		this._searchOptions.next(formData);

		const logData = {
			entity: formData.entity,
			isFreeSearch: formData.searchType === SearchType.Free,
			network: formData.params.outOfNetwork ? 'out-network' : 'in-network',
			...formData.params,
			isCurrentLocation: this.searchBarService.isCurrentLocation$.getValue(),
			specificProvider: formData.entity === SearchEntity.Provider && formData.searchType === SearchType.Free,
			specificFacility: formData.entity === SearchEntity.Facility && formData.searchType === SearchType.Free,
			specificGroupName:
				formData.entity === SearchEntity.SearchByGroupName && formData.searchType === SearchType.Free,
		};

		if (logParams.value) {
			logData['q'] = logParams.value;
		}

		if (logData.name) {
			logData['q'] = logData.name;
			delete logData['name'];
		}

		const isDataFromStoreExist = this.searchResultsStoreService.isDataFromStoreExist(formData);
		this.setIsDataFromStoreState(isDataFromStoreExist);

		this.searchResultsStoreService
			.getNormalized(formData, entityHelper[formData.entity].normalize)
			.pipe(
				switchMap((result: SearchPage<any>) => {
					this._results.next(result);
					this.setServices([{ id: formData.params.service, name: logParams.value }]);
					this._requestStatus.next(HttpRequestProgressStatus.Success);
					logData['totalResults'] = result.totalCount;
					logData['page'] = result.page;
					logData['success'] = true;

					this.trackingService.trackClientEvent('PS - Search', logData);

					return of(result);
				})
			)
			.subscribe({
				error: () => {
					this._requestStatus.next(HttpRequestProgressStatus.Error);
					logData['totalResults'] = 0;
					logData['page'] = 0;
					logData['success'] = false;
					this.trackingService.trackClientEvent('PS - Search', logData);
				},
			});
	}

	reset() {
		this._requestStatus.next(HttpRequestProgressStatus.Pending);
		this._results.next(DEFAULT_RESULTS);
		this._searchOptions.next(DEFAULT_SEARCH_OPTIONS);
		this._showActiveLocationCard$.next(null);
		this._activeLocationIndex$.next(null);
	}
}

export const searchBarParamsToServerParams = (
	searchFields: SearchFields,
	customFieldValue: string,
	networkStructure: NetworkStructure
): SearchOptions => {
	const searchOptions: SearchOptions = {
		searchType: searchFields.searchType,
		entity: searchFields.entity,
		params: {
			...ObjectUtils.filterOutNils({
				language: searchFields.language,
				gender: searchFields.gender,
				minRating: searchFields.minRating,
				onlineBookingOnly: searchFields.onlineBookingOnly,
				insurance_group_name: searchFields.insuranceGroup,
				mpi_languages: searchFields.mpiLanguage,
				networkStructure: networkStructure,
				mnFavoriteSearch: searchFields.mnFavoriteSearch,
				[retrieveSearchTypeAttr(searchFields.entity, searchFields.searchType)]: customFieldValue,
			}),
			distance: searchFields.distance || DEFAULT_FORM_VALUES.distance,
			address: searchFields.address,
			page: searchFields.page || DEFAULT_FORM_VALUES.page,
		},
	};

	if (searchFields.outOfNetwork === 'true' || searchFields.outOfNetwork === true) {
		searchOptions.params.outOfNetwork = true;
	}

	if (searchOptions.params['name']) {
		searchOptions.params['name'] = searchOptions.params['name'].replace(/-/g, ' ');
	}

	if (!searchOptions.entity && searchOptions.searchType === SearchType.Free) {
		searchOptions.entity = SearchEntity.Provider;
	}

	if (searchOptions.entity === SearchEntity.SearchByGroupName) {
		searchOptions.params['insurance_group_name'] = searchOptions.params['name'];
		delete searchOptions.params['name'];
	}
	return searchOptions;
};
