import { Injectable } from '@angular/core';
import { T } from '@transifex/angular';

import { Capacitor } from '@capacitor/core';

import { UIService } from './ui.service';
import { HealtheeDialogService } from './healthee-dialog.service';
import { GeolocationService } from './helpers/geolocation-service.base';
import { WebGeolocationService } from './helpers/geolocation.service.web';
import { IOSGeolocationService } from './helpers/geolocation.service.ios';
import { AndroidGeolocationService } from './helpers/geolocation.service.android';

import { HealtheeDialogData } from '../modules/app-shared/healthee-dialog/healthee-dialog.component';

import {
	GeolocationServiceNotAvailableError,
	GeolocationPermissionsDeniedOnWebError,
	GeolocationPermissionsDeniedOniOSError,
	GeolocationPermissionsDeniedOnAndroidError,
	GeolocationUnknownError,
	GeolocationAccessDeniedTemporarilyError,
} from './helpers/geolocation-error';
import { UnleashService } from './unleash.service';
import { FfNewBrandLogo } from '../config/feature-flags/ff-new-brand-logo';
import { take } from 'rxjs';

type GeocoderResult = google.maps.GeocoderResult;

@Injectable({ providedIn: 'root' })
export class CurrentLocationService {
	@T('Geolocation is not supported or disabled on this device')
	private errorMessage_geolocationIsNotAvailable: string;

	@T('Please enable location sharing in your browser')
	private errorMessge_geolocationPermissionsDeniedOnWeb: string;

	@T(
		'To search providers around you, please grant location permission by going to Settings > Healthee > Location and select "While Using the App".'
	)
	private errorMessge_geolocationPermissionsDeniedOniOS: string;

	@T(
		'To search providers around you, please grant location permission by going Settings > Apps > Healthee > Permissions > Location and select "While Using the App".'
	)
	private errorMessge_geolocationPermissionsDeniedOnAndroid: string;

	@T("Can't determine your location at the moment. Please try again later")
	private errorMessage_cantGetYourLocationAtTheMomentMsg: string;

	@T('Location permission required')
	private actionRequiredDialogTitle: string;

	@T('Ok')
	private confirmText: string;

	// DO NOT change text indentation in the T decorator below, or it will break
	@T("Healthee needs to know your location to show you providers around you.\
	Without location permissions, you'd have to enter your location manually.\
	Are you sure you want to continue without location permissions?")
	private permissionRationaleMessageAfterPermsDeniedOnAndroid: string;

	@T("Yes, continue without location")
	private permissionRationaleActionContinueWithoutPermsOnAndroid: string;

	@T("No, give location permission")
	private permissionRationaleActionAskForPermsAgainOnAndroid: string;

	private currentPlaceInfo: any;
	private geoLocationService: GeolocationService;

	isNewBrandLogoEnabled$ = this.unleashService.isEnabled$(FfNewBrandLogo);
	private updateIcon: string = 'new_update.svg';

	constructor(
		private uiService: UIService,
		private dialogService: HealtheeDialogService,
		private unleashService: UnleashService
	) {
		const platform = Capacitor.getPlatform();

		if (platform === 'android') {
			this.geoLocationService = new AndroidGeolocationService();
			this.displayPermissionsRationaleOnAndroid = this.displayPermissionsRationaleOnAndroid.bind(this);
			this.geoLocationService.setDisplayPermissionsRationaleCallback(this.displayPermissionsRationaleOnAndroid);
			return;
		}

		if (platform === 'ios') {
			this.geoLocationService = new IOSGeolocationService();
			return;
		}

		this.geoLocationService = new WebGeolocationService();

		this.isNewBrandLogoEnabled$.pipe(take(1)).subscribe((isEnabled) => {
			this.updateIcon = isEnabled ? 'logo_text.svg' : 'new_update.svg';
		});
	}

	public async getCurrentPlaceInfo(): Promise<any> {
		if (!this.currentPlaceInfo) {
			await this.loadCurrentPlace();
		}
		return this.currentPlaceInfo;
	}

	private async loadCurrentPlace(): Promise<void> {
		let position: GeolocationPosition;

		try {
			position = await this.geoLocationService.getCurrentPosition();
		} catch (error: unknown) {
			if (error instanceof GeolocationServiceNotAvailableError) {
				return this.uiService.displayAppMessage(this.errorMessage_geolocationIsNotAvailable);
			}

			if (error instanceof GeolocationPermissionsDeniedOnWebError) {
				return this.uiService.displayAppMessage(this.errorMessge_geolocationPermissionsDeniedOnWeb);
			}

			if (error instanceof GeolocationPermissionsDeniedOniOSError) {
				return this.openHowToEnablePermissionsInstructionsDialog(
					this.errorMessge_geolocationPermissionsDeniedOniOS
				);
			}

			if (error instanceof GeolocationPermissionsDeniedOnAndroidError) {
				return this.openHowToEnablePermissionsInstructionsDialog(
					this.errorMessge_geolocationPermissionsDeniedOnAndroid
				);
			}

			if (error instanceof GeolocationAccessDeniedTemporarilyError) {
				return; // do nothing
			}

			if (error instanceof GeolocationUnknownError) {
				this.uiService.displayAppMessage(this.errorMessage_cantGetYourLocationAtTheMomentMsg);
				throw error; // rethrow only unknown errors
			}

			// This is an unanticipated error which this service doesn't
			// know how to handle, so rethrow it
			throw error;
		}

		let place: GeocoderResult;

		try {
			place = await this.getPlaceForCoordinates(position);
		} catch (error: unknown) {
			this.uiService.displayAppMessage(this.errorMessage_cantGetYourLocationAtTheMomentMsg);
			throw error; // rethrow to log the error
		}

		const placeInfo = { isCurrentPlace: true, ...place };
		this.currentPlaceInfo = placeInfo;
	}

	private getPlaceForCoordinates(position: GeolocationPosition): Promise<google.maps.GeocoderResult> {
		const lat = position?.coords?.latitude;
		const lng = position?.coords?.longitude;

		return new Promise((resolve, reject) => {
			if (!lat || !lng) {
				const technicalErrMsg =
					'Did not provide valid arguments to getPlaceForCoordinates (possibly null position)';
				reject(new Error(technicalErrMsg)); // Error (and not AppError) = sent to logger, not displayed as a user message
				return;
			}

			const numLat = Number(lat);
			const numLng = Number(lng);

			if (Number.isNaN(numLat) || Number.isNaN(numLng)) {
				const technicalErrMsg = `Unexpected lat or lng values: ${lat}, ${lng}`;
				reject(new Error(technicalErrMsg));
				return;
			}

			const geocoder = new google.maps.Geocoder();
			geocoder.geocode({ location: { lat: numLat, lng: numLng } }, (res, status) => {
				if (status === 'OK' && res[0] && res[0].geometry.location) {
					resolve(res[0]);
					return;
				}

				const technicalErrMsg = `Unexpected error received from google.maps.Geocoder in getPlaceForCoordinates (status: ${status})`;
				reject(new Error(technicalErrMsg));
			});
		});
	}

	/** Check for Geolocation availability and location permission,
	 * by calling the approporiate Web or Native checkPermission implementation.
	 * @returns Promise<boolean> -
	 * 		true - if geolocation can be used (is available and permissions granted)
	 * 		false - if geolocation cannot be used (not available or has no perms)
	 */
	public async checkPermission(): Promise<boolean> {
		try {
			return await this.geoLocationService.checkAndRequestPermission();
		} catch (e) {
			// Converting any thrown errors to 'false' for backward compatability with
			// existing external implementations that call checkPermission directly
			// For ex. in providers-search.service.ts and
			return false;
		}
	}

	public getPlaceByAddress(address: string) {
		return new Promise((resolve, reject) => {
			const geocoder = new google.maps.Geocoder();
			geocoder.geocode({ address }, (res, status) => {
				if (status === 'OK' && res[0] && res[0].geometry.location) {
					resolve(res[0].address_components);
				} else {
					reject('error in getPlaceByAddress');
				}
			});
		});
	}

	private openHowToEnablePermissionsInstructionsDialog(message: string) {
		const options: HealtheeDialogData = {
			icon: this.updateIcon,
			title: this.actionRequiredDialogTitle,
			message: message,
			confirmText: this.confirmText,
			hasCloseButton: true,
		};

		this.dialogService.open(options);
	}

	private displayPermissionsRationaleOnAndroid(): Promise<boolean> {
		return new Promise((resolve, reject) => {
			const options: HealtheeDialogData = {
				icon: this.updateIcon,
				title: this.actionRequiredDialogTitle,
				message: this.permissionRationaleMessageAfterPermsDeniedOnAndroid,
				confirmText: this.permissionRationaleActionAskForPermsAgainOnAndroid,
				cancelText: this.permissionRationaleActionContinueWithoutPermsOnAndroid,
				hasCloseButton: false,
			};

			this.dialogService.open(options).subscribe({
				next: (action) => resolve(action),
				error: (err) => reject(new Error(err?.message)),
			});
		});
	}
}
