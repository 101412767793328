import { Input, Output, Component, EventEmitter, HostBinding } from '@angular/core';

@Component({
	selector: 'app-search-results-base-card',
	templateUrl: './search-results-base-card.component.html',
	styleUrls: ['./search-results-base-card.component.scss'],
})
export class SearchResultsBaseCardComponent {
	@Input() appearance: 'standard' | 'outlined' = 'outlined';
	@Input() isFavorite: boolean = null;
	@Output() isFavoriteChange = new EventEmitter<boolean>();

	@HostBinding('class') get classes() {
		return this.appearance;
	}
}
