import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import CFG from '../../../config/app-config.json';
import { Deductible } from '../domain/deductible';
import { ConnectParams } from './helpers/connect.params';
import { StoreApi } from '../../../utils/store/store-api';
import { StoreUpdateRegistryService } from '../../stores/store-update-registry.service';
import { DeductiblesStoreToken } from '../../stores/deductibles-store/deductibles-store.token';
import { Observable } from 'rxjs';
import { MeUserStoreToken } from '../../stores/me-user-store/me-user-store.token';
import { PlanType } from 'src/app/models/plan-type.model';

@Injectable({
	providedIn: 'root',
})
export class DeductiblesApiService extends StoreApi {
	constructor(private httpClient: HttpClient, private storeUpdateRegistryService: StoreUpdateRegistryService) {
		super(storeUpdateRegistryService);
	}

	getDeductibles() {
		return this.httpClient.get<Deductible>(CFG.apiEndpoints.deductiblesAPIs.getDeductibles);
	}

	connect(params: ConnectParams) {
		return this.httpClient
			.post(CFG.apiEndpoints.deductiblesAPIs.connect, params)
			.pipe(this.withStoreUpdate(MeUserStoreToken, undefined))
			.pipe(this.withStoreUpdate(DeductiblesStoreToken, undefined));
	}

	disconnect(planType: PlanType) {
		return this.httpClient
			.post(
				CFG.apiEndpoints.deductiblesAPIs.disconnect,
				{ planType },
				{
					responseType: 'text',
				}
			)
			.pipe(this.withStoreUpdate(DeductiblesStoreToken, undefined))
			.pipe(this.withStoreUpdate(MeUserStoreToken, undefined));
	}

	public updateDeductibles(): Observable<object> {
		return this.httpClient.post(CFG.apiEndpoints.deductiblesAPIs.updateDeductibles, {});
	}
}
