import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as Sentry from '@sentry/angular';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import CFG from '../config/app-config.json';
import { captureExceptionSentry } from '../utils/sentry.utils';

export enum ReportFeature {
	ProvidersSearch = 'ProvidersSearch',
	PCTProvidersSearch = 'PCTProvidersSearch',
	CoverageInfoSearch = 'CoverageInfoSearch',
	Feedback = 'Feedback',
}

export interface ProviderNotFoundReport {
	feature: ReportFeature;
	titlePrefix?: string;
	searchText: string;
}

export interface UserFeedbackReport {
	rating?: number;
	feature: string;
	data: object;
	description?: string;
}

const SENTRY_TAG = 'ReportService';

@Injectable({
	providedIn: 'root',
})
export class ReportService {
	constructor(private http: HttpClient) {
	}

	reportProviderNotFound(data: ProviderNotFoundReport): Observable<unknown> {
		const url = CFG.apiEndpoints.report.providerNotFound;

		return this.http.post(url, data).pipe(
			catchError((error) => {
				Sentry.withScope((scope) => {
					scope.setLevel('info');
					captureExceptionSentry(error, SENTRY_TAG);
				});
				// return throwError(error);
				console.error('reporting provider not found error', error);
				return of({ error: error });
			})
		);
	}

	reportUserFeedback(data: UserFeedbackReport): Observable<unknown> {
		const url = CFG.apiEndpoints.report.userFeedback;

		return this.http.post(url, data).pipe(
			catchError((error) => {
				Sentry.withScope((scope) => {
					scope.setLevel('info');
					captureExceptionSentry(error, SENTRY_TAG);
				});
				return throwError(error);
			})
		);
	}
}
