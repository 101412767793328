import { T } from '@transifex/angular';

export class ServicePanelCopayLocalize {
	@T('Coverage depends on location')
	static coverageDependsOnLocation: string;

	@T('Not covered')
	static notCovered: string;

	@T('Covered at 100%')
	static coveredAt100Percent: string;

	@T('per')
	static per: string;

	@T('for the first')
	static forTheFirst: string;

	@T('days')
	static days: string;

	@T('Copay')
	static copay: string;

	@T('up to')
	static upTo: string;

	@T('then')
	static then: string;

	@T('Coinsurance')
	static coinsurance: string;
}
