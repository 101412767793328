<div class="modal-body">
	<h3 class="header">Mobile debug logs</h3>
	<p class="text">{{ logs.length }} messages
		<span class="link" (click)="refreshLogs()">Refresh</span> <span class="link" (click)="clearLogs()">Clear</span>
	</p>
</div>

<mat-divider></mat-divider>

<div class="logs">
	<p *ngFor="let log of logs">
		<span>[{{ log.timestamp | date: 'dd/MM/yy, hh:mm'}}] {{ log.message }}</span>
		<br>
		<span class="params" *ngIf="log.params">Params = {{ log.paramsJSONStr }}</span>
	</p>
</div>
