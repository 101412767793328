import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ProviderDetails, ProviderLocation, isMp3Location } from '../../helpers/providers.helpers';
import { MPIProvidersSearchService } from 'src/app/services/providers-search/mpi-providers-search.service';
import { MPIRibbonEntitiesStoreService } from 'src/app/services/stores/ribbon-entities-store/mpi-ribbon-entities-store.service';
import { RxjsUtils } from 'src/app/utils/rxjs';
import { retrieveValueByEntity, SearchEntity } from '../../helpers/providers-search.helper';
import { Observable, take } from 'rxjs';
import { UIService } from 'src/app/services/ui.service';

@Component({
	selector: 'app-provider-card-details',
	templateUrl: './provider-card-details.component.html',
	styleUrls: ['./provider-card-details.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ProviderCardDetailsComponent implements OnChanges {
	@Input() providerData: ProviderDetails;
	@Input() isFavorite: boolean = false;
	@Output() isFavoriteChange = new EventEmitter<boolean>();
	@Output() backClick = new EventEmitter();

	public ribbonEntities$ = this._ribbonEntitiesStoreService.get().pipe(RxjsUtils.isNotNil(), take(1));
	public isMobile$: Observable<boolean> = this._uiService.isMobile$;
	public locations: ProviderLocation[] = [];

	constructor(
		private _providersSearchService: MPIProvidersSearchService,
		private _ribbonEntitiesStoreService: MPIRibbonEntitiesStoreService,
		private _uiService: UIService
	) {}
	ngOnChanges(changes: SimpleChanges): void {
		if (changes.providerData && this.providerData?.locations?.length > 0) {
			this.locations = this.providerData.locations.filter(isMp3Location);
		}
		if (changes.providerData && this.providerData?.specialties.length > 0) {
			const services = [];

			this.ribbonEntities$.subscribe((ribbonEntities) => {
				services.push(
					...this.providerData.specialties.map((specialty) => {
						const id = retrieveValueByEntity(specialty, SearchEntity.Provider, ribbonEntities);
						return { id, name: specialty };
					})
				);
				this._providersSearchService.setServices(services);
			});
		}
	}

	public goBack() {
		this.backClick.emit();
	}
}
