export interface AppointmentData extends AvailabilityData, UserInfoData, SlotData {}

export interface SlotData {
	date: Date;
}

export interface AvailabilityData {
	visitReasonId: string;
	providerLocationId: string;
	patientType: 'new' | 'existing';
}

export interface UserInfoData {
	firstName: string;
	lastName;
	dob;
	gender;
	phoneNumber;
	email;
	userAddress;
	userCity;
	userState;
	userZipCode;
}

export enum AppointmentStatuses {
	pending = 'pending_booking',
	confirmed = 'confirmed',
	failed = 'booking_failed',
	cancelPending = 'pending_cancel',
	cancelFailed = 'cancel_failed',
	cancelled = 'cancelled',
	noShow = 'no_show',
	reschedulePending = 'pending_reschedule',
	rescheduled = 'rescheduled',
	rescheduleFailed = 'reschedule_failed',
}

export interface AppointmentFetch {
	appointmentId: string;
	locationId: string;
	date: Date;
	status: AppointmentStatuses;
	npi: string;
	reason: string;
	user: string;
	pendingBookingLastCheckDate: Date;
	createdAt: Date;
	updatedAt: Date;
	rescheduleCounter: number;
	patientType: 'new' | 'existing';
	providerName: string;
	locationName: string;
}

export interface ZocdocAnonymousToken {
	token: string;
}

export interface ZocdocRefreshTokenExpiry {
	expiresAt: string;
}
