<ng-container *ngIf="{
	appInfo: ionicAppInfo$ | async,
	buildInfo: ionicBuildInfo$ | async
} as ionic; else noContent">
	<span class="app-verion-container" (click)="countClicksOnVersionCaption()">
		<span *ngIf="ionic.appInfo && showBundleId">{{ ionic.appInfo.id }} </span>
		<span *ngIf="ionic.appInfo">v{{ ionic.appInfo.version }} ({{ ionic.appInfo.build }}) - </span>
		<span *ngIf="showIonicBuildId"> Build {{ ionic.buildInfo ? ionic.buildInfo.buildId : '00-000-00' }} </span>
		<span *ngIf="ionic.appInfo && showWebAppVersion"><br>v{{ webAppVersion }}</span>

		<ng-container *ngIf="isDevelopmentOrLocalEnv || forceProductionEnvInfo">
			<ion-badge color="{{ isDevelopmentOrLocalEnv ? 'primary' : 'danger' }}">
				{{ env.envName }}
			</ion-badge>

			<span>
				<br />
				host: {{ hostUrl }}
			</span>
		</ng-container>

		<span *ngIf="apiVersion">
			<br />
			API: {{ apiVersion }}
		</span>
	</span>

	<app-mobile-developer-menu
		[appInfo]="ionic.appInfo"
		[appFlowInfo]="ionic.buildInfo"
		[environment]="env"
		[host]="hostUrl"
		[isOpen]="showDeveloperMenu"
		(dismissed)="dismissDeveloperMenu()"
		(dismissedAndDeactivated)="lockDeveloperMode()"
	></app-mobile-developer-menu>
</ng-container>

<ng-template #noContent>
	No content
</ng-template>
