<ng-container
	*ngIf="{
		favoriteProvidersByNpi: favoriteProvidersByNpi$ | async,
		providersLocations: providersLocations$ | async,
		resultsInNetwork: resultsInNetwork$ | async,
		results: results$ | async,
		entityType: entityType$ | async,
		isSuccess: isSuccess$ | async,
		isLoading: isLoading$ | async
	} as asyncData"
>
	<app-providers-search-results-print [providers]="asyncData.results" [entityType]="asyncData.entityType">
	</app-providers-search-results-print>
	<div class="providers-search-main-layout-container">
		<div *ngIf="showOonLoader" class="oon-loader">
			<div class="title">{{ 'We were unable to find In-Network providers' | translate }}</div>
			<div class="sub-title">{{ 'Searching for Out-of-Network providers' | translate }}</div>
			<ripple-loader size="sm"></ripple-loader>
		</div>
		<div class="results-container">
			<div *ngIf="asyncData.isLoading" class="results-list" [class.mobile-active]="asyncData.isLoading">
				<div class="provider-cards">
					<app-provider-card-loader></app-provider-card-loader>
					<app-provider-card-loader></app-provider-card-loader>
					<app-provider-card-loader></app-provider-card-loader>
					<app-provider-card-loader></app-provider-card-loader>
					<app-provider-card-loader></app-provider-card-loader>
				</div>
			</div>

			<div
				*ngIf="!asyncData.isLoading && asyncData.results.records.length > 0"
				class="results-list"
				[class.mobile-active]="!showMobileMapView"
			>
				<div *ngIf="networkStructure$ | async as networkStructure" class="disclaimer-container">
					<div
						*ngIf="
							asyncData.results.isInNetwork === null &&
							networkStructure !== NetworkStructure.INDEMNITY &&
							networkStructure !== NetworkStructure.RBP
						"
						class="unknown-network-banner"
					>
						<img src="/assets/images/providers-search/unknown-network.svg" alt="unknown-network" />
						<div>{{ 'Network status is missing for the following providers' | translate }}</div>
					</div>
				</div>

				<div class="provider-cards">
					<div class="results-count-container" *ngIf="asyncData.results.totalCount > 0">
						<div class="results-count-wrapper">
							<div class="radius-circle icon-size-8 background-success mr-8"></div>
							<div *ngIf="networkStructure$ | async as networkStructure">
								<div>
									{{ asyncData.results.totalCount | approximateProvidersCount }}
									{{ 'Results' | translate }}
								</div>
							</div>
						</div>

						<ripple-button
							leadingIcon="print"
							(click)="printResults($event, asyncData.results)"
							[isDisabled]="isPrintingInProgress"
							[hierarchy]="ButtonHierarchy.tertiary"
							matTooltip="{{ 'Print current page results' | translate }}"
						>
						</ripple-button>
					</div>

					<ng-container *ngIf="asyncData.entityType === SearchEntity.Facility">
						<app-facility-card
							class="card-to-scroll-to"
							*ngFor="let facility of asyncData.results.records; let index = index"
							[facility]="facility"
							[isFavorite]="false"
							[routerLink]="[providersSearchUrlsService.facilityUrl, facility.uuid]"
							(mouseleave)="setActiveLocationIndex(null)"
							(mouseenter)="setActiveLocationIndex(index)"
						>
						</app-facility-card>
					</ng-container>

					<ng-container
						*ngIf="
							asyncData.entityType === SearchEntity.Provider ||
							asyncData.entityType === SearchEntity.Treatment ||
							asyncData.entityType === SearchEntity.Condition ||
							asyncData.entityType === SearchEntity.SearchByGroupName
						"
					>
						<app-provider-card
							class="card-to-scroll-to"
							*ngFor="let providerData of asyncData.results.records; let index = index"
							[routerLink]="[providersSearchUrlsService.specialistUrl, providerData.npi]"
							[queryParams]="{ location_uuid: providerData.locations[0]?.uuid }"
							[providerData]="providerData"
							[isFavorite]="!!asyncData.favoriteProvidersByNpi[providerData.npi]?.isFavorite"
							(isFavoriteChange)="onIsFavoriteChange($event, providerData)"
							(click)="onProviderCardClick()"
							(mouseleave)="setActiveLocationIndex(null)"
							(mouseenter)="setActiveLocationIndex(index)"
							(openScheduleAppointment)="openCreateAppointment(providerData)"
							eventSource="Search Results card"
						>
						</app-provider-card>
					</ng-container>

					<ng-container *ngIf="asyncData.entityType === SearchEntity.SearchByNeed">
						<ng-container *ngFor="let data of asyncData.results.records; let index = index">
							<app-provider-card
								*ngIf="data.npi"
								class="card-to-scroll-to"
								[routerLink]="[providersSearchUrlsService.specialistUrl, data.npi]"
								[queryParams]="{ location_uuid: data.locations[0].uuid }"
								[providerData]="data"
								[isFavorite]="!!asyncData.favoriteProvidersByNpi[data.npi]?.isFavorite"
								(isFavoriteChange)="onIsFavoriteChange($event, data)"
								(click)="onProviderCardClick()"
								(mouseleave)="setActiveLocationIndex(null)"
								(mouseenter)="setActiveLocationIndex(index)"
								(openScheduleAppointment)="openCreateAppointment(data)"
								eventSource="Search Results card"
							>
							</app-provider-card>

							<app-facility-card
								*ngIf="data.uuid"
								class="card-to-scroll-to"
								[facility]="data"
								[isFavorite]="false"
								[routerLink]="[providersSearchUrlsService.facilityUrl, data.uuid]"
								(mouseleave)="setActiveLocationIndex(null)"
								(mouseenter)="setActiveLocationIndex(index)"
							>
							</app-facility-card>
						</ng-container>
					</ng-container>

					<app-pagination (goToPage)="goToPage($event)"></app-pagination>
				</div>
			</div>
			<div
				*ngIf="
					(isSuccess$ | async) &&
					asyncData.results.records.length === 0 &&
					!showOonLoader &&
					!showMobileMapView
				"
				class="no-results"
			>
				<div class="no-results-message">
					<img src="/assets/images/providers-search/no-results.svg" alt="noresult" />
					<div class="title">{{ 'No results were found' | translate }}</div>
					<div class="no-results-message-body">
						{{
							'MP3 Service Area consists of the Los Angeles and Ventura Counties in California only.'
								| translate
						}}
					</div>
				</div>
			</div>

			<div
				class="map-container"
				*ngIf="asyncData.results.records.length >= 0"
				[class.mobile-active]="showMobileMapView"
			>
				<ng-container *ngIf="asyncData.providersLocations.length >= 0">
					<app-providers-map
						[providerLocations]="asyncData.providersLocations"
						[hasSearchThisAreaButton]="true"
						[activeLocationIndex]="activeLocationIndex$ | async"
						[showActiveLocationCard]="showActiveLocationCard$ | async"
						[type]="asyncData.entityType"
						[isLoading]="asyncData.isLoading"
						(openCreateAppointment)="openCreateAppointment($event)"
						[disablePanTo]="disablePanTo$ | async"
					>
					</app-providers-map>
				</ng-container>
			</div>
		</div>
	</div>
</ng-container>
