import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map } from 'rxjs';
import { TranslationService } from '@transifex/angular';

import CFG from '../config/app-config.json';
import {
	AppLanguage as AppLanguagesAlias,
	DEFAULT_LOCALE,
	DEFAULT_APP_LANG,
	DEFAULT_SUPPORTED_LANGS,
	LANGUAGE_LIST
} from './helpers/language.defaults';
import { StorageService } from './storage.service';

export type AppLanguage = AppLanguagesAlias; // Expose AppLanguage to other consumers from this file

@Injectable({ providedIn: 'root' })
export class LanguageService {
	private _showLoginLanguagePicker$ = new BehaviorSubject<boolean>(false);
	private _appLanguage$ = new BehaviorSubject<AppLanguage>(DEFAULT_APP_LANG);
	private _supportedLanguages$ = new BehaviorSubject<AppLanguage[]>(DEFAULT_SUPPORTED_LANGS);

	public get showLoginLanguagePicker$() {
		return this._showLoginLanguagePicker$.asObservable();
	}
	public get appLanguage$() {
		return this._appLanguage$.asObservable();
	}
	public get supportedLanguages$() {
		return this._supportedLanguages$.asObservable();
	}
	public currentLanguage: string = DEFAULT_LOCALE;

	public isSpanish$ = this.appLanguage$.pipe(map((language) => language.locale === 'es'));

	constructor(
		private http: HttpClient,
		private storageService: StorageService,
		private translationService: TranslationService
	) {
		this.translationService.init({
			token: CFG.transifex.token,
		});

		const localeFromLocalStorage = this.storageService.getLocaleSettings() || DEFAULT_LOCALE;
		this.setLocale(localeFromLocalStorage);
	}

	public translate(str: string, params: Record<string, unknown>, instanceAlias?: string) {
		return this.translationService.translate(str, params, instanceAlias);
	}

	public async setLocale(locale: string) {
		if (!locale) {
			console.warn(`Locale "${locale}" is undefined. Fallback to default "${DEFAULT_LOCALE}" locale.`);
			locale = DEFAULT_LOCALE;
		}

		if (!LANGUAGE_LIST[locale]) {
			console.error(`Cannot set language for ${locale} because it's language definition is missing.`);
			return;
		}

		const language: AppLanguage = LANGUAGE_LIST[locale];

		await this.translationService.setCurrentLocale(language.localeTagOnTransifex);
		this.storageService.saveLocaleSettings(locale);
		this.currentLanguage = locale;
		this._showLoginLanguagePicker$.next(true);
		this._appLanguage$.next(language);
	}

	public updateUserLocale(locale: string) {
		const newLanguageData = { preferredLanguage: locale };

		this.http.post(CFG.apiEndpoints.userProfileData, newLanguageData).subscribe({
			next: () => {
				this.storageService.saveLocaleSettings(locale);
				window.location.reload();
			},
		});
	}

	public setSupportedLanguages(localesArray: string[]) {
		const languagesArray: AppLanguage[] = localesArray.map((locale: string) => {
			const languageDefinition = LANGUAGE_LIST[locale];

			if (!languageDefinition)
				console.warn(`Language definition for "${locale}" was not found.`);

			return languageDefinition;
		});

		this._supportedLanguages$.next(languagesArray);
	}
}
