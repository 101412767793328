<div class="plan-selection-container" *ngIf="!shrink">
	<a
		[routerLink]="[planSelectionPath]"
		class="mx-28 background-white font-size-14 border-border-color-light-blue px-8 py-7 border-60 plan-selection text-dark-blue-120"
		(click)="onClick()"
	>
		<div class="d-flex align-items-center">
			<img class="icon-size-42 mr-10" src="/assets/icons/icon-plan-selection-button.svg" />
			{{ 'Plan selection tool' | translate }}
		</div>
		<mat-icon class="text-azure-radiance">chevron_right</mat-icon>
	</a>
</div>

<a
	*ngIf="shrink"
	class="d-flex justify-content-center pt-13 pb-40"
	(click)="onClick()"
	[routerLink]="['/plan-selection']"
>
	<img class="icon-size-42" src="/assets/icons/icon-plan-selection-button.svg" />
</a>
