export const TOP_RATED_PROVIDERS = [
	{
		ribbonId: '',
		ribbonName: 'Primary Care Provider - PCP',
		text: '',
		selected: false,
	},
	{
		ribbonId: 'f9281d01-128a-4ee9-89ac-612680114304',
		ribbonName: 'Dentist',
		text: '',
		selected: false,
	},
	{
		ribbonId: '967aa811-b696-4b29-9180-4f43e3d77626',
		ribbonName: 'Optometrist',
		text: '',
		selected: false,
	},
	{
		ribbonId: 'd8f7fff0-9c5b-42e2-b011-376315d4be3d,9c470211-e3ea-4963-9568-8d8e9b8f5c44',
		ribbonName: 'Obstetrics and Gynecology - Gynecology',
		text: '',
		selected: false,
	},
	{
		ribbonId: 'b9dc44e1-6add-41f8-8df4-a8ef9cea706a',
		ribbonName: 'Dermatology',
		text: '',
		selected: false,
	},
	{
		ribbonId: 'adfb5cae-37f0-4472-a92f-5448c0ff3d66',
		ribbonName: 'Psychologist',
		text: '',
		selected: false,
	},
];
