import { JwtHelperService } from '@auth0/angular-jwt';

import { UserUpdateAction } from '../services/user.service';
import { UserData } from './user-data';

export class User {
	private jwtHelperService = new JwtHelperService();
	private tokenIsExpired = false;
	private _tokenExpirationDate: Date = null;

	constructor(private userData: UserData, private _updateAction: UserUpdateAction) {
		this.tokenIsExpired = this.jwtHelperService.isTokenExpired(this.userData.token);
		this._tokenExpirationDate = this.jwtHelperService.getTokenExpirationDate(this.userData.token);
	}

	get defaultRoute(): string {
		return '/home';
	}

	get data(): UserData {
		return { ...this.userData };
	}

	get updateAction(): UserUpdateAction {
		return this._updateAction;
	}

	get isAuthenticated() {
		return !!this.token;
	}

	get firstName() {
		return this.userData.firstName;
	}

	get isTalonTpa() {
		return this.userData.company?.isTalonTpa;
	}

	get isBlocked() {
		return this.userData.accountStatus === 'blocked';
	}

	get token(): string {
		if (!this.userData.token || this.tokenIsExpired) return null;

		return this.userData.token;
	}

	get tokenExpirationDate(): Date {
		return this.tokenExpirationDate;
	}

	get timeToTokenExpiry() {
		return this._tokenExpirationDate.getTime() - new Date().getTime();
	}
}
