import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

import { resolveHost } from '../utils/utils';

// Add suffixes or prefixes for routes to be passed "as-is", without changing the hostname.
// For example, relative urls (such as '/assets/images/') that are fetched through http - should be ignored.
export const ignoredRouteSuffixes = [];

export const ignoredRoutePrefixes = [
	'http://', // ignore absolute (not relative) urls that explicitly point to an http host
	'https://', // ignore absolute (not relative) urls that explicitly point to an https host
	'/assets/', // angular's asset directory should be retrieved from the angular host
];

@Injectable()
export class HostnameInterceptor implements HttpInterceptor {
	private resolvedHostname = resolveHost();

	constructor() {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (this.urlIsAnIgnoredRoute(req.url)) {
			return next.handle(req);
		}

		const requestWithAlteredHost = this.changeRequestHostname(req);
		return next.handle(requestWithAlteredHost);
	}

	private urlIsAnIgnoredRoute(url: string) {
		let found = false;

		ignoredRouteSuffixes.forEach((routeSuffix) => {
			if (url.endsWith(routeSuffix)) found = true;
		});

		ignoredRoutePrefixes.forEach((routePrefix) => {
			if (url.startsWith(routePrefix)) found = true;
		});
		return found;
	}

	private changeRequestHostname(req: HttpRequest<any>) {
		return req.clone({
			url: this.resolvedHostname + req.url,
		});
	}
}
