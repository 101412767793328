import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AlertController } from '@ionic/angular';
import { AppInfo } from '@capacitor/app';
import { Snapshot } from '@capacitor/live-updates';

import CFG from '../../../config/app-config.json';
import packageJson from '../../../../../package.json';
import { environment } from '../../../../environments/environment';
import { resolveHost } from '../../../utils/utils';

import { UIService } from '../../../services/ui.service';
import { MobileAppService } from '../../../services/mobile-app.service';

@Component({
	selector: 'app-version-label',
	templateUrl: './app-version-label.component.html',
	styleUrls: ['./app-version-label.component.scss'],
})
export class AppVersionLabelComponent implements OnInit {
	@Input() showBundleId: boolean = false;
	@Input() showIonicBuildId: boolean = false;
	@Input() showWebAppVersion: boolean = false;
	@Input() forceProductionEnvInfo: boolean = CFG.development.forceDisplayingEnvironmentInfoInProduction;
	@Input() showPopup: boolean = false;
	@Input() allowChangingHost: boolean = false;

	public webAppVersion: string = packageJson.version;
	public apiVersion: string = null;
	public ionicAppInfo$: Observable<AppInfo>;
	public ionicBuildInfo$: Observable<Snapshot>;
	public hostUrl = resolveHost();
	public showDeveloperMenu = false;
	public env = environment;
	public isDevelopmentOrLocalEnv = !environment.production;

	private clicksCounter = 0;

	constructor(
		private mobileAppService: MobileAppService,
		private uiService: UIService,
		public alertController: AlertController
	) {}

	ngOnInit(): void {
		this.ionicAppInfo$ = this.mobileAppService.ionicAppInfo$;
		this.ionicBuildInfo$ = this.mobileAppService.ionicBuildInfo$;
	}

	public countClicksOnVersionCaption() {
		if (this.isDevelopmentOrLocalEnv) {
			this.openDeveloperMode();
			return;
		}

		this.mobileAppService.developerMode$.pipe(take(1)).subscribe((activated) => {
			if (activated) {
				this.showDeveloperActionSheet();
				this.resetClicksCounter();
			}
		});

		if (this.clicksCounter === CFG.behavior.clicksToOpenDeveloperMenu) {
			this.resetClicksCounter();
			this.askForMenuNumberToOpenInDeveloperMode();
			return;
		}

		if (this.clicksCounter > CFG.behavior.clicksToOpenDeveloperMenu) {
			this.resetClicksCounter();
			return;
		}

		if (this.clicksCounter >= CFG.behavior.clicksToDisplayNotificationBeforeOpeningDeveloperMenu) {
			const clicksRemaining = CFG.behavior.clicksToOpenDeveloperMenu - this.clicksCounter;
			const youreAlmostThereMessage = `You're ${clicksRemaining} clicks from from dev menu`;
			const MESSAGE_DISPLAY_DURAION = 500;

			this.uiService.displayAppMessage(youreAlmostThereMessage, '', null, 'top', MESSAGE_DISPLAY_DURAION);
		}

		this.clicksCounter++;
	}

	private async askForMenuNumberToOpenInDeveloperMode() {
		const OPEN_MENU_BUTTON_ROLE = 'OPEN_MENU';

		const menuNumberPrompt = await this.alertController.create({
			backdropDismiss: false,
			header: 'Developer Mode',
			message: 'Please enter the number of the menu you want to open',
			inputs: [
				{
					name: 'menuNumber',
					type: 'number',
					placeholder: 'Menu number',
					cssClass: 'developer-mode-input',
				},
			],
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
					cssClass: 'secondary',
				},
				{
					text: 'Open',
					role: OPEN_MENU_BUTTON_ROLE,
				},
			],
		});

		await menuNumberPrompt.present();
		const { data, role } = await menuNumberPrompt.onDidDismiss();

		if (role !== OPEN_MENU_BUTTON_ROLE) return;

		if (+data?.values?.menuNumber === new Date().getDate()) this.openDeveloperMode();
	}

	private openDeveloperMode() {
		this.mobileAppService.developerModeActivated = true;
		this.showDeveloperActionSheet();
	}

	private showDeveloperActionSheet() {
		this.mobileAppService.developerMode$.pipe(take(1)).subscribe((activated) => {
			if (activated) {
				this.showDeveloperMenu = true;
			}
		});
	}

	private resetClicksCounter() {
		this.clicksCounter = 0;
	}

	public dismissDeveloperMenu() {
		this.showDeveloperMenu = false;
	}

	public lockDeveloperMode() {
		this.dismissDeveloperMenu();
		this.mobileAppService.developerModeActivated = false;
	}
}
