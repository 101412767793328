export interface ChatMessage {
	created?: Date;
	time?: string;
	type?: string;
	text?: any;
	status?: string;
	entities?: any;
	owner?: string;
	options?: any;
	msg?: any;
	areOptionsClickable?: boolean;
	origin?: string;
	rounded?: boolean;
	title?: string;

	planType?: string;
	iconURL?: string;
	inNetwork?: string;
	outNetwork?: string;
	blueOptionsNetwork?: string;
	inNetworkSubjectToDeductibles?: boolean;
	outNetworkSubjectToDeductibles?: boolean;
	blueOptionsNetworkSubjectToDeductibles?: boolean;
	notes?: string[];
	related?: {
		title: string;
		iconURL: string;
		name: string;
	}[];
	planCareType?: string;
	showCoverage?: boolean;
	hasRibbonData?: boolean;
	serviceId?: string;
	additionalNetworks?: any[];
	isCovered?: boolean;
	networkStructure?: NetworkStructure;
}

export enum NetworkStructure {
	RBP = 'rbp',
	SINGLE = 'single',
	MN = 'multi_network',
	INDEMNITY = 'indemnity',
	MTN = 'multi_tier_network',
	NN = 'no_network'
}
export interface DeductibleChat extends ChatMessage {
	contractType: string;
	talonConnected: boolean;
	talonData?: Deductibles;

	family?: {
		inNetwork: { text: string };
		outNetwork: { text: string };
		blueOptionNetwork: { text: string };
	};
	individual?: {
		inNetwork: { text: string };
		outNetwork: { text: string };
		blueOptionNetwork: { text: string };
	};
	individualPlusOne?: {
		inNetwork: { text: string };
		outNetwork: { text: string };
		blueOptionNetwork: { text: string };
	};
	outOfPocketMax?: {
		individual: Deductible[];
		family: Deductible[];
		individualPlusOne: Deductible[];
	};
}
export interface Deductibles {
	deductible: {
		individual: Deductible[];
		family: Deductible[];
		individualPlusOne: Deductible[];
	};
	outOfPocketMax: {
		individual: Deductible[];
		family: Deductible[];
		individualPlusOne: Deductible[];
	};
}

export interface Deductible {
	currentAmount: string;
	maxAmount: string;
	network: string;
	scope: string;
	type: string;
	_id: string;
}

export interface ChatData {
	origin: string;
	message: string;
	analyticsOrigin: string;
}

export interface ChatUserLogData {
	_id: string;
	company: string;
	contracts: string[];
}
