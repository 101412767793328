<div class="no-ps-results-container">
	<div class="section section-1">
		<p class="title">
			{{ 'Sorry, we couldn’t find any results for' | translate }} <span>‘{{ searchQuery }}’</span>
		</p>

		<p class="description">
			{{ 'You can modify your search or' | translate }}
			<a (click)="resetFiltersEvent.emit()">{{ 'Reset filters' | translate }}</a>
		</p>
	</div>

	<div class="section section-2">
		<h6 class="title">{{ 'Explore top searches' | translate }}</h6>

		<div class="explore-top-searches-container">
			<app-explore-top-searches
				(setTopServiceSelected)="setTopServiceSelected.emit($event)"
			></app-explore-top-searches>
		</div>
	</div>

	<div class="section section-3" *ngIf="(isTelehealth$ | async) === true">
		<h6 class="title">{{ 'Need medical advice?' | translate }}</h6>

		<app-telehealth-medical-advice></app-telehealth-medical-advice>
	</div>
</div>
