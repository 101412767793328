import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { T } from '@transifex/angular';
import { filter, Observable, switchMap, take, tap } from 'rxjs';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';

import { isIonic } from '../utils/utils';
import CFG from '../config/app-config.json';

import { UserService } from './user.service';
import { UIService } from './ui.service';
import { TrackingService } from './tracking.service';
import { HealtheeDialogService } from './healthee-dialog.service';

import { EditInfoModalComponent } from '../modules/main-layout/main-layout-shared/edit-info-modal/edit-info-modal.component';
import { ContactUsModalComponent } from '../modules/main-layout/main-layout-shared/contact-us-modal/contact-us-modal.component';
import { ChangePassDialogComponent } from '../modules/main-layout/health-profile/personal-info/change-pass-dialog/change-pass-dialog.component';
import { ChangeNameDialogComponent } from '../modules/main-layout/my-profile/change-name-dialog/change-name-dialog.component';
import { ChangeAvatarDialogComponent } from '../modules/main-layout/my-profile/change-avatar-dialog/change-avatar-dialog.component';
import { HealtheeDialogData } from '../modules/app-shared/healthee-dialog/healthee-dialog.component';
import { AddressBarDialogComponent } from '../modules/app-shared/address-bar-dialog/address-bar-dialog.component';
import { BlockedAccountModalComponent } from '../modules/main-layout/main-layout-shared/blocked-account-modal/blocked-account-modal.component';
import { TalonWidgetComponent } from '../modules/main-layout/main-layout-shared/talon-widget/talon-widget.component';
import { ChangeLanguageComponent } from '../modules/account/change-language/change-language.component';
import { RecomparePlanModalComponent } from '../modules/plan-selection/summary-page/recompare-plan-modal/recompare-plan-modal.component';
import { ChatDialogComponent } from '../modules/main-layout/ask-zoe/chat-dialog/chat-dialog.component';
import { ShareSummaryModalComponent } from '../modules/plan-selection/summary-page/share-summary-modal/share-summary-modal.component';
import { RedirectToCompleteEnrollmentComponent } from '../modules/plan-selection/summary-page/redirect-to-complete-enrollment/redirect-to-complete-enrollment.component';
import { VerifyPhoneModalComponent } from '../modules/account/verify-phone-modal/verify-phone-modal.component';
import { ToggleNotificationsModalComponent } from '../modules/account/toggle-notifications-modal/toggle-notifications-modal.component';
import { PreventiveCareDetailsCardComponent } from '../modules/preventive-care/preventive-care-details-card/preventive-care-details-card.component';
import { PreventiveCareMarkAsCompletedComponent } from '../modules/preventive-care/preventive-care-mark-as-completed/preventive-care-mark-as-completed.component';
import { PreventiveCarePersonalizeTrackerComponent } from '../modules/preventive-care/preventive-care-personalize-tracker/preventive-care-personalize-tracker.component';
import { PreventiveCareParams } from './preventive-care.service';
import { UnleashService } from './unleash.service';
import { FfNewBrandLogo } from '../config/feature-flags/ff-new-brand-logo';

const TAG = 'AppManager';
declare let cordova: any;

@Injectable({ providedIn: 'root' })
export class AppManagerService {
	private changePassDialogRef: MatDialogRef<ChangePassDialogComponent>;
	private changeNameDialogRef: MatDialogRef<ChangeNameDialogComponent>;
	private changeAvatarDialogRef: MatDialogRef<ChangeAvatarDialogComponent>;
	private blockedAccountModalOpened: boolean;
	private userName: string;
	private isInactivityDialogOpen: boolean;

	@T('Language')
	language: string;

	@T('Change language')
	changeLanguage: string;

	@T('Contact Us')
	contactUs: string;

	@T('Edit your information')
	editInfo: string;

	@T('Logout')
	logoutTitle: string;

	@T('Are you sure you want to logout?')
	logoutAssurance: string;

	@T('Cancel')
	logoutCancel: string;

	@T('Logout')
	logoutConfirm: string;

	@T('Password Changed')
	passChanged: string;

	@T('Your password has been changed successfully')
	passChangedSuccess: string;

	@T('I would like to send you notifications')
	pushNotificationsTitle: string;

	@T(
		'Notifications may include alerts, sounds, and icon badges. These could be configured in your device’s Settings. Go to Settings -> Healthee -> Notifications -> Allow Notifications'
	)
	enablePushNotificationsIos: string;

	@T('Notifications may include alerts, sounds, and icon badges. These could be configured in Settings.')
	enablePushNotificationsAndroid: string;

	@T('Ok')
	confirmText: string;

	@T('Yes')
	yesText: string;

	@T('No')
	noText: string;

	@T('Open settings')
	openSettings: string;

	@T('Not now')
	notNow: string;

	@T('Re-compare plans')
	recomparePlansTitle: string;

	@T('Share')
	shareSummaryTitle: string;

	@T('The app is currently being updated with a new version.')
	updateAvailableTitle: string;

	@T('You will be automatically logged out. Re-logging into your account will display the new version. See you soon.')
	updateAvailableMessage: string;

	@T('Complete your enrollment')
	redirectToCompleteEnrollment: string;

	@T('Activate verification via sms?')
	public verifyPhoneTitle: string;

	@T('Change your phone number')
	public changePhoneTitle: string;

	@T('Email / Text notifications')
	public toggleNotificationsTitle: string;

	isNewBrandLogoEnabled$ = this.unleashService.isEnabled$(FfNewBrandLogo);
	private updateIcon: string = 'new_update.svg';

	constructor(
		private dialogService: HealtheeDialogService,
		private idleService: Idle,
		private userService: UserService,
		private uiService: UIService,
		private trackingService: TrackingService,
		private unleashService: UnleashService
	) {
		this.userService.user$.subscribe((user) => (user ? this.onUserLogin() : this.onUserLogout()));

		this.userService.userData$.pipe(take(1)).subscribe((data) => {
			this.userName = data.nickname || data.firstName;
		});

		this.isNewBrandLogoEnabled$.pipe(take(1)).subscribe((isEnabled) => {
			this.updateIcon = isEnabled ? 'logo_text.svg' : 'new_update.svg';
		});
	}

	/** Should be called after the login is completed.
	 * Should load settings and variables that require user authentication
	 */
	private onUserLogin() {
		this.configureAppAutoLogout();
	}

	private configureAppAutoLogout() {
		// set an inactivity timeout to ask to stay connected (resume) or disconnect (see app-manager). i.e. how long can a user be inactive before considered idle
		this.idleService.setIdle(CFG.behavior.sessionInactivityTimeoutInSeconds);
		// set a timeout to auto-logout anyway after inactivity notification is displayed (if user chose no action). i.e. how long can a user be idle before considered timed-out
		this.idleService.setTimeout(CFG.behavior.sessionInactivityNotificationCountdownInSeconds);
		// set the default interrupts to mousemoves, clicks, keyboard hits, scrolls and touches
		this.idleService.setInterrupts(DEFAULT_INTERRUPTSOURCES);
		this.idleService.watch();
	}

	private onUserLogout() {
		this.idleService.stop();
	}

	public initMainLayout() {
		this.configureAppIdleNotification();
	}

	public configureAppIdleNotification() {
		// idle monitoring starts only after login
		this.idleService.onIdleStart
			.pipe(
				filter(() => !this.isInactivityDialogOpen),
				tap(() => {
					this.isInactivityDialogOpen = true;
				}),
				switchMap(() => this.uiService.askToStayConnectedOrDisconnect()),
				// The dialog resolves after closing, so we should make the boolean false
				tap(() => {
					this.isInactivityDialogOpen = false;
				})
			)
			.subscribe((shouldLogout: boolean) => {
				if (shouldLogout) {
					console.log(TAG, 'User chose to logout after inactivity notification.');
					this.userService.logout();
				}
			});
	}

	public openBlockedAccountModal(name?: string) {
		if (this.blockedAccountModalOpened) return;
		this.blockedAccountModalOpened = true;
		const isMobileView = UIService.isMobileDeviceScreen(window);
		name = name || this.userName;
		const options: HealtheeDialogData = {
			componentOnly: true,
			fullHeight: isMobileView,
			component: BlockedAccountModalComponent,
			data: { name },
		};

		this.dialogService.open(options).subscribe(() => {
			this.blockedAccountModalOpened = false;
		});
	}

	public openContactUsModal(subject: string = '', modalText: string = null, modalTitle: string = null) {
		if (isIonic()) {
			this.uiService.navigate(['/me/contact-us', { subject }]); // contact-us is accessed via route and not modal in ionic
			return;
		}

		const options: HealtheeDialogData = {
			hasCloseButton: true,
			title: modalTitle ?? this.contactUs,
			component: ContactUsModalComponent,
			data: { subject, modalText },
		};

		return this.dialogService.open(options);
	}

	public openTalon(widgetType) {
		const options: HealtheeDialogData = {
			hasCloseButton: false,
			componentOnly: true,
			fullHeight: true,
			fullWidth: true,
			noPadding: true,
			component: TalonWidgetComponent,
			data: { widgetType },
		};

		this.dialogService.open(options);
	}

	public openEditInfoModal(data = {}) {
		const options: HealtheeDialogData = {
			hasCloseButton: true,
			title: this.editInfo,
			data: data,
			component: EditInfoModalComponent,
		};

		return this.dialogService.open(options).pipe(
			tap((confirmed) => {
				if (confirmed) {
					this.trackingService.trackClientEvent('Health Profile Edit my personal info');
					return;
				}
			})
		);
	}

	public openChangePasswordModal() {
		if (this.changePassDialogRef) return;
		const options: HealtheeDialogData = {
			hasCloseButton: false,
			component: ChangePassDialogComponent,
			componentOnly: true,
		};

		this.dialogService.open(options);
	}

	public openChangeAvatarModal() {
		if (this.changeAvatarDialogRef) return;

		const options: HealtheeDialogData = {
			componentOnly: true,
			component: ChangeAvatarDialogComponent,
			hasCloseButton: true,
		};

		this.dialogService.open(options).subscribe(() => {
			this.changeAvatarDialogRef = null;
		});
	}

	public openChangeNameModal() {
		if (this.changeNameDialogRef) return;

		const options: HealtheeDialogData = {
			componentOnly: true,
			component: ChangeNameDialogComponent,
			hasCloseButton: true,
		};

		this.dialogService.open(options).subscribe(() => {
			this.changeNameDialogRef = null;
		});
	}

	public openLogoutConfirmationModal() {
		const options: HealtheeDialogData = {
			hasCloseButton: true,
			title: this.logoutTitle,
			message: this.logoutAssurance,
			cancelText: this.logoutCancel,
			confirmText: this.logoutConfirm,
		};

		this.dialogService.open(options).subscribe((confirmed) => {
			if (confirmed) {
				const LOGOUT_REQUESTED_EXPLICITLY_BY_USER = true;
				this.userService.logout(LOGOUT_REQUESTED_EXPLICITLY_BY_USER);
				return;
			}
		});
	}

	public openPasswordChangeSuccess() {
		this.trackingService.trackClientEvent('Reset Password Success');
		const options: HealtheeDialogData = {
			title: this.passChanged,
			message: this.passChangedSuccess,
			confirmText: this.confirmText,
		};

		this.dialogService.open(options);
	}

	public openAddressBarDialog(serviceId: string) {
		const isMobileView = UIService.isMobileDeviceScreen(window);
		const options: HealtheeDialogData = {
			componentOnly: true,
			component: AddressBarDialogComponent,
			hasCloseButton: false,
			noPadding: true,
			data: { serviceId },
			fullHeight: isMobileView,
		};

		return this.dialogService.open(options);
	}

	public openChangeLanguageDialog(isActivationFlow = false): Observable<any> {
		const isMobileView: boolean = UIService.isMobileDeviceScreen(window);
		const title: string = isMobileView ? this.language : this.changeLanguage;

		const options: HealtheeDialogData = {
			hasCloseButton: true,
			title: title,
			data: { isActivationFlow },
			component: ChangeLanguageComponent,
		};

		return this.dialogService.open(options);
	}

	public openChooseFromListModal() {
		const options: HealtheeDialogData = {
			componentOnly: true,
			component: ChatDialogComponent,
			hasCloseButton: true,
		};

		return this.dialogService.open(options);
	}

	public openTalonModal() {
		const options: HealtheeDialogData = {
			title: 'Services search',
			hasCloseButton: true,
			fullHeight: true,
			fullWidth: true,
			noPadding: true,
			disableClose: true,
			component: TalonWidgetComponent,
		};
		this.dialogService.open(options);
	}

	public openIosRequestToSendNotifications() {
		const options: HealtheeDialogData = {
			title: this.pushNotificationsTitle,
			message: this.enablePushNotificationsIos,
			confirmText: this.confirmText,
		};

		this.dialogService.open(options);
	}

	public openAndroidRequestToSendNotifications() {
		const options: HealtheeDialogData = {
			title: this.pushNotificationsTitle,
			message: this.enablePushNotificationsAndroid,
			confirmText: this.openSettings,
			cancelText: this.notNow,
		};

		this.dialogService.open(options).subscribe((confirmed) => {
			if (confirmed) {
				cordova.plugins.diagnostic.switchToNotificationSettings();
			}
		});
	}

	public openRecomparePlanSelectionConfirmation(): Observable<any> {
		const options: HealtheeDialogData = {
			hasCloseButton: true,
			title: this.recomparePlansTitle,
			component: RecomparePlanModalComponent,
			noPadding: true,
		};

		return this.dialogService.open(options);
	}

	public openSharePlanSelectionSummary(): Observable<any> {
		const options: HealtheeDialogData = {
			hasCloseButton: true,
			title: this.shareSummaryTitle,
			component: ShareSummaryModalComponent,
			noPadding: true,
		};

		return this.dialogService.open(options);
	}

	public openUpdateVersionAvailabe() {
		const options: HealtheeDialogData = {
			icon: this.updateIcon,
			title: this.updateAvailableTitle,
			message: this.updateAvailableMessage,
			confirmText: this.confirmText,
			hasCloseButton: false,
		};

		this.dialogService.open(options).subscribe(() => {
			this.uiService.navigate(['/app-update']);
			return;
		});
	}

	public openRedirectToCompleteEnrollment(): Observable<any> {
		const options: HealtheeDialogData = {
			hasCloseButton: true,
			title: this.redirectToCompleteEnrollment,
			component: RedirectToCompleteEnrollmentComponent,
			noPadding: true,
		};

		return this.dialogService.open(options);
	}

	public openVerifyPhoneNumber() {
		const options: HealtheeDialogData = {
			title: this.verifyPhoneTitle,
			component: VerifyPhoneModalComponent,
			noPadding: true,
			hasCloseButton: true,
		};

		return this.dialogService.open(options);
	}

	public openChangePhoneNumber() {
		const options: HealtheeDialogData = {
			title: this.changePhoneTitle,
			component: VerifyPhoneModalComponent,
			noPadding: true,
			hasCloseButton: true,
			data: {
				isChangeExistingNumber: true,
			},
		};

		return this.dialogService.open(options);
	}

	public openToggleNotifications() {
		const options: HealtheeDialogData = {
			title: this.toggleNotificationsTitle,
			component: ToggleNotificationsModalComponent,
			noPadding: true,
			hasCloseButton: true,
		};

		return this.dialogService.open(options);
	}

	public openPreventiveCareDetailsCard(id: string) {
		const options: HealtheeDialogData = {
			component: PreventiveCareDetailsCardComponent,
			componentOnly: true,
			noPadding: true,
			hasCloseButton: true,
			data: {
				id,
			},
		};

		return this.dialogService.open(options);
	}

	public openPreventiveCareMarkAsCompleted(data: PreventiveCareParams) {
		const options: HealtheeDialogData = {
			component: PreventiveCareMarkAsCompletedComponent,
			componentOnly: true,
			noPadding: true,
			hasCloseButton: true,
			data,
		};

		return this.dialogService.open(options);
	}

	public openPreventiveCarePersonalizeTracker(source: string) {
		const options: HealtheeDialogData = {
			component: PreventiveCarePersonalizeTrackerComponent,
			componentOnly: true,
			hasCloseButton: true,
			data: { source },
		};

		return this.dialogService.open(options);
	}
}
