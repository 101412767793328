import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { ICON_COLOR, ICON_SIZE } from 'ripple';
import { Deductible, DeductibleNetwork } from 'src/app/services/api/domain/deductible';
import { HealtheeDialogService } from 'src/app/services/healthee-dialog.service';
import { T } from '@transifex/angular';
import { TrackingService } from 'src/app/services/tracking.service';
import { DeductiblesService } from 'src/app/services/deductibles/deductibles.service';

@Component({
	selector: 'app-service-panel-deductible',
	templateUrl: './service-panel-deductible.component.html',
	styleUrls: ['./service-panel-deductible.component.scss'],
})
export class ServicePanelDeductibleComponent {
	public readonly ICON_SIZE = ICON_SIZE;
	public readonly ICON_COLOR = ICON_COLOR;

	@T('Connect your plan')
	public connectFormModalTitle: string;

	@T('Connect your insurance account to see how much more you’ll pay before the rest of your coverage kicks in.')
	public connectFormModalDescription: string;

	@Input() public isContainerExpanded: boolean = false;
	@Input() public networkTitle: string;
	@Input() public subjectToDeductible: boolean;

	@ViewChild('deductiblesForm')
	public deductiblesForm: TemplateRef<any>;

	public deductible: Deductible;

	deductibleDetailsCardData: DeductibleNetwork;

	outOfPocketDetail: DeductibleNetwork;

	deductibleOOPMNetwork: DeductibleNetwork;

	deductibleNetwork: DeductibleNetwork;

	public constructor(
		private trackingService: TrackingService,
		public healtheeDialogService: HealtheeDialogService,
		public deductiblesService: DeductiblesService
	) {}

	public get showDeductible(): boolean {
		return (
			this.subjectToDeductible &&
			(this.deductiblesService.hasPlanLevelDeductible || this.deductiblesService.hasPlanLevelOutOfNetworkMaximum)
		);
	}

	public get inNetwork(): boolean {
		return this.networkTitle !== 'Out network';
	}

	public toggleContainer(): void {
		this.isContainerExpanded = !this.isContainerExpanded;
	}

	public openDeductibleConnectionForm() {
		this.healtheeDialogService.open({
			title: this.connectFormModalTitle,
			templateRef: this.deductiblesForm,
			maxWidth: '390',
		});

		this.trackingService.trackClientEvent('Deductible Connection start', { Source: 'Benefit Page' });
	}
}
