import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PreventiveCare } from './preventive-care.service';
import { StoreUpdateRegistryService } from './stores/store-update-registry.service';
import { StoreApi } from '../utils/store/store-api';
import { PreventiveCareStoreToken } from './stores/preventive-care-store/preventive-care-store.token';
import { take } from 'rxjs';

const PREVENTIVE_CARE_URLS = {
	CHECKLIST: '/preventive-care/checklist',
	SAVE_PINNED_STATE: '/preventive-care/pinned',
	SAVE_COMPLETED: '/preventive-care/completed',
};

@Injectable({
	providedIn: 'root',
})
export class PreventiveCareApiService extends StoreApi {
	constructor(private _http: HttpClient, private _storeUpdateRegistryService: StoreUpdateRegistryService) {
		super(_storeUpdateRegistryService);
	}

	public getChecklist() {
		return this._http.get<PreventiveCare[]>(PREVENTIVE_CARE_URLS.CHECKLIST);
	}

	public savePinnedState(id: number, state: boolean) {
		return this._http
			.put(PREVENTIVE_CARE_URLS.SAVE_PINNED_STATE, { id, state })
			.pipe(this.withStoreUpdate(PreventiveCareStoreToken, undefined), take(1));
	}

	public saveCompletedDate(id: number, date: Date) {
		return this._http
			.put(PREVENTIVE_CARE_URLS.SAVE_COMPLETED, { id, date })
			.pipe(this.withStoreUpdate(PreventiveCareStoreToken, undefined), take(1));
	}

	public clearCompletedDate(id: number) {
		const params = new HttpParams({
			fromObject: {
				id: id.toString(),
			},
		});
		return this._http
			.delete(PREVENTIVE_CARE_URLS.SAVE_COMPLETED, { params })
			.pipe(this.withStoreUpdate(PreventiveCareStoreToken, undefined), take(1));
	}
}
