import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TrackingService } from '../../../../../services/tracking.service';
import { UIService } from '../../../../../services/ui.service';
import { ProviderGender } from '../../helpers/providers.helpers';
import { ICON_SIZE } from 'ripple';
import { TelehealthService } from 'src/app/modules/telehealth/services/telehealth.service';

@Component({
	selector: 'app-telehealth-card',
	templateUrl: './telehealth-card.component.html',
	styleUrls: ['./telehealth-card.component.scss'],
})
export class TelehealthCardComponent {
	@Input() appearance: 'standard' | 'outlined' = 'outlined';
	@Input() isFavorite: boolean = null;
	@Input() category: string = null;
	@Output() isFavoriteChange = new EventEmitter<boolean>();
	@Output() detailsClicked = new EventEmitter<boolean>();

	ProviderGender = ProviderGender;

	public isMobileScreen: boolean = UIService.isMobileDeviceScreen(window) || UIService.isTabletDeviceScreen(window);
	public rippleSize: typeof ICON_SIZE = ICON_SIZE;

	constructor(private telehealthService: TelehealthService, private trackingService: TrackingService) {}

	public openDetails() {
		this.detailsClicked.emit(true);
		this.trackingService.trackClientEvent('Teleehalth free service details', { Source: 'Providers Search' });
	}

	openTelehealth() {
		this.telehealthService.onOpenTelehealth();
		this.trackingService.trackClientEvent('Telehealth modal started', { Source: 'Providers Search' });
	}
}
