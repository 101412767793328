import { T } from '@transifex/angular';

export class VersionUpdateLocalize {
	// Mandatory updates
	@T('Update required!')
	static txtAppUpdateIsRequired: string;

	@T('Mandatory update required. Please visit the App Store and update the Healthee app.')
	static txtMandatoryUpdateInstructionsIos: string;

	@T('Mandatory update required. Please visit the Google Play store and update the Healthee app.')
	static txtMandatoryUpdateInstructionsAndroid: string;

	// Optional updates
	@T('Update available!')
	static txtAppUpdateIsAvailable: string;

	@T('To access the latest features and improvements, open the App Store to get the latest version of Healthee app')
	static txtOptionalUpdateInstructionsIos: string;

	@T('To access the latest features and improvements, open Google Play to get the latest version of Healthee app')
	static txtOptionalUpdateInstructionsAndroid: string;

	@T('Open Google Play')
	static txtOpenGooglePlay: string;

	@T('Open App Store')
	static txtOpenAppStore: string;

	// Opening external browser
	@T('Opening App Store...')
	static txtOpeningAppStore: string;

	@T('Opening Google Play...')
	static txtOpeningGooglePlay: string;

	@T('Failed to open App Store. Please update the app manually.')
	static txtFailedToOpenAppStore: string;

	@T('Failed to open Google Play. Please update the app manually.')
	static txtFailedToOpenGooglePlay: string;

	// Optional native app update
	@T('Skip update')
	static txtSkipUpdating: string;
}
