<div class="service-footer">
  <button (click)="feedbackClick.emit()" class="give-feedback">
    {{ 'Give us feedback' | translate }}
  </button>
  <div *ngIf="displayFindProviders">
    <button (click)="findProviderClick.emit()" class="find-providers-button">
      {{ 'Find Providers' | translate }}
      <mat-icon>arrow_right_alt</mat-icon>
    </button>
  </div>

  <div *ngIf="displayConnectNow">
    <button (click)="connectNowClicked.emit()" class="find-providers-button">
      <ripple-icon [name]="'videoOutlined'"></ripple-icon>
      {{ 'Connect Now' | translate }}
    </button>
  </div>
</div>