import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {
	BehaviorSubject,
	catchError,
	combineLatest,
	filter,
	map,
	Observable,
	of,
	Subject,
	switchMap,
	take,
	tap
} from 'rxjs';

import CFG from '../config/app-config.json';
import { StorageService } from './storage.service';
import { ProviderType, UserDoctorData, UserOpenEnrollmentData } from '../models/open-enrollment.model';
import { RibbonHealthService } from './ribbon.service';
import { RibbonDoctorDetails, RibbonFacilityDetails } from '../models/ribbon-data.model';
import {
	PlanSelectionData,
	PlanSelectionStoreService,
} from './stores/plan-selection-store/plan-selection-store.service';
import { PlanSelectionAPIService, RecommendationsRequest } from './api/plan-selection-api/plan-selection-api.service';
import { SpendingAccountSelection, RibbonSearchEntity } from '../modules/plan-selection/plan-selection.types';
import { LoggerService } from './logger.service';
import { RxjsUtils } from '../utils/rxjs';
import { RibbonPCTStoreService } from './stores/ribbon-pct-store/ribbon-pct-store.service';

const STEPS_DICT = {
	profile: 0,
	medical: 1,
	dental: 2,
	vision: 3,
	summary: 4,
};

export interface PlansObject {
	medical: any[];
	dental: any[];
	vision: any[];
	hsaPlan?: any;
	hsaPlanFsaAddon?: any;
	fsaPlan?: any;
	fsaDependentCarePlan?: any;
}
export interface PlanIdsObject {
	medical?: string;
	dental?: string;
	vision?: string;
}

export interface PlanPremiumsObject {
	medical?: number;
	dental?: number;
	vision?: number;
}

export interface SkippedPlansByType {
	medical?: boolean;
	dental?: boolean;
	vision?: boolean;
}
export interface PlansIdsObject {
	medical?: string[];
	dental?: string[];
	vision?: string[];
}

export interface DoctorFormData extends UserDoctorData {
	insurances: string[];
	name: string;
	gender?: string;
	specialties: {
		display: string;
	}[];
	providerType: string;
	degrees: string[];
}
export interface DoctorsFormData {
	dataSaved: DoctorFormData[];
}

export interface EnrollmentFormData {
	facilitiesData?: DoctorsFormData;
	pcpData?: DoctorsFormData;
	specialistsData?: DoctorsFormData;
	optometristData?: DoctorsFormData;
	dentistData?: DoctorsFormData;
	noDoctorMarked?: boolean;
	numOfDependents?: number;
	childrenInfos?: any[];
	spouse?: {
		gender: string;
	};
	proceedWithoutProviders?: boolean;
	arePlansWithoutReferralsPreferred?: boolean;
}
export enum MedicalEvent {
	PCP = 'pcp',
	SPECIALIST = 'specialist',
	SURGERY = 'surgery',
	INPATIENT_SURGERY = 'inpatient-surgery',
	OUTPATIENT_SURGERY = 'outpatient-surgery',
	PREGNANCY = 'pregnancy',
	ER = 'er',
	OTHER = 'other',
	DENTIST = 'dentist',
	OPTOMETRIST = 'optometrist',
}
export interface UserEnrollmentData {
	status?: string;
	effectiveDate?: string;
	form?: EnrollmentFormData;
	selectedPlans?: PlansObject;
	selectedPlanIds?: PlanIdsObject;
	selectedPlanPremiums?: PlanPremiumsObject;
	pinnedPlanIds?: PlansIdsObject;
	lastStepIndex?: number;
	summary_isRedirecting?: boolean;
	summary_isExternalLinkOpened?: boolean;
	summary_isEnrolled?: boolean;
	filtersSaved?: any;
	isFamilyEnforced?: any;
	projectionSelected?: any;
	spendingAccountSelection?: SpendingAccountSelection;
	isSpendingAccountsTabDisabled?: boolean;
}

export interface Incentive {
	title: string;
	description: string;
}

export interface OpenEnrollmentData {
	effectiveDate: string;
	externalEnrollmentLink: string;
	educationCenterLink: string;
	customText: {
		enrollmentCompletion: string;
		benAdminName: string;
	};
	isTriNet?: boolean;
	isZipCodeLocked?: boolean;
	goLiveDate: Date;
	endDate: Date | null;
	isActive: boolean;
	coveragePeriodStart: Date;
	numOfPayPeriods: number;
	incentives?: Incentive[];
	plans: PlansObject;
	addons?: {
		fsaPlan?: FsaPlan;
		hsaPlan?: {
			id: string;
			name: string;
			fsaPlan?: FsaPlan;
			individualMaxContribution: number;
			familyMaxContribution: number;
		};
		fsaDependentCarePlan: {
			id: string;
			name: string;
			minContribution: number;
			fillingSeparatelyMaxContribution: number;
			householdMaxContribution: number;
		};
	};
}

export interface FsaPlan {
	id: string;
	name: string;
	maxContribution?: number;
	minContribution?: number;
}

export interface Step {
	currentIndex: number;
	lastIndex?: number;
}

@Injectable({
	providedIn: 'root',
})
export class PlanSelectionService {
	public userEnrollmentData: UserEnrollmentData = {};

	constructor(
		private logger: LoggerService,
		private http: HttpClient,
		private storageService: StorageService,
		private planSelectionStore: PlanSelectionStoreService,
		private ribbonHealthService: RibbonHealthService,
		private planSelectionAPIService: PlanSelectionAPIService,
		private ribbonPCTStore: RibbonPCTStoreService
	) { }
	public userId: string;

	private effectiveDate: string;
	private _selectedPlans$: BehaviorSubject<any> = new BehaviorSubject<any>({});
	private _filtersSaved$: BehaviorSubject<any> = new BehaviorSubject<any>({});
	private _pinnedPlans$: BehaviorSubject<any> = new BehaviorSubject<any>({});
	private _step$: BehaviorSubject<Step> = new BehaviorSubject<Step>(null);
	private _isFamilyEnforced$: BehaviorSubject<any> = new BehaviorSubject<any>({});
	private _resetData$: Subject<void> = new Subject();

	public readonly planSelectionRequest$ = new Subject<any>();

	get selectedPlans$(): Observable<any> {
		return this._selectedPlans$.asObservable();
	}

	get filtersSaved$(): Observable<any> {
		return this._filtersSaved$.asObservable();
	}

	get pinnedPlans$(): Observable<any> {
		return this._pinnedPlans$.asObservable();
	}

	get isFamilyEnforced$(): Observable<any> {
		return this._isFamilyEnforced$.asObservable();
	}

	get resetData$(): Observable<void> {
		return this._resetData$.asObservable();
	}

	public setEffectiveDate(effectiveDate: string): void {
		this.effectiveDate = effectiveDate;
	}

	public updateRecommendationData() {
		return combineLatest([this.planSelectionStore.get(), this.fetchRecommendations()]).pipe(
			take(1),
			tap(([data, recommendations]) => {
				return this.replaceRecommendationData(data, recommendations);
			}),
			catchError((err) => {
				this.logger.error('Error fetching recommendations', err);
				return of(null);
			})
		);
	}

	private replaceRecommendationData(data, recommendations) {
		if (!recommendations) return;
		const planType = 'medical';
		const planTypeData = data.companyData.plans[planType];
		planTypeData.forEach((plan) => {
			const recommendation = recommendations[plan.contract?.id] || recommendations[plan._id];

			plan.recommendation = { ...plan.recommendation };

			if (recommendation) {
				plan.recommendation.family = recommendation['EE+FAMILY'];
				plan.recommendation.individual = recommendation['EE_ONLY'];
				plan.recommendation.individualPlusOne = recommendation['EE+CHILD'];
				plan.recommendation.individualPlusSpouse = recommendation['EE+SPOUSE'];
			}
		});
		return true;
	}

	public getMedicalEvents(medicalEventsStrings: string[]): { serviceType: MedicalEvent }[] {
		if (!medicalEventsStrings?.length) return [];
		const eventMapping: { [key: string]: MedicalEvent } = {
			Pregnancy: MedicalEvent.PREGNANCY,
			'ER Visit': MedicalEvent.ER,
			'Surgery requiring hospital stay (inpatient)': MedicalEvent.INPATIENT_SURGERY,
			'Surgery not requiring hospital stay (outpatient)': MedicalEvent.OUTPATIENT_SURGERY,
		};

		return medicalEventsStrings.filter((eventString) => !!eventMapping[eventString]).map((eventString) => {
			return {
				serviceType: eventMapping[eventString],
			};
		});
	}

	public fetchRecommendations(): Observable<any> {
		const savedData = this.getSavedData();
		const medicalEventsStrings = savedData.projectionSelected;

		const medicalEvents: { serviceType: MedicalEvent }[] = this.getMedicalEvents(medicalEventsStrings);
		const referralRequired = savedData.form.arePlansWithoutReferralsPreferred;
		const effectiveDate = this.effectiveDate;
		const params: RecommendationsRequest = {
			effectiveDate,
			referralRequired,
			medicalEvents,
		};
		return this.planSelectionAPIService.fetchRecommendations(params);
	}

	public setSelectedPlanIds(selectedPlansId: PlanIdsObject, selectedPlanPremiums: PlanPremiumsObject): void {
		this.planSelectionStore.get().subscribe({
			next: (data: PlanSelectionData) => {
				if (!data?.companyData) return;
				const plans = data.companyData.plans;
				const medical = plans.medical.find((plan) => plan._id === selectedPlansId.medical);
				const dental = plans.dental.find((plan) => plan._id === selectedPlansId.dental);
				const vision = plans.vision.find((plan) => plan._id === selectedPlansId.vision);
				this._selectedPlans$.next({
					medical: medical && { ...medical, premiumPerPayPeriod: selectedPlanPremiums.medical },
					dental: dental && { ...dental, premiumPerPayPeriod: selectedPlanPremiums.dental },
					vision: vision && { ...vision, premiumPerPayPeriod: selectedPlanPremiums.vision },
				});
			},
		});
	}

	get selectedPlanRecommendations$() {
		return combineLatest([this.planSelectionStore.get(), this.selectedPlans$]).pipe(
			filter(([planSelectionData, selectedPlans]) => {
				return !!(planSelectionData?.companyData && Object.keys(selectedPlans).length);
			}),
			map(([planSelectionData, selectedPlans]) => {
				const planRecommendations = {};
				const plans = planSelectionData.companyData.plans;

				Object.keys(selectedPlans).forEach((planType) => {
					const selectedPlan = selectedPlans[planType];
					if (!selectedPlan) return;

					const allPlansOfType = plans[planType];
					const activeFilter = selectedPlan.activeFilter || planSelectionData.userData?.userData?.coverageTierFilters?.[planType];
					const selectedPlanId = selectedPlan._id;
					const recommendation = allPlansOfType.find(plan => plan._id === selectedPlanId)?.recommendation;
					const score = recommendation?.[activeFilter]?.score;
					if (!score) {
						planRecommendations[planType] = { selectedPlan, score: null, scorePosition: null };
						return;
					}
					const scorePosition = this.calculateScorePosition(allPlansOfType, selectedPlanId, activeFilter);
					planRecommendations[planType] = { selectedPlan, score, scorePosition };
				});

				return planRecommendations;
			})
		);
	}

	private calculateScorePosition(plans, selectedPlanId: string, activeFilter: string) {
		const allPlansOfTypeSorted = plans.sort((a, b) => b.recommendation[activeFilter].score - a.recommendation[activeFilter].score);
		const selectedPlanPosition = allPlansOfTypeSorted.findIndex(plan => plan._id === selectedPlanId);
		return selectedPlanPosition + 1;
	}

	get userEnrollmentData$(): Observable<UserEnrollmentData> {
		return this.planSelectionStore.get().pipe(
			RxjsUtils.isNotNil(),
			switchMap((data: PlanSelectionData) => this.mapPlanSelectionToUserEnrollmentData(data))
		);
	}

	public enrichDoctorsData(list: UserDoctorData[]): Observable<DoctorFormData[]> {
		const npis = list?.filter((provider) => provider.type !== ProviderType.FACILITY).map((doctor) => doctor.npi);
		if (!npis?.length) {
			return of([]);
		}

		const searchOptions = {
			entity: RibbonSearchEntity.Npis,
			parameter: npis,
		};
		return this.ribbonPCTStore.getByContext(searchOptions).pipe(
			map((response: RibbonDoctorDetails) => {
				if (!response.data?.length) return [];
				const data: DoctorFormData[] = response.data.map((doctorData) => {
					const doctor = list.find((provider) => provider.npi === doctorData.npi);

					return ({
						...doctor,
						insurances: doctorData.insurances.map((insurance) => insurance.uuid),
						name: doctorData.first_name + ' ' + doctorData.last_name,
						gender: doctorData.gender,
						specialties: doctorData.specialties,
						providerType: doctor.type,
						degrees: doctorData.degrees,
						urlId: doctorData.npi,
						urlKey: doctor.type,
						location: this.getProviderAddressFormated(doctorData.locations)
					});
				});

				return data;
			}),
			catchError((err) => {
				this.logger.error('Error in enrichDoctorsData', err);
				return of([]);
			})
		);
	}

	public getProviderAddressFormated(locations): string {
		return locations?.[0]?.address_details?.city;
	}

	public enrichFacilitiesData(list: UserDoctorData[]): Observable<DoctorFormData[]> {
		const uuids = list
			?.filter((provider) => provider.type === ProviderType.FACILITY)
			.map((facility) => facility.npi);

		if (!uuids?.length) {
			return of([]);
		}

		const searchOptions = {
			entity: RibbonSearchEntity.Uuids,
			parameter: uuids,
		};

		return this.ribbonPCTStore.getByContext(searchOptions).pipe(
			map((response: RibbonFacilityDetails) => {
				if (!response.data?.length) return [];
				const data: DoctorFormData[] = response.data.map((facilityData) => ({
					...list.find((provider) => provider.npi === facilityData.uuid),
					providerType: ProviderType.FACILITY,
					insurances: facilityData.insurances.map((insurance) => insurance.uuid),
					name: facilityData.name,
					specialties: [],
					degrees: [],
					urlId: facilityData.uuid,
					urlKey: ProviderType.FACILITY,
					location: this.getFacilityAddressFormated(facilityData.address_details)
				}));

				return data;
			}),
			catchError((err) => {
				this.logger.error('Error in enrichFacilitiesData', err);
				return of([]);
			})
		);
	}

	public getFacilityAddressFormated(addressDetails): string {
		let addressFormated = '';

		if (addressDetails?.street) {
			addressFormated += addressDetails.street + ', ';
		}
		if (addressDetails?.city) {
			addressFormated += addressDetails.city + ', ';
		}
		if (addressDetails?.state) {
			addressFormated += addressDetails.state;
		}

		return addressFormated;
	}

	public mapPlanSelectionToUserEnrollmentData(planSelectionData: PlanSelectionData): Observable<UserEnrollmentData> {
		return combineLatest([
			this.planSelectionStore.get(),
			this.generateFormDataFromUserData(planSelectionData.userData),
		]).pipe(
			map(([planSelectionData, form]) => {
				const userData = planSelectionData?.userData?.userData;
				const status = userData.status;
				const plans = planSelectionData?.companyData?.plans;
				const filtersSaved = userData?.coverageTierFilters ?? {};
				const mappedFiltersSaved = {
					medical: this.mapCoverageTierFilter(filtersSaved.medical),
					dental: this.mapCoverageTierFilter(filtersSaved.dental),
					vision: this.mapCoverageTierFilter(filtersSaved.vision),
				};
				const isFamilyEnforced = userData?.isFamilyEnforced;
				const rawSelectedPlans = userData?.selectedPlans;
				const selectedPlanIds = {
					medical: rawSelectedPlans?.medical?.planId ?? null,
					dental: rawSelectedPlans?.dental?.planId ?? null,
					vision: rawSelectedPlans?.vision?.planId ?? null,
				};
				const selectedPlans = {
					medical: plans?.medical?.find((plan) => plan._id === selectedPlanIds.medical),
					dental: plans?.dental?.find((plan) => plan._id === selectedPlanIds.dental),
					vision: plans?.vision?.find((plan) => plan._id === selectedPlanIds.vision),
				};
				const selectedPlanPremiums = {
					medical:
						selectedPlans.medical &&
						mappedFiltersSaved.medical &&
						selectedPlans.medical.premiums[mappedFiltersSaved.medical],
					dental:
						selectedPlans.dental &&
						mappedFiltersSaved.dental &&
						selectedPlans.dental.premiums[mappedFiltersSaved.dental],
					vision:
						selectedPlans.vision &&
						mappedFiltersSaved.vision &&
						selectedPlans.vision.premiums[mappedFiltersSaved.vision],
				};

				if (selectedPlans.medical) selectedPlans.medical.premiumPerPayPeriod = selectedPlanPremiums.medical;
				if (selectedPlans.dental) selectedPlans.dental.premiumPerPayPeriod = selectedPlanPremiums.dental;
				if (selectedPlans.vision) selectedPlans.vision.premiumPerPayPeriod = selectedPlanPremiums.vision;

				const hasSelectedPlan = selectedPlanIds.medical || selectedPlanIds.dental || selectedPlanIds.vision;
				const hasAnySelectedPlanHsaEligibility =
					selectedPlans.medical?.hsaEligibility ||
					selectedPlans.vision?.hsaEligibility ||
					selectedPlans.dental?.hsaEligibility;
				const isFsaPlanAvailable =
					!!planSelectionData?.companyData?.addons?.fsaPlan ||
					!!planSelectionData?.companyData?.addons?.fsaDependentCarePlan;
				const spendingAccountSelection = {
					hsaPlan: rawSelectedPlans?.hsaPlan ?? null,
					hsaPlanFsaAddon: rawSelectedPlans?.hsaPlanFsaAddon ?? null,
					fsaPlan: rawSelectedPlans?.fsaPlan ?? null,
					fsaDependentCarePlan: rawSelectedPlans?.fsaDependentCarePlan ?? null,
				};
				const isSpendingAccountsTabDisabled =
					(!hasSelectedPlan || !hasAnySelectedPlanHsaEligibility) && !isFsaPlanAvailable;
				const localPlanSelectionData = this.storageService.getPlanSelectionData();

				return {
					status,
					effectiveDate: planSelectionData.companyData.effectiveDate,
					form,
					selectedPlans,
					selectedPlanIds,
					selectedPlanPremiums,
					pinnedPlanIds: userData?.pinnedPlanIds ?? {},
					summary_isExternalLinkOpened: (status === 'in_progress' && userData?.isExternalLinkOpened) || false,
					summary_isEnrolled: (status === 'completed' && userData?.isEnrolled) || false,
					filtersSaved: mappedFiltersSaved,
					isFamilyEnforced,
					projectionSelected: userData?.projectionServicesSelected ?? [],
					spendingAccountSelection,
					isSpendingAccountsTabDisabled,
					lastStepIndex: localPlanSelectionData?.lastStepIndex,
				};
			}),
			tap((userEnrollmentData) => {
				this.userEnrollmentData = userEnrollmentData;
			})
		);
	}

	public generateFormDataFromUserData(data: UserOpenEnrollmentData): Observable<EnrollmentFormData> {
		return combineLatest([
			this.enrichDoctorsData(data.doctorData),
			this.enrichFacilitiesData(data.doctorData),
		]).pipe(
			map(([doctorsData, facilitiesData]) => {
				const pcpData = { dataSaved: doctorsData.filter((doctor) => doctor.type === 'pcp') };
				const specialistsData = { dataSaved: doctorsData.filter((doctor) => doctor.type === 'specialist') };
				const optometristData = { dataSaved: doctorsData.filter((doctor) => doctor.type === 'optometrist') };
				const dentistData = { dataSaved: doctorsData.filter((doctor) => doctor.type === 'dentist') };
				const noDoctorMarked = pcpData.dataSaved.length === 0;
				const response: EnrollmentFormData = {
					numOfDependents: data.userData.numOfDependents,
					childrenInfos: data.userData.childrenInfos,
					spouse:
						data.userData.spouse && Object.keys(data.userData.spouse).length !== 0
							? data.userData.spouse
							: undefined,
					facilitiesData: { dataSaved: facilitiesData },
					pcpData,
					specialistsData,
					optometristData,
					dentistData,
					noDoctorMarked,
					proceedWithoutProviders: data.userData.proceedWithoutProviders,
					arePlansWithoutReferralsPreferred: data.userData.arePlansWithoutReferralsPreferred,
				};
				return response;
			})
		);
	}

	public setSelectedPlans(selectedPlans): void {
		this._selectedPlans$.next(selectedPlans);
	}

	public removeSelectedPlan(planType): void {
		this._selectedPlans$.next({ ...this._selectedPlans$, [planType]: undefined });
	}

	public setFiltersSaved(filtersSaved): void {
		this._filtersSaved$.next(filtersSaved);
	}

	public setPinnedPlanIds(pinnedPlanIds: PlansIdsObject): void {
		this._pinnedPlans$.next(pinnedPlanIds);
	}

	public setIsFamilyEnforced(isFamilyEnforced): void {
		this._isFamilyEnforced$.next(isFamilyEnforced);
	}

	public resetData(): void {
		this._isFamilyEnforced$.next({});
		this._selectedPlans$.next({});

		this.updateLocalData({
			status: 'completed_profile',
			lastStepIndex: 1,
			selectedPlans: { medical: null, dental: null, vision: null },
			selectedPlanIds: { medical: null, dental: null, vision: null },
			selectedPlanPremiums: { medical: null, dental: null, vision: null },
			filtersSaved: { medical: null, dental: null, vision: null },
			isFamilyEnforced: {},
			spendingAccountSelection: {},
			summary_isRedirecting: false,
			summary_isExternalLinkOpened: false,
			summary_isEnrolled: false,
		})

		this.updateUserOpenEnrollmentData$('completed_profile').pipe(tap(() => {
			this._step$.next({
				currentIndex: 1,
				lastIndex: 1,
			});
			this._resetData$.next();
		})).subscribe();
	}

	get step$(): Observable<Step> {
		return this._step$.asObservable();
	}

	public getDoctorDataToSave(data: DoctorFormData, type: ProviderType): UserDoctorData {
		return {
			npi: data.npi,
			type: type,
			isMyDoctor: data.isMyDoctor,
			isSpouseDoctor: data.isSpouseDoctor,
			isDependentsDoctor: data.isDependentsDoctor,
			urlKey: data.urlKey,
			urlId: data.urlId,
		};
	}

	public getFacilityDataToSave(data: DoctorFormData): UserDoctorData {
		return {
			npi: data.npi,
			type: ProviderType.FACILITY,
			isMyDoctor: data.isMyDoctor,
			isSpouseDoctor: data.isSpouseDoctor,
			isDependentsDoctor: data.isDependentsDoctor,
			urlKey: data.urlKey,
			urlId: data.urlId,
		};
	}

	public updateUserOpenEnrollmentData$(status?: string) {
		const data = this.getSavedData() || {};

		const facilitiesUuids = data.form?.facilitiesData?.dataSaved.map((item) => item.npi) ?? [];
		const pcpStrings = data.form?.pcpData.dataSaved.map((item) => item.npi);
		const specialistsStrings = data.form?.specialistsData.dataSaved.map((item) => item.npi);

		const facilities = data.form?.facilitiesData?.dataSaved.map((item) => this.getFacilityDataToSave(item)) || [];
		const pcp = data.form?.pcpData.dataSaved.map((item) => this.getDoctorDataToSave(item, ProviderType.PCP)) || [];
		const specialists =
			data.form?.specialistsData.dataSaved.map((item) =>
				this.getDoctorDataToSave(item, ProviderType.SPECIALIST)
			) || [];
		const optometrists =
			data.form?.optometristData.dataSaved.map((item) =>
				this.getDoctorDataToSave(item, ProviderType.OPTOMETRIST)
			) || [];
		const dentists =
			data.form?.dentistData.dataSaved.map((item) => this.getDoctorDataToSave(item, ProviderType.DENTIST)) || [];
		const spendingAccountSelection = data.spendingAccountSelection;
		const serverData: Partial<UserOpenEnrollmentData> = {
			userData: {
				status: status ?? data.status ?? 'started',
				spouse: data.form?.spouse,
				numOfDependents: data.form?.numOfDependents,
				childrenInfos: data.form?.childrenInfos,
				doctorData: {
					pcp: pcpStrings,
					facilities: facilitiesUuids,
					specialists: specialistsStrings,
				},
				selectedPlans: {
					medical: {
						planId: data.selectedPlanIds?.medical,
						planFilter: data.filtersSaved?.medical,
					},
					dental: {
						planId: data.selectedPlanIds?.dental,
						planFilter: data.filtersSaved?.dental,
					},
					vision: {
						planId: data.selectedPlanIds?.vision,
						planFilter: data.filtersSaved?.vision,
					},
					hsaPlan: spendingAccountSelection?.hsaPlan ?? null,
					hsaPlanFsaAddon: spendingAccountSelection?.hsaPlanFsaAddon ?? null,
					fsaPlan: spendingAccountSelection?.fsaPlan ?? null,
					fsaDependentCarePlan: spendingAccountSelection?.fsaDependentCarePlan ?? null,
				},
				proceedWithoutProviders: data.form.proceedWithoutProviders ?? false,
				arePlansWithoutReferralsPreferred: data.form.arePlansWithoutReferralsPreferred ?? false,
				coverageTierFilters: data.filtersSaved ?? {},
				isFamilyEnforced: data.isFamilyEnforced ?? {},
				projectionServicesSelected: data.projectionSelected ?? [],
				pinnedPlanIds: data.pinnedPlanIds ?? {},
				isExternalLinkOpened: (status === 'in_progress' && data.summary_isExternalLinkOpened) || false,
				isEnrolled: (status === 'completed' && data.summary_isEnrolled) || false,
			},
			doctorData: [...pcp, ...specialists, ...optometrists, ...dentists, ...facilities],
		};

		return this.planSelectionAPIService.updateServer(serverData, this.effectiveDate);
	}

	public updateUserOpenEnrollmentData(status?: string) {
		this.updateUserOpenEnrollmentData$(status).subscribe();
	}

	public updateLocalData(data: Partial<UserEnrollmentData>): void {
		const { lastStepIndex, ...restData } = data;
		let currentData = this.getSavedData();
		currentData = { ...currentData, ...restData };
		if (restData.form) currentData.form = restData.form;

		this.userEnrollmentData = currentData;
		const localPlanSelectionData = this.storageService.getPlanSelectionData();

		if ('lastStepIndex' in data) {
			this.storageService.savePlanSelectionData({
				...localPlanSelectionData,
				lastStepIndex,
			});
		}
	}

	public getSavedData(): UserEnrollmentData {
		const lastStepIndex = this.storageService.getPlanSelectionData()?.lastStepIndex;

		if (this.userEnrollmentData.lastStepIndex !== lastStepIndex) {
			this.userEnrollmentData.lastStepIndex = lastStepIndex;
		}

		return this.userEnrollmentData;
	}

	public goToStep(stepAsked: string): void {
		this._step$.next({ currentIndex: STEPS_DICT[stepAsked] });
	}

	public sendSummaryToEmails(emailsList, pdfBase64): Observable<any> {
		const url: string = CFG.apiEndpoints.sharePlanSelectionSummary;

		return this.http.post(url, { pdfBase64, emailsList });
	}

	public isDoctorCoveringPlan(doctorInsurances, planRibbonId: string): boolean {
		const specificInsurancesConnectedByRibbon = this.ribbonHealthService.specificInsurancesConnectedByRibbon;
		const specificRibbonId = specificInsurancesConnectedByRibbon.find((obj) => obj.initial === planRibbonId);
		if (specificRibbonId) {
			return doctorInsurances.includes(planRibbonId) || doctorInsurances.includes(specificRibbonId.mapped);
		} else {
			return doctorInsurances.includes(planRibbonId);
		}
	}

	private mapCoverageTierFilter(filter: string): string {
		switch (filter) {
			case 'plusSpouse':
				return 'individualPlusSpouse';
			case 'plusChild':
				return 'individualPlusOne';
		}

		return filter;
	}
}
