import { Injectable } from '@angular/core';
import { Observable, map, switchMap } from 'rxjs';
import { hasTrulyValue } from '../../utils/utils';
import { UserPlanDataStoreService } from '../stores/user-plan-data-store/user-plan-data-store.service';
import { MeUserStoreService } from '../stores/me-user-store/me-user-store.service';
import { transformDeductible } from './deductible.utils';
import { PlanData } from 'src/app/models/plan-data.model';
import { Deductibles } from 'src/app/models/deductibles.model';

@Injectable({ providedIn: 'root' })
export class PlanLevelDeductiblesService {
	public planLeveldeductibles$: Observable<PlanData>;

	constructor(
		private meUserStoreService: MeUserStoreService,
		private userPlanDataStoreService: UserPlanDataStoreService
	) {
		this.planLeveldeductibles$ = this.userPlanDataStoreService.get();
	}

	public getPlanLevelDeductibles(): Observable<Deductibles> {
		return this.planLeveldeductibles$.pipe(map((data) => data?.contract?.deductible));
	}

	public getDefaultPlanLevelDeductible(networkFieldName: string): Observable<string> {
		return this.defaultCoverageTier().pipe(
			switchMap((planType) =>
				this.getPlanLevelDeductibles().pipe(
					map((deductible) => {
						const planTypeKey = planType === 'individual' ? 'family' : 'individual';
						return (
							transformDeductible(deductible?.[planType]?.[networkFieldName]?.text) ||
							transformDeductible(deductible?.[planTypeKey]?.[networkFieldName]?.text)
						);
					})
				)
			)
		);
	}

	public hasDefaultPlanLevelDeductible(networkFieldName: string): Observable<boolean> {
		return this.getDefaultPlanLevelDeductible(networkFieldName).pipe(map((value) => hasTrulyValue(value)));
	}

	public getPlanLevelOutOfPocketMaximums() {
		return this.planLeveldeductibles$.pipe(map((data) => data?.contract?.outOfPocketMax));
	}

	public getDefaultPlanLevelOutOfPocketMaximum(networkFieldName: string): Observable<string> {
		return this.defaultCoverageTier().pipe(
			switchMap((planType) =>
				this.getPlanLevelOutOfPocketMaximums().pipe(
					map((oopm) => {
						const planTypeKey = planType === 'individual' ? 'family' : 'individual';
						return (
							transformDeductible(oopm?.[planType]?.[networkFieldName]) ||
							transformDeductible(oopm?.[planTypeKey]?.[networkFieldName])
						);
					})
				)
			)
		);
	}

	public hasDefaultPlanLevelOutOfPocketMaximum(networkFieldName: string): Observable<boolean> {
		return this.getDefaultPlanLevelOutOfPocketMaximum(networkFieldName).pipe(map((value) => hasTrulyValue(value)));
	}

	private defaultCoverageTier(): Observable<string> {
		return this.meUserStoreService.getUserCoverageTier().pipe(map((user) => user.medicalPlanTier));
	}
}
