import { T } from '@transifex/angular';

export class ServicePanelInformationLocalize {
	// Function generateTexts
	@T('This service is fully covered by your payer')
	static thisServiceIsFullyCoveredByYourPayer: string;

	@T('You met your out-of-pocket maximum')
	static youMetYourOutOfPocketMax: string;

	@T('Coverage depends on location')
	static coverageDependsOnLocation: string;

	@T("You'll pay 100% of this service cost")
	static youllPay100PercentOfThisServiceCost: string;

	@T('Your insurance does not cover this service')
	static yourInsuranceDoesNotCoverThisService: string;

	@T('Applies always, regardless of your deductible status')
	static appliesAlwaysRegardlessOfYourDeductibleStatus: string;

	// Function setDeductibleInformaton
	@T('Before deductible')
	static beforeDeductible: string;

	@T('After deductible')
	static afterDeductible: string;

	@T('Before out-of-pocket maximum')
	static beforeOutOfPocketMaximum: string;

	@T('After out-of-pocket maximum')
	static afterOutOfPocketMaximum: string;

	@T("You'll pay 100% of the service cost")
	static youllPay10PercentOfTheServiceCost: string;

	// Function setCoinsuranceText + setCopayText
	@T('then')
	static then: string;

	@T('on any additional costs')
	static onAnyAdditionalCost: string;

	@T('and additional')
	static andAdditional: string;

	@T('of this service cost')
	static ofThisServiceCost: string;

	@T("You'll pay")
	static youllPay: string;

	@T('per')
	static per: string;

	@T('up to a maximum of')
	static upToAMaxOf: string;

	@T("Then, you'll pay")
	static thenYoullPay: string;

	@T('of the remaining cost')
	static ofTheRemainingCost: string;

	@T('for this service')
	static forThisService: string;

	@T("You'll pay up to")
	static youllPayUpTo: string;

	@T('per each')
	static perEach: string;

	@T('during the first')
	static duringTheFirst: string;

	@T('days')
	static days: string;
}
