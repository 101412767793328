import { Injectable } from '@angular/core';
import { map, shareReplay } from 'rxjs';
import { AppointmentStatuses } from 'src/app/models/appointment.model';
import { AppointmentsListStoreService } from '../stores/appointments-list-store/appointments-list-store.service';

@Injectable({
	providedIn: 'root',
})
export class ProvidersAppointmentService {
	constructor(private appointmentsListStoreService: AppointmentsListStoreService) {}

	public getAppointments() {
		return this.appointmentsListStoreService.get().pipe(
			map((appointments) => {
				return appointments?.filter(
					(appointment) =>
						appointment.status !== AppointmentStatuses.cancelled &&
						appointment.status !== AppointmentStatuses.noShow &&
						appointment.status !== AppointmentStatuses.cancelFailed &&
						appointment.status !== AppointmentStatuses.cancelPending
				);
			}),
			shareReplay(1)
		);
	}
}
