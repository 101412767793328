import { Injectable, Injector } from '@angular/core';
import { take } from 'rxjs';
import { UserData } from '../models/user-data';
import { IStorage } from '../utils/storage-service.interface';
import { UserService } from './user.service';
import { PreventiveCare } from './preventive-care.service';
import { AuthService } from '../modules/account/login/auth.service';

const TAG = 'StorageService';
const WELCOME_VIDEO_WATCHED = 'watched';

// Local storage item tags
const LOCAL_STORAGE_USER_JWT_TAG = 'userData';
const LOCAL_STORAGE_WELCOME_VIDEO_STATUS_TAG = 'welcomeVideo';
const LOCAL_STORAGE_ALL_ENTITIES_NAMES = 'allEntitiesNames';
const LOCAL_STORAGE_MFA_TOKEN = 'mfa';
// Biometric auth
const LOCAL_STORAGE_BIO_AUTH_TAG = 'BiometryData';
const LOCAL_STORAGE_LOCALE_TAG = 'locale';
// Plan selection
const PLAN_SELECTION_DATA_TAG = 'pctData_1_1';

const LOCAL_STORAGE_LAST_TIME_ASKED_FOR_NOTIFICATION_PERMISSIONS = 'pushNotificationTime';

const PREVENTIVE_CARE_PINNED_ITEMS = 'preventiveCarePinnedItems';

@Injectable({
	providedIn: 'root',
})
export class StorageService {
	private storage: IStorage = localStorage;
	private userDataStorage: IStorage;

	// TODO: Refactor all tags to one cerntralized settings object
	constructor(private injector: Injector) {
		this.userDataStorage = localStorage;
		if (AuthService.isUsingSessionStorage()) {
			console.log(TAG, 'Using session storage for user data');
			this.userDataStorage = sessionStorage;
		}
	}

	public getUserData(): string {
		try {
			return this.userDataStorage.getItem(LOCAL_STORAGE_USER_JWT_TAG);
		} catch (err) {
			this.handleLocalStorageError(LOCAL_STORAGE_USER_JWT_TAG, err);
		}
	}

	public storeToken(token: string): void {
		const tokenObject = { token: token };
		this.storeUserToken(tokenObject);
	}

	public storeUserToken(tokenObject: { token: string }) {
		try {
			return this.userDataStorage.setItem(LOCAL_STORAGE_USER_JWT_TAG, JSON.stringify(tokenObject));
		} catch (err) {
			this.handleLocalStorageError(LOCAL_STORAGE_USER_JWT_TAG, err);
		}
	}

	public storeMfaToken(mfaToken: string): void {
		const mfaTokenObject = { mfaToken };
		try {
			return this.storage.setItem(LOCAL_STORAGE_MFA_TOKEN, JSON.stringify(mfaTokenObject));
		} catch (err) {
			this.handleLocalStorageError(LOCAL_STORAGE_MFA_TOKEN, err);
		}
	}

	public getMfaToken(): string {
		try {
			const mfaToken = this.storage.getItem(LOCAL_STORAGE_MFA_TOKEN) || null;
			return JSON.parse(mfaToken).mfaToken;
		} catch (err) {
			this.handleLocalStorageError(LOCAL_STORAGE_MFA_TOKEN, err);
		}
	}

	public removeUserData() {
		try {
			return this.userDataStorage.removeItem(LOCAL_STORAGE_USER_JWT_TAG);
		} catch (err) {
			this.handleLocalStorageError(LOCAL_STORAGE_USER_JWT_TAG, err);
		}
	}

	public welcomeVideoTagExists(): boolean {
		let videoWatched = null;

		try {
			videoWatched = this.storage.getItem(LOCAL_STORAGE_WELCOME_VIDEO_STATUS_TAG);
		} catch (err) {
			this.handleLocalStorageError(LOCAL_STORAGE_WELCOME_VIDEO_STATUS_TAG, err);
		}

		return videoWatched && videoWatched === WELCOME_VIDEO_WATCHED;
	}

	public saveWelcomeVideoTag() {
		try {
			return this.storage.setItem(LOCAL_STORAGE_WELCOME_VIDEO_STATUS_TAG, WELCOME_VIDEO_WATCHED);
		} catch (err) {
			this.handleLocalStorageError(LOCAL_STORAGE_WELCOME_VIDEO_STATUS_TAG, err);
		}
	}

	public getLocaleSettings(): string {
		try {
			const localeData = this.storage.getItem(LOCAL_STORAGE_LOCALE_TAG) || null;
			return JSON.parse(localeData);
		} catch (err) {
			this.handleLocalStorageError(LOCAL_STORAGE_LOCALE_TAG, err);
		}
	}

	public getLastTimeAskedForPermissionForPushNotifications(): number {
		try {
			const time = parseInt(this.storage.getItem(LOCAL_STORAGE_LAST_TIME_ASKED_FOR_NOTIFICATION_PERMISSIONS));
			return isNaN(time) ? null : time;
		} catch (err) {
			this.handleLocalStorageError(LOCAL_STORAGE_LAST_TIME_ASKED_FOR_NOTIFICATION_PERMISSIONS, err);
			return null;
		}
	}

	public saveLastTimeAskedForPermissionForPushNotifications(time: number) {
		try {
			return this.storage.setItem(LOCAL_STORAGE_LAST_TIME_ASKED_FOR_NOTIFICATION_PERMISSIONS, time?.toString());
		} catch (err) {
			this.handleLocalStorageError(LOCAL_STORAGE_LAST_TIME_ASKED_FOR_NOTIFICATION_PERMISSIONS, err);
		}
	}

	public saveLocaleSettings(locale: string) {
		try {
			return this.storage.setItem(LOCAL_STORAGE_LOCALE_TAG, JSON.stringify(locale));
		} catch (err) {
			this.handleLocalStorageError(LOCAL_STORAGE_LOCALE_TAG, err);
		}
	}

	public getBiometrySettings(): boolean {
		try {
			// isBiometryUsed: true - if configured, false - if explicitly disabled, null - if not set
			const isBiometryUsed = this.storage.getItem(LOCAL_STORAGE_BIO_AUTH_TAG) || null;
			return JSON.parse(isBiometryUsed);
		} catch (err) {
			this.handleLocalStorageError(LOCAL_STORAGE_BIO_AUTH_TAG, err);
		}
	}

	public saveBiometrySettings(isBiometryUsed: boolean) {
		// isBiometryUsed: true - if configured, false - if explicitly disabled, null - if not set
		try {
			return this.storage.setItem(LOCAL_STORAGE_BIO_AUTH_TAG, JSON.stringify(isBiometryUsed));
		} catch (err) {
			this.handleLocalStorageError(LOCAL_STORAGE_BIO_AUTH_TAG, err);
		}
	}

	public removeBiometrySettings() {
		try {
			return this.storage.removeItem(LOCAL_STORAGE_BIO_AUTH_TAG);
		} catch (err) {
			this.handleLocalStorageError(LOCAL_STORAGE_BIO_AUTH_TAG, err);
		}
	}

	private handleLocalStorageError(errorItemTag: string, error: Error) {
		console.log(TAG, 'Error reading/writing local storage. Clearing error item. Error:', error.message);
		this.storage.removeItem(errorItemTag);
	}

	public setChooseFromListEntities(entitiesList: string[]): void {
		const expiryDate = new Date().getTime() + 24 * 60 * 60 * 1000; // 24 HOURS;
		const entitiesWithExpired = { entitiesList, expiryDate };
		try {
			return this.storage.setItem(LOCAL_STORAGE_ALL_ENTITIES_NAMES, JSON.stringify(entitiesWithExpired));
		} catch (err) {
			this.handleLocalStorageError(LOCAL_STORAGE_ALL_ENTITIES_NAMES, err);
		}
	}

	public getChooseFromListEntities() {
		try {
			const now = new Date().getTime();
			const entitiesWithExpired = JSON.parse(this.storage.getItem(LOCAL_STORAGE_ALL_ENTITIES_NAMES)) || null;
			return entitiesWithExpired?.expiryDate > now ? [...entitiesWithExpired.entitiesList] : null;
		} catch (err) {
			this.handleLocalStorageError(LOCAL_STORAGE_ALL_ENTITIES_NAMES, err);
		}
	}

	public removeChooseFromListEntities() {
		try {
			return this.storage.removeItem(LOCAL_STORAGE_ALL_ENTITIES_NAMES);
		} catch (err) {
			this.handleLocalStorageError(LOCAL_STORAGE_ALL_ENTITIES_NAMES, err);
		}
	}

	public getPlanSelectionData(): any {
		const userService = this.injector.get(UserService);
		let userId;
		userService.userData$.pipe(take(1)).subscribe((user: UserData) => (userId = user.uid));
		try {
			const data = this.storage.getItem(`${PLAN_SELECTION_DATA_TAG}_${userId}`) || null;
			return JSON.parse(data);
		} catch (err) {
			this.handleLocalStorageError(`${PLAN_SELECTION_DATA_TAG}_${userId}`, err);
		}
	}

	public savePlanSelectionData(data: any) {
		const userService = this.injector.get(UserService);
		let userId;
		userService.userData$.pipe(take(1)).subscribe((user: UserData) => (userId = user.uid));
		try {
			return this.storage.setItem(`${PLAN_SELECTION_DATA_TAG}_${userId}`, JSON.stringify(data));
		} catch (err) {
			this.handleLocalStorageError(`${PLAN_SELECTION_DATA_TAG}_${userId}`, err);
		}
	}

	public getPreventiveCarePinnedItemsIds(): any {
		try {
			const preventiveCarePinnedItems = this.storage.getItem(PREVENTIVE_CARE_PINNED_ITEMS) || null;
			return JSON.parse(preventiveCarePinnedItems) || [];
		} catch (err) {
			this.handleLocalStorageError(PREVENTIVE_CARE_PINNED_ITEMS, err);
		}
	}

	public savePreventiveCarePinnedItemsIds(items: PreventiveCare[]) {
		const ids = items.map((item) => item.id);
		try {
			return this.storage.setItem(PREVENTIVE_CARE_PINNED_ITEMS, JSON.stringify(ids));
		} catch (err) {
			this.handleLocalStorageError(PREVENTIVE_CARE_PINNED_ITEMS, err);
		}
	}
}
