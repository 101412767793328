import { ErrorHandler, Injectable, NgZone } from '@angular/core';

import { UIService } from '../services/ui.service';
import { LoggerService } from '../services/logger.service';

import { AppError } from './app-errors';
import { environment } from '../../environments/environment';
import { captureExceptionSentry } from '../utils/sentry.utils';

const TAG = 'AppErrorHandler';
const SENTRY_ENABLED = environment.enableSentry;

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
	constructor(private uiService: UIService, private zone: NgZone, private logger: LoggerService) {}

	handleError(error: Error) {
		const errorMsg = error?.message?.toString();
		if (errorMsg?.indexOf('ChunkLoadError') > -1 || errorMsg?.indexOf('Loading chunk') > -1) {
			window.location.reload();
			return;
		}

		if (SENTRY_ENABLED) {
			captureExceptionSentry(error, TAG);
		}

		if (error instanceof AppError) {
			const errorType = error.constructor.name;
			this.logger.error(
				`${TAG} has caught a ${errorType} error: ${error.errorObjectExtention?.technicalMessage}`,
				{
					extention: error.errorObjectExtention,
					originalError: error.originalError,
				}
			);
			this.displayMessageFromWithinAngularZone(error.message);
		} else {
			// error is not an AppError ==> meaning doesn't have a user-friendly message
			this.logger.error(TAG, `An error has occurred: ${error.stack}`);
		}
	}

	private displayMessageFromWithinAngularZone(message: string) {
		// Should be run from ngZone because the execution of the handleError method occurs
		// outside of change detection, therefore the snackbar will not show as "opened"
		// or be responsive until the next change detection cycle runs.
		this.zone.run(() => {
			this.uiService.displayAppMessage(message);
		});
	}
}
