import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SearchEntity, SearchType } from '../../helpers/providers-search.helper';
import { SearchBarService } from 'src/app/services/providers-search/search-bar.service';
import { RibbonEntitiesStoreService } from 'src/app/services/stores/ribbon-entities-store/ha-ribbon-entities-store.service';
import { firstValueFrom } from 'rxjs';

@Component({
	selector: 'app-provider-search-empty-state',
	templateUrl: './provider-search-empty-state.component.html',
	styleUrls: ['./provider-search-empty-state.component.scss'],
})
export class ProviderSearchEmptyStateComponent implements OnChanges {
	@Output() openFiltersEvent = new EventEmitter<void>();

	@Output() selectTopRatedProviderEvent = new EventEmitter<any>();

	@Input() defaultAddress: string;

	private selectedProvider;

	constructor(
		private ribbonEntitiesStoreService: RibbonEntitiesStoreService,
		private searchBarService: SearchBarService
	) {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.defaultAddress && changes.defaultAddress.currentValue) {
			this.selectTopRatedProviderHandler(this.selectedProvider);
		}
	}

	public openFiltersHandler() {
		this.openFiltersEvent.emit();
	}

	public selectTopRatedProviderHandler(provider: any) {
		if (provider) {
			this.selectedProvider = provider;
		}

		if (!this.defaultAddress) return;

		this.selectService(provider);
	}

	public async selectService(service) {
		const healthServices = await firstValueFrom(this.ribbonEntitiesStoreService.getHealthServices());
		const selected = healthServices.find((healthService) => healthService?.ribbon?.id === service?.ribbonId);
		const params = await this.getSearchParams(selected);
		this.selectTopRatedProviderEvent.emit(params);
	}

	private async getSearchParams(service) {
		const params = this.searchBarService.getSearchFormGroup();

		params.patchValue({
			address: this.defaultAddress,
			entity: SearchEntity.Provider,
			value: service.ribbon.key,
			searchType: SearchType.Options,
		});

		return params.value;
	}
}
