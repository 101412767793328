import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PremiumFeature } from 'src/app/models/premium-feature.model';
import { HealtheeService } from 'src/app/models/service.model';
import { PremiumFeatureService } from 'src/app/services/premium-feature.service';

@Component({
	selector: 'app-providers-search-no-result',
	templateUrl: './providers-search-no-result.component.html',
	styleUrls: ['./providers-search-no-result.component.scss'],
})
export class ProvidersSearchNoResultComponent {
	public searchQuery: string = this.route.snapshot.queryParams.value;
	public isTelehealth$: Observable<boolean> = this.premiumFeatureService.isEnabled(PremiumFeature.Telehealth);
	@Output() public setTopServiceSelected = new EventEmitter<HealtheeService>(null);
	@Output() public resetFiltersEvent = new EventEmitter<void>();

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private premiumFeatureService: PremiumFeatureService
	) {}
}
