import { NetworkStructure } from 'src/app/models/chat.model';
import {
	ProvidersSearchResults,
	SearchEntity,
	SearchType
} from '../../../modules/main-layout/care-costs/helpers/providers-search.helper';
import {
	normalizeFacility,
	normalizeProvider,
	normalizeProviderAndFacility,
	Provider,
	ProviderLocation,
} from '../../../modules/main-layout/care-costs/helpers/providers.helpers';

export interface LngLat {
	latitude: number;
	longitude: number;
}

export const retrieveProvidersLocations = (provider: Provider): { latitude: number; longitude: number }[] =>
	provider.locations.map(({ latitude, longitude }) => ({ latitude, longitude })).flat();

export const retrieveFacilitiesLocations = ({ latitude, longitude }): { latitude: number; longitude: number }[] => [
	{ latitude, longitude },
];

export const retrieveProvidersAndFacilitiesLocations = (ribbonData) => {
	if (ribbonData.type === SearchEntity.Provider) {
		return retrieveProvidersLocations(ribbonData);
	}

	if (ribbonData.type === SearchEntity.Facility) {
		return retrieveFacilitiesLocations(ribbonData);
	}
};

export const entityHelper = {
	[SearchEntity.Facility]: {
		id: 'uuid',
		normalize: normalizeFacility,
		retrieveLocations: retrieveFacilitiesLocations,
	},
	[SearchEntity.Provider]: {
		id: 'npi',
		normalize: normalizeProvider,
		retrieveLocations: retrieveProvidersLocations,
	},
	[SearchEntity.Condition]: {
		id: 'npi',
		normalize: normalizeProvider,
		retrieveLocations: retrieveProvidersLocations,
	},
	[SearchEntity.Treatment]: {
		id: 'npi',
		normalize: normalizeProvider,
		retrieveLocations: retrieveProvidersLocations,
	},
	[SearchEntity.SearchByNeed]: {
		id: 'uuid',
		normalize: normalizeProviderAndFacility,
		retrieveLocations: retrieveProvidersAndFacilitiesLocations,
	},
	[SearchEntity.SearchByGroupName]: {
		id: 'uuid',
		normalize: normalizeProvider,
		retrieveLocations: retrieveProvidersLocations,
	},
};

export const retrieveSearchTypeAttr = (entity, searchType) => {
	if (searchType === SearchType.Free) {
		return 'name';
	}

	if (entity === SearchEntity.Facility) {
		return 'facilities';
	}

	if (entity === SearchEntity.Provider) {
		return 'service';
	}

	if (entity === SearchEntity.Procedure) {
		return 'procedure';
	}

	if (entity === SearchEntity.Treatment) {
		return 'treatment';
	}

	if (entity === SearchEntity.Condition) {
		return 'condition';
	}

	if (entity === SearchEntity.SearchByNeed) {
		return 'searchByNeed';
	}

	if (entity === SearchEntity.SearchByGroupName) {
		return 'searchByNeed';
	}
};

export const DEFAULT_RESULTS: ProvidersSearchResults = {
	entity: null,
	page: 0,
	records: [],
	pageSize: 20,
	totalCount: 0,
	isInNetwork: null,
	networkStructure: NetworkStructure.NN, // This hides the in-network/out-network dropdown when the page loads, and displays it back only if the returned networkStructure = NetworkStructure.SINGLE
};

export type ProviderLocationMarker = {
	providerIndex: number;
} & Partial<ProviderLocation>;
