<app-share-provider #shareProviderComponent [provider]="providerData"></app-share-provider>
<app-search-results-base-card
	[appearance]="appearance"
	[isFavorite]="isFavorite"
	(isFavoriteChange)="isFavoriteChange.emit($event)"
>
	<div
		avatar
		[class.male]="providerData.gender === ProviderGender.Male"
		[class.female]="providerData.gender === ProviderGender.Female"
	></div>
	<ng-container title>
		{{ providerData.firstName }} {{ providerData.lastName
		}}<span *ngFor="let degree of providerData.degrees">, {{ degree }}</span>
	</ng-container>
	<ng-container subTitle>
		{{ providerData.primarySpecialty | titlecase }}
		<div
			*ngIf="isMobileScreen && appearance === 'outlined' && providerData.specialties.length > 1"
			class="specialties"
		>
			{{ providerData.specialties.length }} {{ 'specialties' | translate }}
		</div>
		<div
			*ngIf="!isMobileScreen && appearance === 'outlined' && providerData.specialties.length > 1"
			(click)="$event.stopPropagation()"
			class="specialties with-tooltip"
			[appTooltip]="specialtiesTooltip"
		>
			{{ providerData.specialties.length }} {{ 'specialties' | translate }}
		</div>
		<ng-template #specialtiesTooltip>
			<div *ngFor="let specialty of providerData.specialties">• {{ specialty | translate | titlecase }}</div>
		</ng-template>
	</ng-container>

	<div class="body" body>
		<div class="sub-body">
			<div *ngIf="providerData.distance" class="distance-wrapper">
				<img src="{{ assetsPath }}/provider-card-location-pin.svg" alt="Distance" />
				<span>{{ providerData.distance }} {{ 'miles' | translate }}</span>
			</div>
			<div *ngIf="providerData.ratingsAvg" class="rating">
				<img src="{{ assetsPath }}/provider-card-rating.svg" alt="Rating" />
				<span>{{ providerData.ratingsAvg / 2 | number : '1.0-1' }}/5</span>
				<span class="rating-count">&nbsp;({{ providerData.ratingsCount }})</span>
			</div>
			<div
				*ngIf="(isRbpApproved$ | async) === true && (networkStructure$ | async) === NetworkStructure.RBP"
				class="distance-wrapper"
			>
				<ripple-icon [name]="'checkCircle'" [size]="rippleSize.size20"></ripple-icon>
				<span class="rbp-check-mark">{{ 'RBP Participating' | translate }}</span>
			</div>
			<div *ngIf="appearance === 'standard'">
				<div *ngIf="providerData.isInNetwork === true" class="network-wrapper">
					<div class="radius-circle icon-size-8 background-success ml-4 mr-4"></div>
					<div>{{ 'In-Network' | translate }} ({{ providerData.networkContract | titlecase }} Plan)</div>
				</div>
				<div *ngIf="providerData.isInNetwork === false" class="network-wrapper">
					<div class="radius-circle icon-size-8 background-error ml-4 mr-4"></div>
					<div>{{ 'Out-of-Network' | translate }}</div>
				</div>
				<div *ngIf="providerData.isInNetwork === null" class="network-wrapper">
					<div class="radius-circle icon-size-8 background-color-light-blue ml-4 mr-4"></div>
					<div>{{ 'Coverage status is unavailable' | translate }}</div>
				</div>
			</div>
		</div>
	</div>

	<div footer class="footer">
		<div class="btns-wrapper" [ngClass]="{ list: appearance === 'outlined', details: appearance === 'standard' }">
			<button
				class="bt share"
				[class.small]="appearance === 'outlined'"
				(click)="shareProviderComponent.share($event)"
			>
				<img src="{{ assetsPath }}/provider-card-share.svg" alt="Call" />
				<ng-container *ngIf="appearance === 'standard'">
					{{ 'Share' | translate }}
				</ng-container>
			</button>
			<button
				*ngIf="(!phoneNumber || !hasOnlineBookingOption) && appearance === 'outlined' && !isMobileScreen"
				class="bt call"
				[ngClass]="!phoneNumber && !hasOnlineBookingOption ? 'primary-blue' : 'secondary-blue'"
			>
				{{ 'More info' | translate }}
			</button>
			<ng-container *ngIf="phoneNumber">
				<button
					*ngIf="!isMobileScreen; else mobilePhoneNumber"
					[matTooltip]="phoneNumber"
					[matTooltipDisabled]="true"
					#tooltip="matTooltip"
					class="bt call"
					[ngClass]="hasOnlineBookingOption ? 'secondary-blue' : 'primary-blue'"
					(click)="callToProvider($event, phoneNumber)"
					(mouseover)="showTooltip(tooltip)"
				>
					<ripple-icon name="phone"></ripple-icon>
					{{ 'Call' | translate }}
				</button>
				<ng-template #mobilePhoneNumber>
					<a
						[href]="'tel:' + phoneNumber"
						class="bt call primary-blue selectable"
						(click)="$event.stopPropagation()"
						target="_top"
					>
						<ripple-icon name="phone"></ripple-icon>
						{{ phoneNumber }}
					</a>
				</ng-template>
			</ng-container>
			<button *ngIf="hasOnlineBookingOption" class="bt primary-blue" (click)="onOpenScheduleAppointment($event)">
				{{ 'Book' | translate }}
			</button>
		</div>
	</div>
</app-search-results-base-card>
