import { Injectable } from '@angular/core';
import { PlanLevelDeductiblesService } from './plan-level-deductibles.service';
import { ConnectedDeductiblesService } from './connected-deductibles.service';
import { DeductibleNetwork } from '../api/domain/deductible';
import { isNil } from 'projects/ripple/src/lib/utils';
import { Observable, switchMap, map, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DeductiblesService {
	private _isConnectedToDeductible: boolean = false;
	private _hasPlanLevelDeductible: boolean = false;
	private _hasPlanLevelOutOfNetworkMaximum: boolean = false;
	private _spentPercentage: number = 0;
	private _isOutOfPocketMaximumMet: boolean = false;
	private _networkData: DeductibleNetwork;
	private _networkDeductibleOOPMData: DeductibleNetwork;

	constructor(
		private connectedDeductiblesService: ConnectedDeductiblesService,
		private planLevelDeductiblesService: PlanLevelDeductiblesService
	) {}

	public initialize(networkFieldNameForPlanlevel: string, networkFieldNameForConnected: string): Observable<void> {
		return this.connectedDeductiblesService.isConnected().pipe(
			switchMap((isConnected) => {
				this._isConnectedToDeductible = isConnected;
				return this.planLevelDeductiblesService.hasDefaultPlanLevelDeductible(networkFieldNameForPlanlevel);
			}),
			switchMap((hasDeductible) => {
				this._hasPlanLevelDeductible = hasDeductible;
				return this.planLevelDeductiblesService.hasDefaultPlanLevelOutOfPocketMaximum(
					networkFieldNameForPlanlevel
				);
			}),
			switchMap((hasOutOfPocketMaximum) => {
				this._hasPlanLevelOutOfNetworkMaximum = hasOutOfPocketMaximum;
				return this.initializeNetworkData(networkFieldNameForConnected);
			}),
			switchMap(() => {
				this.inititalizeSpendPercentage();
				return of(null);
			})
		);
	}

	private initializeNetworkData(networkFieldName: string): Observable<void> {
		return this.connectedDeductiblesService.getConnectedDeductibleCardData(networkFieldName).pipe(
			switchMap((networkData) => {
				this._networkData = networkData;
				if (!this._hasPlanLevelDeductible) {
					return this.connectedDeductiblesService.getConnectedOutOfPocketMaximumData(networkFieldName);
				} else {
					return this.connectedDeductiblesService.getConnectedOutOfPocketMaximumData(networkFieldName);
				}
			}),
			map((networkDeductibleOOPMData) => {
				this._networkDeductibleOOPMData = networkDeductibleOOPMData;
				this.initializeIsOutOfPocketMaximumMet(networkDeductibleOOPMData);
			})
		);
	}

	public get hasPlanLevelDeductible() {
		return this._hasPlanLevelDeductible;
	}

	public get hasPlanLevelOutOfNetworkMaximum() {
		return this._hasPlanLevelOutOfNetworkMaximum;
	}

	public get isConnectedToDeductible() {
		return this._isConnectedToDeductible;
	}

	public get networkData() {
		return this._networkData;
	}

	public get networkDeductibleOOPMData() {
		return this._networkDeductibleOOPMData;
	}

	public get spendtercentage(): number {
		return this._spentPercentage;
	}

	public get isOutOfPocketMaximumMet(): boolean {
		return this._isOutOfPocketMaximumMet;
	}

	private inititalizeSpendPercentage(): void {
		if (isNil(this.networkData?.maximum) || isNil(this.networkData?.spend)) {
			this._spentPercentage = 0;
			return;
		}

		this._spentPercentage = Math.ceil((100 * this.networkData?.spend) / this.networkData?.maximum);
	}

	private initializeIsOutOfPocketMaximumMet(data: DeductibleNetwork): void {
		this._isOutOfPocketMaximumMet = data?.remaining === 0;
	}
}
