import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Observable, startWith, Subject, switchMap, takeUntil } from 'rxjs';

import { useIonicFile } from '../../../../utils/utils';

import { UserService } from '../../../../services/user.service';
import { filter, map } from 'rxjs/operators';
import { T } from '@transifex/angular';
import { LanguageService } from 'src/app/services/language.service';
import { Capacitor } from '@capacitor/core';
import { SidebarService } from '../../../../services/sidebar/sidebar.service';
import { ButtonHierarchy } from 'ripple';
import { PlanSelectionStoreService } from '../../../../services/stores/plan-selection-store/plan-selection-store.service';
import { RxjsUtils } from '../../../../utils/rxjs';
import { UnleashService } from '../../../../services/unleash.service';

const BACK_BUTTON_PAGES = ['/privacy-policy'];

@Component({
	selector: 'app-topbar',
	templateUrl: './topbar.component.html',
	styleUrls: [useIonicFile('./topbar.component.scss', './topbar.component.ionic.scss')],
})
export class TopbarComponent implements OnInit, OnDestroy {
	@T('Ask Zoe', { _key: 'ask_zoe' })
	private title_chat: string;

	@T('My Benefits', { _key: 'my_benefits' })
	private title_benefits: string;

	@T('My Apps', { _key: 'my_apps' })
	private title_my_apps: string;

	@T('Care & Costs', { _key: 'care_and_costs' })
	private title_care: string;

	@T('Health Profile', { _key: 'health_profile' })
	private title_health: string;

	@T('Session Summary', { _key: 'session_summary' })
	private title_session: string;

	@T('FAQ', { _key: 'faq' })
	private title_faq: string;

	@T('Privacy Policy', { _key: 'privacy_policy' })
	private title_privacy: string;

	@T('Home', { _key: 'title_home' })
	private title_home: string;

	@T('Immediate Care Options')
	private title_immediate_care: string;

	@T('Plan Comparison Tool')
	private title_plan_comparison_tool: string;

	private translatedTitles = {};

	public userInitials$: Observable<string>;
	public pageTitle: string;
	public readonly isMobileApp = Capacitor.isNativePlatform();
	private unsubscribe$ = new Subject<void>();
	public hierarchy = ButtonHierarchy;
	public displayBackButton$: Observable<boolean>;

	appLogoUrl$ = this.userService._appLogoUrl;

	get isDefaultLayout$(): Observable<boolean> {
		return this.userService.isUserEligible$;
	}

	constructor(
		private languageService: LanguageService,
		private router: Router,
		private userService: UserService,
		public sidebarService: SidebarService,
		private titleService: Title,
		private activatedRoute: ActivatedRoute,
		private planSelectionStoreService: PlanSelectionStoreService,
		private unleashService: UnleashService
	) {
		this.displayBackButton$ = this.router.events.pipe(
			startWith(null),
			switchMap(() => this.planSelectionStoreService.get().pipe(RxjsUtils.isNotNil())),
			map(
				(planSelectionData) =>
					!planSelectionData.companyData?.isTriNet &&
					BACK_BUTTON_PAGES.some((page) => this.router.url.includes(page))
			)
		);
	}

	ngOnInit() {
		this.initTitleTranslations();
		this.setPageTitles();
		this.subscribeToLocale();
		this.subscribeToPageTitle();
		this.subscribeToUserInitials();
	}

	private initTitleTranslations() {
		this.translatedTitles = {
			ask_zoe: this.title_chat,
			my_benefits: this.title_benefits,
			care_and_costs: this.title_care,
			health_profile: this.title_health,
			session_summary: this.title_session,
			my_apps: this.title_my_apps,
			faq: this.title_faq,
			privacy_policy: this.title_privacy,
			home: this.title_home,
			immediateCare: this.title_immediate_care,
			pct: this.title_plan_comparison_tool,
		};
	}

	private subscribeToLocale() {
		this.languageService.appLanguage$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
			this.initTitleTranslations();
			this.setPageTitles();
		});
	}

	private setPageTitles() {
		const title = this.getTitleFromChildRoute();

		this.pageTitle = title;
		this.titleService.setTitle(title);
	}

	private subscribeToPageTitle() {
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				takeUntil(this.unsubscribe$)
			)
			.subscribe(() => {
				this.setPageTitles();
			});
	}

	private getTitleFromChildRoute(): string {
		let route: ActivatedRoute = this.router.routerState.root;
		while (route.firstChild) {
			route = route.firstChild;
		}
		return this.translatedTitles[route.snapshot.data.title] || '';
	}

	private subscribeToUserInitials() {
		this.userInitials$ = this.userService.userInitials$;
	}

	public toggleSidenavMenu() {
		this.sidebarService.open();
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}

	public goBack() {
		this.router.navigateByUrl(this.activatedRoute.snapshot.queryParams.referrer);
	}
}
