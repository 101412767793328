import { Component, OnInit } from '@angular/core';

import { HealtheeDialogContent } from '../../app-shared/healthee-dialog/healthee-dialog.component';

import { LogWithTimestamp, LoggerService } from '../../../services/logger.service';
import { UIService } from '../../../services/ui.service';

@Component({
	selector: 'app-mobile-developer-debug-log',
	templateUrl: './mobile-developer-debug-log.component.html',
	styleUrls: ['./mobile-developer-debug-log.component.scss'],
})
export class MobileDeveloperDebugLogComponent extends HealtheeDialogContent implements OnInit {
	public logs: LogWithTimestamp[] = [];

	constructor(private logger: LoggerService, private uiService: UIService) {
		super();
	}

	ngOnInit(): void {
		this.refreshLogs();
	}

	refreshLogs() {
		this.logs = this.logger.getOnDeviceDebugLogs().map((log) => ({
			...log,
			paramsJSONStr: JSON.stringify(log.params, null, 2),
		}));

		this.uiService.displayAppMessage('onDevice debug logs refreshed');
	}

	clearLogs() {
		this.logger.clearOnDeviceLogs();
		this.refreshLogs();
	}
}
