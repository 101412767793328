<div class="page-wrapper">
	<div class="side-pane">
		<div class="logo">
			<img
				[src]="
					(isNewBrandLogoEnabled$ | async)
						? '/assets/images/logo-v2/logo_text.svg'
						: '/assets/images/icons/healthee-icon-text-black.svg'
				"
				alt="healthee logo"
			/>
		</div>

		<div class="footer-text">
			<p><UT str="A Healthee future awaits." key="healthee_slogan"></UT></p>
			<app-locale-select [fullSize]="true"></app-locale-select>
		</div>
	</div>

	<div class="main-pane">
		<ng-content></ng-content>
	</div>
</div>
