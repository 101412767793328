import {
	Directive,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	Output,
	TemplateRef
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { ConnectedPosition, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Subscription } from 'rxjs';
import { Capacitor } from '@capacitor/core';

import { TooltipBottomSheetComponent } from './tooltip-bottom-sheet/tooltip-bottom-sheet.component';
import { TooltipBubbleComponent } from './tooltip-bubble/tooltip-bubble.component';
import { isNil } from '../utils';

@Directive({
	selector: '[rippleTooltipWithTouch]',
})
export class TooltipWithTouchDirective implements OnInit, OnDestroy {
	@Input('rippleTooltipWithTouch') template: TemplateRef<any>;
	@Input('rippleTooltipWithTouchDisabled') disabled = false;
	@Input() tooltipTitleOnMobile: string;
	@Input() rippleTooltipWithTouchDynamicWidth = false;

	@Input() tooltipAutoHide: boolean = true;

	@Input()
	set tooltipPositionX(value: 'start' | 'center' | 'end') {
		this._tooltipPositionX = value;
		this.positionWasExplicitlySetByParent = true;
	}
	get tooltipPositionX() { return this._tooltipPositionX; }

	@Input()
	set tooltipPositionY(value: 'top' | 'center' | 'bottom') {
		this._tooltipPositionY = value;
		this.positionWasExplicitlySetByParent = true;
	}
	get tooltipPositionY() { return this._tooltipPositionY; }

	@Input()
	set tooltipOffsetX(value: number) {
		this._tooltipOffsetX = value;
		this.positionWasExplicitlySetByParent = true;
	}
	get tooltipOffsetX() { return this._tooltipOffsetX; }

	@Input()
	set tooltipOffsetY(value: number) {
		this._tooltipOffsetY = value;
		this.positionWasExplicitlySetByParent = true;
	}
	get tooltipOffsetY() { return this._tooltipOffsetY; }

	@Output() tooltipClosed = new EventEmitter<void>();
	@Output() tooltipOpened = new EventEmitter<void>();

	private positionWasExplicitlySetByParent = false;
	private _tooltipPositionX: 'start' | 'center' | 'end' = 'center';
	private _tooltipPositionY: 'top' | 'center' | 'bottom' = 'top';
	private _tooltipOffsetX: number = 0;
	private _tooltipOffsetY: number = 5;

	private overlayRef: OverlayRef;
	private overlayBottomRef: OverlayRef;
	private isTouchDevice: boolean = Capacitor.isNativePlatform(); // Don't wait for touchstart event, on ionic this is always a touch device
	private sheetCloseClickSub: Subscription;
	private backdropClickSub: Subscription;
	private routerNavigationSub: Subscription;

	@HostListener('touchstart')
	handleTouchStart() {
		this.isTouchDevice = true;
	}

	@HostListener('click') // Mouse click on web or touch on mobile
	onWebClickOrMobileTap() {
		if (this.isTouchDevice) {
			this.showTooltipOnMobile();
			return;
		}

		this.handleClickOnWeb();
	}

	@HostListener('mouseenter')
	onMouseEnter() {
		this.showTooltipOnWeb();
	}

	@HostListener('mouseleave')
	onMouseLeave() {
		if (this.tooltipAutoHide)
			this.hideTooltipOnWeb();
	}

	@HostListener('window:scroll')
	@HostListener('window:wheel')
	onWindowScroll() {
		// The tooltip doesn't move with scroll, so it should be closed when scrolling,
		// otherwise it'll be stuck on some irrelevant corner of the screen
		this.hideTooltipOnWeb();
	}

	constructor(
		private elementRef: ElementRef,
		private overlay: Overlay,
		private overlayBottom: Overlay,
		private router: Router
	) {}

	ngOnInit(): void {
		const positions = this.getTooltipPosition();

		this.overlayRef = this.overlay.create({
			positionStrategy: this.overlay
				.position()
				.flexibleConnectedTo(this.elementRef)
				.withPositions(positions),
		});

		this.overlayBottomRef = this.overlayBottom.create({
			hasBackdrop: true,
			scrollStrategy: this.overlayBottom.scrollStrategies.block(),
			positionStrategy: this.overlayBottom.position().global().centerHorizontally().bottom(),
		});

		// Listen to router navigation to close the tooltip. This is for closing the overlays on ionic mobile
		// Note: This may NOT work when changing ionic native tabs, where you must listen to ionTabsWillChange
		// to close the overlays. Currently tab navigation is blocked when the bottom overlay is open (because
		// overlay covers the native tabs) so the user must close the overlay before navigating to other tabs,
		// so this solution is sufficient.
		this.routerNavigationSub = this.router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				this.closeAllOverlays();
			}
		});
	}

	private getTooltipPosition() {
		const dynamicPositioning: ConnectedPosition[] = [
			// default values + another alternative position
			{
				originX: 'center',
				originY: 'bottom',
				overlayX: this.tooltipPositionX,
				overlayY: this.tooltipPositionY,
				offsetX: this.tooltipOffsetX,
				offsetY: this.tooltipOffsetY,
			},
			{
				originX: 'center',
				originY: 'top',
				overlayX: 'center',
				overlayY: 'bottom',
				offsetY: -this.tooltipOffsetY,
			},
		];

		const explicitPositioning: ConnectedPosition[] = [
			// use only the position set by the parent
			{
				originX: 'center',
				originY: 'bottom',
				overlayX: this.tooltipPositionX,
				overlayY: this.tooltipPositionY,
				offsetX: this.tooltipOffsetX,
				offsetY: this.tooltipOffsetY,
			}
		];

		return this.positionWasExplicitlySetByParent
			? explicitPositioning
			: dynamicPositioning;
	}

	private showTooltipOnMobile() {
		if (this.disabled) {
			return;
		}

		if (isNil(this.overlayBottomRef) || this.overlayBottomRef.hasAttached()) {
			return;
		}

		// Open sheet
		const tooltipRef = this.overlayBottomRef.attach(new ComponentPortal(TooltipBottomSheetComponent));
		tooltipRef.instance.tooltip = this.template;
		tooltipRef.instance.title = this.tooltipTitleOnMobile;
		this.tooltipOpened.emit();

		// Subscribe to close events
		this.sheetCloseClickSub = tooltipRef.instance.closeClicked.subscribe(() => this.onMobileTooltipCloseTriggered());
		this.backdropClickSub = this.overlayBottomRef.backdropClick().subscribe(() => this.onMobileTooltipCloseTriggered());
	}

	private handleClickOnWeb() {
		if (this.tooltipAutoHide) {
			// if 'tooltipAutoHide', no manual open or close upon click, and the tooltip should be opened
			// or closed by mouseenter and mouseleave events. This is to keep the original tooltip behavior
			// without the ability to manualy close or open the tooltip on mouse click events
			return;
		}

		// if 'tooltipAutoHide' = false, on web the tooltip is shown first time upon 'mouseenter'. The subsequent
		// 'click' event is registered by clicking the triggering element:
		// 1. if the tooltip is visible, clicking the triggering element, should close the tooltip:
		if (this.isTooltipVisible()) {
			this.hideTooltipOnWeb();
		} else {
			// 2. if the tooltip was already closed, clicking the triggering element should show the tooltip:
			this.showTooltipOnWeb();
		}
	}

	private showTooltipOnWeb() {
		if (this.disabled || this.isTouchDevice) {
			return;
		}

		if (isNil(this.overlayRef) || this.overlayRef.hasAttached()) {
			return;
		}

		const tooltipRef = this.overlayRef.attach(new ComponentPortal(TooltipBubbleComponent));
		tooltipRef.instance.tooltip = this.template;
		tooltipRef.instance.overlayRef = this.overlayRef;
		tooltipRef.instance.autoHide = this.tooltipAutoHide;
		tooltipRef.instance.isDynamicWidth = this.rippleTooltipWithTouchDynamicWidth;

		tooltipRef.changeDetectorRef.markForCheck();
		this.tooltipOpened.emit();
	}

	private hideTooltipOnWeb() {
		if (isNil(this.overlayRef)) {
			return;
		}

		this.overlayRef.detach();
		this.tooltipClosed.emit();
	}

	private isTooltipVisible(): boolean {
		return this.overlayRef?.hasAttached() ?? false;
	}

	private onMobileTooltipCloseTriggered() {
		this.tooltipClosed.emit();
		this.overlayBottomRef.detach();
	}

	private closeAllOverlays() {
		this.overlayRef?.detach();
		this.overlayBottomRef?.detach();
	}

	ngOnDestroy(): void {
		this.closeAllOverlays();
		this.sheetCloseClickSub?.unsubscribe();
		this.backdropClickSub?.unsubscribe();
		this.routerNavigationSub?.unsubscribe();
	}
}
