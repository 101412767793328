<div class="redirect-container">
	<div class="content">
		<div [ngSwitch]="!!enrollmentCompletionText" class="first-text">
			<div *ngSwitchCase="true">
				{{ enrollmentCompletionText }}
			</div>
			<div *ngSwitchCase="false">
				{{ 'To ' | translate }}
				<b>{{ 'complete your enrollment' | translate }}</b>
				{{
					', please log in to your ADP account where you will need to input your selected medical, dental, and vision plans. Be sure to review the other voluntary benefits offered by ADP.'
						| translate
				}}
			</div>
		</div>
		<div class="second-text">
			{{
				'As soon as you have completed your enrollment, please return to Healthee and mark that it’s been completed.'
					| translate
			}}
		</div>
	</div>
	<mat-divider></mat-divider>

	<div class="actions">
		<button class="cancel" (click)="onCancel()">{{ 'Cancel' | translate }}</button>
		<button class="bt primary-blue" (click)="onConfirm()">{{ 'Continue' | translate }}</button>
	</div>
</div>
