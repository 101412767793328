<ng-container>
  <div *ngIf="services?.length > 0" class="service-content-benefits">
    <div class="service-content-benefits-title">{{ 'Related Benefits' | translate }}</div>

    <div class="service-content-benefits-wrapper">
      <div
        class="related-benefit"
        (click)="relatedBenefitClick.emit(service._id)"
        [appClientClickEventTracker]="{
          event: 'Coverage Info Related Benefits click',
          metaData: {
            'service name': serviceName,
            relatedServiceName: service.abbreviate
          }
        }"
        *ngFor="let service of services; let i = index"
      >
        <img class="related-benefit-image" [src]="service.iconPath | serviceImage : i" />
        <div class="related-benefit-content">
          <span class="related-benefit-title">{{ service.abbreviate }}</span>
          <span
            class="coverage-badge covered"
            *ngIf="userBenefitsMap.get(service._id)?.covered === 'covered'"
            >{{ 'Covered' | translate }}</span
          >
          <span
            class="coverage-badge not-covered"
            *ngIf="userBenefitsMap.get(service._id)?.covered !== 'covered' && !telehealthServiceIds.includes(service._id)"
            >{{ 'Not Covered' | translate }}</span
          >
          <span
          class="coverage-badge covered_by_healthee"
          *ngIf="telehealthServiceIds.includes(service._id)"
          >{{ 'Covered by Healthee' | translate }}</span
        >
        </div>
      </div>
    </div>
  </div>
  <div class="feedback-wrapper">
    <app-feedback-block
      [feature]="'benefit panel'"
      [data]="{ 'benefit ID': benefitId }"
    ></app-feedback-block>
  </div>
</ng-container>
