import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { Copay, CopayCadence, MemberResponsibility } from '../../../../models/benefits.model';
import { PremiumFeature } from '../../../../models/premium-feature.model';
import { CommonComponent } from '../../../../utils/components/common-component';
import { ServicePanelInformationLocalize as T } from './service-panel-information.localize';

import { PremiumFeatureService } from '../../../../services/premium-feature.service';
import { TelehealthDataService } from '../../../../services/telehealth-data.service';
import { DeductiblesService } from '../../../../services/deductibles/deductibles.service';
import { UserPlanDataStoreService } from '../../../../services/stores/user-plan-data-store/user-plan-data-store.service';

interface InformationTexts {
	headline?: string;
	text?: string;
	ps?: string;
	showDeductibleTooltip: boolean;
}

@Component({
	selector: 'app-service-panel-information',
	templateUrl: './service-panel-information.component.html',
	styleUrls: ['./service-panel-information.component.scss'],
})
export class ServicePanelInformationComponent extends CommonComponent implements OnInit, OnChanges {
	public isTelehealth$: Observable<boolean> = this.premiumFeatureService.isEnabled(PremiumFeature.Telehealth);
	public userHasHRAPlan$ = this.userPlanDataStoreService.isHRAPlan();

	@Input()
	copay: Copay;

	@Input()
	hasPlanDeductible: boolean;

	@Input()
	mr: MemberResponsibility;

	@Input()
	subjectToDeductible: boolean;

	@Input()
	dependsOnLocation: boolean;

	@Input()
	networkTitle: string;

	@Input()
	serviceId: string;

	@Input()
	deductibleOutOfPocketDetailData: any;

	@Input()
	deductibleDetailsCardData: any;

	public firstLineText: InformationTexts;
	public secondLineText: InformationTexts;
	public serviceIsFullyCoveredByPayer: boolean = false;

	constructor(
		private premiumFeatureService: PremiumFeatureService,
		public telehealthDataService: TelehealthDataService,
		public deductiblesService: DeductiblesService,
		private userPlanDataStoreService: UserPlanDataStoreService,
	) {
		super();
	}

	public ngOnInit(): void {
		this.deductiblesService
			.initialize(this.networkFieldNameForPlanlevel, this.networkFieldNameForConnected)
			.subscribe(() => {
				this.generateTexts();
			});
	}

	public ngOnChanges(): void {
		this.generateTexts();
	}

	private generateTexts(): Promise<void> {
		this.resetTexts();

		if (this.deductiblesService.isOutOfPocketMaximumMet) {
			this.firstLineText.text = T.thisServiceIsFullyCoveredByYourPayer;
			this.firstLineText.ps = T.youMetYourOutOfPocketMax;
			this.serviceIsFullyCoveredByPayer = true;
			return;
		}

		if (this.dependsOnLocation) {
			this.firstLineText.text = T.coverageDependsOnLocation;
			return;
		}

		if (this.copay.label) {
			this.firstLineText.text = this.copay.label;
			return;
		}

		// Check if copay amount is 0 and member responsibility is 100%
		if ((this.copay.amount === 0 || !this.copay.amount) && this.mr.percent && this.mr.percent === 100) {
			this.firstLineText.text = T.youllPay100PercentOfThisServiceCost;
			this.firstLineText.ps = T.yourInsuranceDoesNotCoverThisService;
			this.firstLineText.showDeductibleTooltip = false;
			this.subjectToDeductible = false;
			return;
		}

		// Check if copay amount is 0 and member responsibility is 0%
		if ((this.copay.amount === 0 || !this.copay.amount) && (this.mr.percent === 0 || !this.mr.percent)) {
			this.firstLineText.text = T.thisServiceIsFullyCoveredByYourPayer;
			this.firstLineText.ps = T.appliesAlwaysRegardlessOfYourDeductibleStatus;
			this.subjectToDeductible = false;
			this.serviceIsFullyCoveredByPayer = true;
		}

		if (
			((this.mr.dollar && this.mr.dollar > 0) || (this.mr.percent && this.mr.percent > 0)) &&
			((this.copay.amount && this.copay.amount > 0) || (this.copay.upToLimit && this.copay.upToLimit > 0))
		) {
			this.setCopayText();
			this.setCoinsuranceText(false);
		} else if (this.copay.amount && this.copay.amount > 0) {
			this.setCopayText();
		} else if ((this.mr.dollar && this.mr.dollar > 0) || (this.mr.percent && this.mr.percent > 0)) {
			this.setCoinsuranceText(true);
		}

		this.setDeductibleInformaton();
	}

	private setDeductibleInformaton(): void {
		if (this.subjectToDeductible) {
			if (this.deductiblesService.hasPlanLevelDeductible) {
				this.firstLineText.headline = T.beforeDeductible;
				this.secondLineText.headline = T.afterDeductible;
			} else {
				this.firstLineText.headline = T.beforeOutOfPocketMaximum;
				this.secondLineText.headline = T.afterOutOfPocketMaximum;
			}
			this.firstLineText.text = T.youllPay10PercentOfTheServiceCost;
		} else {
			this.secondLineText.ps = T.appliesAlwaysRegardlessOfYourDeductibleStatus;
			this.subjectToDeductible = false;
		}
	}

	private resetTexts() {
		this.firstLineText = { showDeductibleTooltip: true };
		this.secondLineText = { showDeductibleTooltip: true };
		this.serviceIsFullyCoveredByPayer = false;
	}

	// TODO: Fix translations to match ES as well in terms of the sentence structure.
	private setCoinsuranceText(onlyCoinsurance: boolean): void {
		const value = !this.mr.dollar ? `${this.mr.percent}%` : `$${this.mr.dollar}`;

		if (!onlyCoinsurance) {
			this.secondLineText.text += this.mr.cadence
				? `, ${T.then} ${value} ${T.onAnyAdditionalCost}`
				: ` ${T.andAdditional} ${value} ${T.ofThisServiceCost}`;
		} else {
			this.secondLineText.text = `${T.youllPay} ${value} ${T.ofThisServiceCost}`;
		}

		if (this.mr.cadence) {
			this.secondLineText.text += ` ${T.per} ${this.mr.cadence}`;
		}

		if (this.mr.amount) {
			this.secondLineText.text += ` ${T.upToAMaxOf} $${this.mr.amount}`;
		}

		if (this.mr.additionalPercent) {
			this.secondLineText.text += `. ${T.thenYoullPay} ${this.mr.additionalPercent}%`;
			this.secondLineText.text += `${T.ofTheRemainingCost}`;
		}
	}

	// TODO: Fix translations to match ES as well in terms of the sentence structure.
	private setCopayText(): void {
		if (this.copay.amount > 0) {
			this.secondLineText.text = `${T.youllPay} $${this.copay.amount} ${
				this.copay.upToLimit ? ' - $' + this.copay.upToLimit : ''
			} ${T.forThisService}`;
		} else {
			this.secondLineText.text = `${T.youllPayUpTo} $${this.copay.upToLimit}`;
		}

		if (this.copay.unit) {
			this.secondLineText.text += ` ${T.perEach} ${this.copay.unit}`;
		}

		switch (this.copay.cadence) {
			case CopayCadence.Admission:
				this.secondLineText.text += ` ${T.per} ${this.copay.cadenceCount} ${this.copay.cadence}${
					this.copay.cadenceCount > 1 ? 's' : '' // TODO: Fix this plural form for ES language
				}`;
				break;
			case CopayCadence.FirstNDays:
				this.secondLineText.text += ` ${T.duringTheFirst} ${this.copay.cadenceCount} ${T.days}`;
				break;
		}
	}

	public get inNetwork(): boolean {
		return this.networkTitle !== 'Out network';
	}

	private get networkFieldNameForPlanlevel(): string {
		return this.inNetwork ? 'inNetwork' : 'outNetwork';
	}

	private get networkFieldNameForConnected(): string {
		return this.inNetwork ? 'inNetwork' : 'outOfNetwork';
	}
}
