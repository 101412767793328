export function getCookieDomain(hostname: string) {
  if (isLocalhost(hostname)) {
    return hostname;
  }

  const parts = hostname.split('.');
  parts.shift() // remove the subdomain

  return `.${parts.join('.')}`;
}

function isLocalhost(hostname: string) {
  return (
      hostname === "localhost" ||
      hostname === "127.0.0.1"
  );
}
