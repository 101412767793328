import { Component, OnInit } from '@angular/core';
import { Subject, take } from 'rxjs';
import { HealtheeDialogContent } from '../../app-shared/healthee-dialog/healthee-dialog.component';
import { TrackingService } from 'src/app/services/tracking.service';
import { UserService, MFAMethods } from 'src/app/services/user.service';
import { T } from '@transifex/angular';
import { AttributeAlreadyLatest, AuthService } from '../login/auth.service';

@Component({
	selector: 'app-verify-phone-modal',
	templateUrl: './verify-phone-modal.component.html',
	styleUrls: ['./verify-phone-modal.component.scss'],
})
export class VerifyPhoneModalComponent extends HealtheeDialogContent implements OnInit{
	@T('Oops! The code you entered is incorrect. Please try again.')
	public userErrors_wrongTwoFA: string;

	public step: number = 1;
	public credentials = { method: MFAMethods.sms, device: '', id: '', code: '' };

	public error: string = '';
	public isProcessing: boolean = false;

	public twoFAErrorSignal$ = new Subject();
	public show2FACodeResent: boolean = false;
	public isChangeExistingNumber: boolean;

	constructor(private trackingService: TrackingService, private userService: UserService, private authService: AuthService) {
		super();
	}

	ngOnInit(): void {
		this.isChangeExistingNumber = this.data?.isChangeExistingNumber;
	}

	private goToNextStep() {
		this.goToStep(this.step + 1);
	}
	private goToFinalStep() {
		this.goToStep(3);
	}

	public goToPreviousStep() {
		this.goToStep(this.step - 1);
	}


	public closePhoneDialog() {
		this.trackingService.trackClientEvent('Phone verification Flow stopped');
		this.closeDialog.emit(null);
	}

	public onPhoneChanged(newPhone: string): void {
		this.credentials.device = newPhone;
	}

	public async onPhoneSubmit(): Promise<void> {
		this.isProcessing = true;
		await this.tryChangePhoneNumber();
	}

	public async request2FACodeAgain() {
		this.isProcessing = true;
		await this.authService.updateUserPhone(this.credentials.device)
		this.trackingService.trackClientEvent('Phone verification 2FA resend');
		this.show2FACodeResent = true;
		this.isProcessing = false;
	}

	public on2FACodeChanged(verificationCode: string) {
		this.credentials.code = verificationCode;
	}

	public async on2FACodeSubmit(): Promise<void> {
		this.isProcessing = true;
		try {
			await this.authService.changePhoneNumberAnswer(this.credentials.code)
			if (!this.isChangeExistingNumber) {
				await this.authService.updateUserDefaultMfaMethod(MFAMethods.sms)
			}

			this.authService.getJwt().then(async (jwt) => {
				await this.authService.forceRefreshToken();
				this.userService.updateToken(jwt);
			})

			// await this.authService.submitPhoneVerificationCode(this.credentials.code)
			this.trackingService.trackClientEvent('Phone verification Completed');
			this.userService.getUserRequest().subscribe();
			this.goToNextStep();
		} catch (err) {
			this.handleDevice2FAErrors(err.error_message);
		}
	}

	private handleDevice2FAErrors(error: string) {
		// UnauthorizedError is the only error that we want to respond to here.
		this.isProcessing = false;

		this.trackingService.trackClientEvent('Phone verification 2FA failure', { error_message: error });

		const EMPTY_SIGNAL = null;
		this.twoFAErrorSignal$.next(EMPTY_SIGNAL);
		this.error = this.userErrors_wrongTwoFA;
	}

	private async tryChangePhoneNumber() {
		try {
			await this.authService.updateUserPhone(this.credentials.device)
			this.trackingService.trackClientEvent('Phone verification Phone number send');
			this.goToNextStep();
		} catch (err) {
			if (err instanceof AttributeAlreadyLatest) { // phone number is already the same
				if (!this.isChangeExistingNumber) {
					await this.authService.updateUserDefaultMfaMethod(MFAMethods.sms)
				}

				this.goToFinalStep();
				return
			}

			this.closePhoneDialog();
			throw err;
		}
	}

	private goToStep(number: number) {
		console.log('step: ', number);
		this.step = number;
		this.isProcessing = false;
	}

}
