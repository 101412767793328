import { Injectable } from '@angular/core';

import { Amplify, Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';

import { AppError } from '../../../error-handlers/app-errors';
import { EnvironmentConfigurationInterface } from '../../../../environments/environment.interface';
import { environment } from '../../../../environments/environment';
import CFG from '../../../config/app-config.json';

import { MFAMethods } from '../../../services/user.service';
import { StorageService } from '../../../services/storage.service';
import { HybridStorage } from './hybrid-storage';
import { Cookies } from './cookies';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	static NEW_SIGNING_FLOW: Promise<boolean> = undefined;
	private newSigningFlowForEmail: string = undefined;
	public cognitoUser: CognitoUser & { challengeParam: { email: string; phoneNumber: string } };
	private static _isFederatedLogin: boolean = false;
	private static _idpName: string;
	private static _idpPrettyName: string;
	private static _isUsingSessionStorage: boolean = false;
	private static _isUsingHybridStorage: boolean;

	constructor(private storageService: StorageService) {}

	static configureClient(appConfig: EnvironmentConfigurationInterface) {
		const cognitoRegion = 'us-east-1';
		let oauthConfig = {};
		let userPoolWebClientId = this.clientIdFromQueryParam() || appConfig.cognito.defaultClient;
		const idpConfig = this.idpConfig(appConfig) || this.subDomainConfig(appConfig);

		if (this.isZocdocRedirectFlow()) {
			this.redirectWithRenamedParams();
		}

		if (idpConfig && !this.healtheeIdp()) {
			AuthService._isFederatedLogin = true;
			AuthService._idpName = idpConfig.idpName;
			AuthService._idpPrettyName = idpConfig.vendorPrettyName;
			userPoolWebClientId = idpConfig.userPoolWebClientId;
			oauthConfig = {
				domain: appConfig.cognito.cognitoDomain,
				scope: idpConfig.oauth.scope,
				redirectSignIn: idpConfig.oauth.redirectSignIn,
				userPoolId: this.poolIdFromQueryParam() || appConfig.cognito.userPoolId,
				userPoolWebClientId: idpConfig.userPoolWebClientId,
				redirectSignOut: idpConfig.oauth.redirectSignOut,
				responseType: idpConfig.oauth.responseType,
			};
		}

		let storage = undefined;
		if (idpConfig && idpConfig['sessionStorage']) {
			AuthService._isUsingSessionStorage = true;
			storage = window.sessionStorage;
		} else if (appConfig.cognito.hybridStorage) {
			AuthService._isUsingHybridStorage = true;
			storage = new HybridStorage(localStorage);
		}

		const amplifyConfig = {
			Auth: {
				region: cognitoRegion,
				userPoolId: this.poolIdFromQueryParam() || cognitoRegion + '_' + appConfig.cognito.userPoolId,
				userPoolWebClientId: userPoolWebClientId,
				oauth: oauthConfig,
				storage: storage,
			},
		};

		Amplify.configure(amplifyConfig);

		if (appConfig.cognito.hybridStorage) {
			this.tryLoadStateFromCookies();
		}
	}

	static get idpName(): string {
		return this._idpName;
	}

	static get idpPrettyName(): string {
		return this._idpPrettyName;
	}
	static get isFederatedLogin(): boolean {
		return this._isFederatedLogin;
	}

	static isUsingSessionStorage(): boolean {
		return this._isUsingSessionStorage;
	}

	static get isUsingHybridStorage(): boolean {
		return this._isUsingHybridStorage;
	}

	async emailFromActivationToken(activationToken: string): Promise<string> {
		return fetch(`${environment.authAdminApi}/user/activation/decode-token?token=${activationToken}`)
			.then(async (response) => {
				const body = await response.json();
				return String(body.email);
			})
			.catch((error) => {
				console.log('error decoding activation token: ', error);
				return undefined;
			});
	}

	async startSmsFlow(email: string, phoneNumber?: string, extraClientMetadata?: { [id: string]: string }) {
		try {
			await this.signIn(email, 'sms');
			await this.smsOptChallenge('DONT_CARE', phoneNumber, extraClientMetadata);
			return this.cognitoUser.challengeParam;
		} catch (error) {
			this.handleStartOtpFlowError(error);
		}
	}

	async startDefaultOtpLoginFlow(email: string, recaptchaToken?: string) {
		try {
			console.log('startDefaultOtpLoginFlow', recaptchaToken);
			await this.signIn(email, 'sms');
			this.cognitoUser = await Auth.sendCustomChallengeAnswer(this.cognitoUser, 'DONT_CARE', {
				signInMethod: 'DEFAULT_OTP',
				recaptchaToken,
			});
			await this.isAuthenticated();
			return this.cognitoUser.challengeParam;
		} catch (error) {
			this.handleStartOtpFlowError(error);
		}
	}

	async startEmailFlow(email: string, token?: string, recaptchaToken?: string) {
		try {
			await this.signIn(email, 'sms');
			await this.emailOptChallenge('DONT_CARE', token, recaptchaToken);
			return this.cognitoUser.challengeParam;
		} catch (error) {
			this.handleStartOtpFlowError(error);
		}
	}

	async startFederatedFlow() {
		const provider = localStorage.getItem('idp') ? localStorage.getItem('idp') : AuthService._idpName;
		await Auth.federatedSignIn({ customProvider: provider });
	}

	public async signIn(email: string, signInMethod: string) {
		this.cognitoUser = await Auth.signIn(email.toLowerCase(), undefined, {
			signInMethod: signInMethod,
		});
	}

	public async smsOptChallenge(
		answer: string,
		phoneNumber?: string,
		extraClientMetadata?: { [id: string]: string }
	): Promise<boolean> {
		console.log('answerCustomChallenge with answer: ', answer);
		const clientMetadata = { signInMethod: 'SMS_OTP', ...extraClientMetadata };

		if (phoneNumber) {
			clientMetadata['phone_number'] = phoneNumber;
		}

		this.cognitoUser = await Auth.sendCustomChallengeAnswer(this.cognitoUser, answer, clientMetadata);

		console.log('answerCustomChallenge cognitoUser: ', this.cognitoUser);

		return this.isAuthenticated();
	}

	public async emailOptChallenge(answer: string, token?: string, recaptchaToken?: string): Promise<boolean> {
		console.log('answerCustomChallenge with answer: ', answer);
		const clientMetadata = { signInMethod: 'EMAIL_OTP' };

		if (token) {
			clientMetadata['initToken'] = token;
		}

		if (recaptchaToken) {
			clientMetadata['recaptchaToken'] = recaptchaToken;
		}

		this.cognitoUser = await Auth.sendCustomChallengeAnswer(this.cognitoUser, answer, clientMetadata);

		console.log('answerCustomChallenge cognitoUser: ', this.cognitoUser);

		return this.isAuthenticated();
	}

	public async getJwt(): Promise<string> {
		if (AuthService._isUsingHybridStorage && !Cookies.getCookie(Cookies.REFRESH_TOKEN_VALUE)) {
			throw new Error('No refresh token found');
		}

		const session = await Auth.currentSession();
		return session.getIdToken().getJwtToken();
	}

	async signInIdp(idpName?: string) {
		await Auth.federatedSignIn(idpName ? { customProvider: idpName } : undefined);
	}
	public async signOut(isSoftLogout: boolean) {
		try {
			this.resetShouldUseCognitoFlow();
			if (isSoftLogout) {
				await this.softSignout();
			} else {
				await Auth.signOut();
			}
		} catch (error) {
			console.log('error signing out: ', error);
		}
	}

	public async isAuthenticated() {
		try {
			if (AuthService._isUsingHybridStorage && !Cookies.getCookie(Cookies.REFRESH_TOKEN_VALUE)) {
				return false;
			}

			await Auth.currentSession();
			return true;
		} catch {
			return false;
		}
	}

	public async getUserDetails() {
		if (!this.cognitoUser) {
			this.cognitoUser = await Auth.currentAuthenticatedUser();
		}
		return await Auth.userAttributes(this.cognitoUser);
	}

	public async getUserEmail(): Promise<string> {
		return await this.getUserAttribute('email');
	}
	public async getUserPhoneNumber(): Promise<string> {
		return await this.getUserAttribute('phone_number');
	}

	private async getUserAttribute(attributeName: string) {
		const promise = await this.getUserDetails().then((attribute) =>
			attribute.filter((e) => e.Name === attributeName).map((e) => e.getValue())
		);

		return promise.length > 0 ? promise[0] : null;
	}

	async userHasVerifiedPhone() {
		return (await this.getUserAttribute('phone_number_verified')) == 'true';
	}

	async isOtpSmsActivated() {
		return (await this.getUserAttribute('custom:default_auth_method')) == 'sms';
	}

	public async updateUserPhone(phone: string) {
		if (phone === (await this.getUserPhoneNumber())) {
			throw new AttributeAlreadyLatest('phone_number');
		}

		return await this.updateUserAttribute('phone_number', phone);
	}

	public async updateUserDefaultMfaMethod(mfaMethod: MFAMethods) {
		return await this.updateUserAttribute('custom:default_auth_method', mfaMethod);
	}
	public async changePhoneNumberAnswer(answer: string): Promise<boolean> {
		const result = await Auth.verifyCurrentUserAttributeSubmit(
			'phone_number', // The attribute name
			answer
		);

		return result === 'SUCCESS';
	}

	private resetShouldUseCognitoFlow() {
		AuthService.NEW_SIGNING_FLOW = undefined;
		this.newSigningFlowForEmail = undefined;
	}

	public async forceRefreshToken() {
		return await Auth.currentAuthenticatedUser({ bypassCache: true });
	}

	public async softSignout() {
		const localStorageItems = { ...localStorage };
		for (const key in localStorageItems) {
			if (key.startsWith('CognitoIdentityServiceProvider')) {
				localStorage.removeItem(key);
			}
		}

		this.storageService.removeUserData();
	}

	private static subDomainConfig(appConfig: EnvironmentConfigurationInterface) {
		for (const clientsKey in appConfig.cognito.clients) {
			if (document.location.hostname.startsWith(appConfig.cognito.clients[clientsKey].appSubDomain)) {
				return appConfig.cognito.clients[clientsKey];
			}
		}

		return undefined;
	}

	private static idpConfig(appConfig: EnvironmentConfigurationInterface) {
		const idp = this.getIdpQueryParam();
		if (idp && appConfig.cognito.clients[idp]) {
			return appConfig.cognito.clients[idp];
		}

		return undefined;
	}

	private static poolIdFromQueryParam() {
		const searchParams = new URLSearchParams(document.location.search);
		return searchParams.get('poolId');
	}

	private static clientIdFromQueryParam() {
		const searchParams = new URLSearchParams(document.location.search);
		return searchParams.get('clientId');
	}

	private static isZocdocRedirectFlow(): boolean {
		const searchParams = new URLSearchParams(document.location.search);
		return searchParams.get(CFG.TAGS.redirectedFromZocdocTag) === 'true';
	}

	private static getIdpQueryParam() {
		const searchParams = new URLSearchParams(document.location.search);
		return searchParams.get('idp') || localStorage.getItem('idp');
	}

	private handleStartOtpFlowError(error: Error) {
		throw new CognitoOtpError(error.message, error);
	}

	private async updateUserAttribute(attributeName: string, attributeValue: string) {
		try {
			const user = await Auth.currentAuthenticatedUser();
			const result = await Auth.updateUserAttributes(user, {
				[attributeName]: attributeValue,
			});
			console.log('updateUserAttribute result: ', result);
		} catch (err) {
			console.log(err);
			throw err;
		}
	}

	private static healtheeIdp() {
		return this.getIdpQueryParam() == 'healthee';
	}

	private static tryLoadStateFromCookies() {
		const lastAuthUserName = Cookies.getCookie(Cookies.LAST_AUTH_USER_TOKEN_NAME);
		const lastAuthUserValue = Cookies.getCookie(Cookies.LAST_AUTH_USER_TOKEN_VALUE);
		const refreshTokenName = Cookies.getCookie(Cookies.REFRESH_TOKEN_NAME);
		const tokenValue = Cookies.getCookie(Cookies.REFRESH_TOKEN_VALUE);

		if (tokenValue && lastAuthUserValue) {
			const idTokenName = refreshTokenName.replace('refreshToken', 'idToken');
			const accessTokenName = refreshTokenName.replace('refreshToken', 'accessToken');
			localStorage.setItem(lastAuthUserName, lastAuthUserValue);
			localStorage.setItem(refreshTokenName, tokenValue);
			localStorage.setItem(idTokenName, 'hacking');
			localStorage.setItem(accessTokenName, 'hacking');
		}
	}

	private static redirectWithRenamedParams() {
		const urlObj = new URL(window.location.href);

		if (!urlObj.searchParams.has(CFG.TAGS.zocdocTempCodeTag))
			return;

		const value = urlObj.searchParams.get(CFG.TAGS.zocdocTempCodeTag);

		urlObj.searchParams.set(CFG.TAGS.zocdocOriginalCodeTag, value);
		urlObj.searchParams.delete(CFG.TAGS.zocdocTempCodeTag);

		console.log("Renamed code query param", window.location.href, urlObj, urlObj.toString());
		window.location.href = urlObj.toString();
	}
}

export class AttributeAlreadyLatest extends AppError {
	constructor(attrName: string) {
		super(`${attrName} attribute is already the latest value`, null, null);
		Object.setPrototypeOf(this, AttributeAlreadyLatest.prototype);
	}
}

export class CognitoOtpError extends AppError {
	constructor(public message: string, public originalError?: Error) {
		super(message, null, originalError);

		Object.setPrototypeOf(this, CognitoOtpError.prototype);
	}
}
