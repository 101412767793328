import { Component, OnInit } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { filter, Observable, of, shareReplay, switchMap, take } from 'rxjs';

import { IntercomService } from '../../../services/intercom.service';
import { HealtheeDialogContent } from '../../app-shared/healthee-dialog/healthee-dialog.component';
import { TelehealthLocalize } from '../telehealth.localize';
import { TelehealthService } from '../services/telehealth.service';
import {
	createHttpProgress,
	HttpProgress,
	HttpRequestProgressStatus,
} from '../../../utils/http-response/http-response';
import { UserService } from 'src/app/services/user.service';

@Component({
	selector: 'app-telehealth-widget',
	templateUrl: './telehealth-widget.component.html',
	styleUrls: ['./telehealth-widget.component.scss'],
})
export class TelehealthWidgetComponent extends HealtheeDialogContent implements OnInit {
	TelehealthLocalize = TelehealthLocalize;
	HttpRequestProgressStatus = HttpRequestProgressStatus;

	urlProgress$: Observable<SafeUrl>;
	reportAnIssueProgress$: HttpProgress<any, any> = of({ type: HttpRequestProgressStatus.Pending });

	constructor(
		private telehealthService: TelehealthService,
		private intercomService: IntercomService,
		private userService: UserService
	) {
		super();
	}
	ngOnInit(): void {
		this.urlProgress$ = this.userService.userData$.pipe(
			take(1),
			switchMap((userData) =>
				createHttpProgress(
					this.telehealthService.createTelehealthUser(userData.uid).pipe(
						filter((userCreated) => userCreated),
						switchMap(() => this.telehealthService.getTelehealthSsoUrl())
					)
				)
			),
			shareReplay(1)
		);
		this.urlProgress$.subscribe();
	}

	onReportAnIssue(errorMessage: string): void {
		this.reportAnIssueProgress$ = createHttpProgress(
			this.intercomService.initiateIntercomConversation(errorMessage, null) // TODO: Figure out why are we sending errors as an intercom message?!
		);
	}
}
