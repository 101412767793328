export interface UserOpenEnrollmentData {
	isActive: boolean;
	userData: {
		status: string;
		spouse: {
			gender: string;
		};
		numOfDependents: number;
		childrenInfos?: any[];
		doctorData?: {
			pcp: string[];
			facilities: string[];
			specialists: string[];
		};
		selectedPlans: {
			medical: any;
			vision: any;
			dental: any;
			hsaPlan?: any;
			hsaPlanFsaAddon?: any;
			fsaPlan?: any;
			fsaDependentCarePlan?: any;
		};
		proceedWithoutProviders?: boolean;
		arePlansWithoutReferralsPreferred?: boolean;
		coverageTierFilters?: {
			medical?: string;
			dental?: string;
			vision?: string;
		};
		isFamilyEnforced?: {
			medical?: string;
			dental?: string;
			vision?: string;
		};
		projectionServicesSelected?: string[];
		pinnedPlanIds?: PinnedPlans;
		isExternalLinkOpened?: boolean;
		isEnrolled?: boolean;
	};
	doctorData?: UserDoctorData[];
}

export interface PinnedPlans {
	medical?: string[];
	dental?: string[];
	vision?: string[];
}

export enum ProviderType {
	PCP = 'pcp',
	SPECIALIST = 'specialist',
	DENTIST = 'dentist',
	OPTOMETRIST = 'optometrist',
	FACILITY = 'facility',
}
export interface UserDoctorData {
	npi: string;
	type: ProviderType;
	isMyDoctor: ProviderVisitsData;
	isSpouseDoctor: ProviderVisitsData;
	isDependentsDoctor: ProviderVisitsData;
	urlKey: string;
	urlId: string;
}
export interface ProviderVisitsData {
	state: boolean;
	visitsNb: number;
}
