import { Component, Input } from '@angular/core';

@Component({
	selector: 'ripple-label',
	templateUrl: './label.component.html',
	styleUrls: ['./label.component.scss'],
})
export class LabelComponent {
	@Input() text: string;
	@Input() colorTheme: LabelColorTheme = LabelColorTheme.default;
	constructor() {}
}

export enum LabelColorTheme {
	default = 'default',
	allGreen = 'all-green',
	allRed = 'all-red',
	darkTextGreenBg = 'dark-text green-bg',
	darkTextRedBg = 'dark-text red-bg',
	allYellow = 'all-yellow',
	darkTextYellowBg = 'dark-text yellow-bg',
	allBlue = 'all-blue',
}
