import { T } from '@transifex/angular';

export class UserMenuLocalize {
	@T('Language')
	static language: string;

	@T('Change Password')
	static changePass: string;

	@T('Security')
	static security: string;

	@T('Activate verification via sms')
	static verifyPhone: string;

	@T('Change phone number')
	static changePhone: string;

	@T('Email / Text notifications')
	static toggleNotifications: string;

	@T('Contact Us')
	static contactUs: string;

	@T('Privacy policy')
	static privacyPolicy: string;

	@T('Logout')
	static logout: string;
}
