<app-providers-search-bar
	*ngIf="isEmptyStateEnabled !== null"
	[showFilters]="false"
	[appearance]="isEmptyStateEnabled ? 'partial' : 'full'"
	[showEmptyState]="isEmptyStateEnabled"
	(searchEvent)="onSearch($event)"
	[selectedTopSearch]="selectedService"
	(selectTopRatedProviderEvent)="setServiceForEmptyState($event)"
	(applyFiltersEvent)="setFilters($event)"
	[resetFiltersToggle]="resetFiltersToggle"
	[isResultPage]="false"
	[startMobileCollapsed]="isEmptyStateEnabled"
	[(showMobileMapView)]="showMobileMap"
></app-providers-search-bar>
<div class="main-container">
	<app-provider-search-results
		*ngIf="isEmptyStateEnabled"
		(outOfNetworkSearch)="onSearch($event)"
		(resetFiltersEvent)="resetFiltersHandler()"
		(setTopServiceSelected)="setServiceForEmptyState($event)"
		[showMobileMapView]="showMobileMap"
	></app-provider-search-results>

	<ng-container *ngIf="!isEmptyStateEnabled">
		<div class="content">
			<app-top-searched-specialties (setTopServiceSelected)="setService($event)"></app-top-searched-specialties>
		</div>

		<div class="side-panel">
			<app-provider-appointments class="left"></app-provider-appointments>
		</div>
	</ng-container>
</div>
