<div class="header-provider">
	<provider-avatar [gender]="providerData.gender" size="medium"></provider-avatar>
	<div class="info">
		<div class="title">
			{{ providerData.firstName }} {{ providerData.lastName
			}}<span *ngFor="let degree of providerData.degrees">, {{ degree }}</span>
		</div>
		<div class="sub-title">
			<span *ngFor="let specialty of providerData?.specialties">{{ specialty }}</span>
		</div>
	</div>
</div>

<div class="divider"></div>

<div [formGroup]="availabilityForm" class="form">
	<label for="location">{{ 'Available locations' | translate }} ({{ zocdocData.data.locations.length }})</label>
	<ng-container [ngSwitch]="zocdocData.data.locations.length > 1">
		<ng-container *ngSwitchCase="true">
			<div class="input-with-label">
				<app-select-form-control
					[placeholderText]="'Select location' | translate"
					floatLabel="never"
					valueField="locationId"
					[items]="zocdocData.data.locations"
					[multi]="false"
					formControlName="providerLocationId"
					isFullWidth="true"
					markIfValid="true"
					expandHeightToContent="true"
				>
					<ng-template #item let-item>
						<div class="select-content">
							<p *ngIf="!!item.name">{{ item.name }}</p>
							<p>
								{{ item.address1 }}{{ item.address2 ? ', ' + item.address2 : '' }}, {{ item.city }},
								{{ item.state }} {{ item.zipCode }}
							</p>
						</div>
					</ng-template>
				</app-select-form-control>
			</div>
		</ng-container>
		<ng-container *ngSwitchCase="false">
			<div class="adress-uniq">
				{{ zocdocData.data.locations[0]?.address1
				}}{{ zocdocData.data.locations[0]?.address2 ? ', ' + zocdocData.data.locations[0]?.address2 : '' }},
				{{ zocdocData.data.locations[0]?.city }}, {{ zocdocData.data.locations[0]?.state }}
				{{ zocdocData.data.locations[0]?.zipCode }}
			</div>
		</ng-container>
	</ng-container>

	<div class="input-with-label">
		<label for="reason">{{ "What's the reason for your visit?" | translate }}</label>
		<app-select-form-control
			[placeholderText]="'Select reason' | translate"
			floatLabel="never"
			valueField="visitReasonId"
			displayField="name"
			[items]="zocdocData.reasons"
			[multi]="false"
			formControlName="visitReasonId"
			isFullWidth="true"
			markIfValid="true"
		>
		</app-select-form-control>
	</div>

	<div class="input-with-label">
		<label for="firstVisit">{{ 'Have you seen this doctor before?' | translate }}</label>
		<app-healthee-radio-group
			[buttons]="existingPatientAnswer"
			(valueChanged)="onExistingPatientChange($event)"
		></app-healthee-radio-group>
	</div>
</div>

<app-loader *ngIf="showLoader" size="medium"></app-loader>
<app-appointment-slots-available
	[availableSlots]="availableSlots"
	(setSelectedSlot)="setSelectedSlot.emit($event)"
	*ngIf="showSlots"
></app-appointment-slots-available>
