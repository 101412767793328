import { Component, Input, TemplateRef } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';

@Component({
	selector: 'ripple-tooltip-bubble',
	templateUrl: './tooltip-bubble.component.html',
	styleUrls: ['./tooltip-bubble.component.scss'],
})
export class TooltipBubbleComponent {
	@Input() isDynamicWidth = false;

	tooltip: TemplateRef<any>;
	overlayRef: OverlayRef;
	autoHide: boolean;

	public hideTooltip() {
		if (this.overlayRef) {
			this.overlayRef.detach();
		}
	}
}
