import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppointmentFetch } from 'src/app/models/appointment.model';
import { ProvidersAppointmentService } from 'src/app/services/providers-search/providers-appointment.service';

@Component({
	selector: 'app-provider-appointments',
	templateUrl: './provider-appointments.component.html',
	styleUrls: ['./provider-appointments.component.scss'],
})
export class ProviderAppointmentsComponent implements OnInit {
	public activeAppointments$: Observable<AppointmentFetch[]>;
	refreshAppointments$ = new BehaviorSubject<boolean>(null);

	constructor(private providersAppointmentService: ProvidersAppointmentService) {}

	ngOnInit(): void {
		this.getAppointments();
	}

	private getAppointments() {
		this.activeAppointments$ = this.providersAppointmentService.getAppointments();
	}

	appointmentUpdated() {
		this.refreshAppointments$.next(null);
	}
}
