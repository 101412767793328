<div *ngIf="servicesWithNetworkData.length > 0" [class.border]="hasBorder" class="coverage-container">
	<ng-container *ngFor="let service of servicesWithNetworkData;">
		<div>
			<div class="title">
				<div>
					{{ 'Coverage info for a ' | translate }} {{ service.name | lowercase }}
					{{ 'office visit' | translate }}
				</div>
				<button class="bt-link" (click)="onMoreInfo(service)">{{ 'More info' | translate }}</button>
			</div>
			<div class="wrapper">
				<img src="/assets/icons/icon-wallet-coverage.svg" alt="coverage" />
				<div>
					<div class="network-data">{{ networkData[service.id] | translate }}</div>
					<div *ngIf="isSubjectToDeductible[service.id]" class="deductible-data">
						{{ 'Only after meeting your deductible' | translate }}
					</div>
				</div>
				<app-healthee-mark-tooltip
					*ngIf="tooltipText[service.id]"
					(mouseenter)="sendTooltipEvent()"
					(click)="sendTooltipEvent()"
					[text]="tooltipText[service.id]"
				>
				</app-healthee-mark-tooltip>
			</div>
			<div *ngIf="(networkStructure$ | async) as networkStructure">
				<div *ngIf="networkStructure === NetworkStructure.RBP && !inNetwork">
					<div class="wrapper rbp">
						<ripple-icon [size]="ICON_SIZE.size40" name="medicalReport"></ripple-icon>
						<div>
							<div class="deductible-data">
								{{ 'Please note that not all providers accept RBP rates, so you may get a balance bill if you use a non-participating provider.' | translate }}
							</div>
						</div>
					</div>
					<div *ngIf="(isCharterSchool$ | async) === true" class="wrapper rbp">
						<ripple-icon [size]="ICON_SIZE.size40" name="stethoscope"></ripple-icon>
						<div>
							<div class="deductible-data">
								{{ 'If you can’t find the provider you’re looking for, please search directly on the ' | translate }}
								<a
									href="https://hstconnect.com/phcs"
									target="_blank"
									rel="HST Connect Portal"
									>{{ 'HST Connect Portal' | translate }}</a>
							</div>
						</div>
					</div>
				</div>
				<div *ngIf="networkStructure === NetworkStructure.INDEMNITY" class="wrapper mn">
					<ripple-icon [size]="ICON_SIZE.size40" name="medicalReport"></ripple-icon>
					<div>
						<div class="deductible-data">
							{{ 'Note: The plan pays for costs up to the UCR price and will refund you based on this amount once you file a claim.' | translate }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
</div>

<app-drawer [isVisible]="showBenefitDrawer" (isVisibleChange)="closeServicePanel()">
	<ng-template #content>
		<app-service-panel
			[serviceId]="serviceIdForDrawer$ | async"
			[hideFindProvidersButton]="true"
			(findProviderClick)="findProvider(serviceIdForDrawer$.value)"
			(closeClick)="closeServicePanel()"
			(relatedBenefitClick)="setServiceIdForDrawer($event)"
		></app-service-panel>
	</ng-template>
</app-drawer>
