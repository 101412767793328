import { Injectable } from '@angular/core';
import { UnleashClient } from 'unleash-proxy-client';
import { from, map, Observable } from 'rxjs';
import { isProduction, resolveUnleashHostName, timeoutReturnFalse } from '../utils/utils';
import CFG from '../config/app-config.json';
import { captureExceptionSentry } from '../utils/sentry.utils';

const TAG = 'UnleashService';
const UNLEASH_TIMEOUT_THRESHOLD_MS = 5000;
@Injectable({
	providedIn: 'root',
})
export class UnleashService {
	private timeOutMs = UNLEASH_TIMEOUT_THRESHOLD_MS;
	private unleash: UnleashClient;
	private _isReadyPromise: Promise<boolean> = null;
	private isReady: boolean = false;

	get isReady$(): Observable<boolean> {
		return from(this._isReadyPromise);
	}

	constructor() {
		try {
			this.unleash = new UnleashClient({
				url: resolveUnleashHostName(),
				clientKey: CFG.unleash.unleashClientKey,
				appName: CFG.unleash.unleashAppName,
				refreshInterval: CFG.unleash.refreshInterval,
			});
			this.isReady = false;
			this._isReadyPromise = this.unleash
				.start()
				.then(() => {
					this.isReady = true;
					return true;
				})
				.catch((err) => {
					return this.handleUnleashError(err);
				});
		} catch (err) {
			captureExceptionSentry(err, TAG);
		}
	}

	public start(): Promise<boolean> {
		return this._isReadyPromise;
	}

	public updateContext(context) {
		this.isReady = false;
		this._isReadyPromise = this.unleash
			.updateContext(context)
			.then(() => {
				this.isReady = true;
				return true;
			})
			.catch((err) => {
				return this.handleUnleashError(err);
			});
		const timeoutPromise = timeoutReturnFalse(this.timeOutMs);

		return Promise.race([this._isReadyPromise, timeoutPromise]);
	}

	public getUnleash() {
		return this.unleash;
	}

	public isEnabled(flag: string): boolean {
		if (!isProduction()) console.log(flag);
		if (!this.isReady || !this.unleash) return this.handleUnleashError('Unleash is not ready');
		return this.unleash.isEnabled(flag) || false;
	}

	public isEnabled$(flag: string): Observable<boolean> {
		return this.isReady$.pipe(map((isReady) => isReady && this.isEnabled(flag)));
	}

	public handleUnleashError(err) {
		if (!isProduction()) throw err;
		captureExceptionSentry(err, TAG);
		console.error(err);
		return false;
	}
}
