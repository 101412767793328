import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TxNativeModule } from '@transifex/angular';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatRadioModule } from '@angular/material/radio';
import { NgxFilesizeModule } from 'ngx-filesize';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { TooltipModule } from './standalone/tooltip/tooltip.module';

import { IonicModule } from '@ionic/angular';

import { SecurePipe } from '../../pipes/secure.pipe';
import {
	ServiceImagePipe,
	AdditionalBenefitImagePipe,
	CompanyImagePipe,
	AdditionalBenefitBrochure,
} from '../../pipes/assetsImage.pipe';
import { FilterStringsArrayPipe } from '../../pipes/filter-strings-array.pipe';
import { FilterArrayByPropertyValue } from '../../pipes/filter-array-by-prop-value.pipe';
import { FilterOutArrayByPropertyValue } from '../../pipes/filter-out-array-by-prop-value.pipe';
import { FilterBenefitsByProperty } from '../../pipes/filter-benefits-by-property.pipe';
import { DisplayTypeAndAmount } from '../../pipes/display-type-amount.pipe';
import { IsShowImportantToKnow } from '../../pipes/show-important-to-know.pipe';

import { DynamicCmpDirective } from '../../directives/dynamic-cmp.directive';

import { OnboardingLayoutComponent } from './onboarding-layout/onboarding-layout.component';
import { LoaderComponent } from './loader/loader.component';
import { ZoeFaceComponent } from './zoe-face/zoe-face.component';
import { HealtheeRadioGroupComponent } from './healthee-radio-group/healthee-radio-group.component';
import { StepSliderComponent } from './step-slider/step-slider.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { StepperComponent } from './stepper/stepper.component';
import { TwoFAInputComponent } from './twofa-input/twofa-input.component';
import { HealtheeDialogComponent } from './healthee-dialog/healthee-dialog.component';
import { AddressBarDialogComponent } from './address-bar-dialog/address-bar-dialog.component';
import { PlanSelectionButtonComponent } from './plan-selection-button/plan-selection-button.component';

import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';
import { LocaleSelectComponent } from './locale-select/locale-select.component';
import { TabsContainerComponent } from './tabs-container/tabs-container.component';

import { RibbonHealthCardListComponent } from '../main-layout/ask-zoe/ribbon-health/ribbon-health-card-list/ribbon-health-card-list.component';
import { RibbonHealthProvidersBrowserComponent } from '../main-layout/ask-zoe/ribbon-health/ribbon-health-providers-browser/ribbon-health-providers-browser.component';
import { RibbonHealthCardMobileComponent } from '../main-layout/ask-zoe/ribbon-health/ribbon-health-card-list/ribbon-health-card-mobile/ribbon-health-card-mobile.component';
import { RatingAndCostComponent } from '../main-layout/ask-zoe/ribbon-health/ribbon-health-card-list/rating-and-cost/rating-and-cost.component';
import { InOutNetworkStatusComponent } from '../main-layout/ask-zoe/ribbon-health/ribbon-health-card-list/in-out-network-status/in-out-network-status.component';
import { HealtheeAutocompleteSelectComponent } from './healthee-autocomplete-select/healthee-autocomplete-select.component';
import { GooglePlacesAutocompleteComponent } from './google-places-autocomplete/google-places-autocomplete.component';
import { ChangeLanguageComponent } from '../account/change-language/change-language.component';
import { HealtheeMarkTooltipComponent } from './healthee-mark-tooltip/healthee-mark-tooltip.component';
import { TooltipWithTouchModule } from './standalone/tooltip-with-touch/tooltip-with-touch.module';
import { PremiumFeatureModule } from '../premium-feature/premium-feature.module';
import { HealtheeContentSliderModule } from './healthee-content-slider/healthee-content-slider.module';
import { PhoneInputModule } from './standalone/phone-input/phone-input.module';
import { RippleModule } from 'ripple';
import { NgxGooglePlacesAutocompleteModule } from '../standalone/ngx-google-places-autocomplete/ngx-google-places-autocomplete.module';
import { DeductibleSyncComponent } from './deductible-sync/deductible-sync.component';
import { FloorPipe } from '../../pipes/floor.pipe';
import { PreventiveCareProgressComponent } from '../preventive-care/preventive-care-progress/preventive-care-progress.component';
import { CompletedCountPipe } from '../preventive-care/pipes/completed-count.pipe';
import { PreventiveCareTeaserComponent } from '../home-page/teasers/preventive-care-teaser/preventive-care-teaser.component';
import { ApproximateProvidersCountPipe } from '../../pipes/approximate-providers-count.pipe';
import { AgeFromBirthDatePipe } from '../../pipes/age-from-birth-date.pipe';
import { SafeLineBreaksPipe } from '../../pipes/safe-line-breaks.pipe';

import { QuantumSupportComponent } from './quantum-support/quantum-support.component';
import { AppVersionLabelComponent } from './app-version-label/app-version-label.component';
import { MobileDeveloperMenuComponent } from './mobile-developer-menu/mobile-developer-menu.component';
import { MobileDeveloperDebugLogComponent } from './mobile-developer-debug-log/mobile-developer-debug-log.component';

@NgModule({
	declarations: [
		// Pipes
		SecurePipe,
		ServiceImagePipe,
		AdditionalBenefitImagePipe,
		AdditionalBenefitBrochure,
		CompanyImagePipe,
		FilterStringsArrayPipe,
		FilterArrayByPropertyValue,
		FilterOutArrayByPropertyValue,
		FilterBenefitsByProperty,
		DisplayTypeAndAmount,
		IsShowImportantToKnow,
		FloorPipe,
		CompletedCountPipe,
		ApproximateProvidersCountPipe,
		AgeFromBirthDatePipe,
		SafeLineBreaksPipe,

		// Directives
		DynamicCmpDirective,

		// Components
		OnboardingLayoutComponent,
		LoaderComponent,
		ZoeFaceComponent,
		HealtheeRadioGroupComponent,
		StepSliderComponent,
		SetPasswordComponent,
		StepperComponent,
		TwoFAInputComponent,
		HealtheeDialogComponent,
		PlanSelectionButtonComponent,

		LoadingIndicatorComponent,
		LocaleSelectComponent,
		TabsContainerComponent,
		AddressBarDialogComponent,
		ChangeLanguageComponent,
		HealtheeMarkTooltipComponent,
		DeductibleSyncComponent,
		AppVersionLabelComponent,
		MobileDeveloperMenuComponent,
		MobileDeveloperDebugLogComponent,

		// Ribbon
		RibbonHealthCardListComponent,
		RibbonHealthProvidersBrowserComponent,
		RibbonHealthCardMobileComponent,
		RatingAndCostComponent,
		InOutNetworkStatusComponent,
		HealtheeAutocompleteSelectComponent,
		GooglePlacesAutocompleteComponent,
		PreventiveCareProgressComponent,
		PreventiveCareTeaserComponent,
		QuantumSupportComponent,
	],
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
		MatDividerModule,
		MatBottomSheetModule,
		MatRadioModule,
		IonicModule,
		TxNativeModule,
		NgxFilesizeModule,
		GoogleMapsModule,
		// GooglePlaceModule,
		MatAutocompleteModule,
		MatButtonToggleModule,
		MatTooltipModule,
		TxNativeModule,
		TooltipModule,
		MatChipsModule,
		TooltipWithTouchModule,
		PremiumFeatureModule,
		HealtheeContentSliderModule,
		PhoneInputModule,
		RippleModule,
		NgxGooglePlacesAutocompleteModule,
	],
	exports: [
		// Pipes
		SecurePipe,
		ServiceImagePipe,
		AdditionalBenefitImagePipe,
		AdditionalBenefitBrochure,
		FilterStringsArrayPipe,
		FilterArrayByPropertyValue,
		FilterOutArrayByPropertyValue,
		FilterBenefitsByProperty,
		DisplayTypeAndAmount,
		IsShowImportantToKnow,
		TabsContainerComponent,
		PlanSelectionButtonComponent,
		FloorPipe,
		CompletedCountPipe,
		ApproximateProvidersCountPipe,
		AgeFromBirthDatePipe,
		SafeLineBreaksPipe,

		// Components
		LocaleSelectComponent,
		OnboardingLayoutComponent,
		LoaderComponent,
		LoadingIndicatorComponent,
		ZoeFaceComponent,
		HealtheeRadioGroupComponent,
		StepSliderComponent,
		SetPasswordComponent,
		StepperComponent,
		TwoFAInputComponent,
		HealtheeDialogComponent,
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
		MatRadioModule,
		AddressBarDialogComponent,
		RibbonHealthCardListComponent,
		RibbonHealthProvidersBrowserComponent,
		RibbonHealthCardMobileComponent,
		RatingAndCostComponent,
		InOutNetworkStatusComponent,
		HealtheeAutocompleteSelectComponent,
		GooglePlacesAutocompleteComponent,
		HealtheeMarkTooltipComponent,
		MatTooltipModule,
		TooltipModule,
		MatChipsModule,
		TooltipWithTouchModule,
		PremiumFeatureModule,
		HealtheeContentSliderModule,
		PhoneInputModule,
		DeductibleSyncComponent,
		PreventiveCareProgressComponent,
		PreventiveCareTeaserComponent,
		QuantumSupportComponent,
		AppVersionLabelComponent,
		MobileDeveloperMenuComponent,
		CompanyImagePipe,
	],
	// entryComponents: [HealtheeDialogComponent],
	providers: [LoadingIndicatorComponent, TabsContainerComponent],
})
export class AppSharedModule {}
