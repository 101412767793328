<div class="service-header" [class.service-header-shrink]="shrinked">
	<div class="service-header-image">
		<div [ngStyle]="{ 'background-image': 'url(/assets/images/default-service-image-large.svg)' }"></div>
		<div
			[ngStyle]="{
				'background-image': 'url(' + bgImage + ')'
			}"
		></div>
	</div>
	<div class="service-header-content">
		<div class="service-header-close-and-label">
			<button (click)="closeClick.emit()" class="service-header-content-back-button close-button" mat-icon-button>
				<mat-icon>close</mat-icon>
			</button>
			<button (click)="closeClick.emit()" class="service-header-content-back-button arrow-back" mat-icon-button>
				<mat-icon>arrow_back</mat-icon>
			</button>
			<div class="coverage-badge">
				<span *ngIf="serviceCoverage === AllServiceCoverages.covered">
					<ripple-label
						text="{{ 'Covered' | translate }}"
						[colorTheme]="LabelColorTheme.allGreen"
					></ripple-label>
				</span>
				<span *ngIf="serviceCoverage === AllServiceCoverages.not_covered">
					<ripple-label
						text="{{ 'Not Covered' | translate }}"
						[colorTheme]="LabelColorTheme.allRed"
					></ripple-label>
				</span>
				<span *ngIf="serviceCoverage === AllServiceCoverages.not_categorized">
					<ripple-label
						text="{{ 'Not Categorized' | translate }}"
						[colorTheme]="LabelColorTheme.default"
					></ripple-label>
				</span>
				<span *ngIf="serviceCoverage === AllServiceCoverages.covered_by_healthee">
					<ripple-label
						text="{{ 'Covered by Healthee' | translate }}"
						[colorTheme]="LabelColorTheme.allBlue"
					></ripple-label>
				</span>
			</div>
		</div>

		<div class="service-header-content-title">
			{{ title }}
		</div>
	</div>
</div>
