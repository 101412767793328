<div *ngIf="providerData" class="card-details-container" (scroll)="manuallySetActiveTab()">
	<div class="details-header">
		<button class="back-btn" (click)="goBack()">
			<img class="back-icon-desktop" src="/assets/images/icons/back-btn.svg" alt="back" />
			<img class="back-icon-mobile" src="/assets/images/icons/mobile-back-btn.svg" alt="back" />
		</button>
		<app-provider-card
			appearance="standard"
			[providerData]="providerData"
			[isFavorite]="isFavorite"
			(isFavoriteChange)="isFavoriteChange.emit($event)"
			(openScheduleAppointment)="openCreateAppointment(providerData)"
			eventSource="Detail Page"
		>
		</app-provider-card>
	</div>

	<div class="content">
		<div class="details-infos">
			<div class="provider-infos">
				<ng-container class="provider-card-tabs-container">
					<div class="tab-container">
						<nav
							mat-tab-nav-bar
							[tabPanel]="tabPanel"
							disableRipple
							class="tabs"
							[attr.mat-align-tabs]="(isMobile$ | async) ? 'center' : 'start'"
							[disablePagination]="true"
						>
							<a
								mat-tab-link
								*ngFor="let tab of tabs"
								(click)="scrollTo(tab)"
								[active]="activeTab === tab"
							>
								{{ tab | translate }}
								<span *ngIf="tab === locationsTabName">({{ locations?.length }})</span>
								<span *ngIf="tab === reviewsTabName">({{ providerData.reviews?.length }})</span>
							</a>
						</nav>
					</div>

					<mat-tab-nav-panel #tabPanel>
						<app-provider-about
							[id]="tabs[0]"
							[providerData]="providerData"
							class="tab-panel"
						></app-provider-about>
						<app-provider-locations
							*ngIf="locations?.length"
							[id]="tabs[1]"
							[locations]="locations"
							class="tab-panel"
						></app-provider-locations>
						<app-provider-reviews
							*ngIf="providerData.reviews?.length"
							[id]="tabs[2]"
							[providerData]="providerData"
							class="tab-panel"
						></app-provider-reviews>
					</mat-tab-nav-panel>
				</ng-container>
			</div>
			<div class="panel-right">
				<app-coverage-info [inNetworkChange]="true" [hasBorder]="true"> </app-coverage-info>
			</div>
		</div>
	</div>
</div>

<app-loading-indicator *ngIf="!providerData"></app-loading-indicator>

<app-drawer [isVisible]="showAppointmentDrawer" (isVisibleChange)="showAppointmentDrawer = false">
	<ng-template #content>
		<app-provider-appointment
			(closeSignal)="showAppointmentDrawer = false"
			[providerData]="selectedProvider"
			class="provider-appointment-component"
		></app-provider-appointment>
	</ng-template>
</app-drawer>
