import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	TemplateRef,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { T } from '@transifex/angular';
import { Subscription, take } from 'rxjs';
import { AppointmentFetch, AppointmentStatuses } from '../../../../../../models/appointment.model';
import { ZocdocLocation, ZocdocProvider } from '../../../../../../models/zocdoc-data.model';
import { HealtheeDialogData } from '../../../../../app-shared/healthee-dialog/healthee-dialog.component';
import { AppointmentService } from '../../../../../../services/appointment.service';
import { HealtheeDialogService } from '../../../../../../services/healthee-dialog.service';
import { TrackingService } from '../../../../../../services/tracking.service';
import { CancelAppointmentModalComponent } from '../cancel-appointment-modal/cancel-appointment-modal.component';
import { Router } from '@angular/router';
import { RescheduleDisclaimerComponent } from '../../provider-appointment/appointment-reschedule/reschedule-disclaimer/reschedule-disclaimer.component';
import { AppointmentsListStoreService } from 'src/app/services/stores/appointments-list-store/appointments-list-store.service';
import {
	RemoveAppointmentEvents,
	RescheduleAppointmentEvents,
	ScheduleAppointmentEvents
} from 'src/app/models/tracking-events.model';
import { ProvidersSearchUrlsService } from 'src/app/services/providers-search/providers-search-urls.service';
import { AppointmentDrawerService } from '../../../../../../services/appointment-drawer.service';

const ASSETS_PATH = '/assets/images/provider-card';

export interface DropDownMenu {
	name: string;
	label: string;
	func: () => void;
	icon?: string;
	disabled?: boolean;
	hidden?: boolean;
}

@Component({
	selector: 'app-appointment-card',
	templateUrl: './appointment-card.component.html',
	styleUrls: ['./appointment-card.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class AppointmentCardComponent implements OnInit {
	@ViewChild('disclaimerFooter')
	disclaimerFooter: TemplateRef<any>;

	@Input()
	appointment: AppointmentFetch;

	@Output()
	appointmentUpdated = new EventEmitter<AppointmentFetch>();

	public assetsPath: string = ASSETS_PATH;
	public statuses = AppointmentStatuses;
	public providerData: ZocdocProvider;
	public isProcessing = false;
	public showAppointmentDrawer = false;
	public selectedLocation: ZocdocLocation;
	public displayLocation: string;

	@T('Directions')
	directions: string;

	@T('About provider')
	about: string;

	@T('Reschedule appointment')
	reschedule: string;

	@T('Cancel appointment')
	cancel: string;

	public actionMenu: DropDownMenu[] = [
		{
			name: 'directions',
			label: '',
			icon: 'map',
			func: () => this.openGoogleMapsDirections(),
		},
		{
			name: 'about',
			label: '',
			icon: 'user',
			func: () => this.navigateToProviderProfile(),
		},
		{
			name: 'reschedule',
			label: '',
			icon: 'calendar',
			func: () => this.onClickReschedule(),
		},
		{
			name: 'cancel',
			label: '',
			icon: 'closeCircle',
			func: () => this.onClickCancel(),
		},
	];
	private drawerSubscription: Subscription;

	constructor(
		private appointmentService: AppointmentService,
		private appointmentsListStoreService: AppointmentsListStoreService,
		private dialogService: HealtheeDialogService,
		private router: Router,
		private trackingService: TrackingService,
		private providersSearchUrlsService: ProvidersSearchUrlsService,
		private appointmentDrawerService: AppointmentDrawerService
	) {
		this.drawerSubscription = this.appointmentDrawerService.drawerState$.subscribe(open => {
			this.showAppointmentDrawer = open;
		});
	}

	ngOnDestroy() {
		this.drawerSubscription.unsubscribe();
	}


	ngOnInit(): void {
		this.isProcessing = true;
		this.populateTranslatedItems();
		this.getProviderData();
		this.cancelFailedBookingAfterTwoWeeks();
	}

	private populateTranslatedItems() {
		this.actionMenu[0].label = this.directions;
		this.actionMenu[1].label = this.about;
		this.actionMenu[2].label = this.reschedule;
		this.actionMenu[3].label = this.cancel;
	}

	private getProviderData() {
		this.appointmentService
			.getProviderInfosForScheduling(this.appointment.npi)
			.pipe(take(1))
			.subscribe((provider) => {
				this.providerData = provider.data;
				this.getSelectedLocation();
				this.isProcessing = false;
			});
	}

	private onClickCancel() {
		const options: HealtheeDialogData = {
			component: CancelAppointmentModalComponent,
			noPadding: true,
			hasCloseButton: true,
			data: this.appointment,
		};

		return this.dialogService.open(options);
	}

	private onClickReschedule() {
		const options: HealtheeDialogData = {
			title: '',
			component: RescheduleDisclaimerComponent,
			noPadding: true,
			hasCloseButton: true,
			actionsTemplateRef: this.disclaimerFooter,
		};

		return this.dialogService.open(options);
	}

	public onClickRescheduleClose() {
		this.trackingService.trackClientEvent(RescheduleAppointmentEvents.ReschedulingFlowStopped);
		this.dialogService.close();
	}

	public onClickRescheduleContinue() {
		this.dialogService.close();
		this.trackingService.trackClientEvent(RescheduleAppointmentEvents.ReschedulingFormOpened);
		this.showAppointmentDrawer = true;
	}

	private getSelectedLocation() {
		this.selectedLocation = this.providerData.locations.find(
			(location) => location.locationId === this.appointment.locationId
		);
		this.displayLocation = this.selectedLocation.locationName
			? [this.selectedLocation.locationName, this.selectedLocation.address1].join(', ')
			: this.selectedLocation.address1;
	}

	public navigateToProviderProfile(): void {
		this.router.navigate([this.providersSearchUrlsService.specialistUrl, this.appointment.npi]);
	}

	public openGoogleMapsDirections(): void {
		const address =
			this.selectedLocation.locationName +
			' ' +
			this.selectedLocation.address1 +
			', ' +
			this.selectedLocation.city +
			', ' +
			this.selectedLocation.state +
			' ' +
			this.selectedLocation.zipCode +
			', US';

		window.open(`https://www.google.com/maps/dir//${encodeURIComponent(address)}`, '_blank');
	}

	public onSchedulingModalClose() {
		this.appointmentUpdated.emit();
		this.showAppointmentDrawer = false;
	}

	public onClickBookAgain() {
		this.showAppointmentDrawer = true;
		this.trackingService.trackClientEvent(ScheduleAppointmentEvents.SchedulingAfterFailedBooking);
	}

	public onClickRemove() {
		this.appointmentsListStoreService.cancelFailedBooking(this.appointment);
		this.trackingService.trackClientEvent(RemoveAppointmentEvents.RemovingFailedBooking);
		this.appointmentUpdated.emit();
	}

	public cancelFailedBookingAfterTwoWeeks() {
		if (this.appointment.status === this.statuses.failed) {
			const appointmentUpdatedDate = new Date(this.appointment.updatedAt).getDate();
			const todayDate = new Date().getDate();
			const millisecondsInDay = 1000 * 60 * 60 * 24;
			const diffInDays = (todayDate - appointmentUpdatedDate) / millisecondsInDay;
			if (diffInDays > 14) this.onClickRemove();
		}
	}
}
