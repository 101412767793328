<div *ngIf="providerData" class="card-details-container mpi-mode">
	<div class="details-header">
		<button class="back-btn" (click)="goBack()">
			<img class="back-icon-desktop" src="/assets/images/icons/back-btn.svg" alt="back" />
			<img class="back-icon-mobile" src="/assets/images/icons/mobile-back-btn.svg" alt="back" />
		</button>
		<app-provider-card
			appearance="standard"
			[providerData]="providerData"
			[isFavorite]="isFavorite"
			(isFavoriteChange)="isFavoriteChange.emit($event)"
			eventSource="Detail Page"
		>
		</app-provider-card>
	</div>

	<div class="content">
		<div class="details-infos">
			<div class="provider-infos">
				<ng-container class="provider-card-tabs-container">
					<mat-tab-nav-panel #tabPanel>
						<app-provider-about [providerData]="providerData" class="tab-panel"></app-provider-about>
						<app-provider-locations
							*ngIf="locations?.length"
							[locations]="locations"
							class="tab-panel"
						></app-provider-locations>
					</mat-tab-nav-panel>
				</ng-container>
			</div>
			<div class="panel-right"></div>
		</div>
	</div>
</div>

<app-loading-indicator *ngIf="!providerData"></app-loading-indicator>
