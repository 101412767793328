import { Component, Input, EventEmitter } from '@angular/core';

import { telehealthServiceIds } from '../../../../services/telehealth-data.service';
import { CommonComponent } from '../../../../utils/components/common-component';
import { RelatedService } from '../../../../models/benefits.model';

@Component({
	selector: 'app-service-panel-related-services',
	templateUrl: './service-panel-related-services.component.html',
	styleUrls: ['./service-panel-related-services.component.scss'],
})
export class ServicePanelRelatedServicesComponent extends CommonComponent {
	@Input()
	services: Array<RelatedService>;

	@Input()
	benefitId: string;

	@Input()
	serviceName: string;

	@Input()
	userBenefitsMap: Map<string, any>;

	@Input()
	relatedBenefitClick = new EventEmitter<string>();

	telehealthServiceIds = telehealthServiceIds;

	constructor() {
		super();
	}
}
