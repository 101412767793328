import { ProvidersSearchUrlKey, Specialty } from '../../models/ribbon-data.model';
import { ProviderType, ProviderVisitsData } from '../../models/open-enrollment.model';
import { PlanSelectionTabId } from './plan-selection.enum';

export interface SpendingAccount {
	planId: string;
	contribution: number;
}

export type SpendingAccountPlanType = 'fsaPlan' | 'hsaPlan' | 'hsaPlanFsaAddon' | 'fsaDependentCarePlan';

export type SpendingAccountSelection = Partial<Record<SpendingAccountPlanType, SpendingAccount | null>>;

export interface ProviderSelection {
	urlKey: ProvidersSearchUrlKey;
	urlId: string;
	npi: string;
	insurances: string[];
	name: string;
	gender?: string;
	isMyDoctor: ProviderVisitsData;
	isSpouseDoctor: ProviderVisitsData;
	isDependentsDoctor: ProviderVisitsData;
	specialties: Specialty[];
	providerType: ProviderType;
	degrees: string[];
	is_pcp?: boolean;
	locations?: any;
	location?: string;
}

export interface ProvidersGroupedByCoverage {
	inNetwork: ProviderSelection[];
	outNetwork: ProviderSelection[];
}

export interface PlanSelectionTab {
	id: PlanSelectionTabId;
	label: string;
	isEnabled: () => boolean;
}

export interface RibbonSearchOptions {
	entity: RibbonSearchEntity;
	parameter: any;
}

export enum RibbonSearchEntity {
	Npis = 'Npis',
	Npi = 'Npi',
	Uuids = 'Uuids',
	Uuid = 'Uuid',
}