import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, catchError, filter, take, map, tap, switchMap } from 'rxjs';

import CFG from '../config/app-config.json';
import { isArray } from '../utils/is/is-array';
import { ChatData, ChatMessage } from '../models/chat.model';
import { ChatResponse, ChatResponseWithUserInput } from '../models/chat-response.model';

import { UserService } from './user.service';
import { StorageService } from './storage.service';
import { IntercomConversationResponse, IntercomService } from './intercom.service';
import { ConversationsApiService } from './api/conversations-api/conversations-api.service';
import { UIService } from './ui.service';

export interface ChatSession {
	messages: Array<ChatMessage>;
	sessionId: string;
	chatStart: Date;
}
export interface SessionStart {
	session: any;
	welcomeMessage: any;
}

export enum ConversationBot {
	Staging = 'staging',
	Prod = 'production',
	Dev = 'dev',
}

const INITIAL_CHAT_MSG_OBJ = () => {
	return { messages: [], sessionId: null, chatStart: new Date() };
};

@Injectable({
	providedIn: 'root',
})
export class ConversationService {
	private _entitiesNames$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);

	private _conversation$: BehaviorSubject<ChatSession> = new BehaviorSubject<ChatSession>(INITIAL_CHAT_MSG_OBJ());
	private _intercom_conversation$: BehaviorSubject<ChatSession> = new BehaviorSubject<ChatSession>(
		INITIAL_CHAT_MSG_OBJ()
	);
	private currentSessionId: string = null;
	public responseFromZoe$: BehaviorSubject<any> = new BehaviorSubject<any>({});

	public isHistoryLoaded = false;

	public isSessionInitialized = false;

	constructor(
		private http: HttpClient,
		private userService: UserService,
		private storageService: StorageService,
		private intercomService: IntercomService,
		private conversationsApiService: ConversationsApiService,
		private uiService: UIService
	) {
		this.userService.user$.pipe(filter((user) => !user)).subscribe(() => this.resetConversation());
	}

	get conversation$() {
		return this._conversation$.asObservable();
	}

	get intercom_conversation$() {
		return this._intercom_conversation$.asObservable();
	}

	get allEntitiesNames$() {
		return this._entitiesNames$.asObservable();
	}

	startSession(botRBValue: ConversationBot): Observable<SessionStart> {
		if (this.currentSessionId) return;

		return this.http.post(CFG.apiEndpoints.initiateNewSession, { botRBValue }).pipe(
			tap((res: any) => {
				this.currentSessionId = res.session.session_id;

				this._conversation$.next({
					messages: this._conversation$.value.messages || [],
					sessionId: res.session.session_id,
					chatStart: new Date(),
				});
				return res;
			})
		);
	}

	pushChatMessageToConversation(message: ChatMessage): void {
		this.conversation$.pipe(take(1)).subscribe((conversation) => {
			conversation.messages.push(message);
			this._conversation$.next(conversation);
		});
		this.intercom_conversation$.pipe(take(1)).subscribe((conversation) => {
			conversation.messages.push(message);
			this._intercom_conversation$.next(conversation);
		});
	}

	clearIntercomConversation() {
		this._intercom_conversation$.next(INITIAL_CHAT_MSG_OBJ());
	}

	sendMessageData(data: ChatData, botRBValue: ConversationBot): Observable<ChatResponseWithUserInput> {
		const msg = {
			session_id: this.currentSessionId,
			return_context: true,
			data: data,
			message_type: 'text',
			botRBValue,
		};
		return this.http.post<ChatResponse>(CFG.apiEndpoints.sendNewMessage, msg)
			.pipe(map((response: ChatResponse) => ({ ...response, userInput: data.message })))
	}

	history() {
		return this.conversationsApiService.conversations({ limit: 1000 }).pipe(take(1));
	}

	public resetConversation() {
		this.currentSessionId = null;
		this.isSessionInitialized = false;
		this._conversation$.next(INITIAL_CHAT_MSG_OBJ());
	}

	public openChooseFromList(): void {
		const entitiesNames = this.storageService.getChooseFromListEntities();
		entitiesNames ? this._entitiesNames$.next(entitiesNames) : this.fetchAndPublishEntityNames();
	}

	public fetchAndPublishEntityNames(): void {
		this.fetchEntitiesNames().subscribe((entityNamesArray: string[]) => {
			this._entitiesNames$.next(entityNamesArray),
				this.storageService.setChooseFromListEntities(entityNamesArray);
		});
	}

	private fetchEntitiesNames(): Observable<string[]> {
		const url = CFG.apiEndpoints.allEntitiesNames;

		return this.http.get<string[]>(url).pipe(
			catchError((errorRes) => {
				console.log('Error loading entity names from server', errorRes);
				return of(null);
			})
		);
	}

	public setResponseFromZoe(response): void {
		this.responseFromZoe$.next(response);
	}

	public escalateChatToLiveExpert(conversationId: string, userMessage: string)
		: Observable<IntercomConversationResponse> {
		return this.intercom_conversation$.pipe(
			take(1),
			map((conversation: ChatSession) => conversation.messages),
			map((messages: Array<ChatMessage>) => this.summarizeConversation(messages)),
			switchMap((minimizedMessagesArray: Array<string>) => {
				const zoeWelcomeResponse = this.intercomService.zoeWelcomeMessageOnIntercom;
				const isConversationUpdate = !!conversationId;

				const chatHistoryHeader = 'Triggered by <strong>Chat with Zoe</strong>'
					+ (isConversationUpdate ? ' (update)<br>' : '<br>');
				const chatHistoryBody = minimizedMessagesArray.join('<br>');
				const chatHistory = chatHistoryHeader + chatHistoryBody;

				// If escalating for the 2nd time (and on) within the same user-Zoe chat,
				// the message history should be sent again for our CX to have a better
				// understanding of the escalation context and what the user is looking for.
				return isConversationUpdate
					? this.intercomService.updateIntercomConversation(
							conversationId,
							userMessage,
							zoeWelcomeResponse,
							chatHistory
					  )
					: this.intercomService.initiateIntercomConversation(
							userMessage,
							zoeWelcomeResponse,
							chatHistory
					  );
			}),
			catchError((error) => {
				console.log('Failed ot create/update intercom conversation. ', error);
				this.uiService.displayAppMessage(`Couldn't start a conversation with a live expert. Please try again later.`);
				return of({ conversationId: null });
			}),
		);
	}

	public summarizeConversation(messagesArray: Array<ChatMessage>): string[] {
		if (!isArray(messagesArray)) return;

		const minimizedMessages: string[] = [];

		messagesArray.forEach((msg: ChatMessage) => {
			if (!msg) return;

			if (msg.owner === 'user') {
				this.summarizeMessagesFromUser(msg, minimizedMessages);
			} else {
				this.summarizeMessagesFromBot(msg, minimizedMessages);
			}
		});

		return minimizedMessages;
	}

	private summarizeMessagesFromUser(msg: ChatMessage, minimizedMessages: string[]) {
		const msgCreatedDate = new Date(msg.created);

		minimizedMessages.push(`<br><u><strong>User</strong> <i>${msgCreatedDate.toUTCString()}</i></u>:`);
		minimizedMessages.push(msg.text.changingThisBreaksApplicationSecurity);
	}

	private summarizeMessagesFromBot(msg: ChatMessage, minimizedMessages: string[]) {
		switch (msg.type) {
			case 'avatar': {
				const msgCreatedDate = new Date(msg.created);
				minimizedMessages.push(`<br><u><strong>Zoe</strong> <i>${msgCreatedDate.toUTCString()}</i></u>:`);
				break;
			}

			case 'text': {
				const content = msg.text.changingThisBreaksApplicationSecurity;
				const html = this.parseHtml(content);
				minimizedMessages.push(html);
				break;
			}

			case 'option': {
				const content = 'Options: ' + this.summarizeOptions(msg.options.map((option) => option.label));
				const html = this.parseHtml(content);
				minimizedMessages.push(html);
				break;
			}

			case 'get_data_medical':
			case 'get_data':
				if (msg.related[0]) {
					const content = 'Related: ' + this.summarizeRelated(msg.related.map((option) => option.title));
					const html = this.parseHtml(content);
					minimizedMessages.push(html);
				}

				if (msg.inNetwork) {
					const content = this.summarizeCoverage(
						'inNetwork',
						msg.inNetwork,
						msg.inNetworkSubjectToDeductibles
					);
					const html = this.parseHtml(content);
					minimizedMessages.push(html);
				}

				if (msg.outNetwork) {
					const content = this.summarizeCoverage(
						'outNetwork',
						msg.outNetwork,
						msg.outNetworkSubjectToDeductibles
					);
					const html = this.parseHtml(content);
					minimizedMessages.push(html);
				}

				if (msg.additionalNetworks) {
					for (const [index, additionalNetwork] of Object.entries(msg.additionalNetworks)) {
						if (!additionalNetwork.value) return; // prevent logging of additionalNetwork without a value (for some reason they also appear under 'get_data' without a value)
						const content = this.summarizeAdditionalNetwork(additionalNetwork);
						const html = this.parseHtml(content);
						minimizedMessages.push(html);
					}
				}
				break;
		}

		if (msg.isCovered) {
			minimizedMessages.push('You have coverage!');
		}
	}

	private summarizeOptions(strings: Array<string>) {
		if (!strings) return '';

		return strings.map((str) => `(${str})`).join(', ');
	}

	private summarizeRelated(strings: Array<string>) {
		if (!strings) return '';

		return strings.map((str) => `[${str}]`).join(', ');
	}

	private summarizeCoverage(title: string, coverageText: string, subjectToDeductibles: boolean) {
		return `{ ${title}: Coverage: ${coverageText}, Deductibles? ${subjectToDeductibles ? 'Yes' : 'No'} }`;
	}

	private summarizeAdditionalNetwork(additionalNetwork: {
		name: string;
		subjectToDeductible: boolean;
		value: string;
	}) {
		return `{ ${additionalNetwork.name}: Value: ${additionalNetwork.value}, Deductibles? ${
			additionalNetwork.subjectToDeductible ? 'Yes' : 'No'
		} }`;
	}

	private parseHtml(content: string) {
		return new DOMParser().parseFromString(content, 'text/html').documentElement.textContent;
	}
}
