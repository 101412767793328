/**
 * Deductible string should have:
 * 1. number (mandatory)
 * 2. dollar sign prefix (optional)
 * 3. comma (optional)
 * 4. dots (optional)
 */
export function isValidDeductible(deductible: string): boolean {
	return /\$?\d+(\.\d{1,2})?/.test(deductible.trim());
}

export function transformDeductible(value: string): string {
	if (!value) return '';
	return isValidDeductible(value) ? value.trim() : '';
}
