import { Component, OnInit } from '@angular/core';
import { HealtheeDialogContent } from 'src/app/modules/app-shared/healthee-dialog/healthee-dialog.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TrackingService } from 'src/app/services/tracking.service';
import { Rating } from 'src/app/modules/standalone/feedback/feedback-emotions/feedback-emotions.component';
import { FeedbackLocalize } from 'src/app/modules/standalone/feedback/feedback-localize';
import { ReportService } from '../../../../services/report.service';
import { tap } from 'rxjs';

@Component({
	selector: 'app-feedback-dialog',
	templateUrl: './feedback-dialog.component.html',
	styleUrls: ['./feedback-dialog.component.scss'],
})
export class FeedbackDialogComponent extends HealtheeDialogContent implements OnInit {
	public selectedRating: Rating = null;
	public feedbackForm: FormGroup;

	constructor(
		private formBuilder: FormBuilder,
		private reportService: ReportService,
		private trackingService: TrackingService,
		private matSnackBar: MatSnackBar
	) {
		super();
	}

	ngOnInit(): void {
		this.buildFeedbackForm();
		this.selectedRating = this.data?.rating ?? null;
	}

	private buildFeedbackForm() {
		this.feedbackForm = this.formBuilder.group({
			rating: [this.data?.rating, [Validators.required]],
			description: [''],
		});
	}

	onRatingIconSelected(rating: Rating) {
		this.feedbackForm.controls.rating.setValue(rating);
		this.selectedRating = rating;
	}

	public onSubmitFeedbackForm() {
		this.reportService.reportUserFeedback({
			rating: this.feedbackForm.controls.rating.value,
			feature: this.data.feature,
			data: this.data.data,
			description: this.feedbackForm.controls.description.value,
		}).pipe(
			tap(() => {
				this.confirmDialog.emit(true);
				this.matSnackBar.open(FeedbackLocalize.feedbackSentMessage, 'X', {
					duration: 5000,
					panelClass: 'snackbar-success',
					verticalPosition: 'top',
				});

				this.trackingService.trackClientEvent('Feedback Dialog Add Comment', {
					feature: this.data.feature,
					comment: this.feedbackForm.controls.description.value,
					rating: this.feedbackForm.controls.rating.value,
					extraProperties: this.data.logData,
				});
			})
		).subscribe();
	}
}
