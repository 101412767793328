import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AppointmentDrawerService {
	private drawerState = new BehaviorSubject<boolean>(false);
	public drawerState$ = this.drawerState.asObservable();

	constructor() {}

	toggleDrawer(open: boolean) {
		this.drawerState.next(open);
	}
}
