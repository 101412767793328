<div class="container" *ngIf="{ selectedLanguage: selectedLanguage$ | async } as asyncData">
	<mat-button-toggle-group
		*ngIf="asyncData.selectedLanguage as selectedLanguage"
		id="btns-container"
		name="fontStyle"
		aria-label="Font Style"
		vertical="true"
	>
		<mat-button-toggle
			*ngFor="let lang of supportedLanguages$ | async"
			[ngClass]="{ 'text-blue': selectedLanguage.locale === lang?.locale }"
			class="btn-container"
			value="bold"
			(click)="setLanguage(lang?.locale)"
		>
			{{ lang?.displayName }}

			<span *ngIf="selectedLanguage.locale === lang?.locale" class="selected-icon">
				<img src="../../../../assets/images/icons/check-blue.svg" alt="selected" />
			</span>
		</mat-button-toggle>
	</mat-button-toggle-group>
</div>
