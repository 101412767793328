<div class="card-container">
	<div class="header" [ngStyle]="{ 'background-color': backgroundColor }">
		<div class="shadow" [ngSwitch]="resolvedPlanType">
			<ng-container *ngSwitchCase="PlanType.medical">{{ 'Medical plan' | translate }}</ng-container>
			<ng-container *ngSwitchCase="PlanType.dental">{{ 'Dental plan' | translate }}</ng-container>
			<ng-container *ngSwitchCase="PlanType.vision">{{ 'Vision plan' | translate }}</ng-container>
			<ng-container *ngSwitchDefault>{{ 'Plan' | translate }}</ng-container>
		</div>
		<img *ngIf="logo" [src]="logo" />
	</div>
	<div class="content">
		<ng-content select="[content]"></ng-content>
	</div>
</div>
