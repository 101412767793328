import { Injectable } from '@angular/core';
import { Store } from '../../../utils/store/store';
import { AllBenefitsResponse } from '../../api/user-api/helpers/all-benefits.response';
import { UserApiService } from '../../api/user-api/user-api.service';
import { map, Observable, shareReplay, switchMap } from 'rxjs';
import { Maybe } from '../../../utils/types/maybe';
import { isNil } from '../../../utils/is/is-nil';
import { isArray } from '../../../utils/is/is-array';
import { getPath } from '../../../utils/get-path';
import { RxjsUtils } from 'src/app/utils/rxjs';
import { UserPlanDataStoreService } from 'src/app/services/stores/user-plan-data-store/user-plan-data-store.service';

@Injectable({
	providedIn: 'root',
})
export class UserBenefitsStoreService extends Store<string, AllBenefitsResponse> {
	constructor(
		private userApiService: UserApiService,
		private readonly userPlanDataStoreService: UserPlanDataStoreService
	) {
		super();
	}

	protected retrieve(): Observable<Maybe<AllBenefitsResponse>> {
		return this.userApiService.allBenefits();
	}

	benefitsMap(userId: string): Observable<Map<string, any>> {
		return this.userPlanDataStoreService.hasContract().pipe(
			RxjsUtils.isNotNil(),
			switchMap(() =>
				// TODO: add exact type
				this.get(userId).pipe(
					RxjsUtils.isNotNil(),
					map((planData) => {
						const benefitsMap = new Map<string, any>();

						this.indexBenefits(benefitsMap, getPath<any[]>(planData, 'medical'));
						this.indexBenefits(benefitsMap, getPath<any[]>(planData, 'dental'));
						this.indexBenefits(benefitsMap, getPath<any[]>(planData, 'vision'));
						(getPath<any[]>(planData, 'esp') || []).forEach((data) => this.indexBenefits(benefitsMap, data));

						return benefitsMap;
					}),
					shareReplay(1)
				)
			)
		);
	}

	private indexBenefits(benefitMap: Map<string, any>, benefitsList: any[]) {
		if (isNil(benefitsList) || !isArray(benefitsList)) {
			return;
		}
		benefitsList.forEach((benefit: any) => benefitMap.set(benefit.benefit, benefit));
	}
}
