import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs';
import { HealtheeDialogContent } from 'src/app/modules/app-shared/healthee-dialog/healthee-dialog.component';
import { OpenEnrollmentData, PlanSelectionService } from 'src/app/services/plan-selection.service';
import {
	PlanSelectionData,
	PlanSelectionStoreService,
} from 'src/app/services/stores/plan-selection-store/plan-selection-store.service';
import { RxjsUtils } from 'src/app/utils/rxjs';

@Component({
	selector: 'app-redirect-to-complete-enrollment',
	templateUrl: './redirect-to-complete-enrollment.component.html',
	styleUrls: ['./redirect-to-complete-enrollment.component.scss'],
})
export class RedirectToCompleteEnrollmentComponent extends HealtheeDialogContent implements OnInit {
	public enrollmentCompletionText: string;

	constructor(private planSelectionStoreService: PlanSelectionStoreService) {
		super();
	}

	ngOnInit(): void {
		this.getEnrollmentCompletion();
	}

	public onConfirm(): void {
		this.confirmDialog.emit(true);
	}

	public onCancel(): void {
		this.closeDialog.emit();
	}

	private getEnrollmentCompletion(): void {
		this.planSelectionStoreService
			.get()
			.pipe(RxjsUtils.isNotNil(), take(1))
			.subscribe((planSelectionData: PlanSelectionData) => {
				this.enrollmentCompletionText = planSelectionData.companyData?.customText?.enrollmentCompletion;
			});
	}
}
