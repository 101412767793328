<div class="top-rated-porivders-container">
	<div class="header-row">
		<div class="left-column">
			<h2 class="title">Primary care providers near</h2>
			<span class="subtitle">{{ isLocationEnabled ? 'Current Location' : defaultLocation }}</span>
			<ripple-loader
				style="display: inline-block"
				*ngIf="showLocationLoader"
				[size]="loaderSize"
				[color]="loaderColor"
			></ripple-loader>
		</div>
	</div>

	<div class="top-rated-porivders-wrapper">
		<div *ngIf="showLocationLoader || (isLoading$ | async)" class="top-rated-porivders-list-wrapper">
			<div class="results loading-result">
				<app-provider-card-loader></app-provider-card-loader>
				<app-provider-card-loader></app-provider-card-loader>
				<app-provider-card-loader></app-provider-card-loader>
				<app-provider-card-loader></app-provider-card-loader>
			</div>
		</div>

		<div
			*ngIf="(isSuccess$ | async) && (results$ | async)?.records?.length > 0"
			class="top-rated-porivders-list-wrapper"
		>
			<button class="bt-link" (click)="onMoreInfo()">{{ 'Show all' | translate }}</button>

			<div class="results">
				<app-provider-card
					*ngFor="let providerData of (results$ | async)?.records?.slice(0, 4); let index = index"
					[routerLink]="[providersSearchUrlsService.specialistUrl, providerData.npi]"
					[providerData]="providerData"
					eventSource="Search Results card"
				>
				</app-provider-card>
			</div>
		</div>

		<div
			*ngIf="(isSuccess$ | async) && (results$ | async)?.records?.length === 0"
			class="top-rated-porivders-list-wrapper no-results"
		>
			<div class="no-results-message">
				<img src="/assets/images/providers-search/no-results.svg" alt="noresult" />
				<div class="title">{{ 'No results were found' | translate }}</div>
			</div>
		</div>
	</div>
</div>
