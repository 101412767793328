import { Component, Input } from '@angular/core';

import { PlanType } from '../../../../models/plan-type.model';
import { Maybe } from '../../../../utils/types/maybe';

@Component({
	selector: 'app-empty-card',
	templateUrl: './empty-card.component.html',
	styleUrls: ['./empty-card.component.scss'],
})
export class EmptyCardComponent {
	public PlanType = PlanType;

	@Input()
	planType: Maybe<PlanType>;
}
