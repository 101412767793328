<app-mobile-action-bar></app-mobile-action-bar>

<div class="health-profile-main-container">
	<nav mat-tab-nav-bar disableRipple disablePagination="true" mat-stretch-tabs="false" [tabPanel]="tabPanel">
		<a
			mat-tab-link
			*ngFor="let navLink of navLinks; let i = index"
			[routerLink]="navLink.link"
			(click)="setActiveTabLink(navLink.link)"
			[active]="activeTabLink === navLink.link"
		>
			{{ navLink.label | translate | titlecase }}
		</a>
	</nav>
	<mat-tab-nav-panel #tabPanel>
		<div *ngIf="activeTabLink === tabs.personalInfo">
			<app-personal-info class="fast-fadein"></app-personal-info>
		</div>
		<div *ngIf="activeTabLink === tabs.healthPlans">
			<app-talon-widget [widgetType]="talonWidgetType" class="fast-fadein"></app-talon-widget>
		</div>
		<div *ngIf="activeTabLink === tabs.medicalSpendings">
			<app-medical-spending class="fast-fadein"></app-medical-spending>
		</div>
	</mat-tab-nav-panel>
</div>
