import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { isNil } from '../../../../utils/is/is-nil';
import { TooltipComponent } from './tooltip/tooltip.component';

@Directive({
	selector: '[appTooltip]',
})
export class TooltipDirective implements OnInit, OnDestroy {
	@Input('appTooltip')
	template: TemplateRef<any>;

	private overlayRef: OverlayRef;

	@Input() tooltipWidth: number | string = 'auto';
	@Input() tooltipAutoHide: boolean = true;
	@Input() tooltipPositionX: 'start' | 'center' | 'end' = 'center';
	@Input() tooltipPositionY: 'top' | 'center' | 'bottom' = 'top';
	@Input() tooltipOffsetX: number = 0;
	@Input() tooltipOffsetY: number = 5;

	@HostListener('click')
	onClick() {
		if (this.tooltipAutoHide) {
			// if 'tooltipAutoHide', no manual open or close upon click, and the tooltip should be opened
			// or closed by mouseenter and mouseleave events. This is to keep the original tooltip behavior
			// without the ability to manualy close or open the tooltip on mouse click events
			return;
		}

		// if 'tooltipAutoHide' = false, on web the tooltip is shown first time upon 'mouseenter'. The subsequent
		// 'click' event is registered by clicking the triggering element:
		// 1. if the tooltip is visible, clicking the triggering element, should close the tooltip:
		if (this.isTooltipVisible()) {
			this.hideTooltip();
		} else {
			// 2. if the tooltip was already closed, clicking the triggering element should show the tooltip:
			this.showTooltip();
		}
	}

	@HostListener('mouseenter')
	onMouseEnter() {
		this.showTooltip();
	}

	@HostListener('mouseleave')
	onMouseLeave() {
		if (this.tooltipAutoHide)
			this.hideTooltip();
	}

	@HostListener('window:scroll')
	@HostListener('window:wheel')
	onWindowScroll() {
		// The tooltip doesn't move with scroll, so it should be closed when scrolling,
		// otherwise it'll be stuck on some irrelevant corner of the screen
		this.hideTooltip();
	}

	constructor(private elementRef: ElementRef, private overlay: Overlay) {}

	ngOnInit(): void {
		this.overlayRef = this.overlay.create({
			positionStrategy: this.overlay
				.position()
				.flexibleConnectedTo(this.elementRef)
				.withPositions([
					{
						originX: 'center',
						originY: 'bottom',
						overlayX: this.tooltipPositionX,
						overlayY: this.tooltipPositionY,
						offsetX: this.tooltipOffsetX,
						offsetY: this.tooltipOffsetY,
					},
				]),
			width: this.tooltipWidth,
		});
	}

	private showTooltip() {
		if (isNil(this.overlayRef) || this.overlayRef.hasAttached()) {
			return;
		}

		const tooltipRef = this.overlayRef.attach(new ComponentPortal(TooltipComponent));
		tooltipRef.instance.tooltip = this.template;
		tooltipRef.instance.overlayRef = this.overlayRef;
		tooltipRef.instance.autoHide = this.tooltipAutoHide;
		tooltipRef.changeDetectorRef.markForCheck();
	}

	private hideTooltip() {
		if (isNil(this.overlayRef)) {
			return;
		}
		this.overlayRef.detach();
	}

	private isTooltipVisible(): boolean {
		return this.overlayRef?.hasAttached() ?? false;
	}

	ngOnDestroy(): void {
		this.overlayRef.detach();
	}
}
