<ion-modal
	[isOpen]="isOpen"
	[breakpoints]="[0, 0.35, 0.7, 1]"
	[initialBreakpoint]="0.35"
	[backdropDismiss]="true"
	(didDismiss)="dismissMenu()"
>
	<ng-template>
		<ion-header translucent>
			<ion-toolbar>
				<ion-title>Developer Menu</ion-title>

				<ion-buttons slot="end">
					<ion-button (click)="dismissMenu()">Close</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>

		<ion-content fullscreen>
			<ion-list>
				<ion-item>
					<ion-icon class="item-icon" slot="start" name="cog-outline"></ion-icon>
					<ion-label>
						<h2>App info</h2>
						<h3 *ngIf="appInfo">App name: {{ appInfo.name }}</h3>
						<p *ngIf="appInfo; else notANativeBuild">
							Native buildId: {{ appInfo.id }} <br>
							Native version: {{ appInfo.version }} build: {{ appInfo.build }} <br>
						</p>
						<ng-template #notANativeBuild>
							<p>Build info unavailable.</p>
						</ng-template>

						<p *ngIf="appFlowInfo; else noAppFlowInfo">
							AppFlow buildId: {{ appFlowInfo.buildId }}
							AppFlow snapshotId: {{ appFlowInfo.id }}
						</p>
						<ng-template #noAppFlowInfo>
							<p>AppFlow info unavailable.</p>
						</ng-template>

						<h3>
							Environment
							<ion-badge *ngIf="environment.production" color="danger">production</ion-badge>
						</h3>

						<p>name: {{ environment.envName }} ({{ environment.environmentType }})</p>
						<p>host: {{ host }}</p>
					</ion-label>
				</ion-item>

				<!-- FullStory asset map id -->
				<ion-item>
					<ion-icon class="item-icon" slot="start" name="receipt-outline"></ion-icon>
					<ion-label>
						<h3 class="clickable" (click)="copyFullStoryAssetMapToClipboard()">FullStory asset-map id (tap to copy)</h3>
						<p class="item-text">
							{{ fullStoryAssetMapId }}
						</p>
					</ion-label>
				</ion-item>

				<!-- FullStory session url -->
				<ion-item>
					<ion-icon class="item-icon" slot="start" name="link-outline"></ion-icon>
					<ion-label>
						<h3 class="clickable" (click)="copyFullStorySessionUrlToClipboard()">FullStory session URL (tap to copy)</h3>
						<p class="item-text">
							{{ fullStorySessionUrl }}
						</p>
					</ion-label>
				</ion-item>

				<ion-item>
					<ion-icon class="item-icon clickable" slot="start" name="trash"></ion-icon>
					<ion-label>
						<h3 class="clickable" (click)="clearBioAuthCacheData()">Clear bio auth saved data</h3>
						<p class="item-text">
							Click here to clear bio auth data, as
							<br />if you're logging to the app for the first time. <br />BioAuthData: {{ bioAuthData }}
							<span (click)="updateBioAuthSettings()">update</span>
						</p>
					</ion-label>
				</ion-item>

				<ion-item (click)="dismissMenuAndLockDevMode()">
					<ion-icon class="item-icon clickable" slot="start" name="close-circle-outline"></ion-icon>
					<ion-label>
						<h3 class="clickable">Close developer mode</h3>
						<p class="item-text">
							Click here to deactivate developer mode.<br />Developer mode is activated. When unlocked,
							this menu is accessible by clicking the version label without entering a password.
						</p>
					</ion-label>
				</ion-item>

				<ion-item (click)="clearZocdocTokenFromLocalStorage()">
					<ion-icon class="item-icon clickable" slot="start" name="trash"></ion-icon>
					<ion-label>
						<h3 class="clickable">Clear ZocDoc Auth Token</h3>
						<p class="item-text">
							Click here to clear Zocdoc's auth token.<br />
							Use to develop and inspect the Zozdoc auth flow.
						</p>
					</ion-label>
				</ion-item>

				<ion-item (click)="openOnDeviceLogs()">
					<ion-icon class="item-icon clickable" slot="start" name="list"></ion-icon>
					<ion-label>
						<h3 class="clickable">On-device logs</h3>
						<p class="item-text">
							Open on device logs.<br />
						</p>
					</ion-label>
				</ion-item>

			</ion-list>
		</ion-content>
	</ng-template>
</ion-modal>
