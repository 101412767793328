import { Component, EventEmitter, Output } from '@angular/core';
import { RxjsUtils } from 'src/app/utils/rxjs';
import { RibbonEntitiesStoreService } from 'src/app/services/stores/ribbon-entities-store/ha-ribbon-entities-store.service';
import { TrackingService } from 'src/app/services/tracking.service';
import { take } from 'rxjs';
import { HealtheeService } from 'src/app/models/service.model';
import { T } from '@transifex/angular';

@Component({
	selector: 'app-explore-top-searches',
	templateUrl: './explore-top-searches.component.html',
	styleUrls: ['./explore-top-searches.component.scss'],
})
export class ExploreTopSearchesComponent {
	private healthServices;
	private searchByNeedHealthServices;

	@T('Primary Care Provider - PCP')
	private primaryCare: string;
	@T('Psychologist')
	private psychologist: string;
	@T('Gynecology')
	private gynecology: string;
	@T('Dermatology')
	private dermatology: string;
	@T('Physical Therapy')
	private physicalTherapy: string;
	@T('Urgent Care')
	private urgentCare: string;

	public topSearchedServices = [
		{
			ribbonId: '',
			ribbonName: 'Primary Care Provider - PCP',
			text: '',
		},
		{
			ribbonId: '454ede9e-c865-48c9-8def-3cc473849658',
			ribbonName: 'Physical Therapy',
			text: '',
		},
		{
			ribbonId: '5a8f8611-8993-444f-b54e-85eb1a87cf5a',
			ribbonName: 'Urgent Care',
			text: '',
		},
		{
			ribbonId: 'd8f7fff0-9c5b-42e2-b011-376315d4be3d',
			ribbonName: 'Obstetrics and Gynecology - Gynecology',
			text: '',
		},
		{
			ribbonId: 'b9dc44e1-6add-41f8-8df4-a8ef9cea706a',
			ribbonName: 'Dermatology',
			text: '',
		},
		{
			ribbonId: 'adfb5cae-37f0-4472-a92f-5448c0ff3d66',
			ribbonName: 'Psychologist',
			text: '',
		},
	];

	@Output() public setTopServiceSelected = new EventEmitter<HealtheeService>(null);

	constructor(
		private ribbonEntitiesStoreService: RibbonEntitiesStoreService,
		private trackingService: TrackingService
	) {}

	ngOnInit(): void {
		this.ribbonEntitiesStoreService
			.getHealthServices()
			.pipe(RxjsUtils.isNotNil(), take(1))
			.subscribe((services) => (this.healthServices = services));

		this.ribbonEntitiesStoreService
			.getSearchByNeeds()
			.pipe(RxjsUtils.isNotNil(), take(1))
			.subscribe((services) => (this.searchByNeedHealthServices = services));

		this.initiateTranslations();
	}

	private initiateTranslations() {
		this.topSearchedServices[0].text = this.primaryCare;
		this.topSearchedServices[1].text = this.physicalTherapy;
		this.topSearchedServices[2].text = this.urgentCare;
		this.topSearchedServices[3].text = this.gynecology;
		this.topSearchedServices[4].text = this.dermatology;
		this.topSearchedServices[5].text = this.psychologist;
	}

	public onClick(service) {
		this.trackingService.trackClientEvent('[PS][No Result] - Explore top searches', {
			speciality_type: `${service.text}`,
		});

		const searchByneedResult = this.searchByNeedHealthServices.find(
			(healthService) => healthService.text === service.ribbonName
		);

		const selected =
			searchByneedResult ||
			this.healthServices.find((healthService) => healthService.ribbon.id === service.ribbonId);

		if (!selected) {
			return;
		}

		this.setTopServiceSelected.emit(selected);
	}
}
