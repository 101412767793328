<div class="recompare-modal-container">
	<div class="instruction">
		{{ 'Note! Recomparing your health insurance plans will delete previous information.' | translate }}
	</div>
	<div class="instruction">
		{{ 'This will not affect any selections made on the Benefits Administration Platform. ' | translate }}
	</div>
	<mat-divider></mat-divider>
	<div class="actions">
		<button class="cancel" (click)="onCancel()">{{ 'Cancel' | translate }}</button>
		<button class="bt primary-blue" (click)="onConfirm()">{{ 'Start Over' | translate }}</button>
	</div>
</div>
