import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';

import { UserService } from '../../user.service';
import { StoreApi } from '../../../utils/store/store-api';
import { StoreUpdateRegistryService } from '../../stores/store-update-registry.service';
import { FavoriteProvidersStoreToken } from '../../stores/favorite-providers-store/favorite-providers-store.token';
import { FavoriteProvider } from '../../../modules/my-care-team/helpers/providers.helpers';

const GET_FAVORITE_PROVIDERS_URL = '/myCareTeam/favoriteProviders';
const ADD_TO_FAVORITES_URL = '/myCareTeam/add';
const REMOVE_FROM_FAVORITES_URL = '/myCareTeam/remove';

@Injectable({
	providedIn: 'root',
})
export class FavoriteProvidersApiService extends StoreApi {
	constructor(
		private http: HttpClient,
		private userService: UserService,
		private storeUpdateRegistryService: StoreUpdateRegistryService
	) {
		super(storeUpdateRegistryService);
	}

	getFavoriteProviders(): Observable<FavoriteProvider[]> {
		return this.userService.user$.pipe(
			switchMap((user) => this.http.get<FavoriteProvider[]>(`${GET_FAVORITE_PROVIDERS_URL}/${user.data.uid}`))
		);
	}

	addToFavorites(provider: Partial<FavoriteProvider>): Observable<void> {
		return this.http
			.post<void>(ADD_TO_FAVORITES_URL, { provider })
			.pipe(this.withStoreUpdate(FavoriteProvidersStoreToken, undefined));
	}

	removeFromFavorites(npi: string): Observable<void> {
		return this.http
			.put<void>(REMOVE_FROM_FAVORITES_URL, { providerNpi: npi })
			.pipe(this.withStoreUpdate(FavoriteProvidersStoreToken, undefined));
	}
}
