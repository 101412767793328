<app-search-results-base-card [appearance]="appearance">
	<div avatar></div>
	<ng-container title>
		{{ facility.name }}
	</ng-container>
	<ng-container subTitle>
		{{ facility.locationTypes[0] | uppercase }}
	</ng-container>

	<div class="body" body>
		<div class="d-flex justify-content-center" *ngIf="facility.distance">
			<img src="assets/images/provider-card/provider-card-location-pin.svg" alt="Distance" />
			<span>{{ facility.distance }} {{ 'miles' | translate }}</span>
		</div>

		<ng-container *ngIf="appearance === 'standard'">
			<div class="network-wrapper">
				<div class="radius-circle icon-size-8 background-success ml-4 mr-4"></div>
				<div>({{ facility.networkContract | titlecase }} Plan)</div>
			</div>
		</ng-container>
	</div>

	<div footer class="footer">
		<ng-container *ngIf="facility.phoneNumbers?.[0]">
			<button
				*ngIf="!isMobileScreen; else mobilePhoneNumber"
				class="healthee-btn sm primary"
				(click)="onCallClick($event)"
				[matTooltip]="facility.phoneNumbers[0]"
				[matTooltipDisabled]="true"
				#tooltip="matTooltip"
				(mouseover)="showTooltip(tooltip)"
			>
				<ripple-icon name="phone"></ripple-icon>
				{{ 'Call' | translate }}
			</button>
			<ng-template #mobilePhoneNumber>
				<a
					[href]="'tel:' + facility.phoneNumbers[0]"
					class="bt call primary-blue selectable"
					(click)="$event.stopPropagation()"
					target="_top"
				>
					<ripple-icon name="phone"></ripple-icon>
					{{ facility.phoneNumbers[0] }}
				</a>
			</ng-template>
		</ng-container>
	</div>
</app-search-results-base-card>
