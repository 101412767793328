import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Benefit, NewBenefit, UserBenefits } from '../../../models/benefits.model';

@Injectable({
	providedIn: 'root',
})
export class GalleryApiService {
	constructor(private httpClient: HttpClient) {}

	service(serviceId: string) {
		return this.httpClient.get<Benefit>(`/gallery/service/${serviceId}`);
	}

	getNewBenefit(contractId: string, oldBenefitId: string) {
		return this.httpClient.get<NewBenefit>(`/coverageInfo?contractId=${contractId}&oldBenefitId=${oldBenefitId}`);
	}

	getNewBenefitsOfUser() {
		return this.httpClient.get<UserBenefits>(`/coverageInfo/userBenefits`);
	}
}
