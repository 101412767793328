import {
	ProvidersSearchPageComponent
} from 'src/app/modules/main-layout/care-costs/providers-search-page/providers-search-page.component';
import {
	ProvidersSearchResultsPageComponent
} from 'src/app/modules/main-layout/care-costs/providers-search-results-page/providers-search-results-page.component';
import {
	ProviderDetailsPageComponent
} from 'src/app/modules/main-layout/care-costs/provider-details-page/provider-details-page.component';
import {
	FacilityDetailsPageComponent
} from 'src/app/modules/main-layout/care-costs/facility-details-page/facility-details-page.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{
		path: '',
		component: ProvidersSearchPageComponent,
	},
	{
		path: 'results',
		component: ProvidersSearchResultsPageComponent,
		data: { title: 'care_and_costs' },
	},
	{
		path: 'specialist/:npi',
		component: ProviderDetailsPageComponent,
		data: { title: 'care_and_costs' },
	},
	{
		path: 'facility/:uuid',
		component: FacilityDetailsPageComponent,
		data: { title: 'care_and_costs' },
	},
	{
		path: '**',
		redirectTo: '',
		pathMatch: 'prefix',
	},
];


@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class ProvidersSearchPageRoutingModule {}
