import { Component } from '@angular/core';
import { FfNewBrandLogo } from '../../../config/feature-flags/ff-new-brand-logo';
import { UnleashService } from 'src/app/services/unleash.service';

@Component({
	selector: 'app-onboarding-layout',
	templateUrl: './onboarding-layout.component.html',
	styleUrls: ['./onboarding-layout.component.scss'],
})
export class OnboardingLayoutComponent {
	isNewBrandLogoEnabled$ = this.unleashService.isEnabled$(FfNewBrandLogo);

	constructor(private unleashService: UnleashService) {}
}
