import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UserPlanDataStoreService } from './stores/user-plan-data-store/user-plan-data-store.service';

@Injectable({
	providedIn: 'root',
})
export class ExternalApiService {
	constructor(private userPlanDataStoreService: UserPlanDataStoreService) {
	}

	public isUserSupportedByTalon(): Observable<boolean> {
		return this.userPlanDataStoreService.get().pipe(
			take(1),
			map((planData) => {
				return planData?.contract?.isTalonSupported;
			})
		);
	}
}
