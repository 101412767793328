<div class="information-panel">
	<div class="information-panel-wrapper">
		<div class="information-icon">
			<ripple-icon [size]="ICON_SIZE.size40" name="laptop"></ripple-icon>
		</div>
		<div class="information-texts">
			<p class="highlighted">{{ 'Eligible for Free Telehealth 24/7' | translate }}</p>
			<p>{{ 'Connect with a provider now using Free Telehealth 24/7' | translate }}</p>
		</div>
	</div>

	<div class="actions-wrapper">
		<ripple-button
			(onClick)="onTeaserClick('Doctor')"
			hierarchy="secondary"
			leadingIcon="video"
			[label]="'Talk to a Doctor' | translate"
			class="action doctor-btn"
		></ripple-button>
	</div>
</div>
