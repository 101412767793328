import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
	Coverage,
	FacilityDetails,
	normalizeFacility,
	RibbonFacility,
} from '../../../modules/main-layout/care-costs/helpers/providers.helpers';
import { StoreApi } from '../../../utils/store/store-api';
import { StoreUpdateRegistryService } from '../../stores/store-update-registry.service';
import { SearchResponse } from './helpers/providers-api.interface';

const FACILITIES_API = '/api/ribbon/facilities';

@Injectable({
	providedIn: 'root',
})
export class FacilitiesApiService extends StoreApi {
	constructor(private httpClient: HttpClient, private storeUpdateRegistryService: StoreUpdateRegistryService) {
		super(storeUpdateRegistryService);
	}

	facilitiesPaginated(formData: any): Observable<SearchResponse<RibbonFacility>> {
		const params = new HttpParams({
			fromObject: {
				...formData,
			},
		});

		return this.httpClient.get<SearchResponse<RibbonFacility>>(FACILITIES_API, { params });
	}

	facilityByUuid(uuid: string): Observable<FacilityDetails> {
		return this.httpClient
			.get<{ facility: RibbonFacility } & Coverage>(`${FACILITIES_API}/${uuid}`)
			.pipe(map((response) => ({ ...normalizeFacility(response.facility), isInNetwork: response.isInNetwork, networkContract: response.networkContract })));
	}
}
