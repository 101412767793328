import { Injectable } from '@angular/core';
import { T } from '@transifex/angular';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError, TimeoutError } from 'rxjs';
import { timeout, catchError } from 'rxjs/operators';
import { StatusCodes } from 'http-status-codes';

import CFG from '../config/app-config.json';

import {
	ErrorObjectExtention,
	NotFoundError,
	UnauthorizedError,
	BadRequestError,
	InternalServerError,
	ConnectionTimeoutError,
	NoConnectionError,
} from '../error-handlers/app-errors';

import { UIService } from '../services/ui.service';
import { UserService } from '../services/user.service';
import { AppManagerService } from '../services/app-manager.service';

const NO_CONNECTION_STATUS = 0;
const LOGIN_TIMEOUT_STATUS = 440;
const INVALID_TOKEN_STATUS = 40144;
const ACCOUNT_BLOCKED = 40145;

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
	constructor(
		private uiService: UIService,
		private userService: UserService,
		private appManagerService: AppManagerService
	) {}

	@T('It looks like your session has expired. Please login again.')
	private userErrors_sessionExpired: string;

	@T('Ok, reaching the internet is taking too long. Please check your connection and try again.')
	private userErrors_connectionTimeout: string;

	@T("It looks like you're not connected to the internet. Please check your connection and try again.")
	private userErrors_noConnection: string;

	@T('There was something wrong with the request. Please try again or contact support.')
	private userErrors_badRequest: string;

	@T("It looks like you don't have permissions to perform this action. If you think you should have permissions, please contact Healthee's support.")
	private userErrors_connectionUnauthorized: string;

	@T("It looks like we can't find what you're looking for. Please contact Healthee's support.")
	private userErrors_resourceNotFound: string;

	@T('Oops! Something went wrong! Help us improve your experience by sending an error report')
	private userErrors_defaultError: string;

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			timeout(CFG.comm.HTTP_TIMEOUT),
			catchError((error) => this.handleErrors(error, request))
		);
	}

	/** handles only http connection errors (TimeoutError, NoConnection) on the http response, before
	 * continuing to the app. Other errors are translated to App Errors that should be handled at
	 * the service/component level. Unhandled errors result in an unknown error displayed to the user
	 * by the the global app-error handler.
	 * @param  {Error} error
	 */
	private handleErrors(error: Error, request: HttpRequest<any>) {
		const httpErrorObjectExtention = this.getHttpErrorExtensionFromRequest(request);

		if (error instanceof TimeoutError) {
			this.uiService.displayAppMessage(this.userErrors_connectionTimeout);
			return throwError(
				() => new ConnectionTimeoutError(this.userErrors_connectionTimeout, httpErrorObjectExtention, error)
			);
		}
		if (error instanceof HttpErrorResponse) {
			switch (error.status) {
				case NO_CONNECTION_STATUS:
					return throwError(
						() => new NoConnectionError(this.userErrors_noConnection, httpErrorObjectExtention, error)
					);

				case StatusCodes.BAD_REQUEST:
					return throwError(
						() => new BadRequestError(this.userErrors_badRequest, httpErrorObjectExtention, error)
					);

				case StatusCodes.UNAUTHORIZED:
					const errorCode = error.error?.code;

					if (errorCode === LOGIN_TIMEOUT_STATUS) {
						this.uiService.displayAppMessage(this.userErrors_sessionExpired);
						this.userService.logout();
					} else if (errorCode === INVALID_TOKEN_STATUS) {
						this.userService.logout();
						return this.suppressErrorAndStopErrorPropogation();
					} else if (errorCode === ACCOUNT_BLOCKED) {
						this.appManagerService.openBlockedAccountModal(error.error.message);
						this.userService.logout();
						return this.suppressErrorAndStopErrorPropogation();
					}

					return throwError(
						() =>
							new UnauthorizedError(
								this.userErrors_connectionUnauthorized,
								httpErrorObjectExtention,
								error
							)
					);

				case StatusCodes.NOT_FOUND:
					return throwError(
						() => new NotFoundError(this.userErrors_resourceNotFound, httpErrorObjectExtention, error)
					);

				case StatusCodes.INTERNAL_SERVER_ERROR:
					return throwError(
						() => new InternalServerError(this.userErrors_defaultError, httpErrorObjectExtention, error)
					);
			}
		}

		return throwError(() => error);
	}

	private getHttpErrorExtensionFromRequest(request: HttpRequest<any>): ErrorObjectExtention {
		const httpVerb = request.method;
		const url = request.url;
		const technicalMessage = `Error in request to ${httpVerb} ${url}`;

		const httpErrorObjectExtention: ErrorObjectExtention = {
			technicalMessage,
			requestVerb: request.method,
			requestHeaders: request.headers,
			requestUrl: request.url,
			requestBody: request.body,
			requestParams: request.params,
		};

		return httpErrorObjectExtention;
	}

	private suppressErrorAndStopErrorPropogation() {
		// suppress the error stream, so that it doesn't bubble up and reach
		// other parts of the app or the global-error handler, because we want
		// to end handling this error here (and in most cases logout).
		return of(null);
	}
}
