import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { TelehealthService } from 'src/app/modules/telehealth/services/telehealth.service';
import { TrackingService } from 'src/app/services/tracking.service';
import { UIService } from 'src/app/services/ui.service';

@Component({
	selector: 'app-telehealth-medical-advice',
	templateUrl: './telehealth-medical-advice.component.html',
	styleUrls: ['./telehealth-medical-advice.component.scss'],
})
export class TelehealthMedicalAdviceComponent {
	public isMobile$: Observable<boolean> = this.uiService.isMobile$;

	constructor(
		private telehealthService: TelehealthService,
		private trackingService: TrackingService,
		private uiService: UIService
	) {}

	public onTeaserClick(buttonName): void {
		this.telehealthService.onOpenTelehealth();
		this.trackingService.trackClientEvent('PS Telehealth modal start', { Action: buttonName });
	}
}
